import React from "react";
import "./ComponentStyling/PrivacyPolicy.css";
import Navbar from '../Components/Navbar.js';
import Footer from '../Components/Footer';

const PrivacyPolicy = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}>
            <Navbar />
            <main className="privacy-policy-container">
                <div className="privacy-hero">
                    <h1>Privacy Policy</h1>
                    <p className="privacy-subtitle">Last updated: {new Date().toLocaleDateString()}</p>
                    <div className="privacy-commitment">
                        <h2>Our Commitment to Your Privacy</h2>
                        <p>At PMHub.io, protecting your privacy and data is our highest priority. We believe in complete transparency 
                        about how we handle your information. We're committed to ensuring your data remains secure, private, and under 
                        your control at all times. We implement rigorous security measures and maintain strict data protection standards 
                        to safeguard your information.</p>
                    </div>
                </div>
                
                <div className="privacy-content">
                    <div className="privacy-grid">
                        {privacySections.map((section, index) => (
                            <div key={index} className="privacy-section">
                                <div className="privacy-card">
                                    <h2>{section.title}</h2>
                                    <p>{section.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

const privacySections = [
    {
        title: "Information Collection",
        content: "We collect essential information needed to provide our services, including basic account details, project information, and usage data. This helps us deliver a better, more personalized experience while maintaining your privacy."
    },
    {
        title: "Your Privacy Rights",
        content: "You have comprehensive rights over your personal information, including the right to access, modify, export, or delete your data. We ensure these rights are easily accessible and straightforward to exercise through your account settings."
    },
    {
        title: "Data Security",
        content: "We implement industry-leading security measures including encryption, access controls, and regular security assessments. Your data is protected using enterprise-grade encryption standards, with strict protocols to prevent unauthorized access."
    },
    {
        title: "Data Usage",
        content: "We use your information solely to provide and improve our services. We maintain full transparency about data handling and never sell your personal information to third parties. Your data is only used for the specific purposes you've authorized."
    },
    {
        title: "Information Sharing",
        content: "We do not sell or trade your personal information. Any sharing of data is strictly limited to what's necessary to provide our services, and we ensure all service providers adhere to our strict privacy standards."
    },
    {
        title: "Privacy Support",
        content: "Have questions about your data privacy? Contact us at privacy@projectmanagerhub.io, and we'll respond within 2 business days. Your privacy concerns matter to us, and we're committed to addressing them promptly and thoroughly."
    }
];

export default PrivacyPolicy;
