import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FEATURE_TYPES, FEATURE_STATUSES } from '../utils/constants';

const CreateFeatureSlider = ({ 
    showSlider, 
    sliderClosing, 
    closeSlider, 
    currentFeature,
    handleInputChange,
    handleSubmit
}) => {
    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Create New Feature</h2>
                </div>
                
                <div className="sliding-panel-content">
                    <div className="feature-form">
                        {/* Basic Information Section */}
                        <div className="form-section">
                            <h3 className="section-title">Basic Information</h3>
                            <div className="mb-3">
                                <label className="form-label">Feature Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={currentFeature.name || ''}
                                    onChange={(e) => handleInputChange('name', e.target.value)}
                                    required
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    value={currentFeature.description || ''}
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                    rows="3"
                                    required
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Type</label>
                                    <select
                                        className="form-select"
                                        value={currentFeature.type || 'functionality'}
                                        onChange={(e) => handleInputChange('type', e.target.value)}
                                    >
                                        {Object.entries(FEATURE_TYPES).map(([value, label]) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        value={currentFeature.status || 'Planning'}
                                        onChange={(e) => handleInputChange('status', e.target.value)}
                                    >
                                        {FEATURE_STATUSES.map(status => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Planning Section */}
                        <div className="form-section">
                            <h3 className="section-title">Planning</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Planned Start</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={currentFeature.plannedStart || ''}
                                        onChange={(e) => handleInputChange('plannedStart', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Target Completion</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={currentFeature.targetCompletion || ''}
                                        onChange={(e) => handleInputChange('targetCompletion', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <label className="form-label">Priority</label>
                                    <select
                                        className="form-select"
                                        value={currentFeature.priority || 'Medium'}
                                        onChange={(e) => handleInputChange('priority', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Risk Level</label>
                                    <select
                                        className="form-select"
                                        value={currentFeature.riskLevel || 'Low'}
                                        onChange={(e) => handleInputChange('riskLevel', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Requirements Section */}
                        <div className="form-section">
                            <h3 className="section-title d-flex justify-content-between align-items-center">
                                Requirements
                                <button 
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        const newRequirements = [
                                            ...(currentFeature.requirements || []), 
                                            { text: '', completed: false }
                                        ];
                                        handleInputChange('requirements', newRequirements);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Requirement
                                </button>
                            </h3>
                            <div className="requirements-list">
                                {currentFeature.requirements?.length > 0 ? (
                                    currentFeature.requirements.map((req, index) => (
                                        <div 
                                            key={index} 
                                            className={`success-criterion-item ${req.completed ? 'completed' : ''}`}
                                        >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={req.completed}
                                                onChange={(e) => {
                                                    const newRequirements = [...currentFeature.requirements];
                                                    newRequirements[index] = {
                                                        ...req,
                                                        completed: e.target.checked
                                                    };
                                                    handleInputChange('requirements', newRequirements);
                                                }}
                                                id={`new-requirement-${index}`}
                                            />
                                            
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={req.text}
                                                onChange={(e) => {
                                                    const newRequirements = [...currentFeature.requirements];
                                                    newRequirements[index] = {
                                                        ...req,
                                                        text: e.target.value
                                                    };
                                                    handleInputChange('requirements', newRequirements);
                                                }}
                                                placeholder="Enter requirement..."
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger btn-remove"
                                                onClick={() => {
                                                    const newRequirements = currentFeature.requirements.filter((_, i) => i !== index);
                                                    handleInputChange('requirements', newRequirements);
                                                }}
                                                title="Remove requirement"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-muted text-center py-3">
                                        No requirements defined. Add requirements to define feature scope.
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="feature-actions mt-4">
                            <button 
                                className="btn btn-success"
                                onClick={handleSubmit}
                            >
                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                Create Feature
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateFeatureSlider;
