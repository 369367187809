import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks
export const fetchJournalTasks = createAsyncThunk(
    'dailyJournal/fetchTasks',
    async ({ userId, date }) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, {
            params: { date: date, userId }
        });
        return response.data;
    }
);

export const fetchJournalEntry = createAsyncThunk(
    'dailyJournal/fetchEntry',
    async ({ userId, date }) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/journalEntries`, {
            params: { userId, date }
        });
        return response.data;
    }
);

export const saveJournalEntry = createAsyncThunk(
    'dailyJournal/saveEntry',
    async ({ entryId, content, userId, date }) => {
        if (entryId) {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/journalEntries/${entryId}`,
                { content }
            );
            return response.data;
        } else {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/journalEntries`,
                { userId, date, content }
            );
            return response.data;
        }
    }
);

const dailyJournalSlice = createSlice({
    name: 'dailyJournal',
    initialState: {
        tasks: {
            todo: [],
            inProgress: [],
            complete: []
        },
        journalEntry: {
            id: null,
            content: '',
            date: null
        },
        selectedDate: new Date().toISOString().split('T')[0],
        loading: {
            tasks: false,
            entry: false
        },
        error: null
    },
    reducers: {
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        updateTask: (state, action) => {
            const { taskId, columnId, updates } = action.payload;
            if (updates === null) {
                // Handle deletion
                state.tasks[columnId] = state.tasks[columnId].filter(task => task._id !== taskId);
            } else if (taskId === updates._id) {
                // Handle update
                const column = state.tasks[columnId];
                const taskIndex = column.findIndex(task => task._id === taskId);
                if (taskIndex !== -1) {
                    column[taskIndex] = { ...column[taskIndex], ...updates };
                } else {
                    // If task doesn't exist in the column, add it
                    column.push(updates);
                }
            } else {
                // Handle new task
                state.tasks[columnId].push(updates);
            }
        },
        moveTask: (state, action) => {
            const { taskId, fromColumn, toColumn } = action.payload;
            const task = state.tasks[fromColumn].find(t => t._id === taskId);
            if (task) {
                state.tasks[fromColumn] = state.tasks[fromColumn].filter(t => t._id !== taskId);
                state.tasks[toColumn].push({ ...task, status: toColumn });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle fetchJournalTasks
            .addCase(fetchJournalTasks.pending, (state) => {
                state.loading.tasks = true;
                state.error = null;
            })
            .addCase(fetchJournalTasks.fulfilled, (state, action) => {
                state.loading.tasks = false;
                // Group tasks by status
                const tasks = action.payload || [];
                state.tasks = {
                    todo: tasks.filter(task => task.status === 'todo'),
                    inProgress: tasks.filter(task => task.status === 'inProgress'),
                    complete: tasks.filter(task => task.status === 'complete')
                };
                console.log('Tasks updated in Redux:', state.tasks); // Debug log
            })
            .addCase(fetchJournalTasks.rejected, (state, action) => {
                state.loading.tasks = false;
                state.error = action.error.message;
            })
            // Handle fetchJournalEntry
            .addCase(fetchJournalEntry.pending, (state) => {
                state.loading.entry = true;
            })
            .addCase(fetchJournalEntry.fulfilled, (state, action) => {
                state.loading.entry = false;
                if (action.payload) {
                    state.journalEntry = {
                        id: action.payload._id,
                        content: action.payload.content,
                        date: action.payload.date
                    };
                } else {
                    state.journalEntry = {
                        id: null,
                        content: '',
                        date: state.selectedDate
                    };
                }
            })
            .addCase(fetchJournalEntry.rejected, (state, action) => {
                state.loading.entry = false;
                state.error = action.error.message;
            })
            // Handle saveJournalEntry
            .addCase(saveJournalEntry.fulfilled, (state, action) => {
                state.journalEntry = {
                    id: action.payload._id,
                    content: action.payload.content,
                    date: action.payload.date
                };
            });
    }
});

export const { setSelectedDate, updateTask, moveTask } = dailyJournalSlice.actions;
export default dailyJournalSlice.reducer;
