import React from 'react';
import "./ComponentStyling/WBS.css";
import ProjectNavbar from './ProjectNavbar';
import NavbarAuth from './Navbar_Authenticated';
import WBSEditor from './WBSEditor';

const WBS = () => {
    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="content-wrapper">
                <h1 className="h2 mb-4" style={{ textAlign: 'left', paddingLeft: '20px' }}>Work Breakdown Structure</h1>
                <WBSEditor />
            </div>
        </div>
    )
}

export default WBS;