import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';

const RaidLogStatusCard = ({ raidLogStats }) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card-header">
        <h2 className = "shiny-text">RAID Log Status</h2>
        <FontAwesomeIcon icon={faChartPie} />
      </div>
      <div className="dashboard-card-body">
        <div className="chart-container">
          <Doughnut
            data={{
              labels: ['Complete', 'In Progress', 'Not Started', 'Blocked'],
              datasets: [{
                data: [
                  raidLogStats.complete,
                  raidLogStats.inProgress,
                  raidLogStats.notStarted,
                  raidLogStats.blocked
                ],
                backgroundColor: ['#28a745', '#007bff', '#808080', '#dc3545'],
                borderWidth: 0
              }]
            }}
            options={{
              plugins: {
                legend: { 
                  position: 'bottom',
                  labels: { boxWidth: 12, padding: 8, font: { size: 9 } }
                }
              },
              cutout: '70%',
              maintainAspectRatio: false
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RaidLogStatusCard;
