import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { getUserDisplayName } from '../utils/epicUtils';

const ReportBugSlider = ({
    showSlider,
    sliderClosing,
    closeSlider,
    handleSubmit
}) => {
    const { user } = useSelector(state => state.auth);
    const [bugData, setBugData] = useState({
        title: '',
        description: '',
        severity: 'Medium',
        stepsToReproduce: [''],
        expectedBehavior: '',
        actualBehavior: '',
        reportedBy: getUserDisplayName(user)
    });

    const handleInputChange = (field, value) => {
        setBugData(prev => ({ ...prev, [field]: value }));
    };

    const handleStepChange = (index, value) => {
        const newSteps = [...bugData.stepsToReproduce];
        newSteps[index] = value;
        handleInputChange('stepsToReproduce', newSteps);
    };

    const addStep = () => {
        handleInputChange('stepsToReproduce', [...bugData.stepsToReproduce, '']);
    };

    const removeStep = (index) => {
        const newSteps = bugData.stepsToReproduce.filter((_, i) => i !== index);
        handleInputChange('stepsToReproduce', newSteps);
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Report Bug</h2>
                </div>
                
                <div className="sliding-panel-content">
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={bugData.title}
                                onChange={(e) => handleInputChange('title', e.target.value)}
                                placeholder="Brief description of the issue"
                            />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Severity</label>
                                <select
                                    className="form-select"
                                    value={bugData.severity}
                                    onChange={(e) => handleInputChange('severity', e.target.value)}
                                >
                                    <option value="Critical">Critical</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Bug Details</h3>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                rows="4"
                                value={bugData.description}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                placeholder="Detailed description of the bug..."
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label d-flex justify-content-between align-items-center">
                                Steps to Reproduce
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={addStep}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Step
                                </button>
                            </label>
                            {bugData.stepsToReproduce.map((step, index) => (
                                <div key={index} className="input-group mb-2">
                                    <span className="input-group-text">{index + 1}</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={step}
                                        onChange={(e) => handleStepChange(index, e.target.value)}
                                        placeholder="Enter step description..."
                                    />
                                    {bugData.stepsToReproduce.length > 1 && (
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => removeStep(index)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Expected Behavior</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                value={bugData.expectedBehavior}
                                onChange={(e) => handleInputChange('expectedBehavior', e.target.value)}
                                placeholder="What should happen..."
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Actual Behavior</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                value={bugData.actualBehavior}
                                onChange={(e) => handleInputChange('actualBehavior', e.target.value)}
                                placeholder="What actually happens..."
                            />
                        </div>
                    </div>

                    <div className="sliding-panel-actions">
                        <button 
                            className="btn btn-primary"
                            onClick={() => handleSubmit(bugData)}
                            disabled={!bugData.title || !bugData.description}
                        >
                            <FontAwesomeIcon icon={faSave} className="me-2" />
                            Submit Bug Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportBugSlider;
