import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import NavbarAuth from '../Components/Navbar_Authenticated';
import axios from 'axios';
import { useAuth } from "../Components/AuthContext.js";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageCSS/NewProject.css';
import { motion, AnimatePresence } from 'framer-motion'; // <-- new import
import { checkProjectLimit } from '../utils/projectLimitCheck';
import { Link } from 'react-router-dom';

export default function NewProject() {
    const { user, accountType } = useAuth();
    const [project_name, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    // Replace default date calculation:
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split('T')[0];
    const [created_date, setCreatedDate] = useState(formattedYesterday);
    const [status] = useState('Green');
    const [projectState] = useState('Not Started');
    const [step, setStep] = useState(1);
    const [direction, setDirection] = useState(0); // new state for animation direction
    // New state for feature selections
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    // New state for predefined stakeholder columns selection
    const predefinedStakeholderColumns = ['Name', 'Role', 'Email', 'Category', 'Power', 'Interest'];
    const [selectedStakeholderColumns, setSelectedStakeholderColumns] = useState([]);
    // New state for RAID Log columns
    const predefinedRaidLogColumns = {
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    };
    const [selectedRaidLogColumns, setSelectedRaidLogColumns] = useState({ 
        Risks: [], 
        Actions: [], 
        Issues: [], 
        Decisions: [] 
    });
    const [projectCode, setProjectCode] = useState(''); // new state for project code
    const navigate = useNavigate();
    const [limitInfo, setLimitInfo] = useState({ limitReached: false, currentCount: 0, maxAllowed: 0 });

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        // Check project limit when component loads
        setLimitInfo(checkProjectLimit(accountType, user?.isAdmin));
    }, [accountType, user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Construct the project object with all details and settings
        const newProject = {
            project_name,
            project_code: projectCode, // new field
            description,
            created_date,
            status,
            projectState,
            user_id: user.user_id,
            features: selectedFeatures,
            ...(selectedFeatures.includes("Stakeholder Register") && { stakeholderColumns: selectedStakeholderColumns }),
            ...(selectedFeatures.includes("RAID Log") && { raidLogColumns: selectedRaidLogColumns })
        };

        // Send complete project data to the backend Projects collection
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/projects/newProject`, newProject);
            console.log('Project created', response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error creating project: ', error);
        }
    };

    // Function to toggle feature selection
    const toggleFeature = (feature) => {
        if (selectedFeatures.includes(feature)) {
            setSelectedFeatures(selectedFeatures.filter(f => f !== feature));
        } else {
            setSelectedFeatures([...selectedFeatures, feature]);
        }
    };

    // New function to toggle stakeholder register column selection
    const toggleStakeholderColumn = (column) => {
        if (selectedStakeholderColumns.includes(column)) {
            setSelectedStakeholderColumns(selectedStakeholderColumns.filter(c => c !== column));
        } else {
            setSelectedStakeholderColumns([...selectedStakeholderColumns, column]);
        }
    };

    // New function to toggle RAID Log column selection for a specific tab
    const toggleRaidLogColumn = (tab, column) => {
        const current = selectedRaidLogColumns[tab] || [];
        const newSelection = current.includes(column)
            ? current.filter(c => c !== column)
            : [...current, column];
        setSelectedRaidLogColumns({ ...selectedRaidLogColumns, [tab]: newSelection });
    };

    // Define variants for sliding animation
    const pageVariants = {
        initial: (direction) => ({
            x: direction > 0 ? 300 : -300,
            opacity: 0
        }),
        animate: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.3 }
        },
        exit: (direction) => ({
            x: direction > 0 ? -300 : 300,
            opacity: 0,
            transition: { duration: 0.3 }
        })
    };

    // Updated functions to handle step transitions
    const goToNextStep = () => {
        setDirection(1);
        setStep(2);
    };

    const goToPrevStep = () => {
        setDirection(-1);
        setStep(1);
    };

    // New function to go to stakeholder column selection page
    const goToStakeholderStep = () => {
        setDirection(1);
        setStep(3);
    };

    // New function to go to RAID Log columns selection page
    const goToRaidLogStep = () => {
        setDirection(1);
        if(selectedFeatures.includes("Stakeholder Register")){
            setStep(4);
        } else {
            setStep(3);
        }
    };

    // New function to go back from RAID Log step
    const backFromRaidLogStep = () => {
        setDirection(-1);
        if(selectedFeatures.includes("Stakeholder Register")){
            setStep(3);
        } else {
            setStep(2);
        }
    };

    // If limit is reached, show upgrade message
    if (limitInfo.limitReached) {
        return (
            <div className="container mt-5">
                <div className="alert alert-warning">
                    <h4 className="alert-heading">Project Limit Reached</h4>
                    <p>
                        You have reached your limit of {limitInfo.maxAllowed} projects with your {accountType} account.
                    </p>
                    <hr />
                    <p className="mb-0">
                        To create more projects, please upgrade to a Professional account.
                    </p>
                    <Link to="/account" className="btn btn-warning mt-3">Upgrade Now</Link>
                </div>
            </div>
        );
    }

    return (
        <div className="new-project-page">
            <NavbarAuth />
            <div className="new-project-container mt-5">
                <AnimatePresence mode="wait" custom={direction}>
                    {step === 1 && (
                        <motion.div
                            key="step1"
                            custom={direction}
                            variants={pageVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <form onSubmit={(e) => { e.preventDefault(); goToNextStep(); }} className="new-project-form-group">
                                <div className="card p-4 shadow-sm">
                                    <h1 className="card-title text-center mb-4">New Project</h1>
                                    <div className="mb-3">
                                        <label htmlFor="projectName" className="new-project-form-label">Project Name</label>
                                        <input
                                            type="text"
                                            id="projectName"
                                            className="form-control"
                                            placeholder="Enter project name"
                                            value={project_name}
                                            onChange={(e) => setProjectName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="projectCode" className="new-project-form-label">Project Code</label>
                                        <input
                                            type="text"
                                            id="projectCode"
                                            className="form-control"
                                            placeholder="Enter 5-letter code"
                                            pattern="[A-Z]{5}"
                                            title="Must be 5 uppercase letters" // new attribute with instructions
                                            value={projectCode}
                                            onChange={(e) => setProjectCode(e.target.value.toUpperCase())}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="new-project-form-label">Description (optional)</label>
                                        <textarea
                                            id="description"
                                            className="form-control"
                                            placeholder="Enter a short description"
                                            rows="3"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="createdDate" className="new-project-form-label">Created Date</label>
                                        <input
                                            type="date"
                                            id="createdDate"
                                            className="form-control"
                                            value={created_date}
                                            onChange={(e) => setCreatedDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="d-grid">
                                        <button type="submit" className="btn new-project-btn-primary">Next</button>
                                    </div>
                                </div>
                            </form>
                        </motion.div>
                    )}
                    {step === 2 && (
                        <motion.div
                            key="step2"
                            custom={direction}
                            variants={pageVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <form onSubmit={(e) => {
                                    e.preventDefault();
                                    if(selectedFeatures.includes("Stakeholder Register")){
                                        goToStakeholderStep();
                                    } else if(selectedFeatures.includes("RAID Log")){
                                        goToRaidLogStep();
                                    } else {
                                        handleSubmit(e);
                                    }
                                }} 
                                className="new-project-form-group"
                            >
                                <h1 className="text-center mb-4">Select Project Features</h1>
                                {/* Updated feature toggles */}
                                <div className="d-flex flex-wrap justify-content-center gap-3">
                                    {["Stakeholder Register", "RAID Log", "Kanban Board"].map((feature, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`btn feature-toggle ${selectedFeatures.includes(feature) ? 'active' : ''}`}
                                            onClick={() => toggleFeature(feature)}
                                        >
                                            {feature}
                                        </button>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <button type="button" className="btn btn-secondary" onClick={goToPrevStep}>Back</button>
                                    {(selectedFeatures.includes("Stakeholder Register") || selectedFeatures.includes("RAID Log"))
                                        ? <button type="button" className="btn new-project-btn-primary"
                                              onClick={() => {
                                                  if(selectedFeatures.includes("Stakeholder Register")){
                                                      goToStakeholderStep();
                                                  } else {
                                                      goToRaidLogStep();
                                                  }
                                              }}>Next</button>
                                        : <button type="submit" className="btn new-project-btn-primary">Create Project</button>
                                    }
                                </div>
                            </form>
                        </motion.div>
                    )}
                    {step === 3 && selectedFeatures.includes("Stakeholder Register") && (
                        <motion.div
                            key="step3"
                            custom={direction}
                            variants={pageVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <form onSubmit={(e) => {
                                    e.preventDefault();
                                    if(selectedFeatures.includes("RAID Log")){
                                        goToRaidLogStep();
                                    } else {
                                        handleSubmit(e);
                                    }
                                }} 
                                className="new-project-form-group"
                            >
                                <h1 className="text-center mb-4">Select Stakeholder Columns</h1>
                                <div className="mb-3">
                                    {/* Replacing checkbox list with button toggles */}
                                    <div>
                                        {predefinedStakeholderColumns.map((column, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={`btn column-toggle ${selectedStakeholderColumns.includes(column) ? 'active' : ''} me-2 mb-2`}
                                                onClick={() => toggleStakeholderColumn(column)}
                                            >
                                                {column}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <button type="button" className="btn btn-secondary" onClick={() => { setDirection(-1); setStep(2); }}>Back</button>
                                    {selectedFeatures.includes("RAID Log")
                                        ? <button type="button" className="btn new-project-btn-primary" onClick={goToRaidLogStep}>Next</button>
                                        : <button type="submit" className="btn new-project-btn-primary">Create Project</button>
                                    }
                                </div>
                            </form>
                        </motion.div>
                    )}
                    {((step === 3 && !selectedFeatures.includes("Stakeholder Register") && selectedFeatures.includes("RAID Log")) || step === 4) && (
                        <motion.div
                            key="stepRAID"
                            custom={direction}
                            variants={pageVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <form onSubmit={handleSubmit} className="new-project-form-group">
                                <h1 className="text-center mb-4">Select RAID Log Columns</h1>
                                <div className="mb-3">
                                    {Object.keys(predefinedRaidLogColumns).map((tab, tabIndex) => (
                                        <div key={tabIndex} className="mb-3">
                                            <h5 className="mb-2">{tab}</h5>
                                            <div>
                                                {predefinedRaidLogColumns[tab].map((column, index) => (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        className={`btn column-toggle ${selectedRaidLogColumns[tab].includes(column) ? 'active' : ''} me-2 mb-2`}
                                                        onClick={() => toggleRaidLogColumn(tab, column)}
                                                    >
                                                        {column}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <button type="button" className="btn btn-secondary" onClick={backFromRaidLogStep}>Back</button>
                                    <button type="submit" className="btn new-project-btn-primary">Create Project</button>
                                </div>
                            </form>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}