import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import DOMPurify from 'dompurify';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Check authentication status
export const checkAuthStatus = createAsyncThunk(
  'auth/checkStatus',
  async (_, { rejectWithValue }) => {
    try {
      // Get CSRF token first to ensure we have it for subsequent requests
      let csrfToken = localStorage.getItem('csrfToken');
      
      if (!csrfToken) {
        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/csrf-token`);
          const tokenData = await tokenResponse.json();
          
          if (tokenData && tokenData.state) {
            csrfToken = tokenData.state;
            localStorage.setItem('csrfToken', csrfToken);
            console.log('Retrieved new CSRF token:', csrfToken);
          }
        } catch (tokenError) {
          console.warn('Could not fetch CSRF token:', tokenError);
        }
      }
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': csrfToken || '' // Include CSRF token if available
        }
      });
      
      if (!response.ok) {
        console.error('Auth status check failed with status:', response.status);
        return rejectWithValue('Authentication check failed');
      }
      
      const data = await response.json();
      console.log('Auth status response:', data);
      
      if (!data.userData) {
        return rejectWithValue('No user data received');
      }

      // Get additional user profile data
      const profileResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${data.userData.user_id}`, {
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrfToken || ''
        }
      });

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();
        return {
          ...data.userData,
          ...profileData,
        };
      }

      return data.userData;
    } catch (error) {
      console.error('❌ checkAuthStatus error details:', error); // NEW LOG
      console.error('Auth check error:', error);
      return rejectWithValue(error.message);
    }
  }
);

// Login thunk
export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password, csrfToken }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || `Login failed with status ${response.status}`);
      }
      
      const data = await response.json();
      return data.userData;
    } catch (error) {
      console.error('❌ loginUser error details:', error); // NEW LOG
      return rejectWithValue(error.message || 'Login failed');
    }
  }
);

// Logout thunk
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const csrfToken = localStorage.getItem('csrfToken'); // Get stored CSRF token
      
      // Fix the logout endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Logout API error:', response.status, errorData);
      }
      
      return true;
    } catch (error) {
      console.error('❌ logoutUser error details:', error); // NEW LOG
      console.error('Logout error:', error);
      // Still return success even if API call fails, so UI logout still works
      return true;
    }
  }
);

// Update user profile thunk
export const updateUserProfile = createAsyncThunk(
  'auth/updateProfile',
  async (profileData, { getState, rejectWithValue }) => {
    try {
      const { auth } = getState();
      const userId = auth.user?.user_id;
      
      if (!userId) {
        return rejectWithValue('User not authenticated');
      }
      
      const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/user/${userId}`, profileData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Profile update failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    loading: true,
    error: null,
    accountType: null
  },
  reducers: {
    clearAuthError: (state) => {
      state.error = null;
    },
    // Add a reducer to manually update account type if needed
    updateAccountType: (state, action) => {
      if (state.user) {
        state.user.accountType = action.payload;
        state.accountType = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Check auth status
      .addCase(checkAuthStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        state.accountType = action.payload?.accountType || "freeForever";
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        state.error = null; // Don't set error on rejection
        state.accountType = null;
      })
      
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        state.accountType = action.payload?.accountType || "freeForever";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Login failed';
      })
      
      // Logout
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        state.accountType = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Logout failed';
        // Still reset user state even if the API call fails
        state.user = null;
        state.isAuthenticated = false;
        state.accountType = null;
      })
      
      // Update profile
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        // Make sure accountType is preserved when profile is updated
        if (action.payload.accountType) {
          state.accountType = action.payload.accountType;
        }
      });
  }
});

export const { clearAuthError, updateAccountType } = authSlice.actions;
export default authSlice.reducer;
