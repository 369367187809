import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // Add this import
import axios from 'axios'; // Add this import
import PPTX from 'pptxgenjs'; // <-- NEW IMPORT
import ProjectNavbar from "./ProjectNavbar";
import NavbarAuth from './Navbar_Authenticated';
import TimelineContainer from './TimelineContainer';
import SchedulerActivities from './SchedulerActivities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from "./Footer";
import { 
    faTrash, 
    faPalette, 
    faAlignLeft, 
    faTextHeight, 
    faFont,
    faAlignCenter,
    faAlignRight,
    faCheck,
    faCopy // Add this import
} from '@fortawesome/free-solid-svg-icons';
import './ComponentStyling/ProjectScheduler.css';

const ProjectScheduler = () => {
    // Replace static projectId with useParams
    const { projectId } = useParams();
    // Add project data state
    const [projectData, setProjectData] = useState(null);

    const timelineRef = useRef(null);
    const [currentYear, setCurrentYear] = useState(2024);
    const [yearsRange, setYearsRange] = useState({ start: 2024, end: 2026 });
    const [events, setEvents] = useState([]);
    const [resizingEvent, setResizingEvent] = useState(null);
    const [draggingEvent, setDraggingEvent] = useState(null);
    const [editingEventId, setEditingEventId] = useState(null);
    const [editingTitle, setEditingTitle] = useState("");
    const [contextMenu, setContextMenu] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Updated preset colors array with a wider variety
    const presetColors = [
        // Blues
        "#0056b3", "#004494", "#003366", "#1e90ff", "#87ceeb", "#00bfff",
        // Reds
        "#dc3545", "#ff4444", "#8b0000", "#ff6b6b", "#cd5c5c",
        // Greens
        "#28a745", "#32cd32", "#006400", "#90ee90", "#3cb371",
        // Yellows/Oranges
        "#ffc107", "#ff8c00", "#ffd700", "#ffa500", "#ffb347",
        // Purples
        "#9932cc", "#8a2be2", "#9370db", "#ba55d3", "#da70d6",
        // Pinks
        "#ff69b4", "#ff1493", "#db7093", "#ffc0cb", "#ffb6c1",
        // Teals/Cyans
        "#20b2aa", "#48d1cc", "#00ced1", "#40e0d0", "#7fffd4",
        // Browns
        "#8b4513", "#a0522d", "#cd853f", "#deb887", "#d2691e",
        // Grays
        "#2f4f4f", "#696969", "#808080", "#a9a9a9", "#d3d3d3",
        // Base Colors
        "#ffffff", "#000000"
    ];

    // New handler to set a preset color
    const handleSelectPresetColor = (eventId, color) => {
        setEvents(prev => prev.map(ev => ev.id === eventId ? { ...ev, color } : ev));
        setContextMenu(null);
        handleSchedulerChange();
    };

    const handleTextColor = (eventId, color) => {
        setEvents(prev => prev.map(ev => 
            ev.id === eventId ? { ...ev, textColor: color } : ev
        ));
        setContextMenu(null);
        handleSchedulerChange();
    };

    useEffect(() => {
        const handleScroll = () => {
            if (!timelineRef.current) return;
            const timelineRect = timelineRef.current.getBoundingClientRect();
            const centerX = timelineRect.left + timelineRect.width / 2;
            let closestYear = null;
            let closestDistance = Infinity;
            timelineRef.current.querySelectorAll('[data-year]').forEach(yearElem => {
                const rect = yearElem.getBoundingClientRect();
                const elemCenter = (rect.left + rect.right) / 2;
                const distance = Math.abs(elemCenter - centerX);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestYear = yearElem.getAttribute('data-year');
                }
            });
            if (closestYear) setCurrentYear(closestYear);
        };

        const handleInfiniteScroll = () => {
            if (!timelineRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = timelineRef.current;
            const today = new Date();
            const currentYear = today.getFullYear();
            const startYear = currentYear - 1;
            const endYear = currentYear + 1;
            const startOffset = (startYear - 2024) * 365 * 40; // Calculate start offset based on year
            const endOffset = (endYear - 2024 + 1) * 365 * 40; // Calculate end offset based on year

            if (scrollLeft < startOffset) {
                timelineRef.current.scrollLeft = startOffset;
            }
            if (scrollLeft + clientWidth > endOffset) {
                timelineRef.current.scrollLeft = endOffset - clientWidth;
            }
        };

        const refCurrent = timelineRef.current;
        refCurrent && refCurrent.addEventListener('scroll', handleScroll);
        refCurrent && refCurrent.addEventListener('scroll', handleInfiniteScroll);
        handleScroll();
        return () => {
            refCurrent && refCurrent.removeEventListener('scroll', handleScroll);
            refCurrent && refCurrent.removeEventListener('scroll', handleInfiniteScroll);
        };
    }, []);

    useEffect(() => {
        if (timelineRef.current) {
            handleToday();
        }
    }, []);

    useEffect(() => {
        if (!resizingEvent) return;
        const handleMouseMove = (e) => {
            const deltaX = e.clientX - resizingEvent.startX;
            setEvents(prev =>
                prev.map(ev => {
                    if (ev.id !== resizingEvent.id) return ev;
                    let newWidth = ev.width;
                    let newLeft = ev.left;
                    const dayWidth = 40; // Width of one day
                    
                    if (resizingEvent.side === 'right') {
                        // Allow single day width (40px) as minimum
                        newWidth = Math.max(dayWidth, resizingEvent.startWidth + deltaX);
                        // Snap to closest day line
                        newWidth = Math.round(newWidth / dayWidth) * dayWidth;
                    } else if (resizingEvent.side === 'left') {
                        // Calculate new width while maintaining minimum of one day
                        const proposedWidth = resizingEvent.startWidth - deltaX;
                        if (proposedWidth >= dayWidth) {
                            newWidth = proposedWidth;
                            newLeft = resizingEvent.startLeft + deltaX;
                            // Snap to closest day line
                            newLeft = Math.round(newLeft / dayWidth) * dayWidth;
                        }
                    }
                    return { ...ev, width: newWidth, left: newLeft };
                })
            );
            handleSchedulerChange();
        };

        const handleMouseUp = () => {
            setResizingEvent(null);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resizingEvent]);

    // Modify the drag start handler to capture Y axes
    const handleMouseDownDrag = (e, eventItem) => {
        if (e.target.dataset.resizer) return;
        e.preventDefault();
        e.stopPropagation();

        const timelineRect = timelineRef.current.getBoundingClientRect();
        const scrollLeft = timelineRef.current.scrollLeft;

        setDraggingEvent({
            id: eventItem.id,
            startX: e.clientX,
            startY: e.clientY,
            startLeft: eventItem.left,
            startTop: eventItem.top,
            timelineRect,
            scrollLeft,
            initialScrollLeft: scrollLeft
        });
    };

    useEffect(() => {
        if (!draggingEvent) return;

        const handleDragMove = (e) => {
            e.preventDefault();
            
            // Calculate scroll position changes
            const scrollDelta = timelineRef.current.scrollLeft - draggingEvent.initialScrollLeft;
            
            // Adjust for scroll position and calculate new position
            const deltaX = (e.clientX - draggingEvent.startX) + scrollDelta;
            const deltaY = e.clientY - draggingEvent.startY;

            // Calculate new positions
            let newLeft = draggingEvent.startLeft + deltaX;
            let newTop = draggingEvent.startTop + deltaY;

            // Ensure the event stays within bounds
            newLeft = Math.max(0, newLeft);
            newTop = Math.max(60, newTop); // Minimum top position below header

            // Snap to closest day line
            const dayWidth = 40;
            newLeft = Math.round(newLeft / dayWidth) * dayWidth;

            setEvents(prev =>
                prev.map(ev => {
                    if (ev.id !== draggingEvent.id) return ev;
                    return { 
                        ...ev, 
                        left: newLeft,
                        top: newTop,
                    };
                })
            );

            // Auto-scroll functionality
            const timelineEl = timelineRef.current;
            const buffer = 100; // pixels from edge to start scrolling
            const scrollSpeed = 10;

            if (e.clientX - draggingEvent.timelineRect.left < buffer) {
                timelineEl.scrollLeft -= scrollSpeed;
            } else if (draggingEvent.timelineRect.right - e.clientX < buffer) {
                timelineEl.scrollLeft += scrollSpeed;
            }
            handleSchedulerChange();
        };

        const handleDragEnd = () => {
            setDraggingEvent(null);
        };

        window.addEventListener('mousemove', handleDragMove);
        window.addEventListener('mouseup', handleDragEnd);
        
        return () => {
            window.removeEventListener('mousemove', handleDragMove);
            window.removeEventListener('mouseup', handleDragEnd);
        };
    }, [draggingEvent]);

    useEffect(() => {
        const handleClick = () => {
            if (contextMenu) setContextMenu(null);
        };
        window.addEventListener("click", handleClick);
        return () => window.removeEventListener("click", handleClick);
    }, [contextMenu]);

    const today = new Date();
    const baseDate = new Date(today.getFullYear() - 1, 0, 1); // Base date set to the start of the previous year
    const diffInDays = Math.floor((today - baseDate) / (1000 * 60 * 60 * 24)); // Calculate days difference from the start of the previous year
    const currentDayOffset = diffInDays * 40; // Calculate the offset for the current day

    const handleToday = () => {
        if (timelineRef.current) {
            const containerWidth = timelineRef.current.offsetWidth;
            let scrollPosition = currentDayOffset - containerWidth / 2;
            if (scrollPosition < 0) scrollPosition = 0;
            timelineRef.current.scrollLeft = scrollPosition;
        }
    };

    const handleAddEvent = () => {
        const newEvent = {
            id: Date.now(),
            left: currentDayOffset,
            top: 120,
            width: 40, // Change default width to one day (40px)
            height: 30,
            title: "Event",
            color: "#0056b3", // New color property
            textAlign: 'center', // Add default text alignment
            textWrap: false, // Add default text wrap setting
            textColor: '#ffffff', // Add default text color
        };
        setEvents(prev => [...prev, newEvent]);
        handleSchedulerChange();
    };

    const handleChangeColor = (eventId) => {
        const newColor = prompt("Enter new color (CSS valid value)", "#0056b3");
        if(newColor) {
            setEvents(prev => prev.map(ev => ev.id === eventId ? { ...ev, color: newColor } : ev));
        }
        setContextMenu(null);
        handleSchedulerChange();
    };

    const handleMouseDownResize = (e, eventItem, side) => {
        e.stopPropagation();
        setResizingEvent({
            id: eventItem.id,
            side,
            startX: e.clientX,
            startWidth: eventItem.width,
            startLeft: eventItem.left,
        });
    };

    const handleEventDoubleClick = (e, eventItem) => {
        e.stopPropagation();
        setEditingEventId(eventItem.id);
        setEditingTitle(eventItem.title);
    };

    const handleEventContextMenu = (e, eventItem) => {
        e.preventDefault();
        setContextMenu({
            x: e.clientX,
            y: e.clientY,
            eventId: eventItem.id
        });
    };

    const handleDeleteEvent = (eventId) => {
        setEvents(prev => prev.filter(ev => ev.id !== eventId));
        handleSchedulerChange();
    };

    const finishEditingEventTitle = () => {
        setEvents(prev =>
            prev.map(ev =>
                ev.id === editingEventId ? { ...ev, title: editingTitle } : ev
            )
        );
        setEditingEventId(null);
        setEditingTitle("");
        handleSchedulerChange();
    };

    // New function to save scheduler data via API
    const handleSaveSchedulerData = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/scheduler`, {
                schedulerData: {
                    events,
                    lastUpdated: new Date()
                }
            });
            setHasUnsavedChanges(false); // Reset the unsaved changes flag
            console.log('Scheduler data saved successfully');
        } catch (error) {
            console.error('Error saving scheduler data:', error);
            alert('Error occurred while saving scheduler data');
        }
    };

    // New useEffect to load saved scheduler data
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProjectData(response.data);
                // Load scheduler data after project data is fetched
                const schedulerResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/scheduler`);
                if (schedulerResponse.data.schedulerData && schedulerResponse.data.schedulerData.events) {
                    setEvents(schedulerResponse.data.schedulerData.events);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        if (projectId) {
            fetchProjectData();
        }
    }, [projectId]);

    // New useEffect to listen for Cmd/Ctrl + S keydown
    useEffect(() => {
        const keyDownHandler = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 's') {
                e.preventDefault();
                handleSaveSchedulerData();
            }
        };
        window.addEventListener('keydown', keyDownHandler);
        return () => window.removeEventListener('keydown', keyDownHandler);
    }, [events]);

    const handleActivitySelect = (activity) => {
        setSelectedActivity(activity.id);
        if (timelineRef.current) {
            timelineRef.current.scrollLeft = activity.left - (timelineRef.current.offsetWidth / 2);
        }
    };

    const handleTextAlign = (eventId, alignment) => {
        setEvents(prev => prev.map(ev => 
            ev.id === eventId ? { ...ev, textAlign: alignment } : ev
        ));
        setContextMenu(null);
        handleSchedulerChange();
    };

    const handleTextWrap = (eventId, shouldWrap) => {
        setEvents(prev => prev.map(ev => 
            ev.id === eventId ? { ...ev, textWrap: shouldWrap } : ev
        ));
        setContextMenu(null);
        handleSchedulerChange();
    };

    const handleEventUpdate = (updatedEvent) => {
        if (Array.isArray(updatedEvent)) {
            // Handle bulk update
            setEvents(updatedEvent);
        } else {
            // Handle single event update
            setEvents(prev => prev.map(ev => 
                ev.id === updatedEvent.id ? updatedEvent : ev
            ));
        }
        handleSchedulerChange();
    };

    const handleDuplicateEvent = (eventId) => {
        const eventToDuplicate = events.find(ev => ev.id === eventId);
        if (eventToDuplicate) {
            const newEvent = {
                ...eventToDuplicate,
                id: Date.now(),
                left: eventToDuplicate.left + eventToDuplicate.width + 40, // Place it one day after the original
                title: `${eventToDuplicate.title} (Copy)`,
            };
            setEvents(prev => [...prev, newEvent]);
        }
        setContextMenu(null);
        handleSchedulerChange();
    };

    // NEW: Function to export timeline as a PowerPoint slide with a table of year and month
    const handleExportPPT = () => {
        if (!events.length) {
            alert("No events to export");
            return;
        }

        // Get absolute dates from pixel offsets
        const minLeft = Math.min(...events.map(ev => ev.left));
        const maxRight = Math.max(...events.map(event => event.left + event.width)); // Fixed the undefined ev variable
        
        // Calculate actual dates using baseDate (same as timeline)
        const startDate = new Date(baseDate.getTime() + (minLeft / 40) * 86400000);
        const endDate = new Date(baseDate.getTime() + (maxRight / 40) * 86400000);

        // Adjust to Monday/Sunday boundaries
        while (startDate.getDay() !== 1) startDate.setDate(startDate.getDate() - 1);
        while (endDate.getDay() !== 0) endDate.setDate(endDate.getDate() + 1);

        // Helper to collect all Mondays in a month
        const getMondays = (year, month) => {
            const mondays = [];
            let d = new Date(year, month, 1);
            // Find first Monday
            while (d.getDay() !== 1) {
                d.setDate(d.getDate() + 1);
                if(d.getMonth() !== month) break; // No Monday in this month, edge-case
            }
            while (d.getMonth() === month) {
                mondays.push(new Date(d));
                d.setDate(d.getDate() + 7);
            }
            return mondays;
        };

        // Build table with three rows: year, month, and Monday labels
        let yearRow = [];
        let monthRow = [];
        let mondayRow = [];
        let spacerRow = [];
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        let totalMondays = 0; // Track total number of Mondays

        for (let y = startYear; y <= endYear; y++) {
            let totalMondaysForYear = 0;
            let startMonth = (y === startYear) ? startDate.getMonth() : 0;
            let endMonth = (y === endYear) ? endDate.getMonth() : 11;
            
            for (let m = startMonth; m <= endMonth; m++) {
                const monthName = new Date(y, m, 1).toLocaleString('default', { month: 'long' });
                let mondays = getMondays(y, m);
                // If this month is the last event's month, filter Mondays beyond the end date.
                if (y === endDate.getFullYear() && m === endDate.getMonth()) {
                    mondays = mondays.filter(monday => monday.getDate() <= endDate.getDate());
                }
                monthRow.push({ 
                    text: monthName, 
                    options: { 
                        colspan: mondays.length, 
                        align: "center", 
                        fill: "#808080", 
                        color: "#ffffff"
                    } 
                });
                totalMondaysForYear += mondays.length;
                totalMondays += mondays.length; // Increment total Mondays count

                mondays.forEach(mondayDate => {
                    // Adjust font size based on total number of Mondays
                    const dayFontSize = totalMondays > 52 ? Math.max(6, 8 - Math.floor(totalMondays / 52)) : 8;
                    
                    mondayRow.push({ 
                        text: mondayDate.getDate().toString(), 
                        options: { 
                            align: "center",
                            fontSize: dayFontSize // Apply dynamic font size to day numbers
                        } 
                    });
                    spacerRow.push({ text: "", options: { align: "center" } });
                });
            }
            
            yearRow.push({ 
                text: y.toString(), 
                options: { 
                    colspan: totalMondaysForYear, 
                    align: "center", 
                    bold: true, 
                    fill: "#0056b3", 
                    color: "#ffffff"
                } 
            });
        }

        // Calculate header font sizes
        const yearFontSize = totalMondays > 52 ? Math.max(10, 12 - Math.floor(totalMondays / 52)) : 12;
        const monthFontSize = totalMondays > 52 ? Math.max(8, 10 - Math.floor(totalMondays / 52)) : 10;

        // Update font sizes for year and month rows
        yearRow.forEach(cell => {
            cell.options.fontSize = yearFontSize;
        });
        monthRow.forEach(cell => {
            cell.options.fontSize = monthFontSize;
        });

        const tableData = [yearRow, monthRow, mondayRow, spacerRow];

        // Calculate total number of monday columns from the year row
        const totalColumns = yearRow.reduce((sum, cell) => sum + cell.options.colspan, 0);
        // Adjust font size: use a base of 12; reduce for many columns
        let fontSize = 12;
        if (totalColumns > 20) {
            fontSize = Math.max(8, 12 - Math.floor((totalColumns - 20) / 10));
        }
        
        let pptx = new PPTX();
        let slide = pptx.addSlide();
        // Define PPT table properties with slightly adjusted x-coordinate
        const tableX = 0.5; // Shifted left from 0.5 to 0.4
        const tableY = 0.1;
        const tableW = 9.0;

        // Adjust table position in slide.addTable
        slide.addTable(tableData, {
            x: tableX,          // Use tableX instead of hardcoded 0.5
            y: tableY,
            w: tableW,         // Use exact width instead of percentage
            border: { pt: 1, color: "CCCCCC" },
            fontSize,
            align: "center",
            rowH: [ null, null, null, 4.5 ] // fourth row height set to 6 inches
        });

        // Calculate timeline duration in days using startDate and endDate
        const timelineDays = (endDate - startDate) / (86400000);

        // Define PPT table properties used for mapping (table at x=0.5, width=9 inches)
        // const tableX = 0.5;
        // const tableY = 0.1;
        // const tableW = 9.0;

        // Define vertical conversion: assume 100px = 1 inch and base top is 120px (scheduler's top offset)
        const verticalScale = 200; 
        const baseTop = 100; 

        events.forEach(event => {
            // Calculate event start date from scheduler offset (40px = 1 day), subtract one day to fix alignment
            const eventDate = new Date(baseDate.getTime() + (event.left / 40) * 86400000 - 86400000);
            const durationDays = event.width / 40;
            const eventDayOffset = (eventDate - startDate) / 86400000;

            // Map to PPT slide coordinates for horizontal positioning
            const eventX = tableX + (eventDayOffset / timelineDays) * tableW;
            const eventW = (durationDays / timelineDays) * tableW;
            // Map vertical position: convert (event.top - baseTop) to inches and add offset
            const eventY = tableY + 1.2 + ((event.top - baseTop) / verticalScale);
            // Convert event height from px to inches
            const eventH = 0.15;
            
            // Add the event rectangle with text
            slide.addText(
                [
                    {
                        text: event.title || "",
                        options: {
                            fontSize: 8,
                            align: event.textAlign || "center",
                            color: event.textColor || "#FFFFFF",
                            wrap: false,
                            breakLine: false,
                            fit: false,
                            shrink: false,
                            overflow: 'clip' // Force text to clip rather than wrap
                        },
                    },
                ],
                {
                    shape: pptx.shapes.RECTANGLE,
                    x: eventX,
                    y: eventY,
                    w: eventW,
                    h: eventH,
                    fill: event.color,
                    line: { color: "FFFFFF" },
                    autoFit: false,
                    shrinkText: false,
                    wrap: false,
                    fitText: false,
                    wordWrap: false,
                    verticalAlign: "middle",
                    isTextBox: true, // Treat as textbox to better control text behavior
                    margin: 3,
                    textFit: 'none' // PowerPoint-specific setting
                }
            );
        });

        pptx.writeFile("Timeline.pptx");
    };

    const handleSchedulerChange = useCallback(() => {
        setHasUnsavedChanges(true);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [hasUnsavedChanges]);

    return (
        <div className="App d-flex flex-column min-vh-100"> {/* Changed to use min-vh-100 and d-flex */}
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid flex-grow-1" style={{ minHeight: 0 }}> {/* Add flex-grow-1 and minHeight */}
                <div className="d-flex justify-content-between align-items-center mb-3" style={{ padding: '20px 0 10px 0' }}>
                    <div className="d-flex align-items-center">
                        <h1 className="h2 shiny-text mb-0">
                            {projectData ? `${projectData.project_name} - Scheduler` : 'Project Scheduler'}
                        </h1>
                        {hasUnsavedChanges && (
                            <div 
                                className="ms-3 d-flex align-items-center"
                                style={{ 
                                    fontSize: '0.9rem',
                                    opacity: 0.8,
                                    animation: 'fadeInOut 2s infinite',
                                    color: '#6c757d',  // Added muted color
                                    height: '100%',    // Match parent height
                                }}
                            >
                                Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save changes
                            </div>
                        )}
                    </div>
                    <div>
                        <button className="btn btn-primary me-2 scheduler-button" onClick={handleToday}>Today</button>
                        <button className="btn btn-secondary me-2 scheduler-button" onClick={handleExportPPT}>Export PPT</button>
                    </div>
                </div>
                <div className="d-flex" style={{ gap: '20px', height: 'calc(100% - 80px)' }}>
                    <SchedulerActivities 
                        events={events}
                        selectedActivity={selectedActivity}
                        onActivitySelect={handleActivitySelect}
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                        yearsRange={yearsRange}
                        onEventUpdate={handleEventUpdate}
                        onAddEvent={handleAddEvent}    // Fixed the syntax error here
                    />
                    <TimelineContainer 
                        timelineRef={timelineRef}
                        currentDayOffset={currentDayOffset}
                        yearsRange={yearsRange}
                        events={events}
                        handleMouseDownDrag={handleMouseDownDrag}
                        handleEventDoubleClick={handleEventDoubleClick}
                        handleEventContextMenu={handleEventContextMenu}
                        handleMouseDownResize={handleMouseDownResize}
                        editingEventId={editingEventId}
                        editingTitle={editingTitle}
                        setEditingTitle={setEditingTitle}
                        finishEditingEventTitle={finishEditingEventTitle}
                    />
                </div>
                {/* Context menu remains in parent */}
                {contextMenu && (
                    <div 
                        style={{
                            position: 'fixed',
                            top: contextMenu.y,
                            left: contextMenu.x,
                            background: 'white',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            zIndex: 9999,
                            padding: '8px 0',
                            minWidth: '180px', // Reduce minimum width
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                        }}
                    >
                        {contextMenu.showColors ? (
                            <div>
                                <div style={{ padding: '8px 16px', fontWeight: 500, borderBottom: '1px solid #eee' }}>
                                    <FontAwesomeIcon icon={faPalette} style={{ marginRight: '8px' }} />
                                    Select Color
                                </div>
                                <div style={{ 
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(6, 1fr)', // Show 6 colors per row
                                    gap: '4px',
                                    padding: '8px',
                                    maxWidth: '180px' // Limit the width of the color grid
                                }}>
                                    {presetColors.map(color => (
                                        <div 
                                            key={color}
                                            style={{
                                                aspectRatio: '1',
                                                backgroundColor: color,
                                                cursor: 'pointer',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd',
                                                transition: 'transform 0.1s ease',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectPresetColor(contextMenu.eventId, color);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : contextMenu.showTextColors ? (
                            <div>
                                <div style={{ padding: '8px 16px', fontWeight: 500, borderBottom: '1px solid #eee' }}>
                                    <FontAwesomeIcon icon={faFont} style={{ marginRight: '8px' }} />
                                    Text Color
                                </div>
                                <div style={{ 
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(6, 1fr)', // Show 6 colors per row
                                    gap: '4px',
                                    padding: '8px',
                                    maxWidth: '180px' // Limit the width of the color grid
                                }}>
                                    {/* Same color grid structure for text colors */}
                                    {presetColors.map(color => (
                                        <div 
                                            key={color}
                                            style={{
                                                aspectRatio: '1',
                                                backgroundColor: color,
                                                cursor: 'pointer',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd',
                                                transition: 'transform 0.1s ease',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleTextColor(contextMenu.eventId, color);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : contextMenu.showAlignment ? (
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                {[
                                    { align: 'left', icon: faAlignLeft },
                                    { align: 'center', icon: faAlignCenter },
                                    { align: 'right', icon: faAlignRight }
                                ].map(({ align, icon }) => (
                                    <li 
                                        key={align}
                                        style={{ 
                                            padding: '8px 16px', 
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'background-color 0.1s ease',
                                            ':hover': {
                                                backgroundColor: '#f8f9fa'
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTextAlign(contextMenu.eventId, align);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={icon} style={{ marginRight: '8px', width: '16px' }} />
                                        {align.charAt(0).toUpperCase() + align.slice(1)} Align
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li className="context-menu-item" onClick={(e) => {
                                    e.stopPropagation();
                                    setContextMenu({ ...contextMenu, showColors: true });
                                }}>
                                    <FontAwesomeIcon icon={faPalette} style={{ marginRight: '8px', color: '#0056b3' }} />
                                    Background Color
                                </li>
                                <li className="context-menu-item" onClick={(e) => {
                                    e.stopPropagation();
                                    setContextMenu({ ...contextMenu, showTextColors: true });
                                }}>
                                    <FontAwesomeIcon icon={faFont} style={{ marginRight: '8px', color: '#0056b3' }} />
                                    Text Color
                                </li>
                                <li className="context-menu-item" onClick={(e) => {
                                    e.stopPropagation();
                                    setContextMenu({ ...contextMenu, showAlignment: true });
                                }}>
                                    <FontAwesomeIcon icon={faAlignLeft} style={{ marginRight: '8px', color: '#6c757d' }} />
                                    Text Alignment
                                </li>
                                <li className="context-menu-item" onClick={(e) => {
                                    e.stopPropagation();
                                    const event = events.find(ev => ev.id === contextMenu.eventId);
                                    handleTextWrap(contextMenu.eventId, !event.textWrap);
                                }}>
                                    <FontAwesomeIcon icon={faTextHeight} style={{ marginRight: '8px', color: '#6c757d' }} />
                                    {events.find(ev => ev.id === contextMenu.eventId)?.textWrap 
                                        ? 'Disable Text Wrap' 
                                        : 'Enable Text Wrap'}
                                </li>
                                <li 
                                    className="context-menu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDuplicateEvent(contextMenu.eventId);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCopy} style={{ marginRight: '8px', color: '#0056b3' }} />
                                    Duplicate Event
                                </li>
                                <li 
                                    className="context-menu-item context-menu-item-delete"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteEvent(contextMenu.eventId);
                                        setContextMenu(null);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: '8px', color: '#dc3545' }} />
                                    Delete Event
                                </li>
                            </ul>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ProjectScheduler;
