import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchDailyJournalTasks } from '../../redux/slices/journalSlice';
import { validateUserId, sanitizeText } from '../../utils/securityUtils';

const TodaysTasksCard = ({ userId }) => {
    const dispatch = useDispatch();
    const { tasks: rawTasks, taskCounts, loading, error } = useSelector(state => state.journal);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [validationError, setValidationError] = useState(null);
    
    // Sanitize tasks data for safe rendering
    const tasks = rawTasks.map(task => ({
        ...task,
        title: sanitizeText(task.title),
        description: sanitizeText(task.description)
    }));
    
    useEffect(() => {
        // Validate userId before using it
        if (userId) {
            if (validateUserId(userId)) {
                setValidationError(null);
                dispatch(fetchDailyJournalTasks(userId));
            } else {
                setValidationError('Invalid user ID format');
                console.error('Security warning: Invalid userId format detected');
            }
        }
    }, [userId]); 
    
    const handleManualRefresh = () => {
        if (!userId || !validateUserId(userId)) {
            setValidationError('Invalid user ID format');
            return;
        }
        
        if (!isRefreshing) {
            setValidationError(null);
            setIsRefreshing(true);
            dispatch(fetchDailyJournalTasks(userId)).finally(() => {
                setTimeout(() => setIsRefreshing(false), 500);
            });
        }
    };
    
    // Create a secure link URL
    const getSecureLink = () => {
        if (validateUserId(userId)) {
            return `/${encodeURIComponent(userId)}/dailyJournal`;
        }
        return '/';
    };
    
    return (
        <div className="dashboard-card tasks-card">
            <div className="dashboard-card-header">
                <div className="tasks-header-container">
                    <h2 className="tasks-title shiny-text">
                        <FontAwesomeIcon icon={faClipboardList} className="me-2" />
                        Today's Tasks
                    </h2>
                    <div className="d-flex align-items-center">
                        <button 
                            className="btn btn-sm btn-outline-secondary me-2" 
                            onClick={handleManualRefresh}
                            disabled={isRefreshing || loading || validationError}
                            title="Refresh tasks"
                        >
                            <FontAwesomeIcon icon={faSyncAlt} spin={isRefreshing} />
                        </button>
                        <Link to={getSecureLink()} className="btn btn-sm btn-outline-primary tasks-view-all">
                            View All
                        </Link>
                    </div>
                </div>
            </div>
            <div className="dashboard-card-body">
                {validationError ? (
                    <div className="alert alert-danger" role="alert">
                        {validationError}
                    </div>
                ) : loading || isRefreshing ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mb-0 mt-2">Loading tasks...</p>
                    </div>
                ) : error ? (
                    <div className="alert alert-danger" role="alert">
                        Could not load tasks: {sanitizeText(error)}
                    </div>
                ) : tasks.length === 0 ? (
                    <div className="text-center text-muted">
                        <p>No tasks for today.</p>
                        <Link to={getSecureLink()} className="btn btn-outline-primary upgrade-btn fw-bold">
                            Create Task
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="metrics-container">
                            <div className="metric">
                                <div className="metric-value">{Number(taskCounts.total) || 0}</div>
                                <div className="metric-label">Total Tasks</div>
                            </div>
                            <div className="metric">
                                <div className="metric-value" style={{ color: '#0056b3' }}>{Number(taskCounts.inProgress) || 0}</div>
                                <div className="metric-label">In Progress</div>
                            </div>
                            <div className="metric">
                                <div className="metric-value" style={{ color: '#ffc107' }}>{Number(taskCounts.todo) || 0}</div>
                                <div className="metric-label">To Do</div>
                            </div>
                            <div className="metric">
                                <div className="metric-value" style={{ color: '#28a745' }}>{Number(taskCounts.complete) || 0}</div>
                                <div className="metric-label">Complete</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TodaysTasksCard;
