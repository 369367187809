import "./ComponentStyling/Navbar.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useState, useRef, useEffect } from "react";
import axios from 'axios';
import { FiUser, FiLogOut, FiSettings } from 'react-icons/fi';
import axiosInstance from '../axiosConfig'; // Add this import

export default function NavbarAuthenticated() {
    const { user, isAuthenticated, logout } = useAuth();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);
    const searchTimeoutRef = useRef();
    const searchResultsRef = useRef();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
                setSearchResults(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearch = async (value) => {
        setSearchQuery(value);
        
        // Clear previous timeout
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Don't search if query is empty
        if (!value.trim()) {
            setSearchResults(null);
            return;
        }

        // Debounce search
        searchTimeoutRef.current = setTimeout(async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
                    params: {
                        query: value,
                        userId: user.user_id
                    }
                });
                setSearchResults(response.data);
            } catch (error) {
                console.error('Search failed:', error);
            } finally {
                setIsLoading(false);
            }
        }, 300);
    };

    useEffect(() => {
        if (user) {
            console.log('User data in Navbar:', user);
            console.log('Profile picture URL:', user.profilePicture);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            const loadProfilePicture = async () => {
                console.log('Starting profile picture load attempt for user:', user.user_id);
                try {
                    const response = await axiosInstance.get(`/api/user/${user.user_id}`, {
                        withCredentials: true
                    });
                    console.log('API Response:', response.data);
                    if (response.data.profilePicture) {
                        // Remove URL sanitization and just use the URL directly
                        console.log('Setting profile picture URL:', response.data.profilePicture);
                        setProfilePictureUrl(response.data.profilePicture);
                        setImageLoadError(false);
                    } else {
                        console.log('No profile picture URL in response');
                        setProfilePictureUrl(null);
                    }
                } catch (error) {
                    console.error('Error loading profile picture:', error);
                    setProfilePictureUrl(null);
                }
            };
            loadProfilePicture();
        }
    }, [user]);

    return (
        <header className="navbar-container">
            <div className="navbar">
                <div className="navbar-left">
                    <Link to="/" className="navbar-logo">PMHub.io</Link>
                    <div className="navbar-search-container" ref={searchResultsRef}>
                        <input 
                            type="search" 
                            placeholder="Search projects, meetings, and notes..." 
                            className="navbar-search-input"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        {(isLoading || searchResults) && (
                            <div className="search-results-dropdown">
                                {isLoading ? (
                                    <div className="search-loading">Searching...</div>
                                ) : (
                                    <SearchResults 
                                        results={searchResults} 
                                        onResultClick={() => setSearchResults(null)}
                                        user={user} // Add this line to pass user prop
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <ul className="navbar-right">
                    <CustomLink to="/dashboard">Dashboard</CustomLink>
                    <CustomLink to={`/${user.user_id}/dailyJournal`}>Daily Journal</CustomLink>
                    <div className="profile-menu-container">
                        <div 
                            className="profile-nav-link"
                            onClick={() => setShowProfileMenu(!showProfileMenu)}
                        >
                            {profilePictureUrl && !imageLoadError ? (
                                <img 
                                    src={profilePictureUrl}
                                    alt="Profile"
                                    className="navbar-profile-picture"
                                    // Remove crossOrigin attribute
                                    onLoad={() => {
                                        console.log('Profile picture loaded successfully');
                                        setImageLoadError(false);
                                    }}
                                    onError={(e) => {
                                        console.error('Profile picture load failed');
                                        console.error('Failed URL:', e.target.src);
                                        setImageLoadError(true);
                                        setProfilePictureUrl(null);
                                    }}
                                />
                            ) : (
                                <div className="navbar-profile-placeholder">
                                    <FiUser />
                                </div>
                            )}
                        </div>
                        {showProfileMenu && (
                            <div className="profile-dropdown">
                                <Link to="/account" className="profile-dropdown-item">
                                    <FiSettings /> Account Settings
                                </Link>
                                <button 
                                    onClick={logout} 
                                    className="profile-dropdown-item"
                                >
                                    <FiLogOut /> Log Out
                                </button>
                            </div>
                        )}
                    </div>
                </ul>
            </div>
        </header>
    );
}

function SearchResults({ results, onResultClick, user }) {
    if (!results || (
        results.projects.length === 0 && 
        results.meetings.length === 0 && 
        results.journalEntries.length === 0 &&
        results.tasks.length === 0
    )) {
        return <div className="no-results">No results found</div>;
    }

    const truncateText = (text, maxLength = 100) => {
        if (!text) return '';
        const stripHtml = text.replace(/<[^>]*>/g, '');
        if (stripHtml.length <= maxLength) return stripHtml;
        return stripHtml.substring(0, maxLength) + '...';
    };

    const formatDateForUrl = (dateString) => {
        // Only for journal entries and tasks
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <div className="search-results">
            {results.projects.length > 0 && (
                <section>
                    <h4>Projects</h4>
                    {results.projects.map(project => (
                        <Link 
                            key={project._id} 
                            to={`/projects/${project._id}`}
                            onClick={onResultClick}
                            className="search-result-item"
                        >
                            <div className="result-title">{project.project_name}</div>
                            {project.description && 
                                <div className="result-preview">{truncateText(project.description)}</div>
                            }
                        </Link>
                    ))}
                </section>
            )}

            {results.meetings.length > 0 && (
                <section>
                    <h4>Meetings</h4>
                    {results.meetings.map(meeting => (
                        <Link 
                            key={meeting._id} 
                            to={`/projects/${meeting.projectId}/${meeting._id.toString()}`} // Ensure we're using exact ID
                            onClick={onResultClick}
                            className="search-result-item"
                        >
                            <div className="result-title">
                                {meeting.meetingTitle}
                                {meeting.matchType === 'title' && 
                                    <span className="match-type">(matched in title)</span>
                                }
                            </div>
                            {meeting.preview && (
                                <div 
                                    className="result-preview match-highlight"
                                    dangerouslySetInnerHTML={{ __html: meeting.preview }}
                                />
                            )}
                            <div className="result-meta">
                                <span className="result-date">
                                    {new Date(meeting.date).toLocaleDateString()}
                                    {meeting.time && ` at ${meeting.time}`}
                                </span>
                            </div>
                        </Link>
                    ))}
                </section>
            )}

            {results.journalEntries.length > 0 && (
                <section>
                    <h4>Journal Entries</h4>
                    {results.journalEntries.map(entry => (
                        <Link 
                            key={entry._id}
                            to={`/${user.user_id}/dailyJournal/${formatDateForUrl(entry.date)}`}
                            onClick={onResultClick}
                            className="search-result-item"
                        >
                            <div className="result-title">
                                {new Date(new Date(entry.date).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString()}
                            </div>
                            <div className="result-preview">{truncateText(entry.content)}</div>
                        </Link>
                    ))}
                </section>
            )}

            {results.tasks.length > 0 && (
                <section>
                    <h4>Tasks</h4>
                    {results.tasks.map(task => (
                        <Link 
                            key={task._id}
                            to={`/${user.user_id}/dailyJournal/${formatDateForUrl(task.date)}`}
                            onClick={onResultClick}
                            className="search-result-item"
                        >
                            <div className="result-title">{task.content}</div>
                            {task.notes && <div className="result-preview">{truncateText(task.notes)}</div>}
                            <div className="result-meta">
                                <span className="task-date">
                                    {new Date(new Date(task.date).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString()}
                                </span>
                                <span className={`task-status status-${task.status}`}>{task.status}</span>
                            </div>
                        </Link>
                    ))}
                </section>
            )}
        </div>
    );
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
                {isActive && <span className="active-indicator"></span>}
            </Link>
        </li>
    );
}