import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Use the actual public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ onSubmit, billingCycle, loading, onBack, userData }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [billingDetails, setBillingDetails] = useState({
        name: userData?.name || '',
        email: userData?.email || '',
        phone: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'US'
        }
    });
    const [clientSecret, setClientSecret] = useState('');
    const [processingPayment, setProcessingPayment] = useState(false);

    // Debug to check if environment variables are loaded
    useEffect(() => {
        console.log('Stripe Public Key:', process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        console.log('Stripe key mode:', process.env.REACT_APP_STRIPE_PUBLIC_KEY?.startsWith('pk_test_') ? 'TEST MODE' : 'LIVE MODE');
        console.log('Monthly price ID:', process.env.REACT_APP_STRIPE_TEST_MONTHLY_PRICE_ID);
        console.log('Yearly price ID:', process.env.REACT_APP_STRIPE_TEST_YEARLY_PRICE_ID);
        
        if (!process.env.REACT_APP_STRIPE_TEST_MONTHLY_PRICE_ID || 
            process.env.REACT_APP_STRIPE_TEST_MONTHLY_PRICE_ID === 'price_test_monthly_id') {
            console.error('WARNING: Using placeholder monthly price ID. Update with actual Stripe price ID.');
        }
        
        if (!process.env.REACT_APP_STRIPE_TEST_YEARLY_PRICE_ID || 
            process.env.REACT_APP_STRIPE_TEST_YEARLY_PRICE_ID === 'price_test_yearly_id') {
            console.error('WARNING: Using placeholder yearly price ID. Update with actual Stripe price ID.');
        }
    }, []);

    // Get client secret from server for direct payment confirmation
    useEffect(() => {
        if (billingCycle) {
            const fetchSetupIntent = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/create-setup-intent`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    
                    const data = await response.json();
                    setClientSecret(data.clientSecret);
                } catch (error) {
                    console.error('Error fetching setup intent:', error);
                    setError('Failed to initialize secure payment. Please try again.');
                }
            };
            
            fetchSetupIntent();
        }
    }, [billingCycle]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setBillingDetails(prev => ({
                ...prev,
                [parent]: {
                    ...prev[parent],
                    [child]: value
                }
            }));
        } else {
            setBillingDetails(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements || !clientSecret) return;
        
        setProcessingPayment(true);
        setError(null);

        try {
            // Create payment method
            const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: billingDetails
            });

            if (paymentMethodError) {
                setError(paymentMethodError.message);
                setProcessingPayment(false);
                return;
            }

            // Submit to server
            const response = await onSubmit({
                paymentMethodId: paymentMethod.id,
                priceId: billingCycle === 'monthly' 
                    ? process.env.REACT_APP_STRIPE_TEST_MONTHLY_PRICE_ID
                    : process.env.REACT_APP_STRIPE_TEST_YEARLY_PRICE_ID,
                billingDetails,
                clientSecret
            });

            // Handle any required additional actions
            if (response.requiresAction) {
                const { error: confirmError } = await stripe.confirmCardPayment(response.clientSecret);
                if (confirmError) {
                    setError(confirmError.message);
                    setProcessingPayment(false);
                    return;
                }

                // Confirm the payment completion with the server
                await fetch(`${process.env.REACT_APP_API_URL}/confirm-payment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentIntentId: response.paymentIntentId,
                    }),
                });
            }

            // Payment successful
            setProcessingPayment(false);
        } catch (err) {
            setError('An error occurred while processing your payment.');
            setProcessingPayment(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>Full Name</label>
                        <input
                            type="text"
                            name="name"
                            className="register-form-control"
                            value={billingDetails.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            className="register-form-control"
                            value={billingDetails.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="register-form-group">
                <label>Phone Number</label>
                <input
                    type="tel"
                    name="phone"
                    className="register-form-control"
                    value={billingDetails.phone}
                    onChange={handleInputChange}
                    required
                />
            </div>

            <div className="register-form-group">
                <label>Address Line 1</label>
                <input
                    type="text"
                    name="address.line1"
                    className="register-form-control"
                    value={billingDetails.address.line1}
                    onChange={handleInputChange}
                    required
                />
            </div>

            <div className="register-form-group">
                <label>Address Line 2 (Optional)</label>
                <input
                    type="text"
                    name="address.line2"
                    className="register-form-control"
                    value={billingDetails.address.line2}
                    onChange={handleInputChange}
                />
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>City</label>
                        <input
                            type="text"
                            name="address.city"
                            className="register-form-control"
                            value={billingDetails.address.city}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>State</label>
                        <input
                            type="text"
                            name="address.state"
                            className="register-form-control"
                            value={billingDetails.address.state}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>ZIP/Postal Code</label>
                        <input
                            type="text"
                            name="address.postal_code"
                            className="register-form-control"
                            value={billingDetails.address.postal_code}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="register-form-group">
                        <label>Country</label>
                        <select
                            name="address.country"
                            className="register-form-control"
                            value={billingDetails.address.country}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="US">United States</option>
                            <option value="CA">Canada</option>
                            {/* Add more countries as needed */}
                        </select>
                    </div>
                </div>
            </div>

            <div className="register-form-group">
                <label>Card Details</label>
                <div className="stripe-card-element">
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>
            </div>

            {error && <div className="register-error-message">{error}</div>}
            
            <div className="d-flex justify-content-between mt-4">
                <button
                    type="button"
                    className="register-btn-secondary"
                    onClick={onBack}
                    disabled={loading || processingPayment}
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="register-btn-primary"
                    disabled={loading || processingPayment || !clientSecret || !stripe}
                >
                    {loading || processingPayment ? 'Processing...' : `Pay ${billingCycle === 'monthly' ? '$2.99' : '$29.99'}`}
                </button>
            </div>
            
            {/* Show secure payment badge */}
            <div className="secure-payment-badge">
                <div className="lock-icon">🔒</div>
                <div className="secure-text">Secure checkout via Stripe</div>
            </div>
        </form>
    );
};

export default function PaymentForm(props) {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm {...props} />
        </Elements>
    );
}
