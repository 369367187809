import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks for epics and comments
export const fetchEpics = createAsyncThunk(
    'agileHub/fetchEpics',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const createEpic = createAsyncThunk(
    'agileHub/createEpic',
    async ({ projectId, epicData }, { rejectWithValue }) => {
        try {
            // Normalize tags if they're passed as a string
            if (typeof epicData.tags === 'string') {
                epicData.tags = epicData.tags.split(',').map(tag => tag.trim());
            }

            // Ensure dates are in ISO format
            if (epicData.startDate) {
                epicData.startDate = new Date(epicData.startDate).toISOString();
            }
            if (epicData.targetDate) {
                epicData.targetDate = new Date(epicData.targetDate).toISOString();
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics`,
                epicData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateEpic = createAsyncThunk(
    'agileHub/updateEpic',
    async ({ projectId, epicId, epicData }, { rejectWithValue }) => {
        try {
            // Similar normalizations as createEpic
            if (typeof epicData.tags === 'string') {
                epicData.tags = epicData.tags.split(',').map(tag => tag.trim());
            }

            if (epicData.startDate) {
                epicData.startDate = new Date(epicData.startDate).toISOString();
            }
            if (epicData.targetDate) {
                epicData.targetDate = new Date(epicData.targetDate).toISOString();
            }

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics/${epicId}`, 
                epicData
            );
            return { ...epicData, _id: epicId };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteEpic = createAsyncThunk(
    'agileHub/deleteEpic',
    async ({ projectId, epicId }, { rejectWithValue }) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics/${epicId}`);
            return epicId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Comment-related thunks
export const fetchComments = createAsyncThunk(
    'agileHub/fetchComments',
    async ({ projectId, epicId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics/${epicId}/comments`
            );
            return { epicId, comments: response.data };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addComment = createAsyncThunk(
    'agileHub/addComment',
    async ({ projectId, epicId, content, author }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics/${epicId}/comments`,
                { content, author }
            );
            return { epicId, comment: response.data };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateComment = createAsyncThunk(
    'agileHub/updateComment',
    async ({ projectId, epicId, commentId, content }, { rejectWithValue }) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics/${epicId}/comments/${commentId}`,
                { content }
            );
            return { epicId, commentId, content };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new thunk for fetching available team members
export const fetchTeamMembers = createAsyncThunk(
    'agileHub/fetchTeamMembers',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/team`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new thunk for fetching available epics for dependencies
export const fetchAvailableEpics = createAsyncThunk(
    'agileHub/fetchAvailableEpics',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/epics`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new thunks for user stories
export const fetchUserStories = createAsyncThunk(
    'agileHub/fetchUserStories',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const createUserStory = createAsyncThunk(
    'agileHub/createUserStory',
    async ({ projectId, userStoryData }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories`,
                userStoryData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new thunks for updating and deleting user stories
export const updateUserStory = createAsyncThunk(
    'agileHub/updateUserStory',
    async ({ projectId, storyId, userStoryData }, { rejectWithValue }) => {
        try {
            // Ensure we're not sending immutable fields
            const { _id, createdAt, updatedAt, ...updateData } = userStoryData;
            
            console.log(`Updating story ${storyId} with data:`, updateData);
            
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories/${storyId}`,
                updateData
            );
            
            // If the update included a status change and the story is part of a sprint,
            // we should update both userStories and sprintStories
            return { 
                storyId, 
                updatedData: updateData,
                response: response.data 
            };
        } catch (error) {
            console.error('Error in updateUserStory:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteUserStory = createAsyncThunk(
    'agileHub/deleteUserStory',
    async ({ projectId, storyId }, { rejectWithValue }) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories/${storyId}`);
            return storyId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new thunks for user story comments
export const fetchStoryComments = createAsyncThunk(
    'agileHub/fetchStoryComments',
    async ({ projectId, storyId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories/${storyId}/comments`
            );
            return { storyId, comments: response.data };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addStoryComment = createAsyncThunk(
    'agileHub/addStoryComment',
    async ({ projectId, storyId, content, author }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories/${storyId}/comments`,
                { content, author }
            );
            return { storyId, comment: response.data };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateStoryComment = createAsyncThunk(
    'agileHub/updateStoryComment',
    async ({ projectId, storyId, commentId, content }, { rejectWithValue }) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/userstories/${storyId}/comments/${commentId}`,
                { content }
            );
            return { storyId, commentId, content };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Add new sprint-related thunks
export const createSprint = createAsyncThunk(
    'agileHub/createSprint',
    async ({ projectId, sprintData }, { rejectWithValue }) => {
        try {
            console.log('Creating sprint with data:', sprintData); // Debug logging
            
            // Make sure we're passing the stories correctly
            const payload = {
                name: sprintData.name,
                startDate: sprintData.startDate,
                endDate: sprintData.endDate,
                goal: sprintData.goal,
                totalPoints: sprintData.totalPoints,
                isActive: sprintData.isActive || true,
                stories: sprintData.stories || sprintData.selectedStories || [] // Handle both formats
            };
            
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/sprints`,
                payload
            );
            return response.data;
        } catch (error) {
            console.error('Error in createSprint:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchCurrentSprint = createAsyncThunk(
    'agileHub/fetchCurrentSprint',
    async (projectId, { rejectWithValue, dispatch }) => {
        try {
            // First fetch all user stories to ensure they're in the store
            await dispatch(fetchUserStories(projectId)).unwrap();

            // Then fetch the current sprint
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/sprints/current`
            );
            
            console.log('Current sprint API response:', response.data);
            return response.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            }
            return rejectWithValue(error.message);
        }
    }
);

export const endSprint = createAsyncThunk(
    'agileHub/endSprint',
    async ({ projectId, sprintId }, { rejectWithValue }) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/sprints/${sprintId}/end`
            );
            return sprintId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSprintHistory = createAsyncThunk(
    'agileHub/fetchSprintHistory',
    async (projectId, { rejectWithValue }) => {
        try {
            console.log(`Fetching sprint history for project ${projectId}`);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/sprints/history`
            );
            
            console.log('Sprint history response:', response.data);
            
            // Ensure we always return an array, even if the API returns null or undefined
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            console.error('Error fetching sprint history:', error);
            // Return empty array instead of rejecting to avoid breaking UI
            return [];
        }
    }
);

const initialState = {
    features: [],
    featuresLoading: false,
    featuresError: null,
    epics: [],
    userStories: [],
    loading: false,
    error: null,
    comments: {}, // Map of epicId to comments array
    commentsLoading: false,
    commentsError: null,
    availableTeamMembers: [], // Add this
    availableEpics: [], // Add this
    userStoriesLoading: false,
    userStoriesError: null,
    storyComments: {}, // Add this for user story comments
    storyCommentsLoading: false,
    storyCommentsError: null,
    currentSprint: null,
    sprintStories: [],
    sprintLoading: false,
    sprintError: null,
    sprintHistory: [],
    sprintHistoryLoading: false,
    sprintHistoryError: null,
    bugs: [],
    bugsLoading: false,
    bugsError: null,
};

const agileHubSlice = createSlice({
    name: 'agileHub',
    initialState,
    reducers: {
        setFeatures: (state, action) => {
            state.features = action.payload;
        },
        setEpics: (state, action) => {
            state.epics = action.payload;
        },
        setUserStories: (state, action) => {
            state.userStories = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearComments: (state) => {
            state.comments = {};
        }
    },
    extraReducers: (builder) => {
        builder
            // Epic thunks handling
            .addCase(fetchEpics.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEpics.fulfilled, (state, action) => {
                state.epics = action.payload;
                state.loading = false;
            })
            .addCase(fetchEpics.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createEpic.fulfilled, (state, action) => {
                state.epics.push(action.payload);
            })
            .addCase(updateEpic.fulfilled, (state, action) => {
                const index = state.epics.findIndex(epic => epic._id === action.payload._id);
                if (index !== -1) {
                    state.epics[index] = { ...state.epics[index], ...action.payload };
                }
            })
            .addCase(deleteEpic.fulfilled, (state, action) => {
                state.epics = state.epics.filter(epic => epic._id !== action.payload);
            })
            // Comment thunks handling
            .addCase(fetchComments.pending, (state) => {
                state.commentsLoading = true;
                state.commentsError = null;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                const { epicId, comments } = action.payload;
                state.comments[epicId] = comments;
                state.commentsLoading = false;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.commentsLoading = false;
                state.commentsError = action.payload;
            })
            .addCase(addComment.fulfilled, (state, action) => {
                const { epicId, comment } = action.payload;
                if (!state.comments[epicId]) {
                    state.comments[epicId] = [];
                }
                state.comments[epicId].push(comment);
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                const { epicId, commentId, content } = action.payload;
                if (state.comments[epicId]) {
                    const commentIndex = state.comments[epicId].findIndex(c => c._id === commentId);
                    if (commentIndex !== -1) {
                        state.comments[epicId][commentIndex].content = content;
                        state.comments[epicId][commentIndex].updatedAt = new Date().toISOString();
                    }
                }
            })
            .addCase(fetchTeamMembers.fulfilled, (state, action) => {
                state.availableTeamMembers = action.payload;
            })
            .addCase(fetchAvailableEpics.fulfilled, (state, action) => {
                state.availableEpics = action.payload;
            })
            // User story thunks handling
            .addCase(fetchUserStories.pending, (state) => {
                state.userStoriesLoading = true;
                state.userStoriesError = null;
            })
            .addCase(fetchUserStories.fulfilled, (state, action) => {
                state.userStories = action.payload;
                state.userStoriesLoading = false;
            })
            .addCase(fetchUserStories.rejected, (state, action) => {
                state.userStoriesLoading = false;
                state.userStoriesError = action.payload;
            })
            .addCase(createUserStory.fulfilled, (state, action) => {
                state.userStories.push(action.payload);
            })
            .addCase(updateUserStory.fulfilled, (state, action) => {
                const { storyId, updatedData } = action.payload;
    
                // Update in userStories array
                const storyIndex = state.userStories.findIndex(story => story._id === storyId);
                if (storyIndex !== -1) {
                    state.userStories[storyIndex] = { 
                        ...state.userStories[storyIndex], 
                        ...updatedData 
                    };
                }
                
                // Also update in sprintStories if the story is part of current sprint
                const sprintStoryIndex = state.sprintStories.findIndex(story => story._id === storyId);
                if (sprintStoryIndex !== -1) {
                    state.sprintStories[sprintStoryIndex] = {
                        ...state.sprintStories[sprintStoryIndex],
                        ...updatedData
                    };
                }
            })
            .addCase(deleteUserStory.fulfilled, (state, action) => {
                state.userStories = state.userStories.filter(story => story._id !== action.payload);
            })
            // User story comments thunks handling
            .addCase(fetchStoryComments.pending, (state) => {
                state.storyCommentsLoading = true;
                state.storyCommentsError = null;
            })
            .addCase(fetchStoryComments.fulfilled, (state, action) => {
                const { storyId, comments } = action.payload;
                state.storyComments[storyId] = comments;
                state.storyCommentsLoading = false;
            })
            .addCase(fetchStoryComments.rejected, (state, action) => {
                state.storyCommentsLoading = false;
                state.storyCommentsError = action.payload;
            })
            .addCase(addStoryComment.fulfilled, (state, action) => {
                const { storyId, comment } = action.payload;
                if (!state.storyComments[storyId]) {
                    state.storyComments[storyId] = [];
                }
                state.storyComments[storyId].push(comment);
            })
            .addCase(updateStoryComment.fulfilled, (state, action) => {
                const { storyId, commentId, content } = action.payload;
                if (state.storyComments[storyId]) {
                    const commentIndex = state.storyComments[storyId].findIndex(c => c._id === commentId);
                    if (commentIndex !== -1) {
                        state.storyComments[storyId][commentIndex].content = content;
                        state.storyComments[storyId][commentIndex].updatedAt = new Date().toISOString();
                    }
                }
            })
            // Sprint cases
            .addCase(createSprint.pending, (state) => {
                state.sprintLoading = true;
                state.sprintError = null;
            })
            .addCase(createSprint.fulfilled, (state, action) => {
                state.currentSprint = action.payload;
                state.sprintLoading = false;
                
                // The stories are now in action.payload.stories
                if (action.payload.stories) {
                    state.sprintStories = action.payload.stories;
                    
                    // Also update the sprintId reference in userStories
                    const sprintId = action.payload._id;
                    const storyIds = action.payload.stories.map(story => story._id);
                    
                    state.userStories = state.userStories.map(story => 
                        storyIds.includes(story._id) 
                            ? { ...story, sprintId, status: 'to do' } // Use lowercase status
                            : story
                    );
                }
            })
            .addCase(createSprint.rejected, (state, action) => {
                state.sprintLoading = false;
                state.sprintError = action.payload;
            })
            .addCase(fetchCurrentSprint.fulfilled, (state, action) => {
                if (action.payload) {
                    state.currentSprint = action.payload.sprint;
                    state.sprintStories = action.payload.stories || [];
                    
                    console.log('Setting sprint stories in Redux:', state.sprintStories);
                    
                    // Update user stories that are part of this sprint
                    if (action.payload.stories && action.payload.stories.length > 0) {
                        const sprintStoryIds = action.payload.stories.map(story => story._id);
                        state.userStories = state.userStories.map(story => {
                            // If this story is in the sprint stories, update its properties
                            if (sprintStoryIds.includes(story._id)) {
                                // Find the matching sprint story to get its current properties
                                const sprintStory = action.payload.stories.find(s => s._id === story._id);
                                return { ...story, ...sprintStory };
                            }
                            return story;
                        });
                    }
                } else {
                    state.currentSprint = null;
                    state.sprintStories = [];
                }
            })
            .addCase(endSprint.fulfilled, (state) => {
                // When a sprint ends, all non-done stories should return to backlog
                const sprintStoryIds = state.sprintStories
                    .filter(story => story.status?.toLowerCase() !== 'done')
                    .map(story => story._id);
                
                // Update user stories that were in the sprint but not done
                state.userStories = state.userStories.map(story => {
                    if (sprintStoryIds.includes(story._id)) {
                        // Important: Remove the sprintId property completely
                        const { sprintId, ...storyWithoutSprintId } = story;
                        return {
                            ...storyWithoutSprintId,
                            status: 'backlog'
                        };
                    }
                    return story;
                });
                
                state.currentSprint = null;
                state.sprintStories = [];
            })
            .addCase(fetchSprintHistory.pending, (state) => {
                state.sprintHistoryLoading = true;
                state.sprintHistoryError = null;
            })
            .addCase(fetchSprintHistory.fulfilled, (state, action) => {
                // Ensure we always store an array
                state.sprintHistory = Array.isArray(action.payload) ? action.payload : [];
                state.sprintHistoryLoading = false;
            })
            .addCase(fetchSprintHistory.rejected, (state, action) => {
                state.sprintHistoryLoading = false;
                state.sprintHistoryError = action.payload;
                // Ensure we always have at least an empty array
                state.sprintHistory = [];
            })
            // Fetch Bugs
            .addCase(fetchBugs.pending, (state) => {
                state.bugsLoading = true;
                state.bugsError = null;
            })
            .addCase(fetchBugs.fulfilled, (state, action) => {
                state.bugs = action.payload;
                state.bugsLoading = false;
            })
            .addCase(fetchBugs.rejected, (state, action) => {
                state.bugsLoading = false;
                state.bugsError = action.error.message;
            })
            // Create Bug
            .addCase(createBug.fulfilled, (state, action) => {
                state.bugs.push(action.payload);
            })
            // Update Bug
            .addCase(updateBug.fulfilled, (state, action) => {
                const index = state.bugs.findIndex(bug => bug._id === action.meta.arg.bugId);
                if (index !== -1) {
                    // Preserve original fields like bugNumber when updating
                    state.bugs[index] = { 
                        ...state.bugs[index],  // Keep all original fields
                        ...action.payload,     // Apply updates
                        _id: action.meta.arg.bugId,  // Make sure ID is preserved
                        bugNumber: state.bugs[index].bugNumber  // Ensure bugNumber is preserved
                    };
                }
            })
            // Delete Bug
            .addCase(deleteBug.fulfilled, (state, action) => {
                state.bugs = state.bugs.filter(bug => bug._id !== action.payload);
            })
            // Feature thunks handling
            .addCase(fetchFeatures.pending, (state) => {
                state.featuresLoading = true;
                state.featuresError = null;
            })
            .addCase(fetchFeatures.fulfilled, (state, action) => {
                state.features = action.payload;
                state.featuresLoading = false;
            })
            .addCase(fetchFeatures.rejected, (state, action) => {
                state.featuresLoading = false;
                state.featuresError = action.payload;
            })
            .addCase(createFeature.fulfilled, (state, action) => {
                state.features.push(action.payload);
            })
            .addCase(updateFeature.fulfilled, (state, action) => {
                const index = state.features.findIndex(feature => feature._id === action.payload._id);
                if (index !== -1) {
                    state.features[index] = action.payload;
                }
            })
            .addCase(deleteFeature.fulfilled, (state, action) => {
                state.features = state.features.filter(feature => feature._id !== action.payload);
            });
    }
});

// Bug-related thunks
export const fetchBugs = createAsyncThunk(
    'agileHub/fetchBugs',
    async (projectId) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/bugs`);
        return response.data;
    }
);

export const createBug = createAsyncThunk(
    'agileHub/createBug',
    async ({ projectId, bugData }) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/bugs`, bugData);
        return response.data;
    }
);

// Update the updateBug thunk to return the complete bug object, including ID fields
export const updateBug = createAsyncThunk(
    'agileHub/updateBug',
    async ({ projectId, bugId, bugData }) => {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/bugs/${bugId}`, bugData);
        
        // Make sure we return a complete bug object with the ID fields
        return {
            ...response.data,
            _id: bugId,  // Ensure the _id is included
            bugNumber: response.data.bugNumber || bugData.bugNumber // Keep the original bugNumber if available
        };
    }
);

export const deleteBug = createAsyncThunk(
    'agileHub/deleteBug',
    async ({ projectId, bugId }) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/bugs/${bugId}`);
        return bugId;
    }
);

// Feature action creators
export const fetchFeatures = createAsyncThunk(
    'agileHub/fetchFeatures',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/features`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch features');
        }
    }
);

export const createFeature = createAsyncThunk(
    'agileHub/createFeature',
    async ({ projectId, featureData }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/features`,
                featureData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create feature');
        }
    }
);

export const updateFeature = createAsyncThunk(
    'agileHub/updateFeature',
    async ({ projectId, featureId, featureData }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/features/${featureId}`,
                featureData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to update feature');
        }
    }
);

export const deleteFeature = createAsyncThunk(
    'agileHub/deleteFeature',
    async ({ projectId, featureId }, { rejectWithValue }) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/api/agilehub/projects/${projectId}/features/${featureId}`
            );
            return featureId;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete feature');
        }
    }
);

export const { setFeatures, setEpics, setUserStories, setLoading, setError, clearComments } = agileHubSlice.actions;
export default agileHubSlice.reducer;
