import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash, faPlus, faSpinner, faEdit, faBan, faCheck, faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoryComments, addStoryComment, updateStoryComment } from '../../../redux/slices/agileHubSlice';

// Import utility functions
import { getUserDisplayName, formatCommentDate } from '../utils/epicUtils';

const UserStoryDetailsSlider = ({ 
    showSlider,
    sliderClosing,
    closeSlider,
    currentStory,
    handleInputChange,
    handleUpdateStory,
    handleDeleteStory,
    availableEpics = [],
    availableStories = [],
    availableSprints = [],
    projectId
}) => {
    const [localStory, setLocalStory] = useState({...currentStory});
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);
    const dispatch = useDispatch();
    const { storyComments, storyCommentsLoading } = useSelector(state => state.agileHub);
    const { user } = useSelector(state => state.auth);
    const [newComment, setNewComment] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedCommentContent, setEditedCommentContent] = useState('');

    useEffect(() => {
        setLocalStory({...currentStory});
    }, [currentStory]);

    const handleFieldChange = (field, value) => {
        setLocalStory(prev => ({ ...prev, [field]: value }));
        setIsSaving(true);

        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        const timeoutId = setTimeout(async () => {
            try {
                await handleUpdateStory({
                    ...localStory,
                    [field]: value
                });
            } finally {
                setIsSaving(false);
            }
        }, 1000);

        setSaveTimeout(timeoutId);
    };

    const activeStoryComments = localStory._id ? storyComments[localStory._id] || [] : [];

    useEffect(() => {
        if (localStory._id) {
            dispatch(fetchStoryComments({ projectId, storyId: localStory._id }));
        }
    }, [dispatch, projectId, localStory._id]);

    const handleCommentSubmit = async () => {
        if (!newComment.trim() || !localStory._id) return;
        
        await dispatch(addStoryComment({
            projectId,
            storyId: localStory._id,
            content: newComment,
            author: getUserDisplayName(user)
        }));
        
        setNewComment('');
    };

    const handleUpdateComment = async (commentId) => {
        if (!editedCommentContent.trim()) return;
        
        await dispatch(updateStoryComment({
            projectId,
            storyId: localStory._id,
            commentId,
            content: editedCommentContent
        }));
        
        setEditingCommentId(null);
        setEditedCommentContent('');
    };

    const canEditComment = (commentAuthor) => {
        if (!user) return false;
        const currentUserName = getUserDisplayName(user).toLowerCase();
        const commentUserName = commentAuthor.toLowerCase();
        return currentUserName === commentUserName;
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">{localStory.title || 'User Story Details'}</h2>
                    <div className="sliding-panel-subtitle">
                        Story #{localStory.storyNumber || localStory._id?.substring(0, 8)}
                        <div className="epic-dates">
                            <span>Created: {new Date(localStory.createdAt).toLocaleDateString()}</span>
                            {localStory.updatedAt && (
                                <span> • Last Updated: {new Date(localStory.updatedAt).toLocaleDateString()}</span>
                            )}
                        </div>
                    </div>
                    {isSaving && (
                        <div className="saving-indicator">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <span className="ms-2">Saving...</span>
                        </div>
                    )}
                </div>
                
                <div className="sliding-panel-content">
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.title || ''}
                                onChange={(e) => handleFieldChange('title', e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={localStory.description || ''}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">User Story Format</h3>
                        <div className="mb-3">
                            <label className="form-label">As a</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.asA || ''}
                                onChange={(e) => handleFieldChange('asA', e.target.value)}
                                placeholder="Type of user (e.g., Admin, Customer, Manager)"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">I want to</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.iWantTo || ''}
                                onChange={(e) => handleFieldChange('iWantTo', e.target.value)}
                                placeholder="Action or feature desired"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">So that</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.soThat || ''}
                                onChange={(e) => handleFieldChange('soThat', e.target.value)}
                                placeholder="Benefit or value achieved"
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Planning</h3>
                        <div className="epic-detail-grid">
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Epic</div>
                                <select
                                    className="form-select"
                                    value={localStory.epicId || ''}
                                    onChange={(e) => handleFieldChange('epicId', e.target.value)}
                                >
                                    <option value="">Select Epic</option>
                                    {availableEpics.map(epic => (
                                        <option key={epic._id} value={epic._id}>
                                            {epic.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Priority</div>
                                <select
                                    className="form-select"
                                    value={localStory.priority || 'Medium'}
                                    onChange={(e) => handleFieldChange('priority', e.target.value)}
                                >
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Status</div>
                                <select
                                    className="form-select"
                                    value={localStory.status || 'Backlog'}
                                    onChange={(e) => handleFieldChange('status', e.target.value)}
                                >
                                    <option value="Backlog">Backlog</option>
                                    <option value="Ready">Ready</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Review">Review</option>
                                    <option value="Done">Done</option>
                                    <option value="Blocked">Blocked</option>
                                </select>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Story Points</div>
                                <select
                                    className="form-select"
                                    value={localStory.storyPoints || ''}
                                    onChange={(e) => handleFieldChange('storyPoints', e.target.value)}
                                >
                                    <option value="">Select Points</option>
                                    {[1, 2, 3, 5, 8, 13, 21].map(points => (
                                        <option key={points} value={points}>{points}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title d-flex justify-content-between align-items-center">
                            Acceptance Criteria
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    const newCriteria = [
                                        ...(localStory.acceptanceCriteria || []),
                                        { text: '', completed: false }
                                    ];
                                    handleFieldChange('acceptanceCriteria', newCriteria);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="me-1" />
                                Add Criterion
                            </button>
                        </h3>
                        
                        <div className="success-criteria-list">
                            {localStory.acceptanceCriteria?.length > 0 ? (
                                localStory.acceptanceCriteria.map((criterion, index) => (
                                    <div 
                                        key={index} 
                                        className={`success-criterion-item ${criterion.completed ? 'completed' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={criterion.completed}
                                            onChange={(e) => {
                                                const newCriteria = [...localStory.acceptanceCriteria];
                                                newCriteria[index] = {
                                                    ...criterion,
                                                    completed: e.target.checked
                                                };
                                                handleFieldChange('acceptanceCriteria', newCriteria);
                                            }}
                                            id={`criterion-${index}`}
                                        />
                                        
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={criterion.text}
                                            onChange={(e) => {
                                                const newCriteria = [...localStory.acceptanceCriteria];
                                                newCriteria[index] = {
                                                    ...criterion,
                                                    text: e.target.value
                                                };
                                                handleFieldChange('acceptanceCriteria', newCriteria);
                                            }}
                                            placeholder="Enter acceptance criterion..."
                                        />
                                        <button
                                            className="btn btn-outline-danger btn-remove"
                                            onClick={() => {
                                                const newCriteria = localStory.acceptanceCriteria.filter((_, i) => i !== index);
                                                handleFieldChange('acceptanceCriteria', newCriteria);
                                            }}
                                            title="Remove criterion"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted text-center py-3">
                                    No acceptance criteria defined. Add criteria to define when this story is complete.
                                </p>
                            )}
                        </div>
                        <div className="success-criteria-progress mt-3">
                            {localStory.acceptanceCriteria?.length > 0 && (
                                <>
                                    <div className="progress" style={{ height: '8px' }}>
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${(localStory.acceptanceCriteria.filter(c => c.completed).length / 
                                                        localStory.acceptanceCriteria.length) * 100}%`
                                            }}
                                        ></div>
                                    </div>
                                    <div className="text-muted text-end mt-2 small">
                                        {localStory.acceptanceCriteria.filter(c => c.completed).length} of {localStory.acceptanceCriteria.length} completed
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Technical Details</h3>
                        <div className="mb-3">
                            <label className="form-label">Dependencies</label>
                            <select
                                className="form-select"
                                multiple
                                value={localStory.dependencies || []}
                                onChange={(e) => handleFieldChange('dependencies', 
                                    Array.from(e.target.selectedOptions, option => option.value))}
                            >
                                {availableStories.map(story => (
                                    <option key={story._id} value={story._id}>
                                        US-{story.storyNumber}: {story.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Technical Notes</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={localStory.technicalNotes || ''}
                                onChange={(e) => handleFieldChange('technicalNotes', e.target.value)}
                                placeholder="Implementation details, architectural considerations, etc."
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Testing & Quality</h3>
                        <div className="mb-3">
                            <label className="form-label d-flex justify-content-between align-items-center">
                                Test Scenarios
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        const newScenarios = [...(localStory.testScenarios || []), ''];
                                        handleFieldChange('testScenarios', newScenarios);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Test Scenario
                                </button>
                            </label>
                            <div className="test-scenarios">
                                {(localStory.testScenarios || []).map((scenario, index) => (
                                    <div key={index} className="input-group mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={scenario}
                                            onChange={(e) => {
                                                const newScenarios = [...localStory.testScenarios];
                                                newScenarios[index] = e.target.value;
                                                handleFieldChange('testScenarios', newScenarios);
                                            }}
                                            placeholder="Enter test scenario"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => {
                                                const newScenarios = localStory.testScenarios.filter((_, i) => i !== index);
                                                handleFieldChange('testScenarios', newScenarios);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Planning & Tracking</h3>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Sprint</label>
                                <select
                                    className="form-select"
                                    value={localStory.sprintId || ''}
                                    onChange={(e) => handleFieldChange('sprintId', e.target.value)}
                                >
                                    <option value="">Backlog</option>
                                    {availableSprints.map(sprint => (
                                        <option key={sprint._id} value={sprint._id}>
                                            {sprint.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Estimated Time (hours)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={localStory.estimatedHours || ''}
                                    onChange={(e) => handleFieldChange('estimatedHours', e.target.value)}
                                    min="0"
                                    step="0.5"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tags</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.tags || ''}
                                onChange={(e) => handleFieldChange('tags', e.target.value)}
                                placeholder="Enter tags separated by commas"
                            />
                        </div>
                    </div>

                    <div className="epic-comments-section">
                        <div className="epic-comments-header">
                            <h3 className="section-title d-flex justify-content-between align-items-center">
                                Comments (
                                <span className="comments-count">
                                    {activeStoryComments.length}
                                </span>)
                            </h3>
                        </div>
                        
                        <div className="epic-comments-list">
                            {storyCommentsLoading ? (
                                <div className="text-center p-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : activeStoryComments.length > 0 ? (
                                activeStoryComments.map(comment => (
                                    <div 
                                        key={comment._id} 
                                        className={`comment-item ${editingCommentId === comment._id ? 'editing' : ''}`}
                                    >
                                        <div className="comment-header">
                                            <span className="comment-author">
                                                {comment.author}
                                            </span>
                                            <div className="comment-actions">
                                                {canEditComment(comment.author) && editingCommentId !== comment._id && (
                                                    <button 
                                                        className="btn btn-sm btn-link text-muted p-0 ms-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setEditingCommentId(comment._id);
                                                            setEditedCommentContent(comment.content);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                )}
                                                <span className="comment-date">
                                                    {formatCommentDate(comment.createdAt)}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        {editingCommentId === comment._id ? (
                                            <div className="comment-edit-form">
                                                <textarea
                                                    className="form-control"
                                                    value={editedCommentContent}
                                                    onChange={(e) => setEditedCommentContent(e.target.value)}
                                                    rows={3}
                                                />
                                                <div className="mt-2 d-flex gap-2">
                                                    <button 
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => setEditingCommentId(null)}
                                                    >
                                                        <FontAwesomeIcon icon={faBan} className="me-1" />
                                                        Cancel
                                                    </button>
                                                    <button 
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => handleUpdateComment(comment._id)}
                                                        disabled={!editedCommentContent.trim()}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} className="me-1" />
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="comment-content">
                                                {comment.content}
                                                {comment.updatedAt && comment.updatedAt !== comment.createdAt && (
                                                    <span className="text-muted ms-2 small">
                                                        (edited)
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faComment} size="2x" className="text-muted mb-2" />
                                    <p className="text-muted mb-0">No comments yet</p>
                                </div>
                            )}
                        </div>
                        
                        <div className="comment-form mt-3">
                            <textarea
                                className="form-control mb-2"
                                placeholder="Add a comment..."
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                rows={2}
                            />
                            <div className="d-flex justify-content-end">
                                <button 
                                    className="btn btn-primary"
                                    onClick={handleCommentSubmit}
                                    disabled={!newComment.trim()}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                    Post Comment
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="epic-actions d-flex justify-content-end">
                        <button 
                            className="btn btn-danger"
                            onClick={() => {
                                if(window.confirm('Are you sure you want to delete this user story?')) {
                                    handleDeleteStory(localStory._id);
                                    closeSlider();
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Story
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStoryDetailsSlider;
