import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import NavbarAuth from './Navbar_Authenticated';
import { useAuth } from "../Components/AuthContext.js";
import "./ComponentStyling/DailyJournal.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ReactGA from 'react-ga4';
import { Modal, Button } from 'react-bootstrap';
import { FaInfoCircle, FaClock, FaPlay, FaPause, FaStar, FaSave } from 'react-icons/fa'; // Add FaSave
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import ProjectTag from './ProjectTag';
import { useDispatch, useSelector } from 'react-redux';
import { 
    fetchJournalTasks, 
    fetchJournalEntry, 
    saveJournalEntry,
    setSelectedDate,
    updateTask,
    moveTask 
} from '../redux/slices/dailyJournalSlice';
import { isFeatureAvailable } from '../utils/featureUtils';
import { FEATURES, ACCOUNT_TYPES } from '../config/featureAccess';

// Register chart components
Chart.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, zoomPlugin);

const DailyJournal = () => {
    const dispatch = useDispatch();
    const { userId, date: urlDate } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    // Replace local state with Redux selectors
    const {
        tasks,
        journalEntry,
        selectedDate: reduxSelectedDate,
        loading
    } = useSelector(state => state.dailyJournal);

    const userProfile = useSelector(state => state.profile.data);
    const accountType = userProfile?.accountType;
    const isAdmin = userProfile?.isAdmin || false;

    // Debug the values
    console.log('Account Type Debug:', {
        rawAccountType: accountType,
        isAdmin,
        canAccessAnalytics: isFeatureAvailable('ADVANCED_ANALYTICS', accountType, isAdmin),
        expectedProfessionalType: ACCOUNT_TYPES.PROFESSIONAL,
        isMatch: accountType === ACCOUNT_TYPES.PROFESSIONAL
    });

    // Update the feature check to use proper account type comparison
    const canAccessAnalytics = isFeatureAvailable(
        'ADVANCED_ANALYTICS',
        accountType === 'Professional' ? ACCOUNT_TYPES.PROFESSIONAL : accountType,
        isAdmin
    );

    const [newTask, setNewTask] = useState('');
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [quillEditor, setQuillEditor] = useState(null);
    const [journalContent, setJournalContent] = useState('');
    const [journalEntryId, setJournalEntryId] = useState(null);
    const [editingCriticality, setEditingCriticality] = useState(null);
    const [sortBy, setSortBy] = useState('none'); // Add this state
    const [selectedTask, setSelectedTask] = useState(null); // Add this state
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [taskNotes, setTaskNotes] = useState('');
    const [taskDueDate, setTaskDueDate] = useState('');
    const [modalTaskContent, setModalTaskContent] = useState('');
    const [modalStoryPoints, setModalStoryPoints] = useState('0');
    const [modalCriticality, setModalCriticality] = useState('L');
    const [modalTaskTitle, setModalTaskTitle] = useState('');
    const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null); // Add this state

    // Add these new states for project integration
    const [userProjects, setUserProjects] = useState([]);
    const [modalProjectId, setModalProjectId] = useState('');
    const [selectedProjectName, setSelectedProjectName] = useState('');
    const [projectHashtags, setProjectHashtags] = useState({});
    const [projectSuggestions, setProjectSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const inputRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

    // Add new state for time tracking
    const [activeTimers, setActiveTimers] = useState({});
    const [taskTimeLogs, setTaskTimeLogs] = useState({});
    const [timerIntervals, setTimerIntervals] = useState({});
    const [focusTasks, setFocusTasks] = useState([]);

    // Add new state for journal edit mode
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Modify getLocalDateString to not set state
    const getLocalDateString = () => {
        if (urlDate) {
            return urlDate;
        }
        const dateParam = searchParams.get('date');
        if (dateParam) {
            return dateParam;
        }
        const localDate = new Date();
        localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
        return localDate.toISOString().split('T')[0];
    };

    const [selectedDate, setSelectedDate] = useState(getLocalDateString());

    // Add new useEffect to handle date-based state updates
    useEffect(() => {
        const date = urlDate || selectedDate;
        if (date) {
            const [year, month] = date.split('-').map(num => parseInt(num, 10));
            setCurrentMonth(month - 1);
            setCurrentYear(year);
        }
    }, [urlDate, selectedDate]);

    useEffect(() => {
        if (user) {
            const newDate = getLocalDateString();
            setSelectedDate(newDate);
            console.log('Setting date from URL or default:', newDate);
            fetchTasks();
            fetchJournalEntry();
        }
    }, [urlDate, user]); // Add searchParams to dependency array

    useEffect(() => {
        if (!quillEditor) {
            const quillConfig = {
                theme: 'snow',
                placeholder: 'Write your journal entry here...',
                formats: [
                    'bold', 'italic', 'underline', 'strike', 'align', 'list', 'indent',
                    'header', 'font', 'color', 'background', 'script', 'direction'
                ],
                modules: {
                    toolbar: [
                        [{ 'font': [] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'align': [] }],
                        ['clean']
                    ],
                    keyboard: {
                        bindings: {
                            space: {
                                key: 32,
                                handler: function(range, context) {
                                    this.quill.insertText(range.index, ' ');
                                    this.quill.setSelection(range.index + 1);
                                }
                            }
                        }
                    }
                }
            };
            
            // Create a fresh editor
            const editorContainer = document.getElementById('editor-journal');
            if (editorContainer) {
                // Clean up any existing toolbars first to prevent duplicates
                const existingToolbar = document.querySelector('.ql-toolbar');
                if (existingToolbar) {
                    existingToolbar.remove();
                }
                
                const quillInstance = new Quill(editorContainer, quillConfig);
                quillInstance.root.style.fontSize = '16px';
                quillInstance.root.style.minHeight = '200px';
                quillInstance.root.style.width = '100%';
                quillInstance.on('text-change', () => {
                    const content = quillInstance.root.innerHTML;
                    // Only set unsaved changes if content actually changed
                    if (content !== journalContent) {
                        setJournalContent(content);
                        setHasUnsavedChanges(true);
                    }
                });
                setQuillEditor(quillInstance);
            }
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                handleSaveJournal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [journalContent, journalEntryId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const handleSaveJournal = async () => {
        if (journalContent.trim() === '') return;

        try {
            if (journalEntryId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/journalEntries/${journalEntryId}`, { content: journalContent });
                console.log('Journal entry saved');
                setHasUnsavedChanges(false); // Reset flag after successful save
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/journalEntries`, {
                    userId: user.user_id,
                    date: selectedDate,
                    content: journalContent
                });
                setJournalEntryId(response.data._id);
                console.log('New journal entry saved');
                setHasUnsavedChanges(false); // Reset flag after successful save
            }
        } catch (error) {
            console.error('Error saving journal entry:', error);
        }
    };

    useEffect(() => {
        if (quillEditor) {
            quillEditor.root.innerHTML = '';
            fetchJournalEntry();
        }
    }, [selectedDate]);

    useEffect(() => {
        if (quillEditor && journalContent) {
            const currentContent = quillEditor.root.innerHTML;
            if (currentContent !== journalContent) {
                const range = quillEditor.getSelection();
                quillEditor.clipboard.dangerouslyPasteHTML(journalContent);
                if (range) {
                    quillEditor.setSelection(range);
                }
                setHasUnsavedChanges(false); // Reset when loading new content
            }
        }
    }, [quillEditor, journalContent]);

    const fetchTasks = async () => {
        try {
            const dateToUse = urlDate || selectedDate;
            const isoDate = new Date(dateToUse).toISOString();
            dispatch(fetchJournalTasks({ 
                userId: user.user_id, 
                date: isoDate 
            }));
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const fetchJournalEntry = async () => {
        try {
            const dateToUse = urlDate || selectedDate;
            console.log('Fetching journal entry for user:', user.user_id, 'and date:', dateToUse);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/journalEntries`, {
                params: { userId: user.user_id, date: dateToUse }
            });
            console.log('Journal entry response:', response.data);
            if (response.data) {
                setJournalContent(response.data.content);
                setJournalEntryId(response.data._id);
                if (quillEditor) {
                    quillEditor.clipboard.dangerouslyPasteHTML(response.data.content);
                }
                setHasUnsavedChanges(false); // Reset when loading new entry
            } else {
                setJournalContent('');
                setJournalEntryId(null);
                if (quillEditor) {
                    quillEditor.root.innerHTML = '';
                }
                setHasUnsavedChanges(false); // Reset when no entry found
            }
        } catch (error) {
            console.error('Error fetching journal entry:', error);
            setJournalContent('');
            setJournalEntryId(null);
            if (quillEditor) {
                quillEditor.root.innerHTML = '';
            }
            setHasUnsavedChanges(false); // Reset on error
        }
    };

    // Add a new useEffect to fetch user projects
    useEffect(() => {
        const fetchUserProjects = async () => {
            if (user && user.user_id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${user.user_id}`);
                    setUserProjects(response.data);
                } catch (error) {
                    console.error('Error fetching user projects:', error);
                }
            }
        };
        
        fetchUserProjects();
    }, [user]);

    // Update useEffect to create project hashtags when projects are loaded
    useEffect(() => {
        const createProjectHashtags = () => {
            const hashtags = {};
            userProjects.forEach(project => {
                // Create multiple variations of the project name for matching
                const projectName = project.project_name;
                const variations = [
                    projectName.toLowerCase(), // original lowercase
                    projectName.toLowerCase().replace(/\s+/g, ''), // no spaces
                    projectName.toLowerCase().replace(/[^\w\s]/gi, ''), // alphanumeric
                    projectName.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, ''), // alphanumeric no spaces
                    projectName.toLowerCase().split(/\s+/).join('-'), // kebab-case
                    projectName.toLowerCase().split(/\s+/).join('_'), // snake_case
                ];

                // Add all variations to hashtags object
                variations.forEach(variant => {
                    hashtags[variant] = {
                        id: project._id,
                        name: project.project_name
                    };
                });
            });
            setProjectHashtags(hashtags);
            console.log('Created project hashtags:', hashtags);
        };
        
        if (userProjects.length > 0) {
            createProjectHashtags();
        }
    }, [userProjects]);

    // Update findProjectFromHashtag to fix project name matching
    const findProjectFromHashtag = (content) => {
        if (!content.includes('#')) return null;

        // Sort projects by name length (longest first) to match most specific names first
        const sortedProjects = [...userProjects].sort((a, b) => 
            b.project_name.length - a.project_name.length
        );

        for (const project of sortedProjects) {
            const escapedProjectName = project.project_name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const pattern = new RegExp(`^#${escapedProjectName}\\b`, 'i');
            
            if (pattern.test(content)) {
                // Remove the hashtag and project name, keeping the rest of the content
                const cleanContent = content.substring(project.project_name.length + 1).trim();
                return {
                    projectId: project._id,
                    projectName: project.project_name,
                    content: cleanContent
                };
            }
        }
        return null;
    };

    // Update handleAddTask to handle the project linking
    const handleAddTask = async () => {
        if (newTask.trim()) {
            const projectMatch = findProjectFromHashtag(newTask);
            const taskToAdd = {
                content: projectMatch ? projectMatch.content : newTask,
                status: 'todo',
                date: new Date(selectedDate).toISOString(),
                userId: user.user_id,
                projectId: projectMatch ? projectMatch.projectId : '',
                projectName: projectMatch ? projectMatch.projectName : ''
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, taskToAdd);
                if (response.status === 201) {
                    // Make sure we're using the actual task data from the response
                    const newTaskData = {
                        ...taskToAdd,
                        _id: response.data._id || response.data.insertedId,
                        content: taskToAdd.content // Ensure content is included
                    };
                    
                    dispatch(updateTask({
                        columnId: 'todo',
                        taskId: newTaskData._id,
                        updates: newTaskData
                    }));
                    setNewTask('');
                }
            } catch (error) {
                console.error('Error adding task:', error);
            }
        }
    };

    const handleDeleteTask = async (taskId, columnId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`);
            dispatch(updateTask({
                columnId,
                taskId,
                updates: null // null indicates removal
            }));
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        dispatch(moveTask({
            taskId: result.draggableId,
            fromColumn: source.droppableId,
            toColumn: destination.droppableId
        }));

        try {
            const task = tasks[source.droppableId].find(t => t._id === result.draggableId);
            await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${task._id}`, {
                ...task,
                status: destination.droppableId
            });
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    const handleDuplicateTasks = async () => {
        try {
            const previousDate = new Date(selectedDate);
            previousDate.setDate(previousDate.getDate() - 1);
            const isoPreviousDate = previousDate.toISOString();
    
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, {
                params: { date: isoPreviousDate, userId: user.user_id }
            });
    
            const previousTasks = response.data.filter(task => task.status === 'todo' || task.status === 'inProgress');
            const duplicatedTasks = previousTasks.map(task => ({
                ...task,
                date: new Date(selectedDate).toISOString(),
                _id: undefined
            }));
    
            for (const task of duplicatedTasks) {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`, task);
            }
    
            fetchTasks();
        } catch (error) {
            console.error('Error duplicating tasks:', error);
        }
    };

    const handlePreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(prevYear => prevYear - 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(prevYear => prevYear + 1);
        } else {
            setCurrentMonth(prevMonth => prevMonth + 1);
        }
    };

    const getDaysInMonth = (month, year) => {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    };

    const getMonthYear = (date) => {
        const options = { year: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Add this new handler function
    const handleStoryPointsChange = async (taskId, columnId, points) => {
        try {
            const updatedTask = {
                ...tasks[columnId].find(task => task._id === taskId),
                storyPoints: points
            };
            await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`, updatedTask);
            dispatch(updateTask({
                columnId,
                taskId,
                updates: updatedTask
            }));
        } catch (error) {
            console.error('Error updating story points:', error);
        }
    };

    // Add new state for editing story points
    const [editingPoints, setEditingPoints] = useState(null);
    const [pointsContent, setPointsContent] = useState('');

    // Replace handleStoryPointsClick with these functions
    const handleStoryPointsEdit = (e, taskId, points) => {
        e.stopPropagation();
        setEditingPoints(taskId);
        setPointsContent(points || '0');
    };

    const handleStoryPointsBlur = async (taskId, columnId) => {
        const points = parseInt(pointsContent);
        if (!isNaN(points)) { // removed the 0-13 restriction
            await handleStoryPointsChange(taskId, columnId, points);
        }
        setEditingPoints(null);
    };

    // Add this new handler function
    const handleCriticalityChange = async (taskId, columnId, criticality) => {
        try {
            const updatedTask = {
                ...tasks[columnId].find(task => task._id === taskId),
                criticality
            };
            await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`, updatedTask);
            dispatch(updateTask({
                columnId,
                taskId,
                updates: updatedTask
            }));
            setEditingCriticality(null);
        } catch (error) {
            console.error('Error updating criticality:', error);
        }
    };

    const getCriticalityLabel = (criticality) => {
        switch (criticality) {
            case 'L': return 'Low';
            case 'M': return 'Medium';
            case 'H': return 'High';
            default: return 'Low';
        }
    };

    // Add this sorting function
    const getSortedTasks = (taskList) => {
        const sortingFunctions = {
            'none': tasks => [...tasks],
            'criticality': tasks => {
                const order = { 'H': 1, 'M': 2, 'L': 3 };
                return [...tasks].sort((a, b) => (order[a.criticality] || 3) - (order[b.criticality] || 3));
            },
            'storyPoints': tasks => {
                return [...tasks].sort((a, b) => (b.storyPoints || 0) - (a.storyPoints || 0));
            },
            'storyPointsAsc': tasks => {
                return [...tasks].sort((a, b) => (a.storyPoints || 0) - (b.storyPoints || 0));
            }
        };
        return sortingFunctions[sortBy](taskList);
    };

    const getTotalStoryPoints = (tasks) => {
        return tasks.reduce((total, task) => total + (task.storyPoints || 0), 0);
    };

    // Update openModal to openTaskDetails
    const openTaskDetails = (task) => {
        setSelectedTask(task);
        setTaskNotes(task.notes || '');
        setTaskDueDate(task.dueDate || '');
        setModalTaskContent(task.content);
        setModalStoryPoints(task.storyPoints || '0');
        setModalCriticality(task.criticality || 'L');
        setModalTaskTitle(task.content);
        setModalProjectId(task.projectId || '');
        setSelectedProjectName(task.projectName || '');
        setModalIsOpen(true);
    };

    // Update closeModal to closeTaskDetails
    const closeTaskDetails = () => {
        if (selectedTask) {
            // Save any changes before closing
            handleSaveTaskDetails();
        }
        setModalIsOpen(false);
        setSelectedTask(null);
    };

    // Update handleSaveTaskDetails to use Redux
    const handleSaveTaskDetails = async () => {
        if (selectedTask) {
            try {
                const updatedTask = {
                    ...selectedTask,
                    content: modalTaskTitle,
                    storyPoints: parseInt(modalStoryPoints) || 0,
                    criticality: modalCriticality,
                    notes: taskNotes,
                    dueDate: taskDueDate,
                    projectId: modalProjectId,
                    projectName: selectedProjectName
                };
                
                await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${selectedTask._id}`, updatedTask);
                
                dispatch(updateTask({
                    columnId: selectedTask.status,
                    taskId: selectedTask._id,
                    updates: updatedTask
                }));
                
                return true;
            } catch (error) {
                console.error('Error saving task details:', error);
                return false;
            }
        }
        return false;
    };

    // Add a function to handle project selection
    const handleProjectChange = (e) => {
        const selectedProjectId = e.target.value;
        setModalProjectId(selectedProjectId);
        
        if (selectedProjectId) {
            const project = userProjects.find(p => p._id === selectedProjectId);
            setSelectedProjectName(project ? project.project_name : '');
        } else {
            setSelectedProjectName('');
        }
    };

    // Create a new component for displaying project badge
    const ProjectBadge = ({ projectName }) => {
        if (!projectName) return null;
        
        return (
            <div className="dailyJournal-project-badge" title={`Project: ${projectName}`}>
                {projectName}
            </div>
        );
    };

    const openAnalyticsPane = () => setIsAnalyticsOpen(true);
    const closeAnalyticsPane = () => setIsAnalyticsOpen(false);

    // Fetch analytics data when the Analytics pane is opened
    useEffect(() => {
        console.log(user)
        if (isAnalyticsOpen && user && user.user_id) { // Ensure user and user._id are defined
            fetchJournalAnalytics(user.user_id);
        }
    }, [isAnalyticsOpen, user]);

    // Add the fetchJournalAnalytics function
    const fetchJournalAnalytics = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/journalAnalytics?user_id=${userId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAnalyticsData(data);
        } catch (error) {
            console.error('Error fetching analytics data:', error);
        }
    };

    // Add a utility function to format dates
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add 1 day
        return date.toLocaleDateString('en-US', options);
    };

    // Define data for the stacked bar chart
    const barData = {
        labels: analyticsData ? analyticsData.map(entry => formatDate(entry.date)) : [],
        datasets: [
            {
                label: 'Completed Story Points',
                data: analyticsData ? analyticsData.map(entry => entry.completedPoints) : [],
                backgroundColor: 'rgba(40, 167, 69, 0.6)', // Semi-transparent green
            },
            {
                label: 'Todo Story Points',
                data: analyticsData ? analyticsData.map(entry => entry.todoPoints) : [],
                backgroundColor: 'rgba(255, 206, 86, 0.6)', // Semi-transparent yellow
            },
        ],
    };

    // Define options for the stacked bar chart
    const barOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Story Points Distribution',
                font: {
                    size: 18
                }
            },
            legend: { position: 'top' },
        },
        scales: {
            x: { 
                stacked: true,
                title: {
                    display: true,
                    text: 'Date',
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45 // Added rotation for x-axis labels
                }
            },
            y: { 
                stacked: true,
                title: {
                    display: true,
                    text: 'Story Points',
                },
                beginAtZero: true,
            },
        },
    };

    const lineOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Story Points Over Time',
                font: {
                    size: 18
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    drag: {
                        enabled: true,
                        modifierKey: null, // Remove the modifierKey requirement
                    },
                    threshold: 10, // Add a small threshold to prevent accidental panning
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: false,
                    },
                    mode: 'x',
                },
            },
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: { 
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45 // Added rotation for x-axis labels
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Total Points'
                }
            },
        },
    };

    // Update date selection handler
    const handleDateSelection = (newDate) => {
        setSelectedDate(newDate);
        // Update URL to include the selected date
        navigate(`/${user.user_id}/dailyJournal/${newDate}`);
        // Update current month and year when date is selected
        const [year, month] = newDate.split('-').map(num => parseInt(num, 10));
        setCurrentMonth(month - 1);
        setCurrentYear(year);
    };

    // Add Enter key handler to add tasks
    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleAddTask();
        }
    };

    // Add useEffect to create a portal container for suggestions
    useEffect(() => {
        // Create portal container for suggestions if it doesn't exist
        let portalContainer = document.getElementById('suggestions-portal');
        if (!portalContainer) {
            portalContainer = document.createElement('div');
            portalContainer.id = 'suggestions-portal';
            portalContainer.style.position = 'absolute';
            portalContainer.style.top = '0';
            portalContainer.style.left = '0';
            portalContainer.style.width = '100%';
            portalContainer.style.height = '0';
            portalContainer.style.overflow = 'visible';
            portalContainer.style.zIndex = '99999';
            document.body.appendChild(portalContainer);
        }

        // Cleanup function to remove the portal container
        return () => {
            if (portalContainer && document.body.contains(portalContainer)) {
                document.body.removeChild(portalContainer);
            }
        };
    }, []);

    // Replace updateDropdownPosition
    const updateDropdownPosition = () => {
        if (inputRef.current) {
            const rect = inputRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width
            });
        }
    };

    // Add this new function to handle input changes with improved project matching
    const handleTaskInputChange = (e) => {
        const value = e.target.value;
        setNewTask(value);

        // Check if we're typing a hashtag
        const hashtagIndex = value.lastIndexOf('#');
        if (hashtagIndex !== -1) {
            const query = value.slice(hashtagIndex + 1).toLowerCase();
            // Filter projects and sort by closest match
            const suggestions = userProjects
                .filter(project => 
                    project.project_name.toLowerCase().includes(query)
                )
                .sort((a, b) => {
                    // Sort exact matches first, then by length
                    const aStart = a.project_name.toLowerCase().startsWith(query);
                    const bStart = b.project_name.toLowerCase().startsWith(query);
                    if (aStart && !bStart) return -1;
                    if (!aStart && bStart) return 1;
                    return a.project_name.length - b.project_name.length;
                });
            setFilteredProjects(suggestions);
            if (suggestions.length > 0) {
                updateDropdownPosition();
                setShowSuggestions(true);
            } else {
                setShowSuggestions(false);
            }
        } else {
            setShowSuggestions(false);
        }
    };

    // Update this function to handle suggestion selection
    const handleSuggestionClick = (projectName) => {
        console.log("Suggestion clicked:", projectName); // Add logging for debugging
        
        // Find the last hashtag position
        const lastHashIndex = newTask.lastIndexOf('#');
        
        if (lastHashIndex !== -1) {
            // Get text before hashtag
            const beforeHash = newTask.slice(0, lastHashIndex);
            
            // Construct the new value with the project name and a space
            const newValue = `${beforeHash}#${projectName} `;
            
            // Update the input value
            setNewTask(newValue);
            
            // Ensure suggestions are hidden
            setShowSuggestions(false);
            
            // Focus the input field and position cursor at the end
            setTimeout(() => {
                if (inputRef.current) {
                    const input = inputRef.current.querySelector('input');
                    if (input) {
                        input.focus();
                        input.setSelectionRange(input.value.length, input.value.length);
                    }
                }
            }, 10);
        }
    };

    // Add this useEffect to handle clicks outside suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Render the project suggestions using a portal
    const renderProjectSuggestions = () => {
        if (!showSuggestions || filteredProjects.length === 0) return null;
        
        return ReactDOM.createPortal(
            <div 
                className="dailyJournal-project-suggestions"
                style={{
                    position: 'absolute',
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                    width: `${dropdownPosition.width}px`,
                    zIndex: 99999
                }}
            >
                {filteredProjects.map((project) => (
                    <ProjectTag
                        key={project._id}
                        projectName={project.project_name}
                        clickHandler={handleSuggestionClick}
                    />
                ))}
            </div>,
            document.getElementById('suggestions-portal')
        );
    };

    // Add function to format time for display
    const formatTimeSpent = (seconds) => {
        if (!seconds) return '0m';
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
    };

    // Add function to calculate time spent including active timer
    const getDisplayTime = (task) => {
        const savedTime = task.timeSpent || 0;
        if (activeTimers[task._id]) {
            const { startTime } = activeTimers[task._id];
            const activeTime = Math.floor((new Date() - startTime) / 1000);
            return formatTimeSpent(savedTime + activeTime);
        }
        return formatTimeSpent(savedTime);
    };

    // Update toggleTaskTimer to use Redux
    const toggleTaskTimer = async (taskId, columnId) => {
        if (activeTimers[taskId]) {
            // Stop timer and save elapsed time
            const { startTime } = activeTimers[taskId];
            const elapsedSeconds = Math.floor((new Date() - startTime) / 1000);
            
            try {
                const task = tasks[columnId].find(t => t._id === taskId);
                const updatedTask = {
                    ...task,
                    timeSpent: (task.timeSpent || 0) + elapsedSeconds
                };
                
                await axios.put(`${process.env.REACT_APP_API_URL}/api/dailyJournalTasks/${taskId}`, updatedTask);
                
                // Update Redux state
                dispatch(updateTask({
                    columnId,
                    taskId,
                    updates: updatedTask
                }));

                // Clear timer states
                const { [taskId]: _, ...remainingTimers } = activeTimers;
                setActiveTimers(remainingTimers);
                
                if (timerIntervals[taskId]) {
                    clearInterval(timerIntervals[taskId]);
                    const { [taskId]: __, ...remainingIntervals } = timerIntervals;
                    setTimerIntervals(remainingIntervals);
                }
            } catch (error) {
                console.error('Error saving task time:', error);
            }
        } else {
            // Start timer
            setActiveTimers(prev => ({
                ...prev,
                [taskId]: { startTime: new Date() }
            }));
            
            // Set up interval to update UI
            const intervalId = setInterval(() => {
                setActiveTimers(prev => ({
                    ...prev,
                    [taskId]: { ...prev[taskId] }
                }));
            }, 1000);
            
            setTimerIntervals(prev => ({
                ...prev,
                [taskId]: intervalId
            }));
        }
    };

    // Clean up timers on unmount
    useEffect(() => {
        return () => {
            Object.values(timerIntervals).forEach(intervalId => {
                clearInterval(intervalId);
            });
        };
    }, [timerIntervals]);

    // Update renderTaskItem to include all badges
    const renderTaskItem = (task, columnId) => (
        <div className="dailyJournal-task-content">
            <div className="dailyJournal-task-info">
                <span className="dailyJournal-task-text">{task.content}</span>
                {task.projectName && <ProjectBadge projectName={task.projectName} />}
                <div className="dailyJournal-time-display">
                    {getDisplayTime(task)}
                </div>
            </div>
            <div className="dailyJournal-badges">
                {editingCriticality === task._id ? (
                    <select
                        className="dailyJournal-criticality-select"
                        value={task.criticality || 'L'}
                        onChange={(e) => handleCriticalityChange(task._id, columnId, e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        autoFocus
                        onBlur={() => setEditingCriticality(null)}
                    >
                        <option value="L">L</option>
                        <option value="M">M</option>
                        <option value="H">H</option>
                    </select>
                ) : (
                    <div
                        className={`dailyJournal-criticality-badge criticality-${getCriticalityLabel(task.criticality || 'L').toLowerCase()}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditingCriticality(task._id);
                        }}
                        title={`Criticality: ${getCriticalityLabel(task.criticality || 'L')}`}
                    >
                        {task.criticality || 'L'}
                    </div>
                )}
                {editingPoints === task._id ? (
                    <input
                        type="number"
                        className="dailyJournal-story-points-input"
                        value={pointsContent}
                        onChange={(e) => setPointsContent(e.target.value)}
                        onBlur={() => handleStoryPointsBlur(task._id, columnId)}
                        onClick={(e) => e.stopPropagation()}
                        autoFocus
                    />
                ) : (
                    <div 
                        className={`dailyJournal-story-points-badge sp-${task.storyPoints || 0}`}
                        onClick={(e) => handleStoryPointsEdit(e, task._id, task.storyPoints)}
                        title="Click to edit story points"
                    >
                        {task.storyPoints || '0'}
                    </div>
                )}
                <button
                    className={`dailyJournal-timer-button ${activeTimers[task._id] ? 'active' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleTaskTimer(task._id, columnId);
                    }}
                    title={activeTimers[task._id] ? "Stop timer" : "Start timer"}
                >
                    {activeTimers[task._id] ? <FaPause /> : <FaClock />}
                </button>
                <button
                    className="dailyJournal-details-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        openTaskDetails(task);
                    }}
                    title="View details"
                >
                    <FaInfoCircle />
                </button>
            </div>
        </div>
    );

    return (
        <>
            <div className="dailyJournal-App">
                <NavbarAuth />
                <main className="dailyJournal-main-content">
                    <div className="dailyJournal-tasks-container">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                <h1 className="dailyJournal-header">Daily Journal</h1>
                            </div>
                            <div className="dailyJournal-month-year text-center mb-4">
                                <button className="dailyJournal-arrow" onClick={handlePreviousMonth}>&lt;</button>
                                {getMonthYear(new Date(currentYear, currentMonth))}
                                <button className="dailyJournal-arrow" onClick={handleNextMonth}>&gt;</button>
                            </div>
                            <div className="dailyJournal-date-picker">
                                {getDaysInMonth(currentMonth, currentYear).map((day, index) => {
                                    const dayString = day.toISOString().split('T')[0];
                                    return (
                                        <div
                                            key={index}
                                            className={`dailyJournal-day ${dayString === selectedDate ? 'dailyJournal-day-selected' : ''}`}
                                            onClick={() => handleDateSelection(dayString)}
                                        >
                                            {day.getDate()}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="dailyJournal-input">
                                <div className="dailyJournal-input-container" ref={inputRef}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newTask}
                                        onChange={handleTaskInputChange}
                                        onKeyDown={handleInputKeyDown}
                                        placeholder="Enter new task (use # to tag a project)"
                                    />
                                </div>
                                <button className="btn btn-primary dailyJournal-addTask" onClick={handleAddTask}>
                                    Add Task
                                </button>
                            </div>
                            {/* Render suggestions using the portal */}
                            {renderProjectSuggestions()}
                            <div className="dailyJournal-sort-controls">
                                <select 
                                    className="dailyJournal-sort-select"
                                    value={sortBy}
                                    onChange={(e) => setSortBy(e.target.value)}
                                >
                                    <option value="none">Sort by...</option>
                                    <option value="criticality">Criticality (H → L)</option>
                                    <option value="storyPoints">Story Points (High → Low)</option>
                                    <option value="storyPointsAsc">Story Points (Low → High)</option>
                                </select>
                                <button className="btn btn-primary" onClick={handleDuplicateTasks}>
                                    Duplicate Tasks
                                </button>
                                {canAccessAnalytics && (
                                    <button onClick={openAnalyticsPane} className="dailyJournal-analytics-button">
                                        Analytics
                                    </button>
                                )}
                            </div>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <div className="dailyJournal-board">
                                    {[
                                        { id: 'todo', title: 'To Do' },
                                        { id: 'inProgress', title: 'In Progress' },
                                        { id: 'complete', title: 'Complete' }
                                    ].map((column) => (
                                        <Droppable key={column.id} droppableId={column.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`dailyJournal-column ${snapshot.isDraggingOver ? 'dailyJournal-dragging-over' : ''}`}
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    <div className="dailyJournal-column-header">
                                                        <h3 className="dailyJournal-column-title">
                                                            {column.title}
                                                            <div className="dailyJournal-story-points-badge-header">
                                                                {getTotalStoryPoints(tasks[column.id])}
                                                            </div>
                                                        </h3>
                                                    </div>
                                                    <ul className="list-group">
                                                        {getSortedTasks(tasks[column.id]).map((task, index) => (
                                                            <Draggable key={task._id} draggableId={task._id} index={index}>
                                                                {(provided) => (
                                                                    <li
                                                                        className="list-group-item dailyJournal-task-item"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        {renderTaskItem(task, column.id)}
                                                                        <button
                                                                            className="dailyJournal-delete-button"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleDeleteTask(task._id, column.id);
                                                                            }}
                                                                            title="Delete task"
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </ul>
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className="dailyJournal-notes-section">
                        <div className="dailyJournal-notes-header">
                            <h3 className="dailyJournal-notes-label">Daily Notes</h3>
                            {hasUnsavedChanges && (
                                <span className="save-prompt">
                                    Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save
                                </span>
                            )}
                        </div>
                        <div className="dailyJournal-editor-container">
                            <div id="editor-journal"></div>
                        </div>
                    </div>
                </main>
                <SlidingPane
                    className="dailyJournal-details-pane"
                    isOpen={modalIsOpen}
                    title={
                        <div className="dailyJournal-details-header">
                            <span>Task Details</span>
                            <div className="dailyJournal-auto-save-indicator">
                                Auto-save enabled
                            </div>
                        </div>
                    }
                    from="left"
                    width="750px"
                    onRequestClose={closeTaskDetails}
                >
                    {selectedTask && (
                        <div className="dailyJournal-details-content">
                            <div className="dailyJournal-details-title-section">
                                <textarea
                                    className="dailyJournal-details-title"
                                    value={modalTaskTitle}
                                    onChange={(e) => setModalTaskTitle(e.target.value)}
                                    placeholder="Task title..."
                                    rows={2}
                                />
                            </div>

                            <div className="dailyJournal-details-meta">
                                <div className="dailyJournal-details-time">
                                    <FaClock className="dailyJournal-details-icon" />
                                    <span>{getDisplayTime(selectedTask)}</span>
                                </div>
                            </div>

                            <div className="dailyJournal-details-grid">
                                <div className="dailyJournal-details-item">
                                    <label>Story Points</label>
                                    <input
                                        type="number"
                                        value={modalStoryPoints}
                                        onChange={(e) => setModalStoryPoints(e.target.value)}
                                        min="0"
                                    />
                                </div>

                                <div className="dailyJournal-details-item">
                                    <label>Criticality</label>
                                    <select
                                        value={modalCriticality}
                                        onChange={(e) => setModalCriticality(e.target.value)}
                                        className={`criticality-${modalCriticality.toLowerCase()}`}
                                    >
                                        <option value="L">Low</option>
                                        <option value="M">Medium</option>
                                        <option value="H">High</option>
                                    </select>
                                </div>

                                <div className="dailyJournal-details-item">
                                    <label>Due Date</label>
                                    <input
                                        type="date"
                                        value={taskDueDate}
                                        onChange={(e) => setTaskDueDate(e.target.value)}
                                    />
                                </div>

                                <div className="dailyJournal-details-item">
                                    <label>Project</label>
                                    <select
                                        value={modalProjectId}
                                        onChange={handleProjectChange}
                                    >
                                        <option value="">None</option>
                                        {userProjects.map(project => (
                                            <option key={project._id} value={project._id}>
                                                {project.project_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="dailyJournal-details-notes">
                                <label>Notes</label>
                                <textarea
                                    value={taskNotes}
                                    onChange={(e) => setTaskNotes(e.target.value)}
                                    placeholder="Add notes here..."
                                />
                            </div>
                        </div>
                    )}
                </SlidingPane>
                <SlidingPane
                    className="dailyJournal-sliding-pane" // Renamed className for uniqueness
                    isOpen={isAnalyticsOpen}
                    title="Analytics"
                    from="right"
                    width="600px" // Increased width for better chart display
                    onRequestClose={closeAnalyticsPane}
                >
                    <div>
                        {analyticsData && analyticsData.length > 0 ? ( // Updated condition to check for data
                            <>
                                <Line
                                    data={{
                                        labels: analyticsData.map(entry => formatDate(entry.date)), // Updated formatting
                                        datasets: [
                                            {
                                                label: 'Total Story Points',
                                                data: analyticsData.map(entry => entry.totalPoints),
                                                fill: false,
                                                backgroundColor: 'rgb(0, 122, 255)',     // Changed to blue
                                                borderColor: 'rgba(0, 122, 255, 0.8)',   // Changed to blue
                                                borderWidth: 3,
                                            },
                                            {
                                                label: 'Completed Story Points',
                                                data: analyticsData.map(entry => entry.completedPoints),
                                                fill: false,
                                                backgroundColor: 'rgb(40, 167, 69)',     // Changed to green
                                                borderColor: 'rgba(40, 167, 69, 0.8)',   // Changed to green
                                                borderWidth: 3,
                                            },
                                            {
                                                label: 'Todo Story Points',
                                                data: analyticsData.map(entry => entry.todoPoints),
                                                fill: false,
                                                backgroundColor: 'rgb(255, 206, 86)',    // Kept yellow
                                                borderColor: 'rgba(255, 206, 86, 0.8)',  // Kept yellow
                                                borderWidth: 3,
                                            },
                                            {
                                                label: 'Average Points Per Day',
                                                data: Array(analyticsData.length).fill(
                                                    analyticsData.reduce((sum, entry) => sum + entry.totalPoints, 0) / analyticsData.length
                                                ),
                                                fill: false,
                                                backgroundColor: 'rgb(128, 128, 128)',
                                                borderColor: 'rgba(128, 128, 128, 0.8)',
                                                borderWidth: 2,
                                                borderDash: [5, 5], // Add dashed line style
                                            },
                                            {
                                                label: 'Average Completed Points Per Day',
                                                data: Array(analyticsData.length).fill(
                                                    analyticsData.length ? analyticsData.reduce((sum, entry) => sum + entry.completedPoints, 0) / analyticsData.length : 0
                                                ),
                                                fill: false,
                                                backgroundColor: 'rgb(128, 128, 128)',     // Grey color
                                                borderColor: 'rgba(128, 128, 128, 0.8)',   // Grey color
                                                borderWidth: 2,
                                                borderDash: [5, 5],                         // Dashed line style
                                            },
                                        ],
                                    }}
                                    options={lineOptions}
                                />
                                {/* Add the stacked Bar chart */}
                                <Bar
                                    data={barData}
                                    options={barOptions}
                                    className="dailyJournal-bar-chart"
                                />
                            </>
                        ) : (
                            <p>No data available for the selected period.</p> // Updated message
                        )}
                    </div>
                </SlidingPane>
            </div>
        </>
    );
};

export default DailyJournal;