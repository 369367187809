import React from 'react';
import { Link } from 'react-router-dom';
import { getGreeting } from '../../utils/dashboardUtils';

const DashboardHeader = ({ userProfile, userId }) => {
  return (
    <div className="dashboard-header">
      <h1 className = "shiny-text">
        {userProfile ? `${getGreeting()}, ${userProfile.firstname}` : 'Project Dashboard'}
      </h1>
      <div className="dashboard-actions">
        <Link to="/newProject" className="btn btn-primary">New Project</Link>
        <Link to={`/${userId}/dailyJournal`} className="btn btn-primary">Daily Journal</Link>
      </div>
    </div>
  );
};

export default DashboardHeader;
