import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faBug, faPlus, faSearch, faFilter,
    faExclamationCircle, faCheckCircle, faClock,
    faList, faTh, faSort, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';
import ReportBugSlider from '../components/ReportBugSlider';
import BugDetailsSlider from '../components/BugDetailsSlider';
import { createBug, fetchBugs, updateBug, deleteBug } from '../../../redux/slices/agileHubSlice';
import './Bugs.css';

export const Bugs = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { bugs, loading, error } = useSelector(state => state.agileHub);
    
    // Filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [severity, setSeverity] = useState('All');
    const [status, setStatus] = useState('All');
    const [sortBy, setSortBy] = useState('bugNumber');
    const [sortDirection, setSortDirection] = useState('desc');
    const [viewMode, setViewMode] = useState('card'); // Changed default to 'card'
    const [showReportSlider, setShowReportSlider] = useState(false);
    const [sliderClosing, setSliderClosing] = useState(false);
    const [showDetailsSlider, setShowDetailsSlider] = useState(false);
    const [detailsSliderClosing, setDetailsSliderClosing] = useState(false);
    const [selectedBug, setSelectedBug] = useState(null);

    const severityOptions = ['Critical', 'High', 'Medium', 'Low'];
    const statusOptions = ['Open', 'In Progress', 'Fixed', 'Verified', 'Closed'];

    // Analytics data - calculated from bugs array
    const [bugsAnalytics, setBugsAnalytics] = useState({
        totalBugs: 0,
        openBugs: 0,
        criticalBugs: 0,
        fixedBugs: 0,
        resolvedRate: 0
    });

    useEffect(() => {
        if (bugs?.length) {
            const openBugs = bugs.filter(bug => bug.status === 'Open').length;
            const criticalBugs = bugs.filter(bug => bug.severity === 'Critical').length;
            const fixedBugs = bugs.filter(bug => ['Fixed', 'Verified', 'Closed'].includes(bug.status)).length;
            
            setBugsAnalytics({
                totalBugs: bugs.length,
                openBugs,
                criticalBugs,
                fixedBugs,
                resolvedRate: Math.round((fixedBugs / bugs.length) * 100)
            });
        }
    }, [bugs]);

    const getSeverityColor = (severity) => {
        switch(severity) {
            case 'Critical': return 'rgb(220, 53, 69)';
            case 'High': return 'rgb(253, 126, 20)';
            case 'Medium': return 'rgb(255, 193, 7)';
            case 'Low': return 'rgb(13, 202, 240)';
            default: return 'rgb(108, 117, 125)';
        }
    };

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortDirection('asc');
        }
    };

    const getSortedAndFilteredBugs = () => {
        return bugs
            ?.filter(bug => {
                // Check if properties exist before accessing toLowerCase()
                const matchesSearch = bug.title && bug.description ? 
                    (bug.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    bug.description.toLowerCase().includes(searchTerm.toLowerCase()))
                    : bug.title ? 
                        bug.title.toLowerCase().includes(searchTerm.toLowerCase())
                        : bug.description ?
                            bug.description.toLowerCase().includes(searchTerm.toLowerCase())
                            : true;
                
                const matchesSeverity = severity === 'All' || bug.severity === severity;
                const matchesStatus = status === 'All' || bug.status === status;
                
                return matchesSearch && matchesSeverity && matchesStatus;
            })
            .sort((a, b) => {
                let valueA, valueB;
                
                if (sortBy === 'bugNumber') {
                    valueA = a.bugNumber || 0;
                    valueB = b.bugNumber || 0;
                } else if (sortBy === 'title') {
                    valueA = a.title || '';
                    valueB = b.title || '';
                } else if (sortBy === 'severity') {
                    // Custom sort order for severity
                    const severityOrder = {'Critical': 0, 'High': 1, 'Medium': 2, 'Low': 3};
                    valueA = severityOrder[a.severity] ?? 4;
                    valueB = severityOrder[b.severity] ?? 4;
                } else if (sortBy === 'status') {
                    // Custom sort order for status
                    const statusOrder = {'Open': 0, 'In Progress': 1, 'Fixed': 2, 'Verified': 3, 'Closed': 4};
                    valueA = statusOrder[a.status] ?? 5;
                    valueB = statusOrder[b.status] ?? 5;
                } else if (sortBy === 'date') {
                    valueA = new Date(a.createdAt || 0).getTime();
                    valueB = new Date(b.createdAt || 0).getTime();
                } else {
                    valueA = a[sortBy] || 0;
                    valueB = b[sortBy] || 0;
                }
                
                // String comparison
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return sortDirection === 'asc' 
                        ? valueA.localeCompare(valueB)
                        : valueB.localeCompare(valueA);
                }
                
                // Number comparison
                return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
            });
    };

    const filteredBugs = getSortedAndFilteredBugs();

    const handleReportBug = () => {
        setShowReportSlider(true);
        setSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeReportSlider = () => {
        setSliderClosing(true);
        setTimeout(() => {
            setShowReportSlider(false);
            setSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleSubmitBug = async (bugData) => {
        try {
            await dispatch(createBug({ projectId, bugData }));
            closeReportSlider();
        } catch (error) {
            console.error('Error submitting bug:', error);
        }
    };

    const handleBugClick = (bug) => {
        setSelectedBug(bug);
        setShowDetailsSlider(true);
        setDetailsSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeDetailsSlider = () => {
        setDetailsSliderClosing(true);
        setTimeout(() => {
            setShowDetailsSlider(false);
            setDetailsSliderClosing(false);
            setSelectedBug(null);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleUpdateBug = async (updateData) => {
        try {
            await dispatch(updateBug({ 
                projectId, 
                bugId: selectedBug._id,
                bugData: updateData
            }));
        } catch (error) {
            console.error('Error updating bug:', error);
        }
    };

    const handleDeleteBug = async (bugId) => {
        try {
            await dispatch(deleteBug({ projectId, bugId }));
        } catch (error) {
            console.error('Error deleting bug:', error);
        }
    };

    const clearFilters = () => {
        setSearchTerm('');
        setSeverity('All');
        setStatus('All');
    };

    // Add useEffect to fetch bugs when component mounts
    useEffect(() => {
        dispatch(fetchBugs(projectId));
    }, [dispatch, projectId]);

    const portalContainer = document.getElementById('sliding-panel-root');

    const renderAnalyticsDashboard = () => (
        <div className="bugs-analytics mb-4">
            <div className="analytics-grid">
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faBug} className="text-primary" />
                    </div>
                    <div className="analytic-content">
                        <h4>Total Bugs</h4>
                        <div className="analytic-value">{bugsAnalytics.totalBugs}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faExclamationCircle} className="text-warning" />
                    </div>
                    <div className="analytic-content">
                        <h4>Open Bugs</h4>
                        <div className="analytic-value">{bugsAnalytics.openBugs}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faInfoCircle} className="text-danger" />
                    </div>
                    <div className="analytic-content">
                        <h4>Critical Issues</h4>
                        <div className="analytic-value">{bugsAnalytics.criticalBugs}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                    </div>
                    <div className="analytic-content">
                        <h4>Resolved Rate</h4>
                        <div className="analytic-value">
                            {bugsAnalytics.resolvedRate}%
                            <div className="progress mt-2" style={{ height: '4px' }}>
                                <div 
                                    className="progress-bar" 
                                    style={{ 
                                        width: `${bugsAnalytics.resolvedRate}%`,
                                        backgroundColor: `hsl(${bugsAnalytics.resolvedRate * 1.2}, 70%, 50%)`
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="bugs-loading">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p>Loading bugs...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bugs-error">
                <div className="alert alert-danger">
                    <h4>Error Loading Bugs</h4>
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="bugs-container">
            <div className="bugs-header">
                <div className="header-content">
                    <h3>Bug Tracking</h3>
                </div>
                <button className="btn btn-primary" onClick={handleReportBug}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Report Bug
                </button>
            </div>

            {renderAnalyticsDashboard()}

            <div className="bugs-filters mb-4">
                <div className="row g-3 align-items-center">
                    <div className="col-md-4">
                        <div className="input-group">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search bugs..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <select 
                            className="form-select"
                            value={severity}
                            onChange={(e) => setSeverity(e.target.value)}
                        >
                            <option value="All">All Severities</option>
                            {severityOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select 
                            className="form-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="All">All Statuses</option>
                            {statusOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <div className="view-controls">
                            <div className="btn-group me-2">
                                <button 
                                    className={`btn btn-outline-secondary ${viewMode === 'list' ? 'active' : ''}`}
                                    onClick={() => setViewMode('list')}
                                    title="List View"
                                >
                                    <FontAwesomeIcon icon={faList} />
                                </button>
                                <button 
                                    className={`btn btn-outline-secondary ${viewMode === 'card' ? 'active' : ''}`}
                                    onClick={() => setViewMode('card')}
                                    title="Card View"
                                >
                                    <FontAwesomeIcon icon={faTh} />
                                </button>
                            </div>
                            <div className="btn-group">
                                <button 
                                    className="btn btn-outline-secondary dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    title="Sort Options"
                                >
                                    <FontAwesomeIcon icon={faSort} />
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button 
                                            className="dropdown-item"
                                            onClick={() => handleSort('bugNumber')}
                                        >
                                            Sort by ID {sortBy === 'bugNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="dropdown-item"
                                            onClick={() => handleSort('title')}
                                        >
                                            Sort by Title {sortBy === 'title' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="dropdown-item"
                                            onClick={() => handleSort('severity')}
                                        >
                                            Sort by Severity {sortBy === 'severity' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="dropdown-item"
                                            onClick={() => handleSort('status')}
                                        >
                                            Sort by Status {sortBy === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="dropdown-item"
                                            onClick={() => handleSort('date')}
                                        >
                                            Sort by Date {sortBy === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="filter-badges mt-2">
                    {searchTerm && (
                        <span className="filter-badge">
                            Search: {searchTerm}
                            <button onClick={() => setSearchTerm('')}>×</button>
                        </span>
                    )}
                    {severity !== 'All' && (
                        <span className="filter-badge">
                            Severity: {severity}
                            <button onClick={() => setSeverity('All')}>×</button>
                        </span>
                    )}
                    {status !== 'All' && (
                        <span className="filter-badge">
                            Status: {status}
                            <button onClick={() => setStatus('All')}>×</button>
                        </span>
                    )}
                    {(searchTerm || severity !== 'All' || status !== 'All') && (
                        <button 
                            className="clear-filters-btn"
                            onClick={clearFilters}
                        >
                            Clear All Filters
                        </button>
                    )}
                </div>
            </div>

            <div className="bugs-content">
                {filteredBugs?.length === 0 ? (
                    <div className="bugs-empty-state">
                        <div className="empty-icon">
                            <FontAwesomeIcon icon={faBug} size="3x" />
                        </div>
                        <h4>No Bugs Found</h4>
                        <p>No bugs match your current filters or none have been reported yet.</p>
                        <button className="btn btn-primary" onClick={handleReportBug}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Report Bug
                        </button>
                    </div>
                ) : viewMode === 'list' ? (
                    <div className="bugs-list">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('bugNumber')} className="sortable-header">
                                            ID {sortBy === 'bugNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th onClick={() => handleSort('title')} className="sortable-header">
                                            Title {sortBy === 'title' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th onClick={() => handleSort('severity')} className="sortable-header">
                                            Severity {sortBy === 'severity' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th onClick={() => handleSort('status')} className="sortable-header">
                                            Status {sortBy === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th>Reported By</th>
                                        <th onClick={() => handleSort('date')} className="sortable-header">
                                            Reported Date {sortBy === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredBugs.map(bug => (
                                        <tr 
                                            key={bug._id}
                                            onClick={() => handleBugClick(bug)}
                                            style={{ cursor: 'pointer' }}
                                            className="bug-row"
                                        >
                                            <td className="bug-id">BUG-{bug.bugNumber || 'N/A'}</td>
                                            <td>
                                                <div className="bug-title-cell">
                                                    <div className="bug-title">{bug.title || 'Untitled Bug'}</div>
                                                    <div className="bug-description">{bug.description?.substring(0, 80)}...</div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="severity-badge" style={{
                                                    backgroundColor: getSeverityColor(bug.severity)
                                                }}>
                                                    {bug.severity || 'Unknown'}
                                                </span>
                                            </td>
                                            <td>
                                                <span className={`status-badge status-${(bug.status || 'open').toLowerCase()}`}>
                                                    {bug.status || 'Open'}
                                                </span>
                                            </td>
                                            <td>{bug.reportedBy || 'Anonymous'}</td>
                                            <td>{bug.createdAt ? new Date(bug.createdAt).toLocaleDateString() : 'Unknown'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className="bugs-cards">
                        {filteredBugs.map(bug => (
                            <div 
                                key={bug._id} 
                                className="bug-card"
                                onClick={() => handleBugClick(bug)}
                            >
                                <div className="bug-card-header">
                                    <span className="bug-id">BUG-{bug.bugNumber || 'N/A'}</span>
                                    <span className="severity-badge card-severity" style={{
                                        backgroundColor: getSeverityColor(bug.severity)
                                    }}>
                                        {bug.severity || 'Unknown'}
                                    </span>
                                </div>
                                <h5 className="bug-card-title">{bug.title || 'Untitled Bug'}</h5>
                                <p className="bug-card-description">
                                    {bug.description?.substring(0, 120) || 'No description'}
                                    {bug.description?.length > 120 ? '...' : ''}
                                </p>
                                <div className="bug-card-footer">
                                    <div className="bug-meta">
                                        <span className={`status-badge status-${(bug.status || 'open').toLowerCase()}`}>
                                            {bug.status || 'Open'}
                                        </span>
                                        <span className="bug-reporter">
                                            {bug.reportedBy || 'Anonymous'}
                                        </span>
                                    </div>
                                    <div className="bug-date">
                                        {bug.createdAt ? new Date(bug.createdAt).toLocaleDateString() : 'Unknown'}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {showReportSlider && portalContainer && createPortal(
                <ReportBugSlider
                    showSlider={showReportSlider}
                    sliderClosing={sliderClosing}
                    closeSlider={closeReportSlider}
                    handleSubmit={handleSubmitBug}
                />,
                portalContainer
            )}

            {showDetailsSlider && portalContainer && createPortal(
                <BugDetailsSlider
                    showSlider={showDetailsSlider}
                    sliderClosing={detailsSliderClosing}
                    closeSlider={closeDetailsSlider}
                    currentBug={selectedBug}
                    handleUpdateBug={handleUpdateBug}
                    handleDeleteBug={handleDeleteBug}
                />,
                portalContainer
            )}
        </div>
    );
};

export default Bugs;
