import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faPlus, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const BugDetailsSlider = ({
    showSlider,
    sliderClosing,
    closeSlider,
    currentBug,
    handleUpdateBug,
    handleDeleteBug
}) => {
    const [localBug, setLocalBug] = useState({ ...currentBug });
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);
    const { user } = useSelector(state => state.auth);

    // Ensure we keep the original bugNumber and ID values
    const originalBugNumber = currentBug?.bugNumber;
    const originalBugId = currentBug?._id;

    useEffect(() => {
        // When currentBug changes, make sure we preserve important fields
        setLocalBug(prevBug => ({
            ...currentBug,
            bugNumber: currentBug?.bugNumber || prevBug.bugNumber,
            _id: currentBug?._id || prevBug._id
        }));
    }, [currentBug]);

    const handleFieldChange = (field, value) => {
        const updatedBug = { 
            ...localBug, 
            [field]: value,
            // Always keep these values
            bugNumber: originalBugNumber,
            _id: originalBugId
        };
        
        setLocalBug(updatedBug);
        setIsSaving(true);

        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        const timeoutId = setTimeout(async () => {
            try {
                // Remove immutable fields but keep bugNumber and _id for local state
                const { _id, createdAt, updatedAt, bugNumber, ...updateData } = updatedBug;
                await handleUpdateBug(updateData);
            } finally {
                setIsSaving(false);
            }
        }, 1000);

        setSaveTimeout(timeoutId);
    };

    const handleStepChange = (index, value) => {
        const newSteps = [...localBug.stepsToReproduce];
        newSteps[index] = value;
        handleFieldChange('stepsToReproduce', newSteps);
    };

    const addStep = () => {
        const newSteps = [...(localBug.stepsToReproduce || []), ''];
        handleFieldChange('stepsToReproduce', newSteps);
    };

    const removeStep = (index) => {
        const newSteps = localBug.stepsToReproduce.filter((_, i) => i !== index);
        handleFieldChange('stepsToReproduce', newSteps);
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Bug Details</h2>
                    <div className="sliding-panel-subtitle">
                        BUG-{localBug.bugNumber || originalBugNumber || 'N/A'}
                        <div className="bug-dates">
                            <span>Reported: {new Date(localBug.createdAt).toLocaleDateString()}</span>
                            {localBug.updatedAt && (
                                <span> • Last Updated: {new Date(localBug.updatedAt).toLocaleDateString()}</span>
                            )}
                        </div>
                    </div>
                    {isSaving && (
                        <div className="saving-indicator">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <span className="ms-2">Saving...</span>
                        </div>
                    )}
                </div>
                
                <div className="sliding-panel-content">
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localBug.title || ''}
                                onChange={(e) => handleFieldChange('title', e.target.value)}
                            />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Severity</label>
                                <select
                                    className="form-select"
                                    value={localBug.severity || 'Medium'}
                                    onChange={(e) => handleFieldChange('severity', e.target.value)}
                                >
                                    <option value="Critical">Critical</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Status</label>
                                <select
                                    className="form-select"
                                    value={localBug.status || 'Open'}
                                    onChange={(e) => handleFieldChange('status', e.target.value)}
                                >
                                    <option value="Open">Open</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Fixed">Fixed</option>
                                    <option value="Verified">Verified</option>
                                    <option value="Closed">Closed</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Bug Details</h3>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                rows="4"
                                value={localBug.description || ''}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label d-flex justify-content-between align-items-center">
                                Steps to Reproduce
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={addStep}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Step
                                </button>
                            </label>
                            {(localBug.stepsToReproduce || []).map((step, index) => (
                                <div key={index} className="input-group mb-2">
                                    <span className="input-group-text">{index + 1}</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={step}
                                        onChange={(e) => handleStepChange(index, e.target.value)}
                                        placeholder="Enter step description..."
                                    />
                                    {localBug.stepsToReproduce.length > 1 && (
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => removeStep(index)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Expected Behavior</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                value={localBug.expectedBehavior || ''}
                                onChange={(e) => handleFieldChange('expectedBehavior', e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Actual Behavior</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                value={localBug.actualBehavior || ''}
                                onChange={(e) => handleFieldChange('actualBehavior', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="epic-actions d-flex justify-content-end">
                        <button 
                            className="btn btn-danger"
                            onClick={() => {
                                if(window.confirm('Are you sure you want to delete this bug?')) {
                                    handleDeleteBug(localBug._id);
                                    closeSlider();
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Bug
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BugDetailsSlider;
