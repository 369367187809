import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../Components/AuthContext.js";
import Navbar from '../Components/Navbar.js';
import ReactGA from 'react-ga4';
import DOMPurify from 'dompurify';
import { loginUser, clearAuthError } from '../redux/slices/authSlice';
import CryptoJS from 'crypto-js'; // Import crypto-js for password hashing
import './PageCSS/Login.css';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [csrfToken, setCsrfToken] = useState('');
    const [retryDelay, setRetryDelay] = useState(0);
    const [canAttempt, setCanAttempt] = useState(true);
    const [tokenLoading, setTokenLoading] = useState(true);

    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.auth);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        
        // Clear any previous auth errors when component mounts
        dispatch(clearAuthError());

        // Always fetch a fresh CSRF token when Login component mounts
        const fetchCsrfToken = async () => {
            setTokenLoading(true);
            let retryCount = 0;
            const maxRetries = 3;
            
            const tryFetch = async () => {
                try {
                    console.log('Fetching new CSRF token...');
                    // Remove any existing token first
                    localStorage.removeItem('csrfToken');
                    
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/csrf-token`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
                    
                    if (!response.ok) {
                        console.error(`CSRF token fetch failed with status: ${response.status}`);
                        throw new Error(`Failed to fetch CSRF token: ${response.status}`);
                    }
                    
                    const data = await response.json();
                    console.log('CSRF token response received:', data);
                    
                    if (data && data.state) {
                        console.log('CSRF token successfully retrieved');
                        setCsrfToken(data.state);
                        localStorage.setItem('csrfToken', data.state);
                        return true;
                    } else {
                        console.error('Invalid CSRF token format in response:', data);
                        throw new Error('Invalid CSRF token response format');
                    }
                } catch (error) {
                    console.error('Error fetching CSRF token:', error);
                    return false;
                }
            };

            // Try to fetch, with retries
            while (retryCount < maxRetries) {
                console.log(`CSRF token fetch attempt ${retryCount + 1} of ${maxRetries}`);
                const success = await tryFetch();
                
                if (success) {
                    setTokenLoading(false);
                    return;
                }
                
                retryCount++;
                if (retryCount < maxRetries) {
                    // Wait before retry (exponential backoff)
                    const delayMs = Math.min(1000 * (2 ** retryCount), 8000);
                    console.log(`Retrying CSRF token fetch in ${delayMs}ms...`);
                    await new Promise(resolve => setTimeout(resolve, delayMs));
                }
            }
            
            setTokenLoading(false);
            setMessage('Failed to fetch security token. Please try again later.');
        };

        fetchCsrfToken();
    }, [dispatch]);

    // Redirect if user is already authenticated
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    // Update message when error changes
    useEffect(() => {
        if (error) {
            setMessage('Invalid credentials'); // Replace backend error with generic message
        }
    }, [error]);

    // Custom email validation function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!canAttempt) {
            setMessage(`Please wait ${Math.ceil(retryDelay/1000)} seconds before trying again`);
            return;
        }

        setMessage('');
        
        // Validate email format using the custom function
        if (!isValidEmail(email)) {
            setMessage('Invalid email format');
            return;
        }

        // Use the token from state (which should be freshly fetched)
        if (!csrfToken) {
            setMessage('Security token missing. Please refresh the page.');
            return;
        }

        try {
            const resultAction = await dispatch(loginUser({ 
                email, 
                password,
                csrfToken
            }));
            
            if (loginUser.fulfilled.match(resultAction)) {
                await login(resultAction.payload);
                
                // Track account type for analytics
                ReactGA.event({
                    category: 'User',
                    action: 'Login',
                    label: resultAction.payload.accountType || 'freeForever'
                });
                
                // Add a small delay to ensure state updates are processed
                setTimeout(() => {
                    navigate('/dashboard', { replace: true });
                }, 100);
            } else {
                const errorData = resultAction.payload;
                if (errorData?.retryDelay) {
                    setRetryDelay(errorData.retryDelay);
                    setCanAttempt(false);
                    setTimeout(() => {
                        setCanAttempt(true);
                        setRetryDelay(0);
                    }, errorData.retryDelay);
                }
                setMessage(errorData?.message || 'Login failed');
            }
        } catch (e) {
            setMessage('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div className="App">
            <header className="siteHeader text-white text-center">
                <Navbar />
            </header>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Login</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type='email' className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type='password' className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} />
                                    </div>
                                    
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary login-btn-primary btn-block" 
                                        disabled={loading || !canAttempt}
                                    >
                                        {loading ? 'Logging in...' : 
                                         !canAttempt ? `Wait ${Math.ceil(retryDelay/1000)}s` : 'Log In'}
                                    </button>
                                </form>
                                <a href='/register' className="d-block text-center mt-3">New User</a>
                                <a href='/requestPasswordReset' className="d-block text-center mt-3">Forgot Password?</a>
                                {message && <p className="text-center mt-3">{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};