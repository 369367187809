import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTable, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FEATURE_TYPES, FEATURE_STATUSES } from '../utils/constants';

const FeatureFilters = ({ 
    searchTerm, 
    setSearchTerm, 
    filterType, 
    setFilterType, 
    filterStatus, 
    setFilterStatus,
    viewMode,
    setViewMode
}) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="feature-controls d-flex gap-3 align-items-center">
                <div className="search-field" style={{ width: '300px' }}>
                    <div className="input-group">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search features..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>

                <select 
                    className="form-select"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    style={{ width: '150px' }}
                >
                    <option value="All">All Types</option>
                    {Object.entries(FEATURE_TYPES).map(([value, label]) => (
                        <option key={value} value={value}>{label}</option>
                    ))}
                </select>

                <select 
                    className="form-select"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    style={{ width: '150px' }}
                >
                    <option value="All">All Statuses</option>
                    {FEATURE_STATUSES.map(status => (
                        <option key={status} value={status}>{status}</option>
                    ))}
                </select>
            </div>
            
            <div className="view-toggle-container d-flex">
                <button 
                    className={`view-toggle-btn me-2 ${viewMode === 'grid' ? 'active' : ''}`}
                    onClick={() => setViewMode('grid')}
                    title="Grid View"
                >
                    <FontAwesomeIcon icon={faThLarge} />
                </button>
                <button 
                    className={`view-toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
                    onClick={() => setViewMode('table')}
                    title="Table View"
                >
                    <FontAwesomeIcon icon={faTable} />
                </button>
            </div>
        </div>
    );
};

export default FeatureFilters;
