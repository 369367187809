import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks
export const fetchNotes = createAsyncThunk(
    'notes/fetchNotes',
    async (projectId) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes`);
        return response.data;
    }
);

export const createNote = createAsyncThunk(
    'notes/createNote',
    async (projectId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes`, {
            title: 'Untitled Note', // Changed back to match what the server expects
            content: '<p><br></p>',
            created_date: new Date().toISOString()
        });
        return response.data;
    }
);

export const updateNote = createAsyncThunk(
    'notes/updateNote',
    async ({ projectId, noteId, title, content }) => {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes/${noteId}`,
            { title, content }
        );
        return response.data;
    }
);

export const deleteNote = createAsyncThunk(
    'notes/deleteNote',
    async ({ projectId, noteId }) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes/${noteId}`);
        return noteId;
    }
);

const notesSlice = createSlice({
    name: 'notes',
    initialState: {
        notes: [],
        selectedNote: null,
        loading: false,
        error: null
    },
    reducers: {
        setSelectedNote: (state, action) => {
            state.selectedNote = action.payload;
        },
        clearSelectedNote: (state) => {
            state.selectedNote = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch notes
            .addCase(fetchNotes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotes.fulfilled, (state, action) => {
                state.notes = action.payload;
                state.loading = false;
            })
            .addCase(fetchNotes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Create note
            .addCase(createNote.fulfilled, (state, action) => {
                state.notes.push(action.payload);
                state.selectedNote = action.payload;
            })
            // Update note
            .addCase(updateNote.fulfilled, (state, action) => {
                const index = state.notes.findIndex(note => note._id === action.payload._id);
                if (index !== -1) {
                    state.notes[index] = { ...action.payload };
                    if (state.selectedNote?._id === action.payload._id) {
                        state.selectedNote = { ...action.payload };
                    }
                }
            })
            // Delete note
            .addCase(deleteNote.fulfilled, (state, action) => {
                state.notes = state.notes.filter(note => note._id !== action.payload);
                if (state.selectedNote?._id === action.payload) {
                    state.selectedNote = null;
                }
            });
    }
});

export const { setSelectedNote, clearSelectedNote } = notesSlice.actions;
export default notesSlice.reducer;
