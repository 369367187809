import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title,
    PointElement,
    LineElement 
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { fetchEpics, fetchUserStories, fetchCurrentSprint, fetchSprintHistory } from '../../../redux/slices/agileHubSlice';
import './Overview.css';  // Add this import

// Register Chart.js components
ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title,
    PointElement,
    LineElement
);

export const Overview = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { 
        epics, 
        userStories,
        loading,
        error,
        userStoriesLoading,
        currentSprint,
        sprintStories,
        sprintHistory,
        sprintHistoryLoading
    } = useSelector(state => state.agileHub);
    
    // States for chart data
    const [statusData, setStatusData] = useState(null);
    const [priorityData, setPriorityData] = useState(null);
    const [distributionData, setDistributionData] = useState(null);
    const [burndownData, setBurndownData] = useState(null);
    const [velocityData, setVelocityData] = useState(null);
    const [timeMetrics, setTimeMetrics] = useState({
        leadTime: 0,
        cycleTime: 0
    });

    // Fetch all required data when component mounts
    useEffect(() => {
        if (projectId) {
            dispatch(fetchEpics(projectId));
            dispatch(fetchUserStories(projectId));
            dispatch(fetchCurrentSprint(projectId));
            dispatch(fetchSprintHistory(projectId));
        }
    }, [dispatch, projectId]);

    // Process data for charts when it changes
    useEffect(() => {
        if (userStories && userStories.length > 0) {
            processStatusData();
            processPriorityData();
            calculateTimeMetrics();
        }
        
        if (epics?.length > 0 || userStories?.length > 0) {
            processDistributionData();
        }

        if (currentSprint && sprintStories) {
            processBurndownData();
        }

        if (sprintHistory && sprintHistory.length > 0) {
            processVelocityData();
        }
    }, [epics, userStories, currentSprint, sprintStories, sprintHistory]);

    const processStatusData = () => {
        // Count stories by status
        const statusCounts = userStories.reduce((acc, story) => {
            const status = story.status?.toLowerCase() || 'backlog';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {});

        // Transform status names for better display
        const statusLabels = {
            'backlog': 'Backlog',
            'to do': 'To Do',
            'todo': 'To Do',
            'in progress': 'In Progress',
            'inprogress': 'In Progress',
            'review': 'Review',
            'done': 'Done',
            'blocked': 'Blocked'
        };

        // Define status colors
        const statusColors = {
            'backlog': '#6c757d',
            'to do': '#0d6efd',
            'todo': '#0d6efd',
            'in progress': '#ffc107',
            'inprogress': '#ffc107',
            'review': '#fd7e14',
            'done': '#198754',
            'blocked': '#dc3545'
        };

        // Get data in consistent order
        const orderedStatuses = Object.keys(statusCounts).sort((a, b) => {
            const order = { 'backlog': 1, 'to do': 2, 'todo': 2, 'in progress': 3, 'inprogress': 3, 'review': 4, 'done': 5, 'blocked': 6 };
            return (order[a] || 99) - (order[b] || 99);
        });

        setStatusData({
            labels: orderedStatuses.map(status => statusLabels[status] || status),
            datasets: [
                {
                    data: orderedStatuses.map(status => statusCounts[status]),
                    backgroundColor: orderedStatuses.map(status => statusColors[status] || '#6c757d'),
                    borderColor: 'rgba(255, 255, 255, 0.6)',
                    borderWidth: 2,
                    hoverOffset: 10
                },
            ],
        });
    };

    const processPriorityData = () => {
        // Count stories by priority
        const priorityCounts = userStories.reduce((acc, story) => {
            const priority = story.priority?.toLowerCase() || 'medium';
            acc[priority] = (acc[priority] || 0) + 1;
            return acc;
        }, {});

        // Ensure all priorities are represented
        const priorities = ['low', 'medium', 'high'];
        priorities.forEach(priority => {
            if (!priorityCounts[priority]) priorityCounts[priority] = 0;
        });

        // Sort priorities by importance
        const sortedPriorities = Object.keys(priorityCounts).sort((a, b) => {
            const order = { 'low': 1, 'medium': 2, 'high': 3 };
            return order[a] - order[b];
        });

        // Define priority colors
        const priorityColors = {
            'low': 'rgba(13, 202, 240, 0.8)', // cyan
            'medium': 'rgba(253, 126, 20, 0.8)', // orange
            'high': 'rgba(220, 53, 69, 0.8)', // red
        };

        setPriorityData({
            labels: sortedPriorities.map(priority => priority.charAt(0).toUpperCase() + priority.slice(1)),
            datasets: [
                {
                    label: 'User Stories',
                    data: sortedPriorities.map(priority => priorityCounts[priority]),
                    backgroundColor: sortedPriorities.map(priority => priorityColors[priority] || 'rgba(108, 117, 125, 0.8)'),
                    borderColor: 'rgba(255, 255, 255, 0.6)',
                    borderWidth: 1,
                    borderRadius: 6,
                    barPercentage: 0.6,
                    categoryPercentage: 0.7
                },
            ],
        });
    };

    const processDistributionData = () => {
        // If there are no epics, show distribution by story points per status
        if (!epics || epics.length === 0) {
            const statusPoints = userStories.reduce((acc, story) => {
                const status = story.status?.toLowerCase() || 'backlog';
                const points = parseInt(story.storyPoints) || 0;
                acc[status] = (acc[status] || 0) + points;
                return acc;
            }, {});

            const statuses = Object.keys(statusPoints).sort((a, b) => {
                const order = { 'backlog': 1, 'to do': 2, 'todo': 2, 'in progress': 3, 'inprogress': 3, 'review': 4, 'done': 5, 'blocked': 6 };
                return (order[a] || 99) - (order[b] || 99);
            });

            setDistributionData({
                labels: statuses.map(status => status.charAt(0).toUpperCase() + status.slice(1)),
                datasets: [
                    {
                        label: 'Story Points',
                        data: statuses.map(status => statusPoints[status]),
                        backgroundColor: [
                            'rgba(108, 117, 125, 0.8)', // gray
                            'rgba(13, 110, 253, 0.8)', // blue
                            'rgba(255, 193, 7, 0.8)', // yellow
                            'rgba(25, 135, 84, 0.8)', // green
                        ],
                        borderColor: 'rgba(255, 255, 255, 0.6)',
                        borderWidth: 1,
                        borderRadius: 6,
                    },
                ],
            });
            return;
        }

        // Group user stories by epic
        const storiesByEpic = userStories.reduce((acc, story) => {
            if (story.epicId) {
                if (!acc[story.epicId]) acc[story.epicId] = [];
                acc[story.epicId].push(story);
            }
            return acc;
        }, {});

        // Calculate story points per epic
        const epicPoints = {};
        epics.forEach(epic => {
            const stories = storiesByEpic[epic._id] || [];
            const points = stories.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0);
            epicPoints[epic._id] = { 
                name: epic.title,
                points
            };
        });

        // Sort epics by story points
        const sortedEpics = Object.keys(epicPoints)
            .filter(epicId => epicPoints[epicId].points > 0)
            .sort((a, b) => epicPoints[b].points - epicPoints[a].points)
            .slice(0, 8); // Limit to top 8 epics for readability

        setDistributionData({
            labels: sortedEpics.map(epicId => epicPoints[epicId].name),
            datasets: [
                {
                    label: 'Story Points',
                    data: sortedEpics.map(epicId => epicPoints[epicId].points),
                    backgroundColor: 'rgba(102, 16, 242, 0.7)', // purple
                    borderColor: 'rgba(255, 255, 255, 0.6)',
                    borderWidth: 1,
                    borderRadius: 6,
                    barPercentage: 0.6,
                    categoryPercentage: 0.7
                },
            ],
        });
    };

    const processBurndownData = () => {
        if (!currentSprint || !sprintStories || sprintStories.length === 0) return;

        const startDate = new Date(currentSprint.startDate);
        const endDate = new Date(currentSprint.endDate);
        const totalDays = Math.max(1, Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)));
        const totalPoints = sprintStories.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0);
        const today = new Date();
        
        // Generate dates array
        const dates = Array.from({ length: totalDays + 1 }, (_, i) => {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            return date;
        });

        // Format dates for display
        const formatDate = (date) => {
            if (date.toDateString() === new Date().toDateString()) return 'Today';
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        };

        // Calculate ideal burndown line
        const idealBurndown = dates.map((_, index) => {
            return Math.max(0, totalPoints - ((totalPoints / totalDays) * index));
        });

        // Calculate actual burndown based on story completion dates
        const actualBurndown = dates.map(date => {
            if (date > today) return null; // Don't show future actuals
            
            const completedPoints = sprintStories.reduce((sum, story) => {
                if (story.status?.toLowerCase() === 'done' && 
                    story.completedDate && new Date(story.completedDate) <= date) {
                    return sum + (parseInt(story.storyPoints) || 0);
                }
                return sum;
            }, 0);
            
            return Math.max(0, totalPoints - completedPoints);
        });

        setBurndownData({
            labels: dates.map(formatDate),
            datasets: [
                {
                    label: 'Ideal Burndown',
                    data: idealBurndown,
                    borderColor: 'rgba(108, 117, 125, 0.7)',
                    borderDash: [5, 5],
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0,
                    tension: 0.1
                },
                {
                    label: 'Actual Burndown',
                    data: actualBurndown,
                    borderColor: 'rgba(13, 110, 253, 0.8)',
                    borderWidth: 2,
                    backgroundColor: 'rgba(13, 110, 253, 0.1)',
                    fill: true,
                    pointRadius: 3,
                    pointBackgroundColor: 'rgba(13, 110, 253, 1)',
                    tension: 0.1
                }
            ]
        });
    };

    const processVelocityData = () => {
        if (!sprintHistory || !Array.isArray(sprintHistory) || sprintHistory.length === 0) return;
        
        // Take up to the last 8 sprints
        const recentSprints = sprintHistory.slice(-8);
        
        // Get sprint names or create fallbacks
        const getSprintName = (sprint, index) => {
            if (sprint.name) return sprint.name.replace(/^Sprint\s/i, 'S');
            if (sprint.startDate) {
                const date = new Date(sprint.startDate);
                return `S${date.getMonth() + 1}/${date.getDate()}`;
            }
            return `S${index + 1}`;
        };
        
        // Calculate velocity (completed points)
        const completedPoints = recentSprints.map(sprint => sprint.completedPoints || 0);
        
        // Calculate average velocity across available sprints
        const avgVelocity = completedPoints.length > 0 
            ? Math.round(completedPoints.reduce((sum, points) => sum + points, 0) / completedPoints.length) 
            : 0;
        
        setVelocityData({
            labels: recentSprints.map((sprint, index) => getSprintName(sprint, index)),
            datasets: [
                {
                    label: 'Completed Points',
                    data: completedPoints,
                    borderColor: 'rgba(102, 16, 242, 0.8)',
                    backgroundColor: 'rgba(102, 16, 242, 0.2)',
                    fill: true,
                    tension: 0.2,
                    borderWidth: 2,
                    pointRadius: 4,
                    pointBackgroundColor: 'rgba(102, 16, 242, 1)'
                },
                {
                    label: 'Average Velocity',
                    data: recentSprints.map(() => avgVelocity),
                    borderColor: 'rgba(32, 201, 151, 0.8)',
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0,
                    borderWidth: 2,
                    pointRadius: 0
                }
            ]
        });
    };

    const calculateTimeMetrics = () => {
        if (!userStories || userStories.length === 0) return;

        const completedStories = userStories.filter(story => 
            story.status?.toLowerCase() === 'done' && story.completedDate
        );

        if (completedStories.length === 0) return;

        // Calculate lead time (creation to completion)
        const leadTimes = completedStories.map(story => {
            const created = new Date(story.createdAt);
            const completed = new Date(story.completedDate);
            return Math.ceil((completed - created) / (1000 * 60 * 60 * 24));
        });

        // Calculate cycle time (development start to completion)
        const cycleTimes = completedStories.map(story => {
            const started = new Date(story.startedDate || story.createdAt);
            const completed = new Date(story.completedDate);
            return Math.ceil((completed - started) / (1000 * 60 * 60 * 24));
        });

        const avgLeadTime = Math.round(leadTimes.reduce((a, b) => a + b, 0) / leadTimes.length);
        const avgCycleTime = Math.round(cycleTimes.reduce((a, b) => a + b, 0) / cycleTimes.length);

        setTimeMetrics({
            leadTime: avgLeadTime || 0,
            cycleTime: avgCycleTime || 0
        });
    };

    // Chart options
    const donutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const value = context.raw;
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${context.label}: ${value} (${percentage}%)`;
                    }
                }
            }
        },
        cutout: '65%'
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    font: {
                        size: 11
                    }
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 11
                    }
                }
            }
        }
    };

    const horizontalBarOptions = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    }
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    font: {
                        size: 11
                    }
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 11
                    }
                }
            }
        }
    };

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    font: {
                        size: 11
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    font: {
                        size: 11
                    }
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 10
                    }
                }
            }
        },
        elements: {
            line: {
                tension: 0.3
            }
        }
    };

    return (
        <div className="dashboard-layout">
            <div className="metrics-sidebar">
                <div className="metric-tile gradient-primary">
                    <div className="metric-data">
                        <p>Total Stories</p>
                        <h3>{userStories?.length || 0}</h3>
                    </div>
                    <i className="metric-icon far fa-sticky-note"></i>
                </div>
                
                <div className="metric-tile gradient-success">
                    <div className="metric-data">
                        <p>Epics</p>
                        <h3>{epics?.length || 0}</h3>
                    </div>
                    <i className="metric-icon far fa-bookmark"></i>
                </div>
                
                <div className="metric-tile gradient-info">
                    <div className="metric-data">
                        <p>Sprint Progress</p>
                        <h3>{currentSprint ? 
                            `${Math.round((sprintStories?.filter(s => s.status === 'done').length / 
                            sprintStories?.length) * 100)}%` : '-'}</h3>
                    </div>
                    <i className="metric-icon fas fa-chart-line"></i>
                </div>
                
                <div className="metric-tile gradient-warning">
                    <div className="metric-data">
                        <p>Story Points</p>
                        <h3>{userStories?.reduce((sum, story) => 
                            sum + (parseInt(story.storyPoints) || 0), 0)}</h3>
                    </div>
                    <i className="metric-icon fas fa-star"></i>
                </div>

                <div className="time-metrics-card">
                    <h4>Cycle Times</h4>
                    <div className="metric-data">
                        <p>Average Lead Time</p>
                        <h3>{timeMetrics.leadTime} days</h3>
                        <small>From creation to completion</small>
                    </div>
                    <div className="metric-data">
                        <p>Average Cycle Time</p>
                        <h3>{timeMetrics.cycleTime} days</h3>
                        <small>From development start to completion</small>
                    </div>
                </div>
            </div>

            <div className="dashboard-main">
                <div className="chart-card">
                    <div className="card-header">
                        <h3>Status Distribution</h3>
                    </div>
                    <div className="card-body">
                        {statusData ? (
                            <div className="chart-container">
                                <Doughnut data={statusData} options={donutOptions} />
                            </div>
                        ) : (
                            <div className="no-data">
                                <i className="fas fa-chart-pie"></i>
                                <p>No status data available</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="chart-card">
                    <div className="card-header">
                        <h3>Priority Distribution</h3>
                    </div>
                    <div className="card-body">
                        {priorityData ? (
                            <div className="chart-container">
                                <Bar data={priorityData} options={barOptions} />
                            </div>
                        ) : (
                            <div className="no-data">
                                <i className="fas fa-chart-bar"></i>
                                <p>No priority data available</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="chart-card">
                    <div className="card-header">
                        <h3>Sprint Burndown</h3>
                    </div>
                    <div className="card-body">
                        {burndownData ? (
                            <div className="chart-container">
                                <Line data={burndownData} options={lineOptions} />
                            </div>
                        ) : (
                            <div className="no-data">
                                <i className="fas fa-chart-line"></i>
                                <p>No sprint data available</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="chart-card">
                    <div className="card-header">
                        <h3>Velocity Trend</h3>
                    </div>
                    <div className="card-body">
                        {velocityData ? (
                            <div className="chart-container">
                                <Line data={velocityData} options={lineOptions} />
                            </div>
                        ) : (
                            <div className="no-data">
                                <i className="fas fa-chart-area"></i>
                                <p>No velocity data available</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
