import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ComponentStyling/ProjectSettings.css';
import { GoPlus } from "react-icons/go";
import { FaTrash } from "react-icons/fa"; // Import trash icon
import ReactGA from 'react-ga4';

const ProjectSettings = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [deleteProjectName, setDeleteProjectName] = useState('');
    const [stakeholderColumns, setStakeholderColumns] = useState([]);
    const [customStakeholderColumns, setCustomStakeholderColumns] = useState([]);
    const [raidLogColumns, setRaidLogColumns] = useState({
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due', 'Criticality'], // Added 'Criticality' to Actions
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status']
    });
    const [showPopup, setShowPopup] = useState(false);
    const [newColumnName, setNewColumnName] = useState('');
    const [selectedTab, setSelectedTab] = useState('Risks'); // State to track selected tab for custom columns
    const [projectCode, setProjectCode] = useState(''); // Add new state for projectCode
    const [projectCodeError, setProjectCodeError] = useState(""); // Add new state for the error message
    const [activeSettingsTab, setActiveSettingsTab] = useState("general");
    const navigate = useNavigate();

    const predefinedColumns = ['Name', 'Role', 'Email', 'Category', 'Power', 'Interest']; // Ensure 'Interest' is used
    const predefinedRaidLogColumns = {
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments', 'Created Date', 'Last Edited'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due', 'Percent Complete', 'Criticality', 'Created Date', 'Last Edited'], // Added 'Percent Complete'
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments', 'Created Date', 'Last Edited'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status', 'Created Date', 'Last Edited']
    };

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProject(response.data);
                setProjectName(response.data.project_name);
                setProjectDescription(response.data.description);
                setProjectCode(response.data.project_code); // set project code
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        const fetchProjectSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
                setStakeholderColumns(response.data.stakeholderColumns || []);
                setCustomStakeholderColumns(response.data.customStakeholderColumns || []);
                setRaidLogColumns(response.data.raidLogColumns || raidLogColumns); // Ensure raidLogColumns is defined
            } catch (error) {
                console.error('Error fetching project settings:', error);
            }
        };

        const fetchData = async () => {
            try {
                await Promise.all([fetchProject(), fetchProjectSettings()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [projectId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    const handleUpdateProject = async () => {
        try {
            console.log('Updating project with data:', {
                project_name: projectName,
                description: projectDescription
            });
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                project_name: projectName,
                description: projectDescription
            });
            setProject(response.data);
            alert('Project updated successfully');
        } catch (error) {
            console.error('Error updating project:', error);
            alert('Failed to update project');
        }
    };

    const handleDeleteProject = async () => {
        if (deleteProjectName !== projectName) {
            alert('Project name does not match. Please type the correct project name to delete');
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                data: { projectName: deleteProjectName }
            });
            navigate('/dashboard');
        } catch (error) {
            console.error('Error deleting project:', error);
            alert('Failed to delete project');
        }
    };

    const handleBlur = async (field) => {
        try {
            let updateData;
            if (field === 'project_name') {
                updateData = { project_name: projectName };
            } else if (field === 'description') {
                updateData = { description: projectDescription };
            } else if (field === 'project_code') {
                // Validate that project code is exactly 5 letters
                if (projectCode.length !== 5) {
                    setProjectCodeError("Project code must be exactly 5 letters");
                    return;
                } else {
                    setProjectCodeError("");
                }
                updateData = { project_code: projectCode };
            }
            console.log('Updating project with data on blur:', updateData);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, updateData);
            setProject(response.data);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const handleColumnChange = async (column) => {
        const updatedColumns = stakeholderColumns.includes(column)
            ? stakeholderColumns.filter(col => col !== column)
            : [...stakeholderColumns, column]; // Simplified logic

        setStakeholderColumns(updatedColumns);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns: updatedColumns,
                customStakeholderColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error updating project settings:', error);
        }
    };

    const handleRaidLogColumnChange = async (tab, column) => {
        setRaidLogColumns(prevColumns => {
            const currentColumns = prevColumns[tab];
            const updatedColumns = currentColumns.includes(column)
                ? currentColumns.filter(col => col !== column)
                : [...currentColumns, column];
            const newRaidLogColumns = { ...prevColumns, [tab]: updatedColumns };
            saveProjectSettings(newRaidLogColumns); // Save updated columns
            return newRaidLogColumns;
        });
    };

    const handleAddColumn = async () => {
        if (newColumnName.trim() === '') {
            alert('Column name cannot be empty');
            return;
        }

        const updatedCustomColumns = [newColumnName, ...customStakeholderColumns];
        setCustomStakeholderColumns(updatedCustomColumns);
        setShowPopup(false);
        setNewColumnName('');

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns,
                customStakeholderColumns: updatedCustomColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error adding new column:', error);
        }
    };

    const handleDeleteCustomColumn = async (column) => {
        const updatedCustomColumns = customStakeholderColumns.filter(col => col !== column);
        const updatedColumns = stakeholderColumns.filter(col => col !== column);

        setCustomStakeholderColumns(updatedCustomColumns);
        setStakeholderColumns(updatedColumns);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, {
                projectId,
                stakeholderColumns: updatedColumns,
                customStakeholderColumns: updatedCustomColumns,
                raidLogColumns
            });
        } catch (error) {
            console.error('Error deleting custom column:', error);
        }
    };

    const handleAddCustomColumn = async () => {
        if (!newColumnName.trim()) return;
        setRaidLogColumns(prevColumns => {
            const updatedColumns = [...prevColumns[selectedTab], newColumnName.trim()];
            const newRaidLogColumns = { ...prevColumns, [selectedTab]: updatedColumns };
            saveProjectSettings(newRaidLogColumns); // Save updated columns
            return newRaidLogColumns;
        });
        setNewColumnName('');
    };

    const saveProjectSettings = async (updatedColumns) => {
        const settings = {
            projectId: projectId.toString(),
            raidLogColumns: updatedColumns
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, settings);
        } catch (error) {
            console.error('Error saving project settings:', error);
            alert('Failed to save project settings.');
        }
    };

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="main-content col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Project Settings</h1>
                        </div>
                        {/* New Nav Tabs */}
                        <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                                <a 
                                    className={`nav-link ${activeSettingsTab === "general" ? "active" : ""}`} 
                                    onClick={() => setActiveSettingsTab("general")}
                                >
                                    General
                                </a>
                            </li>
                            <li className="nav-item">
                                <a 
                                    className={`nav-link ${activeSettingsTab === "stakeholder" ? "active" : ""}`} 
                                    onClick={() => setActiveSettingsTab("stakeholder")}
                                >
                                    Stakeholder Register
                                </a>
                            </li>
                            <li className="nav-item">
                                <a 
                                    className={`nav-link ${activeSettingsTab === "raid" ? "active" : ""}`} 
                                    onClick={() => setActiveSettingsTab("raid")}
                                >
                                    RAID Log
                                </a>
                            </li>
                        </ul>
                        
                        {/* Conditional Rendering Based on Active Tab */}
                        {activeSettingsTab === "general" && (
                            <div className="row">
                                <div className="col-md-6">
                                    {/* General - Project Info and Delete Project */}
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <label htmlFor="projectName" className="form-label">Project Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="projectName"
                                                value={projectName}
                                                onChange={(e) => setProjectName(e.target.value)}
                                                onBlur={() => handleBlur('project_name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <label htmlFor="projectCode" className="form-label">Project Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="projectCode"
                                                value={projectCode}
                                                onChange={(e) => setProjectCode(e.target.value.toUpperCase().replace(/[^A-Z]/g, ''))}
                                                onBlur={() => handleBlur('project_code')}
                                                pattern="[A-Z]{5}"
                                                title="Must be 5 uppercase letters"
                                            />
                                            {projectCodeError && <span className="error-message" style={{color: 'red', fontSize: '0.9rem'}}>{projectCodeError}</span>}
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <label htmlFor="projectDescription" className="form-label">Project Description</label>
                                            <textarea
                                                className="form-control"
                                                id="projectDescription"
                                                rows="5"
                                                value={projectDescription}
                                                onChange={(e) => setProjectDescription(e.target.value)}
                                                onBlur={() => handleBlur('description')}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary" onClick={handleUpdateProject}>Update Project</button>
                                    <div className="card mt-4">
                                        <div className="project-settings-card-body">
                                            <h2>Delete Project</h2>
                                            <input
                                                type='text'
                                                className="form-control mb-3"
                                                placeholder='Type project name to delete'
                                                value={deleteProjectName}
                                                onChange={(e) => setDeleteProjectName(e.target.value)}
                                                required
                                            />
                                            <button className="btn btn-danger" onClick={handleDeleteProject}>Delete Project</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeSettingsTab === "stakeholder" && (
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Stakeholder Register Settings */}
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <h2>Stakeholder Register Columns</h2>
                                            <h3 className="h5">Pre-Defined</h3>
                                            <ul className="project-settings-card-body">
                                                {predefinedColumns.map((column, index) => (
                                                    <li key={index} className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={stakeholderColumns.includes(column)}
                                                            onChange={() => handleColumnChange(column)}
                                                        />
                                                        <span className="ms-2">{column}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                            <h3 className="h5">Custom Columns</h3>
                                            <ul className="project-settings-card-body">
                                                {customStakeholderColumns.map((column, index) => (
                                                    <li key={index} className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={stakeholderColumns.includes(column)}
                                                            onChange={() => handleColumnChange(column)}
                                                        />
                                                        <span className="ms-2">{column}</span>
                                                        <button className="btn btn-link text-danger ms-auto" onClick={() => handleDeleteCustomColumn(column)}>
                                                            <FaTrash />
                                                        </button>
                                                    </li>
                                                ))}
                                                <li className="add-column">
                                                    <button className="btn btn-link" onClick={() => setShowPopup(true)}>
                                                        <GoPlus />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeSettingsTab === "raid" && (
                            <div className="row">
                                <div className="col-md-12">
                                    {/* RAID Log Settings */}
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <h2>RAID Log Columns</h2>
                                            {['Risks', 'Actions', 'Issues', 'Decisions'].map((tab, tabIndex) => {
                                                const allColumns = [...new Set([...predefinedRaidLogColumns[tab], ...raidLogColumns[tab]])];
                                                return (
                                                    <div key={tabIndex}>
                                                        <h3 className="h5">{tab}</h3>
                                                        <ul className="project-settings-card-body">
                                                            {allColumns.map((column, index) => (
                                                                <li key={index} className="d-flex align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={raidLogColumns[tab].includes(column)}
                                                                        onChange={() => handleRaidLogColumnChange(tab, column)}
                                                                    />
                                                                    <span className="ms-2">{column}</span>
                                                                    {!predefinedRaidLogColumns[tab].includes(column) && (
                                                                        <button
                                                                            className="btn btn-link text-danger ms-auto"
                                                                            onClick={() => handleDeleteCustomColumn(column)}
                                                                        >
                                                                            <FaTrash />
                                                                        </button>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="project-settings-card-body">
                                            <h2>Add Custom Columns to RAID Log</h2>
                                            <div className="mb-3">
                                                <label htmlFor="customColumnName" className="form-label">Column Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="customColumnName"
                                                    value={newColumnName}
                                                    onChange={(e) => setNewColumnName(e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="selectTab" className="form-label">Select Tab</label>
                                                <select
                                                    className="form-select"
                                                    id="selectTab"
                                                    value={selectedTab}
                                                    onChange={(e) => setSelectedTab(e.target.value)}
                                                >
                                                    {['Risks', 'Actions', 'Issues', 'Decisions'].map((tab, index) => (
                                                        <option key={index} value={tab}>{tab}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <button className="btn btn-primary" onClick={handleAddCustomColumn}>Add Custom Column</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </main>
                </div>
            </div>
            <div className="row">
                <Footer />
            </div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Add New Column</h3>
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Column Name"
                            value={newColumnName}
                            onChange={(e) => setNewColumnName(e.target.value)}
                        />
                        <button className="btn btn-primary" onClick={handleAddColumn}>Submit</button>
                        <button className="btn btn-secondary" onClick={() => setShowPopup(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectSettings;
