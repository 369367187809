import React, { useEffect, useState, useMemo, useCallback, lazy, Suspense } from 'react';
import "./PageCSS/Home.css";
import Navbar from '../Components/Navbar.js';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardImage from '../Components/Images/DashboardImage.png';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { FaClipboardCheck, FaUsers, FaCalendarCheck, FaSitemap, FaChartBar } from 'react-icons/fa';
import { MdMeetingRoom } from 'react-icons/md';
// Lazy load Footer component since it's below the fold
const Footer = lazy(() => import("../Components/Footer"));

// Move static data outside the component
const features = [
    {
        title: "Complete Project Planning Suite",
        description: "Visualize your project structure with our interactive Work Breakdown Structure (WBS) tool, create detailed timelines with our Project Scheduler, and organize work with Kanban boards - all integrated to help you deliver on time and within budget.",
        icon: <FaSitemap size={40} />
    },
    {
        title: "Comprehensive RAID Management",
        description: "Proactively manage project risks, track assumptions, address issues, and handle dependencies with our structured RAID logs. Identify potential problems early, assign ownership, and monitor resolution status to keep your projects on track.",
        icon: <FaClipboardCheck size={40} />
    },
    {
        title: "Stakeholder Engagement Matrix",
        description: "Map stakeholder power and interest levels in our interactive matrix. Customize communication strategies based on stakeholder positioning, track engagement history, and export visualizations for presentations to ensure all key players remain aligned with project goals.",
        icon: <FaUsers size={40} />
    },
    {
        title: "Integrated Meeting Management",
        description: "Document and organize project meetings with our rich text editor. Create detailed minutes, track action items, maintain meeting history by project, and ensure nothing falls through the cracks with automatic saving and comprehensive search.",
        icon: <MdMeetingRoom size={40} />
    },
    {
        title: "Task-Focused Daily Journal",
        description: "Plan each day effectively with our personal productivity tracker. Set task priorities with criticality levels, estimate work with story points, track completion rates, and visualize your productivity trends over time with interactive charts.",
        icon: <FaCalendarCheck size={40} />
    },
    {
        title: "Customizable Dashboard Analytics",
        description: "Make data-driven decisions with project performance visualization and reporting tools. Monitor progress across all projects, identify bottlenecks, and demonstrate value to stakeholders with exportable reports tailored to your business needs.",
        icon: <FaChartBar size={40} />
    }
];

const benefits = [
    {
        title: "Single Source of Project Truth",
        description: "Centralize all project artifacts, documentation, and tracking in one integrated platform, eliminating scattered information and reducing communication gaps."
    },
    {
        title: "Increased Project Success Rate",
        description: "Proactively identify and address risks, dependencies, and stakeholder concerns before they become problems that derail your timeline or budget."
    },
    {
        title: "Enhanced Team Productivity",
        description: "Streamline workflows with intuitive tools designed specifically for project management professionals, reducing administrative overhead by up to 40%."
    },
    {
        title: "Stakeholder Confidence",
        description: "Demonstrate project control through comprehensive tracking, transparent reporting, and professional documentation that builds trust with executives and clients."
    }
];

// Animation variants - moved outside to prevent recreation
const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" }
    }
};

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { staggerChildren: 0.2 }
    }
};

// Create memoized components for better performance
const FeatureCard = React.memo(({ feature }) => (
    <div className="feature-card">
        <div className="feature-icon">
            {feature.icon}
        </div>
        <h3>{feature.title}</h3>
        <p>{feature.description}</p>
    </div>
));

const BenefitCard = React.memo(({ benefit }) => (
    <div className="benefit-card">
        <h3>{benefit.title}</h3>
        <p>{benefit.description}</p>
    </div>
));

const PricingCard = React.memo(({ title, price, features, onChoose }) => (
    <div className="pricing-card">
        <h3>{title}</h3>
        <p className="price">{price}</p>
        <ul>
            {features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
            ))}
        </ul>
        <motion.button 
            className="home-btn-primary"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onChoose}
        >
            Choose Plan
        </motion.button>
    </div>
));

// SEO component to prevent rerendering of static SEO content
const SeoHelmet = React.memo(() => (
    <Helmet>
        <title>PMHub.io - Project Management Simplified</title>
        <meta 
            name="description" 
            content="PMHub.io offers streamlined project management tools, including RAID logs, stakeholder registers, daily planners, and comprehensive reporting to boost your team's productivity." 
        />
        <meta 
            name="keywords" 
            content="Project Management, RAID Logs, Stakeholder Registers, Daily Planners, Reporting, Productivity, PMHub" 
        />
    </Helmet>
));

export default function Home() {
    const navigate = useNavigate();
    const [isYearly, setIsYearly] = useState(false);
    
    // Memoize pricing features to prevent unnecessary rerenders
    const freePlanFeatures = useMemo(() => [
        '3 Total Projects',
        'RAID Log',
        'Stakeholder Register',
        'Daily Journal',
        'Limited Dashboard Reporting'
    ], []);
    
    const proPlanFeatures = useMemo(() => [
        'Unlimited Projects',
        'RAID Log',
        'Stakeholder Register',
        'Daily Journal',
        'Full Dashboard Reporting'
    ], []);
    
    // Memoize price calculation
    const proPrice = useMemo(() => 
        isYearly ? '$29.99/year' : '$2.99/month'
    , [isYearly]);
    
    // Use callbacks for event handlers
    const handleTogglePricing = useCallback(() => {
        setIsYearly(prev => !prev);
    }, []);
    
    const handleGetStarted = useCallback(() => {
        navigate('/register');
    }, [navigate]);
    
    const handleChoosePlan = useCallback((plan) => {
        // Navigate to registration with plan selection
        navigate(`/register?plan=${plan}`);
    }, [navigate]);

    // Initialize analytics only once
    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ 
            hitType: "pageview", 
            page: window.location.pathname + window.location.search 
        });
        
        // Preload the Footer component after the main content has loaded
        const preloadFooter = setTimeout(() => {
            // Preload but don't assign to avoid unused variable warning
            import("../Components/Footer");
        }, 2000);
        
        return () => clearTimeout(preloadFooter);
    }, []);

    return (
        <div className="App">
            <SeoHelmet />
            <Navbar />
            <main>
                <div className="main-content-wrapper">
                    <section className="hero">
                        {/* Add cloud elements */}
                        <div className="clouds-container" aria-hidden="true">
                            <div className="cloud cloud-1"></div>
                            <div className="cloud cloud-2"></div>
                            <div className="cloud cloud-3"></div>
                            <div className="cloud cloud-4"></div>
                            <div className="cloud cloud-5"></div>
                        </div>
                        <div className="container">
                            {/* Hero Section */}
                            <div className="row align-items-center">
                                <motion.div 
                                    className="col-lg-6"
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    variants={fadeInUp}
                                >
                                    <motion.h1 
                                        className="hero-title"
                                        animate={{ scale: [1, 1.02, 1] }}
                                        transition={{ duration: 2, repeat: Infinity }}
                                    >
                                        Project management, simplified
                                    </motion.h1>
                                    <p className="hero-subtitle">Plan projects, track artifacts, and manage information all in one hub</p>
                                    <motion.button 
                                        className="home-btn-primary btn-lg"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={handleGetStarted}
                                    >
                                        Get Started
                                    </motion.button>
                                </motion.div>
                                <motion.div 
                                    className="col-lg-6"
                                    initial={{ opacity: 0, x: 50 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true }}
                                >
                                    <img 
                                        src={DashboardImage} 
                                        className="hero-image" 
                                        alt="Dashboard preview" 
                                        loading="eager" 
                                        width="600" 
                                        height="400"
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </section>
                    
                    <motion.section 
                        className="benefits"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <div className="container">
                            <motion.h2 variants={fadeInUp}>Why Choose Us?</motion.h2>
                            <div className="benefit-grid">
                                {benefits.map((benefit, index) => (
                                    <motion.div 
                                        key={index}
                                        variants={fadeInUp}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <BenefitCard benefit={benefit} />
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.section>

                    {/* Features Section */}
                    <motion.section 
                        className="features"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <div className="container">
                            <motion.div 
                                className="section-header"
                                variants={fadeInUp}
                            >
                                <h2>Powerful Tools for Project Success</h2>
                                <p className="lead">Everything you need to deliver exceptional results</p>
                            </motion.div>
                            <div className="row g-4">
                                {features.map((feature, index) => (
                                    <motion.div 
                                        key={index}
                                        className="col-lg-4 col-md-6"
                                        variants={fadeInUp}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <FeatureCard feature={feature} />
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </motion.section>
                </div>

                {/* Pricing Section */}
                <motion.section 
                    className="pricing"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <div className="container">
                        <motion.h2 
                            className="shiny-text text-center mb-5"
                            variants={fadeInUp}
                        >
                            Pricing Plans
                        </motion.h2>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="pricingToggle"
                                checked={isYearly}
                                onChange={handleTogglePricing}
                            />
                            <label className="form-check-label pricingPlanLabel" htmlFor="pricingToggle">
                                {isYearly ? 'Yearly' : 'Monthly'}
                            </label>
                        </div>
                        <div className="row">
                            <motion.div 
                                className="col-lg-6 col-md-6"
                                variants={fadeInUp}
                            >
                                <PricingCard 
                                    title="Free Forever" 
                                    price="Free" 
                                    features={freePlanFeatures} 
                                    onChoose={() => handleChoosePlan('free')} 
                                />
                            </motion.div>
                            <motion.div 
                                className="col-lg-6 col-md-6"
                                variants={fadeInUp}
                            >
                                <PricingCard 
                                    title="Professional" 
                                    price={proPrice} 
                                    features={proPlanFeatures} 
                                    onChoose={() => handleChoosePlan('pro')} 
                                />
                            </motion.div>
                        </div>
                    </div>
                </motion.section>

                {/* About Us Section */}
                <motion.section 
                    className="aboutUs"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <div className="container">
                        <div className="row">
                            <motion.div 
                                className="col-lg-4"
                                variants={fadeInUp}
                            >
                                <h1 className="shiny-text">About Us</h1>
                            </motion.div>
                            <motion.div 
                                className="col-lg-8"
                                variants={fadeInUp}
                            >
                                <p>Welcome to Project Manager Hub, where we simplify project management for teams of all sizes. Our mission is to provide a user-friendly platform that helps you plan, track, and manage your projects efficiently.</p>
                                <p>Founded in 2024, PMHub was created to streamline project management and foster seamless collaboration. Our core values — innovation, collaboration, and customer satisfaction — drive everything we do.</p>
                                <p>We embrace agile methodology, continuously improving our platform to meet the evolving needs of our users. Our dedicated team is passionate about delivering the best experience and helping you succeed.</p>
                                <p>Join us on our journey to make project management effortless and effective. Together, we can achieve great things.</p>
                            </motion.div>
                        </div>
                    </div>
                </motion.section>
            </main>
            <Suspense fallback={<div className="loading-footer">Loading...</div>}>
                <Footer />
            </Suspense>
        </div>
    );
}