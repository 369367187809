import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getStatusIcon, calculateCompletionPercentage } from '../utils/epicUtils';

const EpicListView = ({ epics, sortField, sortDirection, handleSort, onEpicClick, stories = [] }) => {
    return (
        <div className="epic-list-container">
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="table-light">
                        <tr>
                            <th onClick={() => handleSort('epicNumber')} className="sortable-header" style={{ width: '100px' }}>
                                Epic #
                                {sortField === 'epicNumber' && (
                                    <FontAwesomeIcon 
                                        icon={sortDirection === 'asc' ? faCaretUp : faCaretDown} 
                                        className="ms-2" 
                                    />
                                )}
                            </th>
                            <th onClick={() => handleSort('title')} className="sortable-header">
                                Title
                                {sortField === 'title' && (
                                    <FontAwesomeIcon 
                                        icon={sortDirection === 'asc' ? faCaretUp : faCaretDown} 
                                        className="ms-2" 
                                    />
                                )}
                            </th>
                            <th onClick={() => handleSort('priority')} className="sortable-header">
                                Priority
                                {sortField === 'priority' && (
                                    <FontAwesomeIcon 
                                        icon={sortDirection === 'asc' ? faCaretUp : faCaretDown} 
                                        className="ms-2" 
                                    />
                                )}
                            </th>
                            <th onClick={() => handleSort('status')} className="sortable-header">
                                Status
                                {sortField === 'status' && (
                                    <FontAwesomeIcon 
                                        icon={sortDirection === 'asc' ? faCaretUp : faCaretDown} 
                                        className="ms-2" 
                                    />
                                )}
                            </th>
                            <th>Assignee</th>
                            <th>Stories</th>
                            <th>% Complete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {epics.length > 0 ? (
                            epics.map(epic => {
                                // Calculate completion percentage with stories
                                const completionPercentage = calculateCompletionPercentage(epic, stories);
                                
                                // Count stories for this epic
                                const epicStories = stories.filter(story => story.epicId === epic._id);
                                const epicStoriesCount = epicStories.length;
                                
                                // Count completed stories
                                const completedStories = epicStories.filter(
                                    story => story.status?.toLowerCase() === 'done'
                                ).length;
                                
                                return (
                                    <tr 
                                        key={epic._id} 
                                        className="epic-row cursor-pointer" 
                                        onClick={() => onEpicClick(epic)}
                                    >
                                        <td>
                                            <span className="epic-number">
                                                #{epic.epicNumber || epic._id?.substring(0, 6)}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="epic-title-cell">
                                                <strong>{epic.title}</strong>
                                            </div>
                                        </td>
                                        <td>
                                            <span className={`badge bg-${epic.priority?.toLowerCase()}`}>
                                                {epic.priority}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {getStatusIcon(epic.status)}
                                                <span className="ms-2">{epic.status}</span>
                                            </div>
                                        </td>
                                        <td>{epic.assignee || 'Unassigned'}</td>
                                        <td>
                                            <span className="badge bg-light text-dark">
                                                {epicStoriesCount > 0 ? 
                                                    `${completedStories}/${epicStoriesCount}` : 
                                                    '0'
                                                }
                                            </span>
                                        </td>
                                        <td>
                                            <div className="progress-wrapper">
                                                <div className="progress" style={{ height: '8px' }}>
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ 
                                                            width: `${completionPercentage}%`,
                                                            backgroundColor: epic.status === 'Blocked' ? '#dc3545' : '#0d6efd'
                                                        }}
                                                        aria-valuenow={completionPercentage}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    />
                                                </div>
                                                <span className="progress-text">
                                                    {completionPercentage}%
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center py-4">
                                    No epics match your search criteria.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EpicListView;
