import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from './slices/projectsSlice';
import tasksReducer from './slices/tasksSlice';
import profileReducer from './slices/profileSlice';
import journalReducer from './slices/journalSlice';
import analyticsReducer from './slices/analyticsSlice';
import authReducer from './slices/authSlice';
import dailyJournalReducer from './slices/dailyJournalSlice';
import agileHubReducer from './slices/agileHubSlice';
import notesReducer from './slices/notesSlice';
import meetingsReducer from './slices/meetingsSlice';
import aiReducer from './slices/aiSlice';

// Create an object with all reducers
const reducers = {
  projects: projectsReducer,
  tasks: tasksReducer,
  profile: profileReducer,
  journal: journalReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  dailyJournal: dailyJournalReducer,
  agileHub: agileHubReducer,
  notes: notesReducer,
  meetings: meetingsReducer,
  ai: aiReducer,
};

// Root reducer that resets all state (except auth) when logout action is dispatched
const rootReducer = (state, action) => {
  // When logout action is dispatched, reset the state of all reducers except auth
  if (action.type === 'auth/logout/fulfilled') {
    // Keep only the auth state, reset everything else
    state = { auth: state.auth };
  }
  
  // Regular reducer behavior for all other actions
  return Object.keys(reducers).reduce((newState, key) => {
    newState[key] = reducers[key](state?.[key], action);
    return newState;
  }, {});
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
