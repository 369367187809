import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function ProtectedRoute({ children }) {
    const { isAuthenticated, loading } = useAuth();
    console.log('ProtectedRoute: loading:', loading, 'isAuthenticated:', isAuthenticated);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        console.error('ProtectedRoute: User not authenticated, redirecting to /login');
        return <Navigate to="/login" />;
    }
    return children;
}