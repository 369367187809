import "./ComponentStyling/ProjectPage.css";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faProjectDiagram, faCalendarAlt, faClipboardList, 
    faExclamationTriangle, faUsers, faChartPie, faClock,
    faCheckCircle, faHourglassHalf, faTimesCircle, faPauseCircle,
    faEdit, faFlag, faSave, faCog, faSlidersH, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

// Register Chart components
Chart.register(ArcElement, Tooltip, Legend);

const ProjectPage = () => {
    const { projectId } = useParams(); // Get projectId from URL
    const [project, setProject] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [raidlog, setRAID] = useState([]);
    const [projectStatus, setProjectStatus] = useState('');
    const [description, setDescription] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [projectState, setProjectState] = useState('');
    const [raidLogStats, setRaidLogStats] = useState({
        complete: 0,
        inProgress: 0,
        notStarted: 0,
        blocked: 0
    });
    const [upcomingDeadlines, setUpcomingDeadlines] = useState([]);
    const [overdueTasks, setOverdueTasks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [projectProgress, setProjectProgress] = useState(0);
    const [showStatusTooltip, setShowStatusTooltip] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProject(response.data);
                setProjectStatus(response.data.status); // Initialize project status
                setDescription(response.data.description); // Initialize description
                setCreatedDate(response.data.created_date); // Initialize created date
                setProjectState(response.data.projectState); // Initialize project state
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        const fetchStakeholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
                setStakeholders(response.data);
            } catch (error) {
                console.error('Error fetching stakeholders:', error);
            }
        };

        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                setRAID(response.data);
                calculateRaidLogStats(response.data);
                processTasksAndDeadlines(response.data);
            } catch (error) {
                console.error('Error fetching RAID log:', error);
            }
        };

        fetchProject();
        fetchStakeholders();
        fetchRAID();
    }, [projectId]);

    useEffect(() => {
        const calculateProjectProgress = (raidLogs) => {
            if (!raidLogs || raidLogs.length === 0) return 0;
            
            const actionItems = raidLogs.filter(item => item.raidType === 'Action');
            if (actionItems.length === 0) return 0;
            
            const completedItems = actionItems.filter(item => item.status?.toLowerCase() === 'complete').length;
            return Math.round((completedItems / actionItems.length) * 100);
        };
        
        if (raidlog.length > 0) {
            setProjectProgress(calculateProjectProgress(raidlog));
        }
    }, [projectId, raidlog]);

    const calculateRaidLogStats = (raidLogs) => {
        const stats = {
            complete: 0,
            inProgress: 0,
            notStarted: 0,
            blocked: 0
        };

        // Filter for only Action items first
        const actionItems = raidLogs.filter(item => item.raidType === 'Action');

        actionItems.forEach(item => {
            switch(item.status?.toLowerCase()) {
                case 'complete':
                    stats.complete++;
                    break;
                case 'in progress':
                    stats.inProgress++;
                    break;
                case 'not started':
                    stats.notStarted++;
                    break;
                case 'blocked':
                    stats.blocked++;
                    break;
                default:
                    // If no status or unrecognized, count as not started
                    stats.notStarted++;
                    break;
            }
        });

        setRaidLogStats(stats);
    };

    const processTasksAndDeadlines = (raidLogs) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Filter for Action type items with due dates
        const actionItems = raidLogs.filter(item => 
            item.raidType === 'Action' && 
            item.due && 
            item.status?.toLowerCase() !== 'complete'
        );
        
        // Find upcoming deadlines (next 7 days)
        const upcoming = actionItems.filter(item => {
            // Create a copy of the due date for comparison
            const dueDate = new Date(item.due);
            dueDate.setHours(0, 0, 0, 0);
            
            const futureDate = new Date(today);
            futureDate.setDate(today.getDate() + 7);
            
            return dueDate >= today && dueDate <= futureDate;
        }).sort((a, b) => new Date(a.due) - new Date(b.due));
        
        // Find overdue tasks
        const overdue = actionItems.filter(item => {
            // Create a copy of the due date for comparison
            const dueDate = new Date(item.due);
            dueDate.setHours(0, 0, 0, 0);
            return dueDate < today;
        }).sort((a, b) => new Date(a.due) - new Date(b.due));
        
        setUpcomingDeadlines(upcoming);
        setOverdueTasks(overdue);
    };

    const handleStatusUpdate = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                status: projectStatus,
                description: description,
                created_date: createdDate,
                projectState: projectState
            });
            
            setProject({ ...project, status: projectStatus, description: description, created_date: createdDate, projectState: projectState });
            
            // Show feedback to the user
            setShowStatusTooltip(true);
            setTimeout(() => setShowStatusTooltip(false), 3000); // Hide after 3 seconds
        } catch (error) {
            console.error('Error updating project:', error.response ? error.response.data : error.message);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day to the date
        return date.toLocaleDateString('en-US', options);
    };

    const calculateDaysOverdue = (dueDate) => {
        const today = new Date();
        const due = new Date(dueDate);
        const diffTime = Math.abs(today - due);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const getStatusLabel = (status) => {
        switch(status?.toLowerCase()) {
            case 'green': return 'On Track';
            case 'yellow': return 'At Risk';
            case 'red': return 'Off Track';
            default: return 'Unknown';
        }
    };
    
    const getStateEmoji = (state) => {
        switch(state) {
            case 'Cancelled': return '❌';
            case 'In Progress': return '⏳';
            case 'Completed': return '✅';
            case 'Archived': return '📦';
            default: return '⏳';
        }
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="row">
                <main className="main-content col-12 px-md-4" style={{ zIndex: 1 }}>
                    {project ? (
                        <>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                <h1 className="h2">
                                    {project.project_name}
                                    <span className={`status-circle status-${projectStatus.toLowerCase()}`}></span>
                                </h1>
                                <div className="project-quick-actions">
                                    <button 
                                        className="btn btn-outline-secondary me-2"
                                        onClick={() => setIsEditing(!isEditing)}
                                    >
                                        <FontAwesomeIcon icon={isEditing ? faSave : faEdit} className="me-1" />
                                        {isEditing ? 'Save' : 'Edit'}
                                    </button>
                                </div>
                            </div>
                            
                            {/* Project Status Banner */}
                            <div className={`project-status-banner status-${projectStatus.toLowerCase()} mb-4`}>
                                <div className="banner-content">
                                    <div className="status-info">
                                        <span className="status-label">Status:</span>
                                        <span className="status-value">{getStatusLabel(projectStatus)}</span>
                                        <span className="status-dot"></span>
                                    </div>
                                    <div className="status-info">
                                        <span className="status-label">State:</span>
                                        <span className="status-value">
                                            {getStateEmoji(projectState)} {projectState}
                                        </span>
                                    </div>
                                    <div className="status-info">
                                        <span className="status-label">RAID Log Progress:</span>
                                        <div className="progress flex-grow-1" style={{ width: '150px', height: '8px' }}>
                                            <div 
                                                className="progress-bar" 
                                                role="progressbar"
                                                style={{ width: `${projectProgress}%` }}
                                                aria-valuenow={projectProgress} 
                                                aria-valuemin="0" 
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                        <span className="progress-percentage ms-2">{projectProgress}%</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="project-dashboard-grid">
                                {/* Project Control Card */}
                                <div className="dashboard-card controls-card">
                                    <div className="dashboard-card-header">
                                        <h2>Project Controls</h2>
                                        <FontAwesomeIcon icon={faSlidersH} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="projectStatus" className="form-label">Project Status</label>
                                            <div className="status-select-wrapper">
                                                <select
                                                    className={`form-select status-${projectStatus.toLowerCase()} project-status-select`}
                                                    id="projectStatus"
                                                    value={projectStatus}
                                                    onChange={(e) => setProjectStatus(e.target.value)}
                                                    onBlur={handleStatusUpdate}
                                                >
                                                    <option value="Green">Green - On Track</option>
                                                    <option value="Yellow">Yellow - At Risk</option>
                                                    <option value="Red">Red - Off Track</option>
                                                </select>
                                                <div className="status-help-tooltip">
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                    <div className="tooltip-content">
                                                        <p><strong>Green:</strong> Project is on track with no significant issues.</p>
                                                        <p><strong>Yellow:</strong> Project has risks that need attention.</p>
                                                        <p><strong>Red:</strong> Project has significant issues requiring intervention.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {showStatusTooltip && (
                                                <div className="status-updated-tooltip">Status updated!</div>
                                            )}
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label htmlFor="projectState" className="form-label">Project State</label>
                                            <select
                                                className="form-select project-state-select"
                                                id="projectState"
                                                value={projectState}
                                                onChange={(e) => setProjectState(e.target.value)}
                                                onBlur={handleStatusUpdate}
                                            >
                                                <option value="Completed">✅ Completed</option>
                                                <option value="In Progress">⏳ In Progress</option>
                                                <option value="Cancelled">❌ Cancelled</option>
                                                <option value="Archived">📦 Archived</option>
                                            </select>
                                        </div>
                                        
                                        <div className="project-action-buttons mt-4">
                                            <Link to={`/projects/${projectId}/settings`} className="btn btn-outline-primary btn-sm">
                                                <FontAwesomeIcon icon={faCog} className="me-1" />
                                                Settings
                                            </Link>
                                            <Link to={`/projects/${projectId}/meetings`} className="btn btn-outline-primary btn-sm">
                                                Add Meeting
                                            </Link>
                                            <Link to={`/projects/${projectId}/raidlog`} className="btn btn-outline-primary btn-sm">
                                                Add RAID Item
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Task Status Summary Card - Moved here */}
                                <div className="dashboard-card summary-card">
                                    <div className="dashboard-card-header">
                                        <h2>Task Status Summary</h2>
                                        <FontAwesomeIcon icon={faClipboardList} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        <div className="status-summary">
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faCheckCircle} className="status-icon complete" />
                                                <div className="status-count">{raidLogStats.complete}</div>
                                                <div className="status-label">Complete</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faHourglassHalf} className="status-icon in-progress" />
                                                <div className="status-count">{raidLogStats.inProgress}</div>
                                                <div className="status-label">In Progress</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faPauseCircle} className="status-icon not-started" />
                                                <div className="status-count">{raidLogStats.notStarted}</div>
                                                <div className="status-label">Not Started</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faTimesCircle} className="status-icon blocked" />
                                                <div className="status-count">{raidLogStats.blocked}</div>
                                                <div className="status-label">Blocked</div>
                                            </div>
                                        </div>
                                        
                                        <div className="text-center mt-3">
                                            <Link to={`/projects/${projectId}/raidlog`} className="btn btn-outline-primary">
                                                View RAID Log
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Project Description Card */}
                                <div className="dashboard-card description-card">
                                    <div className="dashboard-card-header">
                                        <h2>Description</h2>
                                        <FontAwesomeIcon icon={faClipboardList} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={handleStatusUpdate}
                                            rows="5"
                                            placeholder="Enter project description..."
                                            disabled={!isEditing}
                                        />
                                    </div>
                                </div>
                                
                                {/* RAID Log Status Card */}
                                <div className="dashboard-card raid-card">
                                    <div className="dashboard-card-header">
                                        <h2>RAID Log Status</h2>
                                        <FontAwesomeIcon icon={faChartPie} />
                                    </div>
                                    <div className="dashboard-card-body chart-container">
                                        {raidLogStats.complete + raidLogStats.inProgress + raidLogStats.notStarted + raidLogStats.blocked > 0 ? (
                                            <Doughnut
                                                data={{
                                                    labels: ['Complete', 'In Progress', 'Not Started', 'Blocked'],
                                                    datasets: [{
                                                        data: [
                                                            raidLogStats.complete,
                                                            raidLogStats.inProgress,
                                                            raidLogStats.notStarted,
                                                            raidLogStats.blocked
                                                        ],
                                                        backgroundColor: ['#28a745', '#007bff', '#6c757d', '#dc3545'],
                                                        borderWidth: 0
                                                    }]
                                                }}
                                                options={{
                                                    plugins: {
                                                        legend: { 
                                                            position: 'bottom',
                                                            labels: { boxWidth: 12, padding: 8, font: { size: 9 } }
                                                        }
                                                    },
                                                    cutout: '70%',
                                                    maintainAspectRatio: false
                                                }}
                                            />
                                        ) : (
                                            <div className="text-center">
                                                <p>No RAID log data available</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                {/* Stakeholders Summary Card */}
                                <div className="dashboard-card stakeholders-card">
                                    <div className="dashboard-card-header">
                                        <h2>Stakeholders</h2>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        {stakeholders.length > 0 ? (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Role</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stakeholders.slice(0, 5).map((stakeholder, index) => (
                                                        <tr key={index}>
                                                            <td>{stakeholder.name}</td>
                                                            <td>{stakeholder.role}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p className="text-center">No stakeholders added</p>
                                        )}
                                        {stakeholders.length > 5 && (
                                            <div className="text-center mt-2">
                                                <Link to={`/projects/${projectId}/stakeholders`} className="btn btn-sm btn-outline-primary">
                                                    View all stakeholders
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                {/* Overdue Tasks Count Card */}
                                <div className="dashboard-card overdue-count-card">
                                    <div className="dashboard-card-header">
                                        <h2>Overdue Tasks</h2>
                                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#dc3545' }} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        <div className="big-number">{overdueTasks.length}</div>
                                        <div className="text-center">
                                            {overdueTasks.length > 0 
                                                ? 'Tasks require attention' 
                                                : 'All tasks on track'}
                                        </div>
                                    </div>
                                </div>

                                {/* Overdue Tasks Detailed Card */}
                                {overdueTasks.length > 0 && (
                                    <div className="dashboard-card overdue-detailed-card">
                                        <div className="dashboard-card-header">
                                            <h2>Overdue Tasks Details</h2>
                                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#dc3545' }} />
                                        </div>
                                        <div className="dashboard-card-body">
                                            <div style={{ overflowX: 'auto' }}>
                                                <table className="dashboard-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Due Date</th>
                                                            <th>Owner</th>
                                                            <th>Days Overdue</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {overdueTasks.map((task, index) => {
                                                            const dueDate = new Date(task.due);
                                                            const today = new Date();
                                                            const daysOverdue = Math.floor((today - dueDate) / (1000 * 60 * 60 * 24));
                                                            
                                                            return (
                                                                <tr key={index} className="overdue-task-row">
                                                                    <td>
                                                                        <Link to={`/projects/${projectId}/raidlog`} className="overdue-task-link">
                                                                            {task.action}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{formatDate(task.due)}</td>
                                                                    <td>{task.owner || 'Unassigned'}</td>
                                                                    <td className="days-overdue">{daysOverdue} days</td>
                                                                    <td>{task.status || 'Not Started'}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Upcoming Deadlines Card */}
                                <div className="dashboard-card upcoming-card">
                                    <div className="dashboard-card-header">
                                        <h2>Upcoming Actions</h2>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        {upcomingDeadlines.length > 0 ? (
                                            <div style={{ overflowX: 'auto' }}>
                                                <table className="dashboard-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Due Date</th>
                                                            <th>Owner</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {upcomingDeadlines.map((task, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Link to={`/projects/${projectId}/raidlog`} className="action-link">
                                                                        {task.action}
                                                                    </Link>
                                                                </td>
                                                                <td>{formatDate(task.due)}</td>
                                                                <td>{task.owner || 'Unassigned'}</td>
                                                                <td>{task.status || 'Not Started'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faClock} style={{ fontSize: '2rem', color: '#6c757d' }} />
                                                <p className="mt-2">No upcoming actions</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Task Status Icons Card */}
                                <div className="dashboard-card summary-card">
                                    <div className="dashboard-card-header">
                                        <h2>Task Status Summary</h2>
                                        <FontAwesomeIcon icon={faClipboardList} />
                                    </div>
                                    <div className="dashboard-card-body">
                                        <div className="status-summary">
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faCheckCircle} className="status-icon complete" />
                                                <div className="status-count">{raidLogStats.complete}</div>
                                                <div className="status-label">Complete</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faHourglassHalf} className="status-icon in-progress" />
                                                <div className="status-count">{raidLogStats.inProgress}</div>
                                                <div className="status-label">In Progress</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faPauseCircle} className="status-icon not-started" />
                                                <div className="status-count">{raidLogStats.notStarted}</div>
                                                <div className="status-label">Not Started</div>
                                            </div>
                                            
                                            <div className="status-item">
                                                <FontAwesomeIcon icon={faTimesCircle} className="status-icon blocked" />
                                                <div className="status-count">{raidLogStats.blocked}</div>
                                                <div className="status-label">Blocked</div>
                                            </div>
                                        </div>
                                        
                                        <div className="text-center mt-3">
                                            <Link to={`/projects/${projectId}/raidlog`} className="btn btn-outline-primary">
                                                View RAID Log
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="alert alert-danger mt-4">Project not found</div>
                    )}
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default ProjectPage;