import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes, faCalendarAlt, faCheckSquare, 
    faSpinner, faArrowRight, faPlus, faSearch
} from '@fortawesome/free-solid-svg-icons';
import './NewSprintSlider.css';

const NewSprintSlider = ({ 
    showSlider,
    sliderClosing,
    closeSlider,
    onStartSprint, 
    availableStories = [], 
    isLoading
}) => {
    // State initialization
    const today = new Date();
    const defaultEndDate = new Date(today);
    defaultEndDate.setDate(today.getDate() + 14); // Simple 2 week sprint default

    const [sprintData, setSprintData] = useState({
        name: `Sprint ${today.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`,
        startDate: today.toISOString().split('T')[0],
        endDate: defaultEndDate.toISOString().split('T')[0],
        goal: '',
        selectedStories: []
    });
    const [searchTerm, setSearchTerm] = useState('');

    // Filter stories based on search
    const filteredStories = availableStories.filter(story => 
        story.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        story.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Reset form when slider opens
    useEffect(() => {
        if (showSlider && !sliderClosing) {
            setSprintData({
                name: `Sprint ${today.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`,
                startDate: today.toISOString().split('T')[0],
                endDate: defaultEndDate.toISOString().split('T')[0],
                goal: '',
                selectedStories: []
            });
            setSearchTerm('');
        }
    }, [showSlider, sliderClosing]);

    // Handle field changes
    const handleChange = (field, value) => {
        setSprintData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Handle story selection
    const handleStorySelect = (storyId) => {
        setSprintData(prev => {
            const newSelectedStories = prev.selectedStories.includes(storyId)
                ? prev.selectedStories.filter(id => id !== storyId)
                : [...prev.selectedStories, storyId];
            return { ...prev, selectedStories: newSelectedStories };
        });
    };

    // Handle select all stories
    const handleSelectAll = () => {
        setSprintData(prev => {
            const allSelected = filteredStories.every(story => 
                prev.selectedStories.includes(story._id)
            );
            
            return {
                ...prev,
                selectedStories: allSelected 
                    ? [] 
                    : filteredStories.map(story => story._id)
            };
        });
    };

    // Calculate total points
    const totalPoints = availableStories
        .filter(story => sprintData.selectedStories.includes(story._id))
        .reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0);

    // Handle submit with debug logging
    const handleSubmit = () => {
        // Convert story IDs to strings if they're not already
        const selectedStoriesAsStrings = sprintData.selectedStories.map(id => id.toString());
        
        console.log('Submitting sprint with selected stories:', selectedStoriesAsStrings);
        
        // Create a clean sprint data object
        const submitData = {
            name: sprintData.name,
            startDate: sprintData.startDate,
            endDate: sprintData.endDate,
            goal: sprintData.goal,
            totalPoints,
            stories: selectedStoriesAsStrings
        };
        
        console.log('Final sprint data being submitted:', submitData);
        
        onStartSprint(submitData);
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Start New Sprint</h2>
                    <div className="sliding-panel-subtitle">
                        Set sprint details and select user stories
                    </div>
                </div>
                
                <div className="sliding-panel-content">
                    <div className="form-section">
                        <h3 className="section-title">Sprint Details</h3>
                        <div className="mb-3">
                            <label className="form-label">Sprint Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={sprintData.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label className="form-label">Sprint Goal</label>
                            <input
                                type="text"
                                className="form-control"
                                value={sprintData.goal}
                                onChange={(e) => handleChange('goal', e.target.value)}
                                placeholder="What do we want to achieve in this sprint?"
                            />
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">
                                    <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                                    Start Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={sprintData.startDate}
                                    onChange={(e) => handleChange('startDate', e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">
                                    <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={sprintData.endDate}
                                    onChange={(e) => handleChange('endDate', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-section">
                        <h3 className="section-title">User Stories</h3>
                        <div className="sprint-search-controls d-flex justify-content-between mb-3">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search stories..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <button 
                                className="btn btn-outline-primary ms-2"
                                onClick={handleSelectAll}
                            >
                                {sprintData.selectedStories.length === filteredStories.length && 
                                 filteredStories.length > 0 ? 'Deselect All' : 'Select All'}
                            </button>
                        </div>
                        
                        <div className="sprint-stories-list">
                            {availableStories.length > 0 ? (
                                filteredStories.map(story => (
                                    <div 
                                        key={story._id} 
                                        className={`sprint-story-item ${sprintData.selectedStories.includes(story._id) ? 'selected' : ''}`}
                                        onClick={() => handleStorySelect(story._id)}
                                    >
                                        <div className="story-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={sprintData.selectedStories.includes(story._id)}
                                                onChange={() => handleStorySelect(story._id)}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <div className="story-details">
                                            <div className="story-title-row">
                                                <div className="story-id">US-{story.storyNumber}</div>
                                                <div className="story-title">{story.title}</div>
                                            </div>
                                            <div className="story-meta">
                                                {story.priority && (
                                                    <span className={`badge bg-${story.priority?.toLowerCase()}`}>
                                                        {story.priority}
                                                    </span>
                                                )}
                                                {story.storyPoints && (
                                                    <span className="story-points-badge">
                                                        {story.storyPoints} pts
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-4 text-muted">
                                    No stories available for this sprint. Create stories in the backlog first.
                                </div>
                            )}
                        </div>

                        <div className="selected-count mt-3">
                            <span className="text-muted">
                                <strong>{sprintData.selectedStories.length}</strong> of <strong>{availableStories.length}</strong> stories selected • <strong>{totalPoints}</strong> story points
                            </span>
                        </div>
                    </div>
                    
                    <div className="d-flex justify-content-end mt-4">
                        <button 
                            className="btn btn-secondary me-2"
                            onClick={closeSlider}
                        >
                            Cancel
                        </button>
                        <button 
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={!sprintData.name || !sprintData.startDate || 
                                     !sprintData.endDate || sprintData.selectedStories.length === 0 || 
                                     isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                    Starting Sprint...
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                                    Start Sprint
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSprintSlider;
