import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlus, faEdit, faTrash, faSearch, faFilter, faSort, 
    faTh, faList, faLayerGroup, faEllipsisV, faCheck, 
    faSpinner, faPause, faExclamationTriangle, faCheckCircle, faPlayCircle, faCircle, faMagnifyingGlass, faInbox, faStar, faChartLine 
} from '@fortawesome/free-solid-svg-icons';
import { 
    fetchUserStories, 
    createUserStory,
    updateUserStory,
    deleteUserStory,
    fetchEpics 
} from '../../../redux/slices/agileHubSlice';
import { createPortal } from 'react-dom';
import UserStorySlider from '../components/UserStorySlider';
import UserStoryDetailsSlider from '../components/UserStoryDetailsSlider';
import './Backlog.css';  // Add this import

export const Backlog = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { userStories, epics, userStoriesLoading, userStoriesError } = useSelector(state => state.agileHub);
    
    // State for create slider
    const [showCreateSlider, setShowCreateSlider] = useState(false);
    const [createSliderClosing, setCreateSliderClosing] = useState(false);
    const [currentStory, setCurrentStory] = useState(null);
    
    // State for detail/edit slider
    const [showDetailSlider, setShowDetailSlider] = useState(false);
    const [detailSliderClosing, setDetailSliderClosing] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);

    // State for filtering and sorting
    const [searchTerm, setSearchTerm] = useState('');
    const [filterPriority, setFilterPriority] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [groupBy, setGroupBy] = useState('none'); // 'none', 'epic', 'status', 'priority'
    const [sortBy, setSortBy] = useState('storyNumber'); // 'storyNumber', 'priority', 'title'
    const [sortDirection, setSortDirection] = useState('asc');
    const [viewMode, setViewMode] = useState('card'); // 'card' or 'list'

    // Add new analytics state
    const [backlogAnalytics, setBacklogAnalytics] = useState({
        totalPoints: 0,
        avgStoryPoints: 0,
        priorityBreakdown: { high: 0, medium: 0, low: 0 },
        readinessScore: 0
    });

    useEffect(() => {
        dispatch(fetchUserStories(projectId));
        dispatch(fetchEpics(projectId));
    }, [dispatch, projectId]);

    // Set up sliding panel portal
    useEffect(() => {
        let panelRoot = document.getElementById('sliding-panel-root');
        if (!panelRoot) {
            panelRoot = document.createElement('div');
            panelRoot.id = 'sliding-panel-root';
            document.body.appendChild(panelRoot);
        }
        
        return () => {
            // No need to remove on unmount since other components may use it
        };
    }, []);

    // Filtering and sorting function
    const getFilteredAndSortedStories = () => {
        return userStories
            .filter(story => {
                const matchesSearch = 
                    story.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    story.description?.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesPriority = filterPriority === 'All' || story.priority === filterPriority;
                const matchesStatus = filterStatus === 'All' || story.status === filterStatus;
                return matchesSearch && matchesPriority && matchesStatus;
            })
            .sort((a, b) => {
                if (sortBy === 'storyNumber') {
                    return sortDirection === 'asc' 
                        ? a.storyNumber - b.storyNumber 
                        : b.storyNumber - a.storyNumber;
                } else if (sortBy === 'priority') {
                    const priorityOrder = { 'High': 0, 'Medium': 1, 'Low': 2 };
                    const priorityComparison = priorityOrder[a.priority] - priorityOrder[b.priority];
                    return sortDirection === 'asc' ? priorityComparison : -priorityComparison;
                } else if (sortBy === 'title') {
                    const titleComparison = a.title.localeCompare(b.title);
                    return sortDirection === 'asc' ? titleComparison : -titleComparison;
                }
                return 0;
            });
    };

    // Group stories by the selected grouping
    const getGroupedStories = () => {
        const filtered = getFilteredAndSortedStories();
        
        if (groupBy === 'none') {
            return { 'All Stories': filtered };
        }
        
        if (groupBy === 'epic') {
            const grouped = { 'Unassigned': [] };
            filtered.forEach(story => {
                if (story.epicId) {
                    const epic = epics.find(e => e._id === story.epicId);
                    const epicTitle = epic ? epic.title : 'Unknown Epic';
                    if (!grouped[epicTitle]) {
                        grouped[epicTitle] = [];
                    }
                    grouped[epicTitle].push(story);
                } else {
                    grouped['Unassigned'].push(story);
                }
            });
            return grouped;
        }
        
        if (groupBy === 'status') {
            const grouped = {};
            filtered.forEach(story => {
                const status = story.status || 'Backlog';
                if (!grouped[status]) {
                    grouped[status] = [];
                }
                grouped[status].push(story);
            });
            return grouped;
        }
        
        if (groupBy === 'priority') {
            const grouped = { 'High': [], 'Medium': [], 'Low': [] };
            filtered.forEach(story => {
                grouped[story.priority || 'Medium'].push(story);
            });
            return grouped;
        }
        
        return { 'All Stories': filtered };
    };

    // Calculate backlog analytics
    useEffect(() => {
        if (userStories?.length > 0) {
            const totalPoints = userStories.reduce((sum, story) => 
                sum + (parseInt(story.storyPoints) || 0), 0);
            
            const priorityCount = userStories.reduce((acc, story) => {
                const priority = story.priority?.toLowerCase() || 'medium';
                acc[priority] = (acc[priority] || 0) + 1;
                return acc;
            }, { high: 0, medium: 0, low: 0 });

            // Calculate readiness score based on story completeness
            const readinessScores = userStories.map(story => {
                let score = 0;
                if (story.title) score += 20;
                if (story.description) score += 20;
                if (story.acceptanceCriteria?.length > 0) score += 20;
                if (story.storyPoints) score += 20;
                if (story.priority) score += 20;
                return score;
            });

            const avgReadiness = readinessScores.reduce((a, b) => a + b, 0) / readinessScores.length;

            setBacklogAnalytics({
                totalPoints,
                avgStoryPoints: Math.round(totalPoints / userStories.length * 10) / 10,
                priorityBreakdown: priorityCount,
                readinessScore: Math.round(avgReadiness)
            });
        }
    }, [userStories]);

    // Create new story handlers
    const handleCreateStory = () => {
        setCurrentStory({
            // Basic information
            title: '',
            description: '',
            
            // User story format
            asA: '',
            iWantTo: '',
            soThat: '',
            
            // Planning
            priority: 'Medium',
            status: 'Backlog',
            epicId: '',
            storyPoints: '',
            
            // Acceptance criteria
            acceptanceCriteria: [],
            
            // Technical details
            dependencies: [],
            technicalNotes: '',
            
            // Testing & Quality
            testScenarios: [],
            
            // Planning & Tracking
            sprintId: '',
            estimatedHours: '',
            tags: ''
        });
        setShowCreateSlider(true);
        setCreateSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeCreateSlider = () => {
        setCreateSliderClosing(true);
        setTimeout(() => {
            setShowCreateSlider(false);
            setCreateSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleInputChange = (field, value) => {
        setCurrentStory({...currentStory, [field]: value});
    };

    const handleSubmit = async (storyData) => {
        try {
            // Check if we're updating an existing story or creating a new one
            const isNewStory = !storyData._id;
            const isExplicitSave = storyData._isExplicitSave === true;
            
            let result;
            if (isNewStory) {
                // For a new story, use the createUserStory action
                result = await dispatch(createUserStory({ 
                    projectId, 
                    userStoryData: storyData 
                })).unwrap();
                
                // Update the currentStory with the newly created story's ID
                if (result._id) {
                    setCurrentStory({...result});
                }
            } else {
                // For existing story, use updateUserStory action
                const { _id, createdAt, updatedAt, _isExplicitSave, ...updateData } = storyData;
                result = await dispatch(updateUserStory({
                    projectId,
                    storyId: _id,
                    userStoryData: updateData
                })).unwrap();
            }
            
            // Only close the slider on explicit save (button click)
            if (isExplicitSave) {
                closeCreateSlider();
            }
            
            return result;
        } catch (error) {
            console.error('Error handling user story:', error);
            throw error;
        }
    };

    // Detail/edit story handlers
    const handleViewStory = (story) => {
        setSelectedStory(story);
        setShowDetailSlider(true);
        setDetailSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeDetailSlider = () => {
        setDetailSliderClosing(true);
        setTimeout(() => {
            setShowDetailSlider(false);
            setDetailSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleUpdateStory = async (storyData) => {
        try {
            // Create a clean copy of the data without _id
            const { _id, createdAt, updatedAt, ...updateData } = storyData;
            
            await dispatch(updateUserStory({ 
                projectId, 
                storyId: selectedStory._id,
                userStoryData: updateData
            })).unwrap();
        } catch (error) {
            console.error('Error updating story:', error);
            // You might want to show a notification to the user here
        }
    };

    const handleDeleteStory = async (storyId) => {
        if (window.confirm('Are you sure you want to delete this user story?')) {
            await dispatch(deleteUserStory({ projectId, storyId }));
            closeDetailSlider();
        }
    };

    // Get status icon based on story status
    const getStatusIcon = (status) => {
        switch(status?.toLowerCase()) {
            case 'done':
                return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
            case 'in progress':
                return <FontAwesomeIcon icon={faPlayCircle} className="text-primary" />;
            case 'ready':
                return <FontAwesomeIcon icon={faCircle} className="text-info" />;
            case 'review':
                return <FontAwesomeIcon icon={faMagnifyingGlass} className="text-warning" />;
            case 'blocked':
                return <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />;
            case 'backlog':
            default:
                return <FontAwesomeIcon icon={faInbox} className="text-secondary" />;
        }
    };

    // Toggle sort direction or change sort field
    const handleSort = (field) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortDirection('asc');
        }
    };

    if (userStoriesLoading) {
        return (
            <div className="backlog-loading">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p>Loading user stories...</p>
            </div>
        );
    }

    if (userStoriesError) {
        return (
            <div className="alert alert-danger">
                <strong>Error:</strong> {userStoriesError}
            </div>
        );
    }

    const groupedStories = getGroupedStories();

    // Add after existing render code, before the return statement
    const renderAnalyticsDashboard = () => (
        <div className="backlog-analytics mb-4">
            <div className="analytics-grid">
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faList} className="text-primary" />
                    </div>
                    <div className="analytic-content">
                        <h4>Total Stories</h4>
                        <div className="analytic-value">{userStories?.length || 0}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faStar} className="text-warning" />
                    </div>
                    <div className="analytic-content">
                        <h4>Total Points</h4>
                        <div className="analytic-value">{backlogAnalytics.totalPoints}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faChartLine} className="text-success" />
                    </div>
                    <div className="analytic-content">
                        <h4>Avg. Points/Story</h4>
                        <div className="analytic-value">{backlogAnalytics.avgStoryPoints}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-info" />
                    </div>
                    <div className="analytic-content">
                        <h4>Readiness Score</h4>
                        <div className="analytic-value">
                            {backlogAnalytics.readinessScore}%
                            <div className="progress mt-2" style={{ height: '4px' }}>
                                <div 
                                    className="progress-bar" 
                                    style={{ 
                                        width: `${backlogAnalytics.readinessScore}%`,
                                        backgroundColor: `hsl(${backlogAnalytics.readinessScore * 1.2}, 70%, 50%)`
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="backlog-container">
            <div className="backlog-header">
                <h3>Product Backlog</h3>
                <button className="btn btn-primary" onClick={handleCreateStory}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add User Story
                </button>
            </div>

            {renderAnalyticsDashboard()}

            <div className="backlog-filters mb-4">
                <div className="row g-3 align-items-center">
                    <div className="col-md-4">
                        <div className="input-group">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search user stories..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <select 
                            className="form-select"
                            value={filterPriority}
                            onChange={(e) => setFilterPriority(e.target.value)}
                        >
                            <option value="All">All Priorities</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select 
                            className="form-select"
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <option value="All">All Statuses</option>
                            <option value="Backlog">Backlog</option>
                            <option value="Ready">Ready</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Review">Review</option>
                            <option value="Done">Done</option>
                            <option value="Blocked">Blocked</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select 
                            className="form-select"
                            value={groupBy}
                            onChange={(e) => setGroupBy(e.target.value)}
                        >
                            <option value="none">No Grouping</option>
                            <option value="epic">Group by Epic</option>
                            <option value="status">Group by Status</option>
                            <option value="priority">Group by Priority</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <div className="btn-group">
                            <button 
                                className={`btn btn-outline-secondary ${viewMode === 'card' ? 'active' : ''}`}
                                onClick={() => setViewMode('card')}
                                title="Card View"
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </button>
                            <button 
                                className={`btn btn-outline-secondary ${viewMode === 'list' ? 'active' : ''}`}
                                onClick={() => setViewMode('list')}
                                title="List View"
                            >
                                <FontAwesomeIcon icon={faList} />
                            </button>
                        </div>
                        <div className="btn-group ms-2">
                            <button 
                                className="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                title="Sort Options"
                            >
                                <FontAwesomeIcon icon={faSort} />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <button 
                                        className="dropdown-item"
                                        onClick={() => handleSort('storyNumber')}
                                    >
                                        Sort by ID {sortBy === 'storyNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        className="dropdown-item"
                                        onClick={() => handleSort('title')}
                                    >
                                        Sort by Title {sortBy === 'title' && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        className="dropdown-item"
                                        onClick={() => handleSort('priority')}
                                    >
                                        Sort by Priority {sortBy === 'priority' && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="filter-badges mt-2">
                    {searchTerm && (
                        <span className="filter-badge">
                            Search: {searchTerm}
                            <button onClick={() => setSearchTerm('')}>×</button>
                        </span>
                    )}
                    {filterPriority !== 'All' && (
                        <span className="filter-badge">
                            Priority: {filterPriority}
                            <button onClick={() => setFilterPriority('All')}>×</button>
                        </span>
                    )}
                    {filterStatus !== 'All' && (
                        <span className="filter-badge">
                            Status: {filterStatus}
                            <button onClick={() => setFilterStatus('All')}>×</button>
                        </span>
                    )}
                    {(searchTerm || filterPriority !== 'All' || filterStatus !== 'All') && (
                        <button 
                            className="clear-filters-btn"
                            onClick={() => {
                                setSearchTerm('');
                                setFilterPriority('All');
                                setFilterStatus('All');
                            }}
                        >
                            Clear All Filters
                        </button>
                    )}
                </div>
            </div>

            <div className="backlog-content mt-4">
                {Object.keys(groupedStories).length === 0 ? (
                    <div className="backlog-empty-state">
                        <div className="empty-icon">
                            <FontAwesomeIcon icon={faLayerGroup} size="3x" />
                        </div>
                        <h4>No User Stories Found</h4>
                        <p>Start by adding your first user story to the backlog.</p>
                        <button className="btn btn-primary" onClick={handleCreateStory}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Add User Story
                        </button>
                    </div>
                ) : (
                    Object.entries(groupedStories).map(([groupName, stories]) => (
                        <div key={groupName} className="backlog-group mb-4">
                            <div className="backlog-group-header">
                                <h4>{groupName} <span className="badge bg-secondary">{stories.length}</span></h4>
                            </div>
                            
                            {viewMode === 'card' ? (
                                <div className="backlog-cards">
                                    {stories.map(story => (
                                        <div 
                                            key={story._id} 
                                            className="backlog-card" 
                                            onClick={() => handleViewStory(story)}
                                        >
                                            <div className={`card-priority-indicator priority-${story.priority?.toLowerCase()}`}></div>
                                            <div className="backlog-card-header">
                                                <span className="story-id">US-{story.storyNumber}</span>
                                                <span className={`badge bg-${story.priority?.toLowerCase()}`}>
                                                    {story.priority}
                                                </span>
                                            </div>
                                            <h5 className="backlog-card-title">{story.title}</h5>
                                            {story.description && (
                                                <p className="backlog-card-description">{story.description}</p>
                                            )}
                                            <div className="backlog-card-meta">
                                                <div className="story-meta-item">
                                                    <span className="status-icon">
                                                        {getStatusIcon(story.status)}
                                                    </span>
                                                    <span className="status-text">{story.status || 'Backlog'}</span>
                                                </div>
                                                
                                                {story.epicId && (
                                                    <div className="story-meta-item">
                                                        <span className="epic-badge">
                                                            {epics.find(e => e._id === story.epicId)?.title || 'Epic'}
                                                        </span>
                                                    </div>
                                                )}
                                                
                                                {story.storyPoints && (
                                                    <div className="story-points">{story.storyPoints}</div>
                                                )}
                                            </div>
                                            <div className="backlog-card-footer">
                                                {story.acceptanceCriteria?.length > 0 && (
                                                    <div className="criteria-progress">
                                                        <div className="progress" style={{ height: '6px' }}>
                                                            <div
                                                                className="progress-bar"
                                                                style={{
                                                                    width: `${(story.acceptanceCriteria.filter(c => c.completed).length / 
                                                                        story.acceptanceCriteria.length) * 100}%`
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <span className="criteria-count">
                                                            {story.acceptanceCriteria.filter(c => c.completed).length}/{story.acceptanceCriteria.length}
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="card-actions">
                                                    <button
                                                        className="btn btn-sm btn-icon"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteStory(story._id);
                                                        }}
                                                        title="Delete Story"
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '120px' }}>ID</th> {/* Increased width */}
                                                <th>Title</th>
                                                <th>Epic</th>
                                                <th style={{ width: '100px' }}>Priority</th>
                                                <th style={{ width: '150px' }}>Status</th> {/* Increased width */}
                                                <th style={{ width: '80px' }}>Points</th>
                                                <th style={{ width: '100px' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stories.map(story => (
                                                <tr key={story._id} onClick={() => handleViewStory(story)} style={{ cursor: 'pointer' }}>
                                                    <td>US-{story.storyNumber}</td>
                                                    <td>
                                                        <div className="story-title">
                                                            {story.title}
                                                            {story.acceptanceCriteria?.length > 0 && (
                                                                <div className="criteria-chip">
                                                                    <span className="criteria-count">
                                                                        {story.acceptanceCriteria.filter(c => c.completed).length}/{story.acceptanceCriteria.length}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {story.epicId ? 
                                                            <span className="epic-pill">
                                                                {epics.find(e => e._id === story.epicId)?.title || 'N/A'}
                                                            </span> 
                                                            : 'Unassigned'
                                                        }
                                                    </td>
                                                    <td>
                                                        <span className={`badge bg-${story.priority.toLowerCase()}`}>
                                                            {story.priority}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="status-cell">
                                                            <span className="status-icon">
                                                                {getStatusIcon(story.status)}
                                                            </span>
                                                            <span className="status-text">{story.status || 'Backlog'}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {story.storyPoints ? (
                                                            <div className="story-points-pill">{story.storyPoints}</div>
                                                        ) : '-'}
                                                    </td>
                                                    <td onClick={e => e.stopPropagation()}>
                                                        <div className="action-buttons">
                                                            <button 
                                                                className="btn btn-sm btn-outline-primary btn-icon"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleViewStory(story);
                                                                }}
                                                                title="Edit Story"
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            <button 
                                                                className="btn btn-sm btn-outline-danger btn-icon"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteStory(story._id);
                                                                }}
                                                                title="Delete Story"
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>

            {showCreateSlider && createPortal(
                <UserStorySlider 
                    showSlider={showCreateSlider}
                    sliderClosing={createSliderClosing}
                    closeSlider={closeCreateSlider}
                    currentStory={currentStory}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    availableEpics={epics}
                    availableStories={userStories.filter(story => story._id !== currentStory?._id)}
                    availableSprints={[]} // We'll need to add sprint data fetching in the future
                    projectId={projectId}
                />,
                document.getElementById('sliding-panel-root')
            )}

            {showDetailSlider && createPortal(
                <UserStoryDetailsSlider 
                    showSlider={showDetailSlider}
                    sliderClosing={detailSliderClosing}
                    closeSlider={closeDetailSlider}
                    currentStory={selectedStory}
                    handleUpdateStory={handleUpdateStory}
                    handleDeleteStory={handleDeleteStory}
                    availableEpics={epics}
                    availableStories={userStories.filter(story => story._id !== selectedStory?._id)}
                    availableSprints={[]} // We'll need to add sprint data fetching
                    projectId={projectId}
                />,
                document.getElementById('sliding-panel-root')
            )}
        </div>
    );
};

export default Backlog;
