import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAiSummary } from '../../redux/slices/aiSlice';
import { useAuth } from '../../Components/AuthContext';
import AiInsightSection from './AiInsightSection';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLightbulb, 
  faArrowRight, 
  faTasks, 
  faBell, 
  faShieldAlt, 
  faChartLine 
} from '@fortawesome/free-solid-svg-icons';
import { isFeatureAvailable } from '../../utils/featureUtils';
import { ACCOUNT_TYPES } from '../../config/featureAccess';

const DailySummaryCard = ({ raidLogStats, analyticsData, projects, overdueTasks, upcomingTasks, raidLogs, dailyJournalTasks }) => {
  const dispatch = useDispatch();
  const { insights, isLoading, error, lastUpdated } = useSelector(state => state.ai);
  const { user, accountType } = useAuth();
  
  const initialFetchDone = useRef(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // Check if this user has access to AI features
  const canAccessAiFeatures = isFeatureAvailable(
    'AI_INSIGHTS', 
    accountType || ACCOUNT_TYPES.FREE,
    user?.isAdmin
  );

  const prepareDailyJournalData = (tasks) => {
    if (!tasks || !Array.isArray(tasks) || tasks.length === 0) {
      return { 
        tasks: [], 
        stats: { 
          totalTasks: 0, 
          completedTasks: 0,
          todoTasks: 0,
          inProgressTasks: 0,
          totalPoints: 0,
          completedPoints: 0
        } 
      };
    }
    
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayStr = `${year}-${month}-${day}`;
    
    const todaysTasks = tasks.filter(task => {
      if (!task.date) return false;
      
      try {
        let taskDate;
        if (typeof task.date === 'string') {
          const match = task.date.match(/^(\d{4}-\d{2}-\d{2})/);
          taskDate = match ? match[1] : null;
        } else if (task.date instanceof Date) {
          taskDate = task.date.getFullYear() + '-' + 
                    String(task.date.getMonth() + 1).padStart(2, '0') + '-' + 
                    String(task.date.getDate()).padStart(2, '0');
        }
        return taskDate === todayStr;
      } catch (e) {
        console.error("Error parsing task date:", e, task.date);
        return false;
      }
    });

    return {
      tasks: todaysTasks.map(task => ({
        content: task.content,
        storyPoints: task.storyPoints || 0,
        projectId: task.projectId,
        projectName: projects.find(p => p._id === task.projectId)?.project_name || 'No Project',
        status: task.status || 'todo',
        criticality: task.criticality || 'normal',
        timeSpent: task.timeSpent || 0,
        date: task.date
      })),
      stats: {
        totalTasks: todaysTasks.length,
        completedTasks: todaysTasks.filter(t => t.status === 'complete').length,
        todoTasks: todaysTasks.filter(t => t.status === 'todo').length,
        inProgressTasks: todaysTasks.filter(t => t.status === 'inProgress').length,
        totalPoints: todaysTasks.reduce((sum, t) => sum + (parseInt(t.storyPoints) || 0), 0),
        completedPoints: todaysTasks
          .filter(t => t.status === 'complete')
          .reduce((sum, t) => sum + (parseInt(t.storyPoints) || 0), 0)
      }
    };
  };

  useEffect(() => {
    if (projects?.length > 0 && user?.user_id) {
      if (!initialFetchDone.current) {
        initialFetchDone.current = true;
        const userData = prepareUserData(false);
        dispatch(fetchAiSummary(userData));
      }
    }
  }, [projects, user]);

  const prepareUserData = (forceRefresh = false) => {
    const overdueRaidItems = Array.isArray(overdueTasks) ? overdueTasks : [];
    const upcomingRaidItems = Array.isArray(upcomingTasks) ? upcomingTasks : [];
    
    const raidDetails = {
      activeIssues: [],
      highPriorityItems: [],
      upcomingDecisions: []
    };

    if (raidLogs && Array.isArray(raidLogs) && raidLogs.length > 0) {
      raidDetails.activeIssues = raidLogs
        .filter(item => item.raidType === 'Issue' && item.status !== 'Complete')
        .map(item => ({
          action: item.action || '',
          priority: item.priority || 'Normal',
          impact: item.impact || 'Low',
          owner: item.owner || 'Unassigned',
          projectName: projects.find(p => p._id === item.projectId)?.project_name || 'Unknown Project'
        }));

      raidDetails.highPriorityItems = raidLogs
        .filter(item => item.priority === 'High' && item.status !== 'Complete')
        .map(item => ({
          action: item.action || '',
          type: item.raidType || 'Task',
          due: item.due || null,
          owner: item.owner || 'Unassigned',
          projectName: projects.find(p => p._id === item.projectId)?.project_name || 'Unknown Project'
        }));
        
      raidDetails.upcomingDecisions = raidLogs
        .filter(item => item.raidType === 'Decision' && item.status !== 'Complete' && item.due)
        .map(item => ({
          action: item.action || '',
          due: item.due || null,
          owner: item.owner || 'Unassigned',
          projectName: projects.find(p => p._id === item.projectId)?.project_name || 'Unknown Project'
        }));
    }
    
    const safeProjects = projects && Array.isArray(projects) ? projects : [];
    
    return {
      userId: user.user_id,
      projects: safeProjects.map(project => ({
        _id: project._id,
        project_name: project.project_name,
        status: project.status,
        priority: project.priority || 'Normal'
      })),
      raidDetails,
      dailyJournal: prepareDailyJournalData(dailyJournalTasks),
      stats: {
        overdueTasks: overdueRaidItems.length,
        upcomingTasks: upcomingRaidItems.length,
        raidStats: raidLogStats || { complete: 0, inProgress: 0, blocked: 0 }
      },
      overdueTasks: overdueRaidItems.map(task => ({
        id: task._id,
        action: task.action,
        due: task.due,
        projectId: task.projectId,
        projectName: projects.find(p => p._id === task.projectId)?.project_name || 'Unknown'
      })),
      upcomingTasks: upcomingRaidItems.map(task => ({
        id: task._id,
        action: task.action,
        due: task.due,
        projectId: task.projectId,
        projectName: projects.find(p => p._id === task.projectId)?.project_name || 'Unknown'
      })),
      forceRefresh
    };
  };

  const handleRefreshInsights = () => {
    // Exit early if user doesn't have access
    if (!canAccessAiFeatures) {
      return;
    }
    
    if (!projects?.length || !user?.user_id) {
      setErrorMessage('Unable to generate summary - no projects or user data found');
      return;
    }
    
    // Check for CSRF token in localStorage
    const csrfToken = localStorage.getItem('csrfToken');
    if (!csrfToken) {
      console.log('No CSRF token found, attempting to fetch a new one');
      fetch(`${process.env.REACT_APP_API_URL}/api/auth/csrf-token`)
        .then(response => response.json())
        .then(data => {
          if (data && data.state) {
            localStorage.setItem('csrfToken', data.state);
            console.log('New CSRF token fetched and stored');
          }
          const userData = prepareUserData(true);
          dispatch(fetchAiSummary(userData));
        })
        .catch(err => {
          console.error('Error fetching CSRF token:', err);
          const userData = prepareUserData(true);
          dispatch(fetchAiSummary(userData));
        });
    } else {
      const userData = prepareUserData(true);
      dispatch(fetchAiSummary(userData));
    }
  };

  // Render upgrade component for non-professional users with improved UI
  const renderUpgradeMessage = () => (
    <div className="ai-insight-section">
      <div className="ai-header">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faLightbulb} className="insight-highlight-icon" />
          <h4>AI-Powered Insights</h4>
        </div>
      </div>
      
      <div className="upgrade-container">
        <div className="upgrade-content">
          <FontAwesomeIcon 
            icon={faLightbulb} 
            style={{ fontSize: '2.5rem', color: '#0056b3' }} 
            className="upgrade-icon mb-3"
          />
          <h5 className="fw-bold mb-3">Unlock AI-Powered Insights</h5>
          <p className="text-muted mb-4">
            Get personalized task recommendations, smart follow-ups, and early risk detection with our 
            Professional plan.
          </p>
          
          <Link to="/account" className="btn btn-outline-primary upgrade-btn fw-bold">
            Upgrade to Professional <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className="dashboard-card daily-summary-card">
      <div className="dashboard-card-body">
        {errorMessage && <div className="alert alert-warning py-1 m-2" style={{ fontSize: '0.75rem' }}>{errorMessage}</div>}
        
        {canAccessAiFeatures ? (
          <AiInsightSection 
            insights={insights}
            isLoading={isLoading}
            error={error}
            onRefresh={handleRefreshInsights}
            lastUpdated={lastUpdated}
          />
        ) : (
          renderUpgradeMessage()
        )}
      </div>
    </div>
  );
};

export default DailySummaryCard;
