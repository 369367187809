import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsers, faClipboardList, faColumns, faCalendarAlt, faComments, faCog, faListOl, faStickyNote, faBolt } from '@fortawesome/free-solid-svg-icons';
import "./ComponentStyling/ProjectNavbar.css";

const ProjectNavbar = () => {
    const { projectId } = useParams();

    return (
        <nav className="col-12 bg-light sidebar-horizontal" style={{ position: 'relative', zIndex: 2 }}>
            <div className="position-sticky pt-3">
                <ul className="nav flex-row">
                    <li className="nav-item">
                        <Link className="nav-link active" to={`/projects/${projectId}`} title="Project Dashboard">
                            <FontAwesomeIcon icon={faTachometerAlt} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/stakeholders`} title="Stakeholders">
                            <FontAwesomeIcon icon={faUsers} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/raidlog`} title="RAID Log">
                            <FontAwesomeIcon icon={faClipboardList} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/kanban`} title="Kanban Board">
                            <FontAwesomeIcon icon={faColumns} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/scheduler`} title="Project Scheduler">
                            <FontAwesomeIcon icon={faCalendarAlt} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/meetings`} title="Meetings">
                            <FontAwesomeIcon icon={faComments} /> 
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/wbs`} title="Work Breakdown Structure">
                            <FontAwesomeIcon icon={faListOl} />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/notes`} title="Project Notes">
                            <FontAwesomeIcon icon={faStickyNote} />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/projects/${projectId}/agilehub`} title="Agile Hub">
                            <FontAwesomeIcon icon={faBolt} />
                        </Link>
                    </li>
                    <li className="nav-item settings">
                        <Link className="nav-link" to={`/projects/${projectId}/settings`} title="Project Settings">
                            <FontAwesomeIcon icon={faCog} /> 
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default ProjectNavbar;
