import axios from 'axios';

/**
 * Creates an axios instance with base URL and credentials configuration
 */
export const createAxiosInstance = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });
};

/**
 * Returns a greeting based on the current time of day
 */
export const getGreeting = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return 'Good morning';
  } else if (currentHour < 18) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
};

/**
 * Adjusts a date string for display in YYYY-MM-DD format
 */
export const adjustDateForDisplay = (dateString) => {
  return new Date(dateString).toISOString().split('T')[0];
};

/**
 * Formats a date string for display with month name
 */
export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

/**
 * Gets the local date string in YYYY-MM-DD format
 */
export const getLocalDateString = () => {
  const localDate = new Date();
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
  return localDate.toISOString().split('T')[0];
};

/**
 * Calculates RAID log statistics from RAID log items
 */
export const calculateRaidLogStats = (raidLogs) => {
  const stats = {
    complete: 0,
    inProgress: 0,
    notStarted: 0,
    blocked: 0
  };

  raidLogs.forEach(item => {
    if (!item.status) return;
    
    switch(item.status.toLowerCase()) {
      case 'complete':
        stats.complete++;
        break;
      case 'in progress':
        stats.inProgress++;
        break;
      case 'not started':
        stats.notStarted++;
        break;
      case 'blocked':
        stats.blocked++;
        break;
      default:
        break;
    }
  });

  return stats;
};

/**
 * Filters tasks based on due dates and other criteria
 */
export const filterTasks = (tasks, today, days, projects, isOverdue = false) => {
  return tasks.filter(task => {
    if (!task.due || task.raidType !== 'Action') return false;
    const dueDate = new Date(task.due);
    dueDate.setHours(0, 0, 0, 0);
    dueDate.setDate(dueDate.getDate() + 1);

    if (isOverdue) {
      return dueDate < today && task.status !== 'Complete';
    } else {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + days);
      return dueDate >= today && dueDate <= futureDate && task.status !== 'Complete';
    }
  }).map(task => ({
    ...task,
    projectName: projects.find(project => project._id === task.projectId)?.project_name || 'Unknown Project'
  })).sort((a, b) => new Date(a.due) - new Date(b.due));
};

/**
 * Generic error handler for API calls
 */
export const handleApiError = (error, setError) => {
  if (error.response) {
    console.error('API Error: ', error);
    setError('Error fetching data: ' + (error.response.data?.message || 'Server error'));
  } else if (error.request) {
    console.error('Network error:', error.request);
    setError('Network error: Unable to connect to server');
  } else {
    console.error('Error:', error.message);
    setError('An unexpected error occurred: ' + error.message);
  }
};

/**
 * Calculate a project's attention score based on its RAID log items
 * @param {Array} raidLogs - Array of all RAID log items
 * @param {String} projectId - ID of the project to calculate for
 * @returns {Object} Object containing score and category
 */
export const calculateProjectAttentionScore = (raidLogs, projectId) => {
  const projectLogs = raidLogs.filter(log => log.projectId === projectId);
  
  if (projectLogs.length === 0) {
    return { score: 0, category: 'low', details: [] };
  }
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  // Initialize scoring buckets
  const scoringBuckets = {
    overdue: { items: [], weight: 0.4 },    // 40% of total score
    urgent: { items: [], weight: 0.3 },     // 30% of total score
    critical: { items: [], weight: 0.2 },   // 20% of total score
    upcoming: { items: [], weight: 0.1 }    // 10% of total score
  };
  
  // Sort items into buckets
  projectLogs.forEach(item => {
    if (item.status === 'Complete') return;
    
    const dueDate = item.due ? new Date(item.due) : null;
    const isHighPriority = item.impact === 'High' || item.priority === 'High';
    const isCritical = item.impact === 'Critical' || item.priority === 'Critical';
    
    if (dueDate) {
      // Overdue items
      if (dueDate < today) {
        scoringBuckets.overdue.items.push(item);
      }
      // Due in next 7 days
      else if ((dueDate - today) / (1000 * 60 * 60 * 24) <= 7) {
        scoringBuckets.urgent.items.push(item);
      }
      // Regular upcoming items
      else {
        scoringBuckets.upcoming.items.push(item);
      }
    }
    
    // Add to critical bucket if high priority/impact (can be in multiple buckets)
    if (isHighPriority || isCritical) {
      scoringBuckets.critical.items.push(item);
    }
  });
  
  // Calculate relative scores for each bucket
  const bucketScores = {};
  let totalScore = 0;
  const details = [];
  
  Object.entries(scoringBuckets).forEach(([bucket, { items, weight }]) => {
    let bucketScore = 0;
    
    if (items.length > 0) {
      // Calculate relative score based on proportion of items in this bucket
      const proportionOfTotal = items.length / projectLogs.length;
      bucketScore = proportionOfTotal * weight * 10; // Scale to 0-10
      
      details.push({
        type: bucket,
        count: items.length,
        score: bucketScore.toFixed(2)
      });
    }
    
    bucketScores[bucket] = bucketScore;
    totalScore += bucketScore;
  });
  
  // Normalize total score to 0-10 scale and determine category
  totalScore = Math.min(totalScore, 10);
  
  let category;
  if (totalScore < 3) {
    category = 'low';
  } else if (totalScore < 6) {
    category = 'medium';
  } else {
    category = 'high';
  }
  
  return {
    score: totalScore,
    category,
    details, // Include details for tooltip
    bucketScores // Include raw bucket scores for potential UI use
  };
};
