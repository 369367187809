import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Add this import
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'; // Add this import
import './ComponentStyling/SchedulerActivities.css';

const SchedulerActivities = ({ events, selectedActivity, onActivitySelect, searchTerm, onSearchChange, yearsRange, onEventUpdate, onAddEvent }) => {
    const [editingActivity, setEditingActivity] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [editingDate, setEditingDate] = useState(null); // { id: number, type: 'start' | 'end' }
    const [draggingId, setDraggingId] = useState(null);
    const [dropTarget, setDropTarget] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);

    const calculateDateFromPosition = (positionX) => {
        const dayWidth = 40;
        const daysFromStart = Math.floor(positionX / dayWidth);
        const date = new Date(yearsRange.start, 0, 1);
        date.setDate(date.getDate() + daysFromStart);
        return date;
    };

    const getEventDates = (event) => {
        const startDate = calculateDateFromPosition(event.left);
        const endDate = calculateDateFromPosition(event.left + event.width - 40); // Subtract 40 to correct end date
        const durationInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; // Add 1 to include both start and end dates
        return {
            start: startDate,
            end: endDate,
            duration: durationInDays
        };
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleTitleDoubleClick = (e, activity) => {
        e.stopPropagation(); // Prevent activity selection
        setEditingActivity(activity.id);
        setEditValue(activity.title);
    };

    const handleTitleEdit = (e) => {
        setEditValue(e.target.value);
    };

    const finishTitleEdit = () => {
        if (editingActivity) {
            const updatedEvent = events.find(ev => ev.id === editingActivity);
            if (updatedEvent) {
                onEventUpdate({
                    ...updatedEvent,
                    title: editValue.trim() || updatedEvent.title // Fallback to original title if empty
                });
            }
            setEditingActivity(null);
            setEditValue('');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            finishTitleEdit();
        } else if (e.key === 'Escape') {
            setEditingActivity(null);
            setEditValue('');
        }
    };

    const handleDateDoubleClick = (e, eventId, dateType) => {
        e.stopPropagation();
        setEditingDate({ id: eventId, type: dateType });
    };

    const handleDateChange = (e, eventId, dateType) => {
        e.stopPropagation();
        // Don't prevent default here to allow date picker to work
        
        if (!e.target.value) return;
        
        // Store the selected date but don't update the position yet
        const selectedDate = new Date(e.target.value + 'T12:00:00');
        const event = events.find(ev => ev.id === eventId);
        
        if (event) {
            // Save the selected date but don't update position yet
            setEditingDate(prev => ({
                ...prev,
                selectedDate,
                eventId,
                type: dateType
            }));
        }
    };

    const handleDateInputBlur = (eventId, dateType) => {
        const currentEdit = editingDate;
        if (!currentEdit || !currentEdit.selectedDate) {
            setEditingDate(null);
            return;
        }

        const event = events.find(ev => ev.id === eventId);
        if (event) {
            const baseDate = new Date(yearsRange.start, 0, 1, 12, 0, 0);
            const dayWidth = 40;
            
            const timeDiff = currentEdit.selectedDate.getTime() - baseDate.getTime();
            const daysDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24));
            const newPosition = daysDiff * dayWidth;
            
            let updatedEvent;
            if (dateType === 'start') {
                const endPosition = event.left + event.width;
                const newWidth = Math.max(40, endPosition - newPosition);
                updatedEvent = {
                    ...event,
                    left: newPosition,
                    width: newWidth
                };
            } else {
                const newWidth = (newPosition - event.left) + dayWidth;
                updatedEvent = {
                    ...event,
                    width: Math.max(dayWidth, newWidth)
                };
            }
            
            onEventUpdate(updatedEvent);
        }
        setEditingDate(null);
    };

    // Helper to format date for input field
    const formatDateForInput = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDragStart = (e, activityId) => {
        setDraggingId(activityId);
        e.target.style.opacity = '0.4';
    };

    const handleDragEnd = (e) => {
        setDraggingId(null);
        setDropTarget(null);
        e.target.style.opacity = '1';
    };

    const handleDragOver = (e, targetId) => {
        e.preventDefault();
        if (draggingId !== targetId) {
            setDropTarget(targetId);
        }
    };

    const handleDrop = (e, targetId) => {
        e.preventDefault();
        if (draggingId === targetId) return;

        const draggedIndex = events.findIndex(event => event.id === draggingId);
        const targetIndex = events.findIndex(event => event.id === targetId);
        
        if (draggedIndex !== -1 && targetIndex !== -1) {
            // Calculate new positions
            const TOP_MARGIN = 120;
            const ROW_HEIGHT = 40;
            const ROW_SPACING = 5;
            
            // Create new array with updated order
            const updatedEvents = [...events];
            const [draggedEvent] = updatedEvents.splice(draggedIndex, 1);
            updatedEvents.splice(targetIndex, 0, draggedEvent);
            
            // Update vertical positions for all events
            const reorderedEvents = updatedEvents.map((event, index) => ({
                ...event,
                top: TOP_MARGIN + (index * (ROW_HEIGHT + ROW_SPACING))
            }));
            
            // Update all events at once in the parent component
            onEventUpdate(reorderedEvents);
        }
        
        setDraggingId(null);
        setDropTarget(null);
    };

    const handleContextMenu = (e, activity) => {
        e.preventDefault();
        setContextMenu({
            x: e.clientX,
            y: e.clientY,
            eventId: activity.id
        });
    };

    const handleDeleteEvent = (eventId) => {
        onEventUpdate(events.filter(ev => ev.id !== eventId));
        setContextMenu(null);
    };

    const filteredAndSortedActivities = useMemo(() => {
        return events
            .filter(event => 
                event.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => {
                // Always sort by vertical position, then horizontal position
                return a.top === b.top ? 
                    a.left - b.left : 
                    a.top - b.top;
            });
    }, [events, searchTerm]);

    return (
        <div className="scheduler-activities">
            <div className="activities-header">
                <div className="d-flex justify-content-between align-items-center">
                    <input
                        type="text"
                        className="activities-search"
                        placeholder="Search activities..."
                        value={searchTerm}
                        onChange={(e) => onSearchChange(e.target.value)}
                    />
                    <button 
                        className="btn btn-link p-0 ms-2" 
                        onClick={onAddEvent}
                        style={{ 
                            color: '#0056b3',
                            border: 'none',
                            background: 'none',
                            fontSize: '1.2rem',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'transform 0.2s ease'
                        }}
                        title="Add new event"
                    >
                        <FontAwesomeIcon 
                            icon={faPlus} 
                            style={{ 
                                transition: 'transform 0.2s ease'
                            }}
                        />
                    </button>
                </div>
            </div>
            <div className="activities-table-header">
                <div className="col-title" style={{ fontWeight: 600 }}>Activity</div>
                <div className="col-date" style={{ fontWeight: 600 }}>Start</div>
                <div className="col-date" style={{ fontWeight: 600 }}>End</div>
                <div className="col-duration" style={{ fontWeight: 600 }}>Days</div>
            </div>
            <div className="virtual-scroll-container">
                <ul className="activities-list">
                    {filteredAndSortedActivities.map(activity => {
                        const dates = getEventDates(activity);
                        return (
                            <li
                                key={activity.id}
                                className={`activity-item ${selectedActivity === activity.id ? 'active' : ''} ${dropTarget === activity.id ? 'drop-target' : ''}`}
                                onClick={() => onActivitySelect(activity)}
                                onContextMenu={(e) => handleContextMenu(e, activity)}
                                draggable="true"
                                onDragStart={(e) => handleDragStart(e, activity.id)}
                                onDragEnd={handleDragEnd}
                                onDragOver={(e) => handleDragOver(e, activity.id)}
                                onDrop={(e) => handleDrop(e, activity.id)}
                            >
                                <div 
                                    className="col-title" 
                                    title={activity.title}
                                    onDoubleClick={(e) => handleTitleDoubleClick(e, activity)}
                                >
                                    {editingActivity === activity.id ? (
                                        <input
                                            type="text"
                                            value={editValue}
                                            onChange={handleTitleEdit}
                                            onBlur={finishTitleEdit}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                            className="edit-input"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    ) : (
                                        activity.title
                                    )}
                                </div>
                                <div 
                                    className="col-date"
                                    onDoubleClick={(e) => handleDateDoubleClick(e, activity.id, 'start')}
                                >
                                    {editingDate?.id === activity.id && editingDate?.type === 'start' ? (
                                        <input
                                            type="date"
                                            defaultValue={formatDateForInput(dates.start)}
                                            onChange={(e) => handleDateChange(e, activity.id, 'start')}
                                            onBlur={() => handleDateInputBlur(activity.id, 'start')}
                                            onClick={(e) => e.stopPropagation()} // Remove preventDefault
                                            onKeyDown={(e) => {
                                                e.stopPropagation();
                                                if (e.key === 'Enter') {
                                                    handleDateInputBlur(activity.id, 'start');
                                                }
                                                if (e.key === 'Escape') {
                                                    setEditingDate(null);
                                                }
                                            }}
                                            autoFocus
                                            className="date-input"
                                        />
                                    ) : (
                                        formatDate(dates.start)
                                    )}
                                </div>
                                <div 
                                    className="col-date"
                                    onDoubleClick={(e) => handleDateDoubleClick(e, activity.id, 'end')}
                                >
                                    {editingDate?.id === activity.id && editingDate?.type === 'end' ? (
                                        <input
                                            type="date"
                                            defaultValue={formatDateForInput(dates.end)}
                                            onChange={(e) => handleDateChange(e, activity.id, 'end')}
                                            onBlur={() => handleDateInputBlur(activity.id, 'end')}
                                            onClick={(e) => e.stopPropagation()} // Remove preventDefault
                                            onKeyDown={(e) => {
                                                e.stopPropagation();
                                                if (e.key === 'Enter') {
                                                    handleDateInputBlur(activity.id, 'end');
                                                }
                                                if (e.key === 'Escape') {
                                                    setEditingDate(null);
                                                }
                                            }}
                                            autoFocus
                                            className="date-input"
                                        />
                                    ) : (
                                        formatDate(dates.end)
                                    )}
                                </div>
                                <div className="col-duration">
                                    {dates.duration}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {contextMenu && (
                <div 
                    style={{
                        position: 'fixed',
                        top: contextMenu.y,
                        left: contextMenu.x,
                        background: 'white',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        zIndex: 9999,
                        padding: '8px 0',
                        minWidth: '180px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                    }}
                >
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        <li 
                            className="context-menu-item context-menu-item-delete"
                            onClick={() => handleDeleteEvent(contextMenu.eventId)}
                        >
                            <FontAwesomeIcon icon={faTrash} style={{ marginRight: '8px', color: '#dc3545' }} />
                            Delete Event
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SchedulerActivities;
