import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import WBSToolbar from './WBSToolbar';
import { differenceInDays } from 'date-fns';

let nextId = 1;
const MIN_ROWS = 20;

const WBSEditor = () => {
  const { projectId } = useParams();
  const [saveStatus, setSaveStatus] = useState(''); // For save feedback
  const [items, setItems] = useState(Array(MIN_ROWS).fill(null).map(() => ({
    id: nextId++,
    text: '',
    level: 0,
    owner: '',
    startDate: '',
    endDate: '',
  })));
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Generate hierarchical numbering based on levels
  const generateNumbers = () => {
    const counters = [];
    return items.map(item => {
      const lvl = item.level;
      counters[lvl] = (counters[lvl] || 0) + 1;
      // Reset deeper levels
      counters.length = lvl + 1;
      return counters.join('.');
    });
  };

  const numbers = generateNumbers();

  const updateItemText = (id, newText) => {
    const updatedItems = items.map(item => (item.id === id ? { ...item, text: newText } : item));
    const index = updatedItems.findIndex(item => item.id === id);
    
    // If we're close to the end (within 5 rows), add more empty rows
    if (index >= updatedItems.length - 5) {
      const newRows = Array(5).fill(null).map(() => ({
        id: nextId++,
        text: '',
        level: 0,
        owner: '',
        startDate: '',
        endDate: '',
      }));
      updatedItems.push(...newRows);
    }
    
    setItems(updatedItems);
  };

  const handleIndent = (index) => {
    if (index === null) return;
    setItems(items.map((item, i) => {
      if (i === index) {
        const newLevel = Math.min(item.level + 1, 5); // Maximum 5 levels
        return { ...item, level: newLevel };
      }
      return item;
    }));
  };

  const handleOutdent = (index) => {
    if (index === null) return;
    setItems(items.map((item, i) => {
      if (i === index) {
        const newLevel = Math.max(0, item.level - 1);
        return { ...item, level: newLevel };
      }
      return item;
    }));
  };

  const deleteRow = (index) => {
    if (index === null) return;
    
    const newItems = items.filter((_, i) => i !== index);
    
    // Add new empty rows if we're below MIN_ROWS
    while (newItems.length < MIN_ROWS) {
      newItems.push({
        id: nextId++,
        text: '',
        level: 0,
        owner: '',
        startDate: '',
        endDate: '',
      });
    }
    
    setItems(newItems);
    setSelectedRow(null);
  };

  const insertRow = (index) => {
    if (index === null) return;
    
    const newItems = [...items];
    const newRow = {
      id: nextId++,
      text: '',
      level: items[index].level, // Match the level of the current row
      owner: '',
      startDate: '',
      endDate: '',
    };
    
    newItems.splice(index, 0, newRow);
    setItems(newItems);
    setSelectedRow(index); // Keep the selection on the new row
  };

  // Add click handler for row selection
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  // Update keyboard handler to work globally
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      if (selectedRow === null) return;

      if (e.key === 'Tab') {
        e.preventDefault();
        if (e.shiftKey) {
          handleOutdent(selectedRow);
        } else {
          handleIndent(selectedRow);
        }
      } else if (e.key === 'Backspace' && e.shiftKey) {
        e.preventDefault();
        deleteRow(selectedRow);
      } else if (e.key === 'i' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        insertRow(selectedRow);
      }
    };

    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => document.removeEventListener('keydown', handleGlobalKeyDown);
  }, [selectedRow]); // Add selectedRow to dependencies

  const calculateDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return '';
    try {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (isNaN(start.getTime()) || isNaN(end.getTime())) return '';
      
      const days = differenceInDays(end, start) + 1; // Add 1 to include both start and end dates
      return days > 0 ? `${days} days` : 'Invalid dates';
    } catch (error) {
      return 'Invalid dates';
    }
  };

  const handleWBSChange = useCallback(() => {
    setHasUnsavedChanges(true);
  }, []);

  const calculateParentDates = (items, index) => {
    const currentLevel = items[index].level;
    const subtasks = [];
    
    // Look for subtasks (next items with higher level)
    for (let i = index + 1; i < items.length; i++) {
      if (items[i].level <= currentLevel) break;
      if (items[i].startDate || items[i].endDate) {
        subtasks.push(items[i]);
      }
    }

    if (subtasks.length === 0) return null;

    // Find earliest start date and latest end date
    const startDates = subtasks
      .map(task => task.startDate)
      .filter(date => date)
      .map(date => new Date(date));

    const endDates = subtasks
      .map(task => task.endDate)
      .filter(date => date)
      .map(date => new Date(date));

    if (startDates.length === 0 || endDates.length === 0) return null;

    return {
      startDate: new Date(Math.min(...startDates)).toISOString().split('T')[0],
      endDate: new Date(Math.max(...endDates)).toISOString().split('T')[0]
    };
  };

  const updateParentDates = (items, startIndex) => {
    const currentLevel = items[startIndex].level;
    let parentIndex = -1;

    // Find immediate parent
    for (let i = startIndex - 1; i >= 0; i--) {
      if (items[i].level < currentLevel) {
        parentIndex = i;
        break;
      }
    }

    if (parentIndex === -1) return items; // No parent found

    // Calculate dates for the parent based on all its children
    const parentDates = calculateParentDates(items, parentIndex);
    if (parentDates) {
      items[parentIndex] = {
        ...items[parentIndex],
        startDate: parentDates.startDate,
        endDate: parentDates.endDate
      };
      
      // Recursively update higher level parents
      if (parentIndex > 0 && items[parentIndex].level > 0) {
        return updateParentDates(items, parentIndex);
      }
    }

    return items;
  };

  const updateField = (id, field, value) => {
    let updatedItems = items.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    );

    // Only update parent dates if we're changing a date field
    if (field === 'startDate' || field === 'endDate') {
      const updatedIndex = updatedItems.findIndex(item => item.id === id);
      if (updatedIndex > 0) { // If not the first item
        updatedItems = updateParentDates(updatedItems, updatedIndex);
      }
    }

    setItems(updatedItems);
    handleWBSChange();
  };

  const saveWBS = async () => {
    try {
      setSaveStatus('Saving...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/wbs`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      });

      if (response.ok) {
        setSaveStatus('Saved!');
        setHasUnsavedChanges(false);  // Reset unsaved changes flag
        setTimeout(() => setSaveStatus(''), 2000);
      } else {
        setSaveStatus('Error saving');
        setTimeout(() => setSaveStatus(''), 2000);
      }
    } catch (error) {
      console.error('Error saving WBS:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 2000);
    }
  };

  const fetchWBSData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/wbs`);
      if (response.ok) {
        const { wbsData } = await response.json();
        if (wbsData && wbsData.items && wbsData.items.length > 0) {
          // Ensure we have at least MIN_ROWS
          const savedItems = wbsData.items;
          if (savedItems.length < MIN_ROWS) {
            const additionalRows = Array(MIN_ROWS - savedItems.length).fill(null).map(() => ({
              id: nextId++,
              text: '',
              level: 0,
              owner: '',
              startDate: '',
              endDate: '',
            }));
            setItems([...savedItems, ...additionalRows]);
          } else {
            setItems(savedItems);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching WBS data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchWBSData();
    }
  }, [projectId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 's') {
        e.preventDefault();
        saveWBS();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [items]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  return (
    <div className="wbs-container">
      {isLoading ? (
        <div className="text-center p-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="wbs-header d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <WBSToolbar 
                onIndent={handleIndent}
                onOutdent={handleOutdent}
                selectedRow={selectedRow}
                onDelete={deleteRow}
                onInsert={insertRow}
                onSave={saveWBS}
                saveStatus={saveStatus}
              />
              {hasUnsavedChanges && (
                <div 
                  className="ms-3 d-flex align-items-center"
                  style={{ 
                    fontSize: '0.9rem',
                    opacity: 0.8,
                    animation: 'fadeInOut 2s infinite',
                    color: '#6c757d'
                  }}
                >
                  Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save changes
                </div>
              )}
            </div>
          </div>
          <div className="wbs-editor">
            <div className="wbs-row header">
              <span className="row-number">#</span>
              <div className="wbs-row-content">
                <div className="wbs-indented-section">
                  <span className="wbs-number">ID</span>
                  <span className="header-cell">Task Description</span>
                </div>
                <div className="wbs-fixed-section">
                  <span className="header-cell">Owner</span>
                  <span className="header-cell">Start Date</span>
                  <span className="header-cell">End Date</span>
                  <span className="header-cell">Duration</span>
                </div>
              </div>
            </div>
            {items.map((item, index) => (
              <div 
                key={item.id} 
                className={`wbs-row ${selectedRow === index ? 'selected' : ''}`}
                onClick={() => handleRowClick(index)} // Add click handler
                role="button"
                tabIndex={0}
              >
                <span className="row-number">{index + 1}</span>
                <div className="wbs-row-content">
                  <div 
                    className="wbs-indented-section"
                    style={{ marginLeft: `${item.level * 20}px` }}
                  >
                    <span className="wbs-number">{numbers[index]}</span>
                    <input  // Change back to input from textarea
                      type="text"
                      className="task-input"
                      value={item.text}
                      onChange={(e) => updateField(item.id, 'text', e.target.value)}
                      placeholder="Enter task..."
                    />
                  </div>
                  <div className="wbs-fixed-section">
                    <input
                      type="text"
                      className="owner-input"
                      value={item.owner}
                      onChange={(e) => updateField(item.id, 'owner', e.target.value)}
                      placeholder="Owner"
                    />
                    <input
                      type="date"
                      className="date-input"
                      value={item.startDate}
                      onChange={(e) => updateField(item.id, 'startDate', e.target.value)}
                      disabled={items.some((subitem, i) => i > index && 
                        subitem.level > item.level && 
                        (subitem.startDate || subitem.endDate))}
                    />
                    <input
                      type="date"
                      className="date-input"
                      value={item.endDate}
                      onChange={(e) => updateField(item.id, 'endDate', e.target.value)}
                      disabled={items.some((subitem, i) => i > index && 
                        subitem.level > item.level && 
                        (subitem.startDate || subitem.endDate))}
                    />
                    <span className="duration">
                      {calculateDuration(item.startDate, item.endDate)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WBSEditor;
