import React, { useRef, useEffect, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import axios from 'axios';
import './ComponentStyling/NoteNetwork.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const NoteNetwork = ({ notes, onNodeClick, projectId, setNotes }) => {
    const fgRef = useRef();
    const [hoverLink, setHoverLink] = useState(null);
    const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
    const [generationStatus, setGenerationStatus] = useState(null);
    const [processingComplete, setProcessingComplete] = useState(false);
    const [isGeneratingEmbeddings, setIsGeneratingEmbeddings] = useState(false);

    // Move graphData definition before the debug effect
    const graphData = React.useMemo(() => {
        console.log(`Building graph for ${notes.length} notes`);
        
        const data = {
            nodes: notes.map(note => ({
                id: note._id,
                title: note.title,
                val: 5,
                content: note.content,
                hasEmbedding: !!note.embedding,
                color: note.embedding ? '#3498db' : '#95a5a6'
            })),
            links: []
        };
        
        if (notes.length < 2) return data;
        
        let connectionCount = 0;
        let embeddingConnections = 0;
        
        // Process all note pairs to find connections
        notes.forEach((note1, i) => {
            notes.slice(i + 1).forEach(note2 => {
                const similarity = calculateSimilarity(note1, note2);
                const embeddingBased = note1.embedding && note2.embedding;
                
                // Much more lenient thresholds
                const threshold = embeddingBased ? 0.3 : 0.1; // Lower thresholds significantly
                
                if (similarity > threshold) {
                    const commonKeywords = findCommonKeywords(note1, note2);
                    const keywordBonus = commonKeywords.length * 0.05; // Add bonus for keyword matches
                    
                    // Boost similarity score with keyword matches
                    const adjustedSimilarity = Math.min(1, similarity + keywordBonus);
                    
                    data.links.push({
                        source: note1._id,
                        target: note2._id,
                        value: adjustedSimilarity,
                        strength: Math.floor(adjustedSimilarity * 100),
                        keywords: commonKeywords,
                        embeddingBased: embeddingBased,
                        description: embeddingBased 
                            ? `${Math.floor(adjustedSimilarity * 100)}% semantic similarity`
                            : commonKeywords.length > 0 
                              ? `Connected by keywords: ${commonKeywords.join(', ')}`
                              : `${Math.floor(adjustedSimilarity * 100)}% content similarity`
                    });
                    
                    connectionCount++;
                    if (embeddingBased) embeddingConnections++;
                }
            });
        });
        
        console.log(`Created ${connectionCount} connections (${embeddingConnections} from embeddings)`);
        return data;
    }, [notes]);

    // Debug effect now comes after graphData definition
    useEffect(() => {
        console.log("Processing state:", {
            processingComplete,
            isGeneratingEmbeddings,
            notesCount: notes.length,
            graphNodesCount: graphData.nodes.length,
            graphLinksCount: graphData.links.length
        });
    }, [processingComplete, isGeneratingEmbeddings, notes, graphData]);

    // Separate useEffect for embedding generation and processing state
    useEffect(() => {
        let isMounted = true;

        const generateEmbeddings = async () => {
            if (isGeneratingEmbeddings) return;
            
            try {
                setIsGeneratingEmbeddings(true);
                const missingEmbeddings = notes.filter(note => !note.embedding);
                
                if (missingEmbeddings.length > 0) {
                    setGenerationStatus('Initializing AI analysis...');
                    
                    // Generate embeddings
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes/generate-embeddings`,
                        { 
                            notes: missingEmbeddings,
                            embeddingModel: 'text-embedding-ada-002'
                        }
                    );
                    
                    // Fetch updated notes
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/notes`
                    );
                    
                    if (isMounted) {
                        setNotes(response.data);
                        setGenerationStatus('Analysis complete');
                        setProcessingComplete(true);  // Set this regardless of embedding success
                    }
                } else {
                    if (isMounted) {
                        setGenerationStatus('Loading network...');
                        setProcessingComplete(true);
                    }
                }
            } catch (error) {
                console.error('Embedding error:', error);
                if (isMounted) {
                    setGenerationStatus('Using basic analysis');
                    setProcessingComplete(true);  // Still show the network even if embeddings fail
                }
            } finally {
                if (isMounted) {
                    setIsGeneratingEmbeddings(false);
                    // Clear status message after a delay
                    setTimeout(() => {
                        if (isMounted) setGenerationStatus(null);
                    }, 2000);
                }
            }
        };

        // Start processing if we have notes and haven't started yet
        if (notes.length > 0 && !processingComplete && !isGeneratingEmbeddings) {
            generateEmbeddings();
        } else if (notes.length === 0) {
            // If there are no notes, still show the empty network
            setProcessingComplete(true);
        }

        return () => {
            isMounted = false;
        };
    }, [notes, projectId]); // Remove processingComplete and isGeneratingEmbeddings from dependencies

    // Function to calculate similarity between notes with embeddings
    function calculateSimilarity(note1, note2) {
        // If both notes have embeddings, use cosine similarity
        if (note1.embedding && note2.embedding) {
            const embedding1 = note1.embedding;
            const embedding2 = note2.embedding;
            
            // Calculate cosine similarity between embedding vectors
            return cosineSimilarity(embedding1, embedding2);
        } 
        
        // Fall back to text-based similarity if embeddings are not available
        return calculateTextSimilarity(note1, note2);
    }
    
    // Update cosineSimilarity function to handle embedding arrays properly
    function cosineSimilarity(vecA, vecB) {
        if (!Array.isArray(vecA) || !Array.isArray(vecB)) {
            console.warn('Invalid embedding vectors');
            return 0;
        }

        try {
            let dotProduct = 0;
            let magA = 0;
            let magB = 0;

            for (let i = 0; i < vecA.length; i++) {
                dotProduct += (vecA[i] || 0) * (vecB[i] || 0);
                magA += (vecA[i] || 0) * (vecA[i] || 0);
                magB += (vecB[i] || 0) * (vecB[i] || 0);
            }

            magA = Math.sqrt(magA);
            magB = Math.sqrt(magB);

            if (magA === 0 || magB === 0) return 0;
            
            const similarity = dotProduct / (magA * magB);
            return Math.max(0, Math.min(1, similarity)); // Ensure result is between 0 and 1
        } catch (error) {
            console.error('Error calculating similarity:', error);
            return 0;
        }
    }

    // Function to extract clean text from HTML content
    function extractTextFromHTML(htmlContent) {
        // Create a temporary div to parse HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent || '';
        
        // Get the text content and normalize spaces
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        return textContent.replace(/\s+/g, ' ').trim().toLowerCase();
    }

    // Update calculateTextSimilarity to be more generous
    function calculateTextSimilarity(note1, note2) {
        const text1 = extractTextFromHTML(note1.content).toLowerCase();
        const text2 = extractTextFromHTML(note2.content).toLowerCase();
        
        // Split into words and remove very short words
        const words1 = text1.split(/\W+/).filter(word => word.length > 2);
        const words2 = text2.split(/\W+/).filter(word => word.length > 2);
        
        if (words1.length === 0 || words2.length === 0) return 0;
        
        const set1 = new Set(words1);
        const set2 = new Set(words2);
        
        const intersection = new Set([...set1].filter(word => set2.has(word)));
        
        // Calculate both Jaccard similarity and relative intersection size
        const jaccardSimilarity = intersection.size / (set1.size + set2.size - intersection.size);
        const relativeSimilarity = (2 * intersection.size) / (set1.size + set2.size);
        
        // Use the higher of the two scores
        return Math.max(jaccardSimilarity, relativeSimilarity);
    }

    // Find common keywords with improved text extraction
    function findCommonKeywords(note1, note2) {
        // Common stop words to filter out
        const stopWords = new Set([
            'this', 'that', 'with', 'from', 'have', 'which', 'what', 'when', 'where', 'who', 
            'will', 'would', 'could', 'should', 'there', 'their', 'they', 'them', 'these', 
            'those', 'then', 'than', 'some', 'such', 'very', 'just', 'about', 'into'
        ]);
        
        // Extract clean text from HTML
        const text1 = extractTextFromHTML(note1.content);
        const text2 = extractTextFromHTML(note2.content);
        
        // Tokenize words, filter out stop words and short words
        const words1 = text1.split(/\W+/).filter(word => word.length > 2 && !stopWords.has(word));
        const words2 = text2.split(/\W+/).filter(word => word.length > 2 && !stopWords.has(word));
        
        // Count word frequencies in first note
        const wordFreq1 = {};
        words1.forEach(word => {
            wordFreq1[word] = (wordFreq1[word] || 0) + 1;
        });
        
        // Find common words with their frequencies in second note
        const commonWords = {};
        words2.forEach(word => {
            if (wordFreq1[word] && wordFreq1[word] > 0) {
                commonWords[word] = (commonWords[word] || 0) + 1;
            }
        });
        
        // Get top keywords by frequency, exclude very common words
        const sortedCommonWords = Object.entries(commonWords)
            .sort((a, b) => b[1] - a[1])
            .filter(([word]) => !stopWords.has(word))
            .slice(0, 5)
            .map(entry => entry[0]);
            
        return sortedCommonWords;
    }

    // Zoom to fit when graph renders
    useEffect(() => {
        if (fgRef.current && graphData.nodes.length > 0) {
            setTimeout(() => {
                fgRef.current.zoomToFit(400);
            }, 500);
        }
    }, [graphData]);

    // Handle mouse move for tooltip positioning
    const handleMouseMove = (event) => {
        setTooltipPos({
            x: event.clientX + 15, // Offset from cursor
            y: event.clientY + 15
        });
    };

    // Add event listener for mouse movement
    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    // Handle link hover
    const handleLinkHover = (link) => {
        setHoverLink(link);
    };

    return (
        <div className="note-network">
            {/* Connection tooltip with dynamic positioning */}
            <div className="network-tooltip" style={{
                display: hoverLink ? 'block' : 'none',
                left: tooltipPos.x,
                top: tooltipPos.y,
                transform: 'translate(0, -100%)', // Position above cursor
                position: 'fixed' // Use fixed positioning for proper placement
            }}>
                {hoverLink && (
                    <div>
                        <strong>Connection:</strong> 
                        <div>{hoverLink.description}</div>
                        <div>Strength: {hoverLink.strength}%</div>
                        {hoverLink.embeddingBased && (
                            <div className="embedding-badge">AI-powered connection</div>
                        )}
                    </div>
                )}
            </div>
            
            <div className="note-network-header">
                <div className="note-network-info">
                    {graphData.nodes.length > 0 && (
                        <div className="network-stats">
                            <span>{graphData.nodes.length} notes</span>
                            <span>{graphData.links.length} connections</span>
                        </div>
                    )}
                </div>
                <button className="note-network-toggle" onClick={() => onNodeClick(null)}>
                    Close Network
                </button>
            </div>
            
            {generationStatus && (
                <div className="generation-status">
                    {generationStatus}
                </div>
            )}
            
            {!processingComplete ? (
                <div className="network-loading">
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    <p>Preparing neural network...</p>
                </div>
            ) : (
                <ForceGraph2D
                    ref={fgRef}
                    graphData={graphData}
                    nodeLabel={node => `${node.title}${node.hasEmbedding ? ' (AI-enhanced)' : ''}`}
                    nodeColor={node => node.color}
                    nodeRelSize={6}
                    linkColor={link => link.embeddingBased ? '#6c5ce7' : '#95a5a6'}
                    linkWidth={link => link.embeddingBased ? 2 : 1}
                    onNodeClick={node => onNodeClick(node.id)}
                    onLinkHover={handleLinkHover}
                    linkDirectionalArrowLength={3}
                    linkLabel={link => link.description}
                    linkCurvature={0.1}
                    enableNodeDrag={false} // Prevent node dragging to avoid re-renders
                    cooldownTime={500} // Reduced cooldown time
                    cooldownTicks={100} // Limited ticks
                    d3Force={(d3, nodes) => {
                        // Add collision force to prevent node overlap
                        d3.forceCollide(node => 30) // Adjust the radius (30) to control minimum distance between nodes
                          .strength(1); // Maximum strength to ensure separation
                        
                        // Increase repulsion between nodes
                        d3.forceManyBody()
                          .strength(-200); // More negative value = stronger repulsion
                        
                        // Adjust link distance
                        d3.forceLink()
                          .distance(100); // Increase this value to space out connected nodes more
                    }}
                />
            )}

            {/* Add a debug overlay for development only */}
            {process.env.NODE_ENV === 'development' && (
                <div style={{position: 'absolute', bottom: 0, left: 0, background: 'rgba(0,0,0,0.7)', color: 'white', padding: '5px', fontSize: '10px'}}>
                    Debug: Processing={processingComplete.toString()} | 
                    Generating={isGeneratingEmbeddings.toString()} | 
                    Notes={notes.length} | 
                    Links={graphData.links.length}
                    <button 
                        onClick={() => setProcessingComplete(true)} 
                        style={{marginLeft: '10px', fontSize: '10px'}}
                    >
                        Force Complete
                    </button>
                </div>
            )}
        </div>
    );
};

export default NoteNetwork;