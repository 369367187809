import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes, faTrash, faSpinner, faPlus 
} from '@fortawesome/free-solid-svg-icons';
import { formatDate, getFeatureTypeInfo } from '../utils/epicUtils';
import { FEATURE_TYPES, FEATURE_STATUSES } from '../utils/constants';
import { useSelector } from 'react-redux';

const FeatureDetailsSlider = ({ 
    showSlider,
    sliderClosing,
    closeSlider,
    currentFeature,
    handleDeleteFeature,
    updateFeature,
    epics = []
}) => {
    const [localFeature, setLocalFeature] = useState({ ...currentFeature });
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);

    const { userStories } = useSelector(state => state.agileHub);

    useEffect(() => {
        setLocalFeature({ ...currentFeature });
    }, [currentFeature]);

    const handleFieldChange = useCallback((field, value) => {
        setLocalFeature(prev => ({ ...prev, [field]: value }));
        setIsSaving(true);

        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        const timeoutId = setTimeout(async () => {
            try {
                await updateFeature({
                    featureId: currentFeature._id,
                    featureData: { ...localFeature, [field]: value }
                });
            } finally {
                setIsSaving(false);
            }
        }, 1000);

        setSaveTimeout(timeoutId);
    }, [currentFeature._id, localFeature, saveTimeout, updateFeature]);

    // Filter epics related to this feature
    const featureEpics = epics.filter(epic => epic.featureId === currentFeature._id);

    // Calculate completion percentage based on epic progress
    const completedEpics = featureEpics.filter(epic => epic.status === 'Complete').length;
    const inProgressEpics = featureEpics.filter(epic => epic.status === 'In Progress').length;
    const completionPercentage = featureEpics.length > 0 
        ? Math.round(((completedEpics * 100) + (inProgressEpics * 50)) / featureEpics.length)
        : 0;

    // Get type info
    const typeInfo = getFeatureTypeInfo(localFeature.type);

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">{localFeature.name}</h2>
                    <div className="sliding-panel-subtitle">
                        Feature #{localFeature.featureNumber || localFeature._id?.substring(0, 8)}
                        <div className="feature-dates">
                            <span>Created: {formatDate(localFeature.createdAt)}</span> • 
                            <span> Last Updated: {formatDate(localFeature.updatedAt)}</span>
                        </div>
                    </div>
                    {isSaving && (
                        <div className="saving-indicator">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <span className="ms-2">Saving...</span>
                        </div>
                    )}
                </div>
                
                <div className="sliding-panel-content">
                    {/* Basic Information Section */}
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="mb-3">
                            <label className="form-label">Feature Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localFeature.name || ''}
                                onChange={(e) => handleFieldChange('name', e.target.value)}
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                value={localFeature.description || ''}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                                rows="3"
                            />
                        </div>

                        <div className="feature-detail-grid">
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Type</div>
                                <div className="feature-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localFeature.type || 'functionality'}
                                        onChange={(e) => handleFieldChange('type', e.target.value)}
                                    >
                                        {Object.entries(FEATURE_TYPES).map(([value, label]) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Status</div>
                                <div className="feature-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localFeature.status || 'Planning'}
                                        onChange={(e) => handleFieldChange('status', e.target.value)}
                                    >
                                        {FEATURE_STATUSES.map(status => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Priority</div>
                                <div className="feature-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localFeature.priority || 'Medium'}
                                        onChange={(e) => handleFieldChange('priority', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Risk Level</div>
                                <div className="feature-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localFeature.riskLevel || 'Low'}
                                        onChange={(e) => handleFieldChange('riskLevel', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Planning Section */}
                    <div className="form-section">
                        <h3 className="section-title">Planning</h3>
                        <div className="feature-detail-grid">
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Planned Start</div>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={localFeature.plannedStart ? localFeature.plannedStart.split('T')[0] : ''}
                                    onChange={(e) => handleFieldChange('plannedStart', e.target.value)}
                                />
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Target Completion</div>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={localFeature.targetCompletion ? localFeature.targetCompletion.split('T')[0] : ''}
                                    onChange={(e) => handleFieldChange('targetCompletion', e.target.value)}
                                />
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Created</div>
                                <div className="feature-detail-value">
                                    {formatDate(currentFeature.createdAt)}
                                </div>
                            </div>
                            <div className="feature-detail-item">
                                <div className="feature-detail-label">Last Updated</div>
                                <div className="feature-detail-value">
                                    {formatDate(currentFeature.updatedAt)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Associated Epics Section */}
                    <div className="form-section">
                        <h3 className="section-title">Associated Epics ({featureEpics.length})</h3>
                        
                        {featureEpics.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Priority</th>
                                            <th>Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {featureEpics.map(epic => {
                                            const epicStories = userStories.filter(story => story.epicId === epic._id);
                                            const completedStories = epicStories.filter(s => s.status === 'Done').length;
                                            const epicProgress = epicStories.length > 0 
                                                ? Math.round((completedStories / epicStories.length) * 100) 
                                                : epic.status === 'Complete' ? 100 : 0;
                                                
                                            return (
                                                <tr key={epic._id} className="epic-row">
                                                    <td>#{epic.epicNumber}</td>
                                                    <td>{epic.title}</td>
                                                    <td>
                                                        <span className={`badge ${
                                                            epic.status === 'Complete' ? 'bg-success' :
                                                            epic.status === 'In Progress' ? 'bg-primary' :
                                                            epic.status === 'Blocked' ? 'bg-danger' :
                                                            'bg-secondary'
                                                        }`}>
                                                            {epic.status}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={`badge bg-${epic.priority.toLowerCase()}`}>
                                                            {epic.priority}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="progress" style={{ height: '8px' }}>
                                                            <div
                                                                className="progress-bar"
                                                                style={{ width: `${epicProgress}%` }}
                                                            ></div>
                                                        </div>
                                                        <span className="ms-2 small">{epicProgress}%</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-muted text-center py-3">
                                No epics associated with this feature yet. Associate epics by selecting this feature when creating or editing epics.
                            </p>
                        )}

                        <div className="feature-progress-summary mt-3">
                            <h4 className="mb-3">Feature Progress</h4>
                            <div className="progress" style={{ height: '10px', marginBottom: '8px' }}>
                                <div
                                    className="progress-bar"
                                    style={{ width: `${completionPercentage}%` }}
                                ></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="text-muted small">0%</span>
                                <span className="fw-bold">{completionPercentage}% Complete</span>
                                <span className="text-muted small">100%</span>
                            </div>
                        </div>
                    </div>

                    {/* Requirements Section */}
                    <div className="form-section">
                        <h3 className="section-title d-flex justify-content-between align-items-center">
                            Requirements
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    const newRequirements = [
                                        ...(localFeature.requirements || []),
                                        { text: '', completed: false }
                                    ];
                                    handleFieldChange('requirements', newRequirements);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="me-1" />
                                Add Requirement
                            </button>
                        </h3>
                        
                        <div className="requirements-list">
                            {localFeature.requirements?.length > 0 ? (
                                localFeature.requirements.map((req, index) => (
                                    <div 
                                        key={index} 
                                        className={`success-criterion-item ${req.completed ? 'completed' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={req.completed}
                                            onChange={(e) => {
                                                const newRequirements = [...localFeature.requirements];
                                                newRequirements[index] = {
                                                    ...req,
                                                    completed: e.target.checked
                                                };
                                                handleFieldChange('requirements', newRequirements);
                                            }}
                                            id={`requirement-${index}`}
                                        />
                                        
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={req.text}
                                            onChange={(e) => {
                                                const newRequirements = [...localFeature.requirements];
                                                newRequirements[index] = {
                                                    ...req,
                                                    text: e.target.value
                                                };
                                                handleFieldChange('requirements', newRequirements);
                                            }}
                                            placeholder="Enter requirement..."
                                        />
                                        <button
                                            className="btn btn-outline-danger btn-remove"
                                            onClick={() => {
                                                const newRequirements = localFeature.requirements.filter((_, i) => i !== index);
                                                handleFieldChange('requirements', newRequirements);
                                            }}
                                            title="Remove requirement"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted text-center py-3">
                                    No requirements defined. Add requirements to define feature scope.
                                </p>
                            )}
                        </div>
                        <div className="requirements-progress mt-3">
                            {localFeature.requirements?.length > 0 && (
                                <>
                                    <div className="progress" style={{ height: '8px' }}>
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${(localFeature.requirements.filter(r => r.completed).length / 
                                                        localFeature.requirements.length) * 100}%`
                                            }}
                                        ></div>
                                    </div>
                                    <div className="text-muted text-end mt-2 small">
                                        {localFeature.requirements.filter(r => r.completed).length} of {localFeature.requirements.length} completed
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Action buttons */}
                    <div className="feature-actions d-flex justify-content-end">
                        <button 
                            className="btn btn-danger"
                            onClick={() => {
                                if(window.confirm('Are you sure you want to delete this feature?')) {
                                    handleDeleteFeature(currentFeature._id);
                                    closeSlider();
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Feature
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureDetailsSlider;
