import { ACCOUNT_TYPES, FEATURES, LIMITS } from '../config/featureAccess';

/**
 * Check if a feature is available for a specific account type
 * 
 * @param {string} featureKey - The feature key from FEATURES
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {boolean} - Whether the feature is available
 */
export const isFeatureAvailable = (featureKey, accountType = ACCOUNT_TYPES.FREE, isAdmin = false) => {
  // Admins always have access to all features
  if (isAdmin) {
    return true;
  }
  
  const feature = FEATURES[featureKey];
  if (!feature) {
    console.warn(`Feature ${featureKey} is not defined in the feature configuration`);
    return false;
  }
  
  return feature.availableTo.includes(accountType);
};

/**
 * Check if a user has reached a specific resource limit
 * 
 * @param {string} limitKey - The limit key from LIMITS
 * @param {string} accountType - The user's account type
 * @param {number} currentValue - The current value to check against the limit
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {boolean} - Whether the limit has been reached
 */
export const isLimitReached = (limitKey, accountType = ACCOUNT_TYPES.FREE, currentValue, isAdmin = false) => {
  // Admins have no limits
  if (isAdmin) {
    return false;
  }
  
  const limits = LIMITS[accountType];
  if (!limits || typeof limits[limitKey] === 'undefined') {
    console.warn(`Limit ${limitKey} is not defined for account type ${accountType}`);
    return true; // Be conservative - if limit is not defined, consider it reached
  }
  
  return currentValue >= limits[limitKey];
};

/**
 * Get the limit value for a specific resource and account type
 * 
 * @param {string} limitKey - The limit key from LIMITS
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {number|null} - The limit value or null if not defined
 */
export const getLimitValue = (limitKey, accountType = ACCOUNT_TYPES.FREE, isAdmin = false) => {
  // Admins have no limits
  if (isAdmin) {
    return Infinity;
  }
  
  const limits = LIMITS[accountType];
  if (!limits || typeof limits[limitKey] === 'undefined') {
    console.warn(`Limit ${limitKey} is not defined for account type ${accountType}`);
    return null;
  }
  
  return limits[limitKey];
};

/**
 * Get all features available to a specific account type
 * 
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {Array} - An array of available feature keys
 */
export const getAvailableFeatures = (accountType = ACCOUNT_TYPES.FREE, isAdmin = false) => {
  // Admins have access to all features
  if (isAdmin) {
    return Object.keys(FEATURES);
  }
  
  return Object.keys(FEATURES).filter(key => 
    FEATURES[key].availableTo.includes(accountType)
  );
};

/**
 * Get feature-specific limits
 * 
 * @param {string} featureKey - The feature key from FEATURES
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {Object|null} - The feature-specific limits or null
 */
export const getFeatureLimits = (featureKey, accountType = ACCOUNT_TYPES.FREE, isAdmin = false) => {
  // Admins have no limits
  if (isAdmin) {
    return null;
  }
  
  const feature = FEATURES[featureKey];
  if (!feature || !feature.limits || !feature.limits[accountType]) {
    return null;
  }
  
  return feature.limits[accountType];
};
