import React, { useState } from 'react';
import NavbarAuth from '../Navbar_Authenticated';
import ProjectNavbar from '../ProjectNavbar';
import Footer from "../Footer";
import { Overview } from './Tabs/Overview';
import { CurrentSprint } from './Tabs/CurrentSprint';
import { Epics } from './Tabs/Epics';
import { Backlog } from './Tabs/Backlog';
import { Features } from './Tabs/Features';
import Bugs from './Tabs/Bugs';
import './AgileHub.css';

const AgileHub = () => {
    const [activeTab, setActiveTab] = useState('overview');

    const tabs = [
        { id: 'overview', label: 'Overview', component: Overview },
        { id: 'currentSprint', label: 'Current Sprint', component: CurrentSprint },
        { id: 'epics', label: 'Epics', component: Epics },
        { id: 'features', label: 'Features', component: Features },
        { id: 'bugs', label: 'Bugs', component: Bugs },
        { id: 'backlog', label: 'Backlog', component: Backlog }
    ];

    const renderTab = () => {
        const activeTabObj = tabs.find(tab => tab.id === activeTab);
        return activeTabObj ? <activeTabObj.component /> : <Overview />;
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="row">
                <main className="main-content col-12 px-md-4" style={{ zIndex: 1 }}>
                    <div className="agilehub-container">
                        <div className="agilehub-header">
                            <h2>AgileHub</h2>
                        </div>
                        <div className="agilehub-tabs">
                            <ul className="nav nav-tabs">
                                {tabs.map(tab => (
                                    <li className="nav-item" key={tab.id}>
                                        <button 
                                            className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            {tab.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="agilehub-content">
                            {renderTab()}
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default AgileHub;
