import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes, faTrash, faSpinner, faEdit, 
    faBan, faCheck, faComment, faPaperPlane, faPlus 
} from '@fortawesome/free-solid-svg-icons';
import { getStatusIcon, formatDate, formatCommentDate } from '../utils/epicUtils';
import { DEPENDENCY_TYPES } from '../utils/constants';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import UserStoryDetailsSlider from './UserStoryDetailsSlider';

const DependencyRow = ({ dependency, availableEpics, onUpdate, onRemove, readonly = false }) => (
    <div className="dependency-row mb-2 d-flex gap-2 align-items-start">
        <select
            className="form-select form-select-sm"
            value={dependency.epicId || ''}
            onChange={(e) => onUpdate({ ...dependency, epicId: e.target.value })}
            style={{ minWidth: '200px' }}
            disabled={readonly}
        >
            <option value="">Select Epic</option>
            {availableEpics.map(epic => (
                <option key={epic._id} value={epic._id}>
                    #{epic.epicNumber || epic._id?.substring(0, 6)} - {epic.title}
                </option>
            ))}
        </select>
        
        <select
            className="form-select form-select-sm"
            value={dependency.type || 'blocks'}
            onChange={(e) => onUpdate({ ...dependency, type: e.target.value })}
            disabled={readonly}
        >
            {Object.entries(DEPENDENCY_TYPES).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
            ))}
        </select>
        
        <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Description (optional)"
            value={dependency.description || ''}
            onChange={(e) => onUpdate({ ...dependency, description: e.target.value })}
            disabled={readonly}
        />
        
        {!readonly && (
            <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={onRemove}
            >
                <FontAwesomeIcon icon={faTrash} />
            </button>
        )}
    </div>
);

// Define the business value options consistently with CreateEpicSlider
const businessValueOptions = [
    { value: 'Critical', score: 100 },
    { value: 'High', score: 80 },
    { value: 'Medium', score: 60 },
    { value: 'Low', score: 40 },
    { value: 'Minimal', score: 20 }
];

const EpicDetailsPanel = ({ 
    showPanel,
    panelClosing,
    currentEpic,
    closePanel,
    handleDeleteEpic,
    activeEpicComments,
    commentsLoading,
    canEditComment,
    startEditingComment,
    editingCommentId,
    editedCommentContent,
    setEditedCommentContent,
    cancelEditingComment,
    saveEditedComment,
    editCommentRef,
    newComment,
    setNewComment,
    submitComment,
    updateEpic,
    availableEpics,
}) => {
    const [localEpic, setLocalEpic] = useState({
        ...currentEpic,
        successCriteria: Array.isArray(currentEpic.successCriteria) 
            ? currentEpic.successCriteria 
            : []
    });
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);

    useEffect(() => {
        setLocalEpic({
            ...currentEpic,
            successCriteria: Array.isArray(currentEpic.successCriteria) 
                ? currentEpic.successCriteria 
                : []
        });
    }, [currentEpic]);

    const handleFieldChange = useCallback((field, value) => {
        setLocalEpic(prev => ({ ...prev, [field]: value }));
        setIsSaving(true);

        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        const timeoutId = setTimeout(async () => {
            try {
                await updateEpic({
                    epicId: currentEpic._id,
                    epicData: { ...localEpic, [field]: value }
                });
            } finally {
                setIsSaving(false);
            }
        }, 1000);

        setSaveTimeout(timeoutId);
    }, [currentEpic._id, localEpic, saveTimeout, updateEpic]);

    // Get user stories from Redux store
    const { userStories } = useSelector(state => state.agileHub);
    
    // Filter stories for this epic
    const epicStories = userStories.filter(story => story.epicId === currentEpic._id);
    
    // Calculate progress based on stories
    const completedStories = epicStories.filter(
        story => story.status?.toLowerCase() === 'done'
    ).length;
    
    const inProgressStories = epicStories.filter(
        story => story.status?.toLowerCase() === 'in progress' || 
            story.status?.toLowerCase() === 'review'
    ).length;
    
    const completionPercentage = epicStories.length > 0 
        ? Math.round(((completedStories * 1.0) + (inProgressStories * 0.5)) / epicStories.length * 100)
        : (currentEpic.status === 'Complete' ? 100 : currentEpic.status === 'In Progress' ? 50 : 0);

    // Add new states for user story slider
    const [showStorySlider, setShowStorySlider] = useState(false);
    const [storySliderClosing, setStorySliderClosing] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);

    // Add story slider handlers
    const handleStoryClick = (story) => {
        setSelectedStory(story);
        setShowStorySlider(true);
        setStorySliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeStorySlider = () => {
        setStorySliderClosing(true);
        setTimeout(() => {
            setShowStorySlider(false);
            setStorySliderClosing(false);
            setSelectedStory(null);
            document.body.style.overflow = '';
        }, 400);
    };

    // Add story update handler
    const handleUpdateStory = async (storyData) => {
        try {
            // Dispatch your update story action here
            // For example: await dispatch(updateUserStory({ projectId, storyId: storyData._id, userStoryData: storyData }));
            // Then maybe refresh epics or user stories as needed
        } catch (error) {
            console.error('Error updating story:', error);
        }
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showPanel ? 'visible' : ''} ${panelClosing ? 'hiding' : ''}`} 
                onClick={closePanel}
            ></div>
            <div className={`sliding-panel ${showPanel ? 'open' : ''} ${panelClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closePanel}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">{currentEpic.title}</h2>
                    <div className="sliding-panel-subtitle">
                        Epic #{currentEpic.epicNumber || currentEpic._id?.substring(0, 8)}
                        <div className="epic-dates">
                            <span>Created: {formatDate(currentEpic.createdAt)}</span> • 
                            <span> Last Updated: {formatDate(currentEpic.updatedAt)}</span>
                        </div>
                    </div>
                    {isSaving && (
                        <div className="saving-indicator">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <span className="ms-2">Saving...</span>
                        </div>
                    )}
                </div>
                
                <div className="sliding-panel-content">
                    {/* Basic Information Section */}
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="epic-detail-grid">
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Priority</div>
                                <div className="epic-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localEpic.priority || 'Medium'}
                                        onChange={(e) => handleFieldChange('priority', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Business Value</div>
                                <div className="epic-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localEpic.businessValue || 'Medium'}
                                        onChange={(e) => handleFieldChange('businessValue', e.target.value)}
                                    >
                                        {businessValueOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Status</div>
                                <div className="epic-detail-value">
                                    <select
                                        className="form-select form-select-sm"
                                        value={localEpic.status || 'Not Started'}
                                        onChange={(e) => handleFieldChange('status', e.target.value)}
                                    >
                                        <option value="Not Started">Not Started</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Complete">Complete</option>
                                        <option value="Backlogged">Backlogged</option>
                                        <option value="Blocked">Blocked</option>
                                    </select>
                                </div>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Story Points</div>
                                <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    value={localEpic.storyPoints || ''}
                                    onChange={(e) => handleFieldChange('storyPoints', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="epic-description-section mt-3">
                            <h4 className="mb-2">Description</h4>
                            <textarea
                                className="form-control"
                                value={localEpic.description || ''}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                                rows="4"
                            />
                        </div>
                    </div>

                    {/* Planning Section */}
                    <div className="form-section">
                        <h3 className="section-title">Planning</h3>
                        <div className="epic-detail-grid">
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Start Date</div>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={localEpic.startDate ? localEpic.startDate.split('T')[0] : ''}
                                    onChange={(e) => handleFieldChange('startDate', e.target.value)}
                                />
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Target Date</div>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={localEpic.targetDate ? localEpic.targetDate.split('T')[0] : ''}
                                    onChange={(e) => handleFieldChange('targetDate', e.target.value)}
                                />
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Created</div>
                                <div className="epic-detail-value">
                                    {formatDate(currentEpic.createdAt)}
                                </div>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Last Updated</div>
                                <div className="epic-detail-value">
                                    {formatDate(currentEpic.updatedAt)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Team & Categorization Section */}
                    <div className="form-section">
                        <h3 className="section-title">Team & Categorization</h3>
                        <div className="epic-detail-grid">
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Assignees</div>
                                <div className="epic-detail-value team-members">
                                    {localEpic.team?.length > 0 ? 
                                        localEpic.team.map((member, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                className="form-control form-control-sm mb-1"
                                                value={member}
                                                onChange={(e) => {
                                                    const newTeam = [...localEpic.team];
                                                    newTeam[index] = e.target.value;
                                                    handleFieldChange('team', newTeam);
                                                }}
                                            />
                                        )) : 
                                        'No team members assigned'
                                    }
                                </div>
                            </div>
                            <div className="epic-detail-item">
                                <div className="epic-detail-label">Tags</div>
                                <div className="epic-detail-value tags">
                                    {localEpic.tags?.length > 0 ?
                                        localEpic.tags.map((tag, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                className="form-control form-control-sm mb-1"
                                                value={tag}
                                                onChange={(e) => {
                                                    const newTags = [...localEpic.tags];
                                                    newTags[index] = e.target.value;
                                                    handleFieldChange('tags', newTags);
                                                }}
                                            />
                                        )) :
                                        'No tags'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Dependencies & Technical Section */}
                    <div className="form-section">
                        <h3 className="section-title d-flex justify-content-between align-items-center">
                            Dependencies
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    const newDependencies = [
                                        ...(localEpic.dependencies || []),
                                        { epicId: '', type: 'blocks', description: '' }
                                    ];
                                    handleFieldChange('dependencies', newDependencies);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="me-1" />
                                Add Dependency
                            </button>
                        </h3>
                        
                        <div className="dependencies-list">
                            {localEpic.dependencies?.length > 0 ? (
                                localEpic.dependencies.map((dep, index) => (
                                    <DependencyRow
                                        key={index}
                                        dependency={dep}
                                        availableEpics={availableEpics}
                                        onUpdate={(updatedDep) => {
                                            const newDependencies = [...localEpic.dependencies];
                                            newDependencies[index] = updatedDep;
                                            handleFieldChange('dependencies', newDependencies);
                                        }}
                                        onRemove={() => {
                                            const newDependencies = localEpic.dependencies.filter((_, i) => i !== index);
                                            handleFieldChange('dependencies', newDependencies);
                                        }}
                                    />
                                ))
                            ) : (
                                <p className="text-muted text-center py-3">
                                    No dependencies defined. Add dependencies if this epic depends on others.
                                </p>
                            )}
                        </div>
                    </div>

                    {/* Associated Stories Section */}
                    <div className="form-section">
                        <h3 className="section-title">Associated User Stories ({epicStories.length})</h3>
                        
                        {epicStories.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Points</th>
                                            <th>Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {epicStories.map(story => (
                                            <tr 
                                                key={story._id} 
                                                onClick={() => handleStoryClick(story)}
                                                style={{ cursor: 'pointer' }}
                                                className="story-row"
                                            >
                                                <td>US-{story.storyNumber}</td>
                                                <td>{story.title}</td>
                                                <td>
                                                    <span className={`badge bg-${
                                                        story.status?.toLowerCase() === 'done' ? 'success' :
                                                        story.status?.toLowerCase() === 'in progress' ? 'primary' :
                                                        story.status?.toLowerCase() === 'review' ? 'warning' :
                                                        story.status?.toLowerCase() === 'blocked' ? 'danger' :
                                                        'secondary'
                                                    }`}>
                                                        {story.status || 'Backlog'}
                                                    </span>
                                                </td>
                                                <td>{story.storyPoints || '-'}</td>
                                                <td>
                                                    {story.acceptanceCriteria?.length > 0 ? (
                                                        `${story.acceptanceCriteria.filter(c => c.completed).length}/${story.acceptanceCriteria.length}`
                                                    ) : '-'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                
                                <div className="stories-summary-footer">
                                    <div className="summary-stat">
                                        <div className="stat-label">Total Stories</div>
                                        <div className="stat-value">{epicStories.length}</div>
                                    </div>
                                    <div className="summary-stat">
                                        <div className="stat-label">Completed</div>
                                        <div className="stat-value text-success">{completedStories}</div>
                                    </div>
                                    <div className="summary-stat">
                                        <div className="stat-label">Story Points</div>
                                        <div className="stat-value">
                                            {epicStories.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0)}
                                        </div>
                                    </div>
                                    <div className="summary-stat">
                                        <div className="stat-label">Progress</div>
                                        <div className="stat-value">
                                            <div className="progress me-2" style={{ width: '60px', height: '8px', display: 'inline-block', verticalAlign: 'middle' }}>
                                                <div
                                                    className="progress-bar"
                                                    style={{ width: `${completionPercentage}%` }}
                                                ></div>
                                            </div>
                                            <span>{completionPercentage}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="text-muted text-center py-3">
                                No user stories associated with this epic.
                            </p>
                        )}
                    </div>

                    {/* Success Criteria Section */}
                    <div className="form-section">
                        <h3 className="section-title d-flex justify-content-between align-items-center">
                            Success Criteria
                            <button 
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    const newCriteria = [
                                        ...(localEpic.successCriteria || []), 
                                        { text: '', completed: false }
                                    ];
                                    handleFieldChange('successCriteria', newCriteria);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="me-1" />
                                Add Criterion
                            </button>
                        </h3>
                        <div className="success-criteria-list">
                            {localEpic.successCriteria?.length > 0 ? (
                                localEpic.successCriteria.map((criterion, index) => (
                                    <div 
                                        key={index} 
                                        className={`success-criterion-item ${criterion.completed ? 'completed' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={criterion.completed}
                                            onChange={(e) => {
                                                const newCriteria = [...localEpic.successCriteria];
                                                newCriteria[index] = {
                                                    ...criterion,
                                                    completed: e.target.checked
                                                };
                                                handleFieldChange('successCriteria', newCriteria);
                                            }}
                                            id={`criterion-${index}`}
                                        />
                                        
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={criterion.text}
                                            onChange={(e) => {
                                                const newCriteria = [...localEpic.successCriteria];
                                                newCriteria[index] = {
                                                    ...criterion,
                                                    text: e.target.value
                                                };
                                                handleFieldChange('successCriteria', newCriteria);
                                            }}
                                            placeholder="Enter success criterion..."
                                        />
                                        <button
                                            className="btn btn-outline-danger btn-remove"
                                            onClick={() => {
                                                const newCriteria = localEpic.successCriteria.filter((_, i) => i !== index);
                                                handleFieldChange('successCriteria', newCriteria);
                                            }}
                                            title="Remove criterion"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted text-center py-3">
                                    No success criteria defined. Add some criteria to track epic completion.
                                </p>
                            )}
                        </div>
                        <div className="success-criteria-progress mt-3">
                            {localEpic.successCriteria?.length > 0 && (
                                <>
                                    <div className="progress" style={{ height: '8px' }}>
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${(localEpic.successCriteria.filter(c => c.completed).length / 
                                                        localEpic.successCriteria.length) * 100}%`
                                            }}
                                        ></div>
                                    </div>
                                    <div className="text-muted text-end mt-2 small">
                                        {localEpic.successCriteria.filter(c => c.completed).length} of {localEpic.successCriteria.length} completed
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Comments Section */}
                    <div className="epic-comments-section">
                        <div className="epic-comments-header">
                            <h3 className="section-title d-flex justify-content-between align-items-center">
                                Comments (
                                <span className="comments-count">
                                    {activeEpicComments.length}
                                </span>)
                            </h3>
                        </div>
                        
                        <div className="epic-comments-list">
                            {commentsLoading ? (
                                <div className="text-center p-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : activeEpicComments.length > 0 ? (
                                activeEpicComments.map(comment => (
                                    <div 
                                        key={comment._id} 
                                        className={`comment-item ${editingCommentId === comment._id ? 'editing' : ''}`}
                                    >
                                        <div className="comment-header">
                                            <span className="comment-author">{comment.author}</span>
                                            <div className="comment-actions">
                                                {canEditComment(comment.author) && editingCommentId !== comment._id && (
                                                    <button 
                                                        className="btn btn-sm btn-link text-muted p-0 ms-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            startEditingComment(comment);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                )}
                                                <span className="comment-date">
                                                    {formatCommentDate(comment.createdAt)}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        {editingCommentId === comment._id ? (
                                            <div className="comment-edit-form">
                                                <textarea
                                                    ref={editCommentRef}
                                                    className="form-control"
                                                    value={editedCommentContent}
                                                    onChange={(e) => setEditedCommentContent(e.target.value)}
                                                    rows={3}
                                                />
                                                <div className="mt-2 d-flex gap-2">
                                                    <button 
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={cancelEditingComment}
                                                    >
                                                        <FontAwesomeIcon icon={faBan} className="me-1" />
                                                        Cancel
                                                    </button>
                                                    <button 
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => saveEditedComment(comment._id)}
                                                        disabled={!editedCommentContent.trim()}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} className="me-1" />
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="comment-content">
                                                {comment.content}
                                                {comment.updatedAt && comment.updatedAt !== comment.createdAt && (
                                                    <span className="text-muted ms-2 small">
                                                        (edited)
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faComment} size="2x" className="text-muted mb-2" />
                                    <p className="text-muted mb-0">No comments yet</p>
                                </div>
                            )}
                        </div>
                        
                        <div className="comment-form mt-3">
                            <textarea
                                className="form-control mb-2"
                                placeholder="Add a comment..."
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                rows={2}
                            />
                            <div className="d-flex justify-content-end">
                                <button 
                                    className="btn btn-primary"
                                    onClick={submitComment}
                                    disabled={!newComment.trim()}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                    Post Comment
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Action buttons */}
                    <div className="epic-actions d-flex justify-content-end">
                        <button 
                            className="btn btn-danger"
                            onClick={() => {
                                if(window.confirm('Are you sure you want to delete this epic?')) {
                                    handleDeleteEpic(currentEpic._id);
                                    closePanel();
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Epic
                        </button>
                    </div>
                </div>
            </div>

            {/* Add the User Story Details Slider Portal */}
            {showStorySlider && createPortal(
                <UserStoryDetailsSlider
                    showSlider={showStorySlider}
                    sliderClosing={storySliderClosing}
                    closeSlider={closeStorySlider}
                    currentStory={selectedStory}
                    handleUpdateStory={handleUpdateStory}
                    handleDeleteStory={() => {}} // Implement if needed
                    availableEpics={availableEpics}
                    availableStories={userStories.filter(s => s._id !== selectedStory?._id)}
                    projectId={currentEpic.projectId}
                />,
                document.getElementById('sliding-panel-root')
            )}
        </div>
    );
};

export default EpicDetailsPanel;
