import React from "react";
import "./ComponentStyling/TermsOfUse.css";
import Navbar from '../Components/Navbar.js';
import Footer from '../Components/Footer';

const TermsOfUse = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}>
            <Navbar />
            <main className="terms-container">
                <div className="terms-hero">
                    <h1>Terms of Use</h1>
                    <p className="terms-subtitle">Last updated: {new Date().toLocaleDateString()}</p>
                    <div className="terms-commitment">
                        <h2>Welcome to PMHub.io</h2>
                        <p>By using our service, you agree to these terms. Please read them carefully. We've designed these terms 
                        to be clear and straightforward, ensuring a fair and productive relationship between PMHub.io and our users.</p>
                    </div>
                </div>
                
                <div className="terms-content">
                    <div className="terms-grid">
                        {termsSections.map((section, index) => (
                            <div key={index} className="terms-section">
                                <div className="terms-card">
                                    <h2>{section.title}</h2>
                                    <p>{section.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

const termsSections = [
    {
        title: "Agreement to Terms",
        content: "By accessing or using PMHub.io, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using this service."
    },
    {
        title: "Account Terms",
        content: "You must be 18 years or older to use this service. You must provide accurate and complete information when creating your account. You are responsible for maintaining the security of your account credentials. Each account may only be used by one person or organization."
    },
    {
        title: "User Responsibilities",
        content: "You are responsible for all content posted and activity that occurs under your account. You must not use PMHub.io in any way that could damage, disable, overburden, or impair the service."
    },
    {
        title: "Acceptable Use",
        content: "You agree to use PMHub.io lawfully and ethically. Prohibited activities include: unauthorized access, malware distribution, data mining, content scraping, or any activity that interferes with the service's operation."
    },
    {
        title: "Payment and Subscription",
        content: "Paid services are billed in advance. If we change pricing, we'll notify you before changes take effect. You're responsible for taxes beyond the displayed fees. Refunds are handled case-by-case according to applicable laws."
    },
    {
        title: "Data and Privacy",
        content: "Your use of PMHub.io is also governed by our Privacy Policy. You retain ownership of any content you upload, but grant us license to use it for providing our services. We handle your data as described in our Privacy Policy."
    },
    {
        title: "Service Availability",
        content: "While we strive for 100% uptime, PMHub.io is provided 'as is' without guarantees of continuous availability. We reserve the right to modify, suspend, or discontinue any part of the service with reasonable notice."
    },
    {
        title: "Intellectual Property",
        content: "PMHub.io is developed by Dilyn Zertuche. The service's code, design, and content are protected by applicable laws. You may not copy, modify, or distribute our service's components without explicit permission."
    },
    {
        title: "User Content License",
        content: "You retain ownership of content you upload but grant PMHub.io a license to host, display, and use such content for service operation. You must have necessary rights to content you upload."
    },
    {
        title: "Limitation of Liability",
        content: "PMHub.io is not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the service or any content provided."
    },
    {
        title: "Termination",
        content: "We may suspend or terminate your account for violations of these terms, illegal activity, or extended inactivity. Upon termination, relevant sections of these terms remain in effect."
    },
    {
        title: "Changes to Terms",
        content: "We may modify these terms at any time. We'll notify users of significant changes. Continued use of PMHub.io after changes constitutes acceptance of new terms."
    },
    {
        title: "Dispute Resolution",
        content: "Any disputes will be resolved through good-faith negotiations. If unsuccessful, disputes will be resolved through arbitration in accordance with applicable laws in your jurisdiction."
    },
    {
        title: "Contact Information",
        content: "For any questions about these Terms of Use, please contact us at support@projectmanagerhub.io. We aim to respond to all inquiries within 2 business days."
    }
];

export default TermsOfUse;
