import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import PaymentForm from './PaymentForm'; // Add this import
import './ComponentStyling/Register.css'; // Import the custom CSS

export default function Register() {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOver13, setIsOver13] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [subscriptionTier, setSubscriptionTier] = useState('free');
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [showPayment, setShowPayment] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const minLength = 12;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasPersonalInfo = [firstname, lastname, email].some(info => password.includes(info));
        return {
            minLength: password.length >= minLength,
            hasUpperCase,
            noPersonalInfo: !hasPersonalInfo
        };
    };

    const passwordValidation = validatePassword(password);

    const handleSubscriptionSelect = (tier) => {
        setSubscriptionTier(tier);
        if (tier === 'free') {
            setBillingCycle('none');
        }
        // Reset payment form when changing subscription
        setShowPayment(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (subscriptionTier === 'professional' && !showPayment) {
            // Validate registration data before showing payment
            if (!isOver13) {
                setMessage('You must be over the age of 13 to register.');
                return;
            }
            if (!agreedToPrivacy || !agreedToTerms) {
                setMessage('You must agree to both the Privacy Policy and Terms of Use.');
                return;
            }
            if (!passwordValidation.minLength || !passwordValidation.hasUpperCase || !passwordValidation.noPersonalInfo) {
                setMessage('Password must be at least 12 characters long, include at least one uppercase letter, and not contain personal information.');
                return;
            }
            if (password !== confirmPassword) {
                setMessage('Passwords do not match.');
                return;
            }

            // Store registration data and show payment form
            setRegistrationData({
                firstname,
                lastname,
                email,
                password,
                subscriptionTier,
                billingCycle,
                accountType: subscriptionTier === 'free' ? 'freeForever' : 'Professional'
            });
            setShowPayment(true);
            return;
        }

        // Original registration logic for free tier
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { 
                firstname, 
                lastname, 
                email, 
                password, 
                memberSince: new Date(),
                subscriptionTier,
                billingCycle,
                accountType: subscriptionTier === 'free' ? 'freeForever' : 'Professional'
            });
            console.log(res);
            setMessage('Registration successful');
            navigate('/login');
        } catch (e) {
            console.error('Error:', e.response ? e.response.data : e.message);
            setMessage(e.response ? e.response.data.message : 'Server error');
        } finally {
            setLoading(false);
        }
    };

    const handlePaymentSubmit = async (paymentDetails) => {
        setLoading(true);
        try {
            console.log('Payment details:', paymentDetails);
            // Submit both registration data and payment details
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                ...registrationData,
                paymentDetails,
                subscriptionTier,
                billingCycle
            });
            console.log('Registration response:', res.data);
            setMessage('Registration successful');
            navigate('/login');
        } catch (e) {
            console.error('Payment error:', e);
            setMessage(e.response?.data?.message || 'Payment processing error');
        } finally {
            setLoading(false);
        }
    };

    const handlePrivacyClick = (e) => {
        e.preventDefault();
        window.open('/privacy-policy', '_blank');
    };

    const handleTermsClick = (e) => {
        e.preventDefault();
        window.open('/terms', '_blank');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar />
            <main className="register-container">
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="register-card">
                                <div className="register-card-body">
                                    <h1 className="register-card-title">
                                        {showPayment ? 'Payment Information' : 'Register'}
                                    </h1>
                                    
                                    {showPayment ? (
                                        <PaymentForm 
                                            onSubmit={handlePaymentSubmit}
                                            billingCycle={billingCycle}
                                            loading={loading}
                                            onBack={() => setShowPayment(false)}
                                            userData={{
                                                name: `${firstname} ${lastname}`,
                                                email: email
                                            }}
                                        />
                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="register-form-group">
                                                <label>First Name</label>
                                                <input type="text" className="register-form-control" value={firstname} onChange={(e) => setFirstname(e.target.value)} disabled={loading} />
                                            </div>
                                            <div className="register-form-group">
                                                <label>Last Name</label>
                                                <input type="text" className="register-form-control" value={lastname} onChange={(e) => setLastname(e.target.value)} disabled={loading} />
                                            </div>
                                            <div className="register-form-group">
                                                <label>Email</label>
                                                <input type="email" className="register-form-control" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading} />
                                            </div>
                                            <div className="register-form-group">
                                                <label>Set Password</label>
                                                <input type="password" className="register-form-control" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} />
                                                <ul className="register-password-rules">
                                                    <li className={passwordValidation.minLength ? 'valid' : 'invalid'}>At least 12 characters</li>
                                                    <li className={passwordValidation.hasUpperCase ? 'valid' : 'invalid'}>At least one uppercase letter</li>
                                                    <li className={passwordValidation.noPersonalInfo ? 'valid' : 'invalid'}>Does not contain personal information</li>
                                                </ul>
                                            </div>
                                            <div className="register-form-group">
                                                <label>Confirm Password</label>
                                                <input type="password" className="register-form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} disabled={loading} />
                                            </div>
                                            <div className="register-form-check">
                                                <input type="checkbox" className="register-checkbox" id="ageCheck" checked={isOver13} onChange={(e) => setIsOver13(e.target.checked)} disabled={loading} />
                                                <label htmlFor="ageCheck">I confirm that I am over the age of 13</label>
                                            </div>
                                            
                                            <div className="register-form-check">
                                                <input 
                                                    type="checkbox" 
                                                    className="register-checkbox" 
                                                    id="privacyCheck" 
                                                    checked={agreedToPrivacy} 
                                                    onChange={(e) => setAgreedToPrivacy(e.target.checked)} 
                                                    disabled={loading} 
                                                />
                                                <label htmlFor="privacyCheck">
                                                    I have read and agree to the <a href="#" onClick={handlePrivacyClick} className="register-policy-link">Privacy Policy</a>
                                                </label>
                                            </div>

                                            <div className="register-form-check">
                                                <input 
                                                    type="checkbox" 
                                                    className="register-checkbox" 
                                                    id="termsCheck" 
                                                    checked={agreedToTerms} 
                                                    onChange={(e) => setAgreedToTerms(e.target.checked)} 
                                                    disabled={loading} 
                                                />
                                                <label htmlFor="termsCheck">
                                                    I have read and agree to the <a href="#" onClick={handleTermsClick} className="register-policy-link">Terms of Use</a>
                                                </label>
                                            </div>
                                            
                                            <div className="subscription-section">
                                                <h3 className="subscription-title">Select a Plan</h3>
                                                
                                                <div className="billing-toggle text-center mb-3">
                                                    <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                                                    <label className="switch mx-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={billingCycle === 'yearly'}
                                                            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly')}
                                                            disabled={subscriptionTier === 'free'}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <span className={billingCycle === 'yearly' ? 'active' : ''}>
                                                        Yearly
                                                        <span className="savings-badge">16% off</span>
                                                    </span>
                                                </div>
                                                
                                                <div className="subscription-cards">
                                                    <div className={`subscription-card ${subscriptionTier === 'free' ? 'selected' : ''}`}
                                                         onClick={() => handleSubscriptionSelect('free')}>
                                                        <div className="subscription-card-header">
                                                            <h3>Free</h3>
                                                            <div className="price">$0</div>
                                                        </div>
                                                        <div className="feature-category">
                                                            <h4>Core Features</h4>
                                                            <ul>
                                                                <li>Basic Project Management</li>
                                                                <li>Up to 3 Projects</li>
                                                                <li>Basic Kanban Board</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className={`subscription-card ${subscriptionTier === 'professional' ? 'selected' : ''}`}
                                                         onClick={() => handleSubscriptionSelect('professional')}>
                                                        <div className="subscription-card-header">
                                                            <h3>Professional</h3>
                                                            <div className="price">
                                                                {billingCycle === 'monthly' ? '$2.99/mo' : '$29.99/year'}
                                                            </div>
                                                        </div>
                                                        <div className="feature-category">
                                                            <h4>Core Features</h4>
                                                            <ul>
                                                                <li>Unlimited Projects</li>
                                                                <li>Advanced Kanban Features</li>
                                                            </ul>
                                                        </div>
                                                        <div className="feature-category">
                                                            <h4>Advanced Features</h4>
                                                            <ul>
                                                                <li>Team Collaboration Tools</li>
                                                                <li>Advanced Analytics</li>
                                                                <li>Premium Support</li>
                                                            </ul>
                                                        </div>
                                                        <div className="feature-category">
                                                            <h4>Future Additions</h4>
                                                            <ul>
                                                                <li>All Future Features</li>
                                                                <li>Priority Feature Requests</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="plan-comparison">
                                                    <div className="plan-comparison-toggle" onClick={() => document.querySelector('.plan-comparison-details').classList.toggle('expanded')}>
                                                        View full plan comparison
                                                        <span className="toggle-icon">+</span>
                                                    </div>
                                                    <div className="plan-comparison-details">
                                                        <table className="comparison-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Feature</th>
                                                                    <th>Free</th>
                                                                    <th>Professional</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Projects</td>
                                                                    <td>3</td>
                                                                    <td>Unlimited</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kanban Boards</td>
                                                                    <td>Basic</td>
                                                                    <td>Advanced</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Team Members</td>
                                                                    <td>2</td>
                                                                    <td>Unlimited</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Support</td>
                                                                    <td>Community</td>
                                                                    <td>Priority</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>File Storage</td>
                                                                    <td>100MB</td>
                                                                    <td>10GB</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="submit" className="register-btn-primary" disabled={loading}>
                                                {loading ? 'Processing...' : 
                                                 subscriptionTier === 'professional' ? 'Next' : 'Register'}
                                            </button>
                                        </form>
                                    )}
                                    
                                    {message && <p className="register-message">{message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}