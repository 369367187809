import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlus, faCaretUp, faCaretDown, faChartBar, 
    faProjectDiagram, faPuzzlePiece, faCodeBranch,
    faStream, faShield, faCog
} from '@fortawesome/free-solid-svg-icons';
import { fetchFeatures, createFeature, updateFeature, deleteFeature, fetchEpics } from '../../../redux/slices/agileHubSlice';
import { createPortal } from 'react-dom';
import { formatDate, calculateFeatureCompletion, getFeatureTypeInfo } from '../utils/epicUtils';
import FeatureFilters from '../components/FeatureFilters';
import CreateFeatureSlider from '../components/CreateFeatureSlider';
import FeatureDetailsSlider from '../components/FeatureDetailsSlider';
import './Features.css';

export const Features = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { 
        features, 
        featuresLoading, 
        featuresError, 
        epics,
        userStories
    } = useSelector(state => state.agileHub);
    
    // Filter and view states
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [sortField, setSortField] = useState('featureNumber');
    const [sortDirection, setSortDirection] = useState('asc');
    const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'table'

    // Slider states
    const [showCreateSlider, setShowCreateSlider] = useState(false);
    const [createSliderClosing, setCreateSliderClosing] = useState(false);
    const [showDetailsSlider, setShowDetailsSlider] = useState(false);
    const [detailsSliderClosing, setDetailsSliderClosing] = useState(false);
    const [currentFeature, setCurrentFeature] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);

    // Analytics state
    const [featureAnalytics, setFeatureAnalytics] = useState({
        totalFeatures: 0,
        inProgressFeatures: 0,
        completedFeatures: 0,
        typesDistribution: {}
    });

    // Fetch features data when component mounts
    useEffect(() => {
        dispatch(fetchFeatures(projectId));
        dispatch(fetchEpics(projectId));
    }, [dispatch, projectId]);

    // Calculate analytics
    useEffect(() => {
        if (features && features.length > 0) {
            const inProgress = features.filter(f => f.status === 'In Progress').length;
            const completed = features.filter(f => f.status === 'Complete').length;
            
            const typesDistribution = features.reduce((acc, feature) => {
                const type = feature.type || 'other';
                acc[type] = (acc[type] || 0) + 1;
                return acc;
            }, {});
            
            setFeatureAnalytics({
                totalFeatures: features.length,
                inProgressFeatures: inProgress,
                completedFeatures: completed,
                typesDistribution
            });
        }
    }, [features]);

    // Filter and sort features
    const getFilteredAndSortedFeatures = () => {
        return features
            .filter(feature => {
                const matchesSearch = 
                    feature.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    feature.description?.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesType = filterType === 'All' || feature.type === filterType;
                const matchesStatus = filterStatus === 'All' || feature.status === filterStatus;
                
                return matchesSearch && matchesType && matchesStatus;
            })
            .sort((a, b) => {
                let comparison = 0;
                
                if (sortField === 'name') {
                    comparison = a.name?.localeCompare(b.name || '');
                } else if (sortField === 'featureNumber') {
                    comparison = (a.featureNumber || 0) - (b.featureNumber || 0);
                } else if (sortField === 'status') {
                    comparison = a.status?.localeCompare(b.status || '');
                } else if (sortField === 'priority') {
                    const priorityOrder = { 'High': 1, 'Medium': 2, 'Low': 3 };
                    comparison = (priorityOrder[a.priority] || 0) - (priorityOrder[b.priority] || 0);
                } else if (sortField === 'type') {
                    comparison = a.type?.localeCompare(b.type || '');
                } else if (sortField === 'createdAt') {
                    comparison = new Date(a.createdAt || 0) - new Date(b.createdAt || 0);
                }
                
                return sortDirection === 'asc' ? comparison : -comparison;
            });
    };

    const filteredFeatures = getFilteredAndSortedFeatures();

    // Handle sort toggle
    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    // Slider handlers
    const openCreateFeatureSlider = () => {
        setCurrentFeature({
            name: '',
            description: '',
            type: 'functionality',
            status: 'Planning',
            priority: 'Medium',
            riskLevel: 'Low',
            requirements: []
        });
        setShowCreateSlider(true);
        setCreateSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeCreateSlider = () => {
        setCreateSliderClosing(true);
        setTimeout(() => {
            setShowCreateSlider(false);
            setCreateSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleFeatureInputChange = (field, value) => {
        setCurrentFeature({ ...currentFeature, [field]: value });
    };

    const handleCreateFeature = async () => {
        try {
            await dispatch(createFeature({
                projectId,
                featureData: currentFeature
            })).unwrap();
            closeCreateSlider();
        } catch (error) {
            console.error('Error creating feature:', error);
        }
    };

    const openFeatureDetailsSlider = (feature) => {
        setSelectedFeature(feature);
        setShowDetailsSlider(true);
        setDetailsSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeDetailsSlider = () => {
        setDetailsSliderClosing(true);
        setTimeout(() => {
            setShowDetailsSlider(false);
            setDetailsSliderClosing(false);
            setSelectedFeature(null);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleUpdateFeature = async ({ featureId, featureData }) => {
        try {
            await dispatch(updateFeature({
                projectId,
                featureId,
                featureData
            })).unwrap();
        } catch (error) {
            console.error('Error updating feature:', error);
        }
    };

    const handleDeleteFeature = async (featureId) => {
        try {
            await dispatch(deleteFeature({
                projectId,
                featureId
            })).unwrap();
        } catch (error) {
            console.error('Error deleting feature:', error);
        }
    };

    // Function to render feature type icon
    const getFeatureTypeIcon = (type) => {
        switch (type?.toLowerCase()) {
            case 'functionality': return <FontAwesomeIcon icon={faPuzzlePiece} />;
            case 'ui': return <FontAwesomeIcon icon={faStream} />;
            case 'enhancement': return <FontAwesomeIcon icon={faChartBar} />;
            case 'optimization': return <FontAwesomeIcon icon={faCog} />;
            case 'integration': return <FontAwesomeIcon icon={faProjectDiagram} />;
            case 'security': return <FontAwesomeIcon icon={faShield} />;
            default: return <FontAwesomeIcon icon={faCodeBranch} />;
        }
    };

    // Set up sliding panel portal
    useEffect(() => {
        let panelRoot = document.getElementById('sliding-panel-root');
        if (!panelRoot) {
            panelRoot = document.createElement('div');
            panelRoot.id = 'sliding-panel-root';
            document.body.appendChild(panelRoot);
        }
    }, []);

    // Render analytics dashboard
    const renderAnalyticsDashboard = () => (
        <div className="features-analytics mb-4">
            <div className="analytics-grid">
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faCodeBranch} className="text-primary" />
                    </div>
                    <div className="analytic-content">
                        <h4>Total Features</h4>
                        <div className="analytic-value">{featureAnalytics.totalFeatures}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faChartBar} className="text-warning" />
                    </div>
                    <div className="analytic-content">
                        <h4>In Progress</h4>
                        <div className="analytic-value">{featureAnalytics.inProgressFeatures}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faPuzzlePiece} className="text-success" />
                    </div>
                    <div className="analytic-content">
                        <h4>Completed</h4>
                        <div className="analytic-value">{featureAnalytics.completedFeatures}</div>
                    </div>
                </div>
                <div className="analytic-card">
                    <div className="analytic-icon">
                        <FontAwesomeIcon icon={faProjectDiagram} className="text-info" />
                    </div>
                    <div className="analytic-content">
                        <h4>Completion Rate</h4>
                        <div className="analytic-value">
                            {featureAnalytics.totalFeatures > 0 
                                ? Math.round((featureAnalytics.completedFeatures / featureAnalytics.totalFeatures) * 100)
                                : 0}%
                            <div className="progress mt-2" style={{ height: '4px' }}>
                                <div 
                                    className="progress-bar" 
                                    style={{ 
                                        width: `${featureAnalytics.totalFeatures > 0 
                                            ? Math.round((featureAnalytics.completedFeatures / featureAnalytics.totalFeatures) * 100)
                                            : 0}%`
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Loading and error states
    if (featuresLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (featuresError) {
        return (
            <div className="alert alert-danger">
                Error loading features: {featuresError}
            </div>
        );
    }

    return (
        <div className="features-container">
            <div className="features-header mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <h3>Features</h3>
                    <button className="btn btn-primary" onClick={openCreateFeatureSlider}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        Create Feature
                    </button>
                </div>
            </div>

            {/* Render analytics dashboard */}
            {renderAnalyticsDashboard()}
            
            {/* Filters */}
            <div className="features-filters mb-4">
                <FeatureFilters 
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    filterStatus={filterStatus}
                    setFilterStatus={setFilterStatus}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                />
            </div>

            {/* Feature content */}
            <div className="features-content">
                {filteredFeatures.length === 0 ? (
                    <div className="empty-state text-center py-5">
                        <FontAwesomeIcon icon={faCodeBranch} size="3x" className="text-muted mb-3" />
                        <h4>No Features Found</h4>
                        <p className="text-muted">
                            {features.length === 0 
                                ? "You haven't created any features yet. Get started by creating your first feature."
                                : "No features match your current filter criteria."}
                        </p>
                        <button className="btn btn-primary mt-2" onClick={openCreateFeatureSlider}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Create Feature
                        </button>
                    </div>
                ) : viewMode === 'grid' ? (
                    // Grid view
                    <div className="features-grid">
                        {filteredFeatures.map(feature => {
                            const typeInfo = getFeatureTypeInfo(feature.type);
                            const completionPercentage = calculateFeatureCompletion(feature, epics, userStories);
                            const featureEpics = epics.filter(epic => epic.featureId === feature._id);
                            
                            return (
                                <div 
                                    key={feature._id} 
                                    className="feature-card" 
                                    onClick={() => openFeatureDetailsSlider(feature)}
                                >
                                    <div className={`feature-card-header ${feature.type?.toLowerCase() || ''}`}>
                                        <span className="feature-number">#{feature.featureNumber || '–'}</span>
                                        <div className="feature-type-label">
                                            {getFeatureTypeIcon(feature.type)}
                                            <span className="ms-1">{typeInfo.label}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="feature-card-body">
                                        <h4 className="feature-title">{feature.name}</h4>
                                        <p className="feature-description">
                                            {feature.description?.length > 100 
                                                ? `${feature.description.substring(0, 100)}...` 
                                                : feature.description}
                                        </p>
                                        
                                        <div className="feature-meta">
                                            <span className={`badge feature-badge-${feature.status?.toLowerCase()}`}>
                                                {feature.status}
                                            </span>
                                            <span className={`badge bg-${feature.priority?.toLowerCase()}`}>
                                                {feature.priority}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="feature-card-footer">
                                        <div className="feature-progress">
                                            <div className="progress" style={{ height: '6px' }}>
                                                <div 
                                                    className="progress-bar" 
                                                    style={{ width: `${completionPercentage}%` }}
                                                ></div>
                                            </div>
                                            <span className="progress-text">{completionPercentage}%</span>
                                        </div>
                                        <div className="feature-data">
                                            <span className="feature-epics">
                                                {featureEpics.length} epic{featureEpics.length !== 1 ? 's' : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    // Table view
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th 
                                        onClick={() => handleSort('featureNumber')}
                                        className="sortable-header"
                                    >
                                        ID
                                        {sortField === 'featureNumber' && (
                                            <FontAwesomeIcon 
                                                icon={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                                                className="ms-2"
                                            />
                                        )}
                                    </th>
                                    <th 
                                        onClick={() => handleSort('name')}
                                        className="sortable-header"
                                    >
                                        Name
                                        {sortField === 'name' && (
                                            <FontAwesomeIcon 
                                                icon={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                                                className="ms-2"
                                            />
                                        )}
                                    </th>
                                    <th 
                                        onClick={() => handleSort('type')}
                                        className="sortable-header"
                                    >
                                        Type
                                        {sortField === 'type' && (
                                            <FontAwesomeIcon 
                                                icon={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                                                className="ms-2"
                                            />
                                        )}
                                    </th>
                                    <th 
                                        onClick={() => handleSort('status')}
                                        className="sortable-header"
                                    >
                                        Status
                                        {sortField === 'status' && (
                                            <FontAwesomeIcon 
                                                icon={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                                                className="ms-2"
                                            />
                                        )}
                                    </th>
                                    <th 
                                        onClick={() => handleSort('priority')}
                                        className="sortable-header"
                                    >
                                        Priority
                                        {sortField === 'priority' && (
                                            <FontAwesomeIcon 
                                                icon={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                                                className="ms-2"
                                            />
                                        )}
                                    </th>
                                    <th>Progress</th>
                                    <th>Target Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredFeatures.map(feature => {
                                    const typeInfo = getFeatureTypeInfo(feature.type);
                                    const completionPercentage = calculateFeatureCompletion(feature, epics, userStories);
                                    
                                    return (
                                        <tr 
                                            key={feature._id}
                                            onClick={() => openFeatureDetailsSlider(feature)}
                                            className="cursor-pointer"
                                        >
                                            <td>#{feature.featureNumber || '–'}</td>
                                            <td>{feature.name}</td>
                                            <td>
                                                <span className={`feature-type ${typeInfo.className}`}>
                                                    {getFeatureTypeIcon(feature.type)}
                                                    <span className="ms-1">{typeInfo.label}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <span className={`badge feature-badge-${feature.status?.toLowerCase()}`}>
                                                    {feature.status}
                                                </span>
                                            </td>
                                            <td>
                                                <span className={`badge bg-${feature.priority?.toLowerCase()}`}>
                                                    {feature.priority}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="progress" style={{ height: '8px', width: '100px' }}>
                                                    <div 
                                                        className="progress-bar" 
                                                        style={{ width: `${completionPercentage}%` }}
                                                    ></div>
                                                </div>
                                                <span className="ms-2">{completionPercentage}%</span>
                                            </td>
                                            <td>
                                                {feature.targetCompletion 
                                                    ? formatDate(feature.targetCompletion)
                                                    : '–'}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* Create Feature Slider */}
            {showCreateSlider && createPortal(
                <CreateFeatureSlider
                    showSlider={showCreateSlider}
                    sliderClosing={createSliderClosing}
                    closeSlider={closeCreateSlider}
                    currentFeature={currentFeature}
                    handleInputChange={handleFeatureInputChange}
                    handleSubmit={handleCreateFeature}
                />,
                document.getElementById('sliding-panel-root')
            )}

            {/* Feature Details Slider */}
            {showDetailsSlider && createPortal(
                <FeatureDetailsSlider
                    showSlider={showDetailsSlider}
                    sliderClosing={detailsSliderClosing}
                    closeSlider={closeDetailsSlider}
                    currentFeature={selectedFeature}
                    handleDeleteFeature={handleDeleteFeature}
                    updateFeature={handleUpdateFeature}
                    epics={epics}
                />,
                document.getElementById('sliding-panel-root')
            )}
        </div>
    );
};

export default Features;
