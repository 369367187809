import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true // Ensure cookies are sent with each request
});

// Add a response interceptor for detailed error logging
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.error('Axios error response:', error.response ? error.response : error);
        return Promise.reject(error);
    }
);

export default axiosInstance;