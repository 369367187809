import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAiInsights } from '../../utils/aiUtils';

export const fetchAiSummary = createAsyncThunk(
  'ai/fetchSummary',
  async (userData, { rejectWithValue }) => {
    try {
      // Add clear formatting instruction to the userData
      const enhancedUserData = {
        ...userData,
        formattingPreference: {
          useStructuredFormat: true,
          requiredSections: [
            "🔥 URGENT",
            "👥 FOLLOW-UPS",
            "📋 KEY TASKS", 
            "⚠️ WATCH LIST",
            "💡 QUICK WINS"
          ],
          itemsPerSection: {
            min: 5,  // Request at least 5 items
            max: 8   // But no more than 8
          },
          compactFormat: true
        }
      };
      
      const insights = await fetchAiInsights(enhancedUserData);
      
      return {
        ...insights,
        fromCache: false,
        generatedAt: new Date().toISOString()
      };
    } catch (error) {
      console.error('AI fetch error:', error);

      // Don't include isAuthError flag anymore since we don't want to log out
      return rejectWithValue({ 
        message: error.message || 'Failed to fetch AI insights',
        timestamp: new Date().toISOString()
      });
    }
  }
);

const aiSlice = createSlice({
  name: 'ai',
  initialState: {
    insights: null,
    isLoading: false,
    error: null,
    lastUpdated: null,
    latestRequest: null  // Track when requests were made
  },
  reducers: {
    clearAiInsights: (state) => {
      state.insights = null;
      state.error = null;
      state.lastUpdated = null;
      state.latestRequest = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAiSummary.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        // Track when the request started
        state.latestRequest = new Date().toISOString();
      })
      .addCase(fetchAiSummary.fulfilled, (state, action) => {
        state.insights = action.payload;
        state.isLoading = false;
        state.lastUpdated = new Date().toISOString();
        state.error = null;
      })
      .addCase(fetchAiSummary.rejected, (state, action) => {
        state.isLoading = false;
        
        // Only update error if this is the latest request or no previous error
        if (!state.error || 
            (action.payload?.timestamp && 
             (!state.error.timestamp || new Date(action.payload.timestamp) > new Date(state.error.timestamp)))) {
          state.error = action.payload;
        }
        
        console.error('AI fetch rejected:', action.payload);
      })
      // Add this case to respond to logout action
      .addCase('auth/logout/fulfilled', (state) => {
        // Reset all AI state when user logs out
        state.insights = null;
        state.isLoading = false;
        state.error = null;
        state.lastUpdated = null;
        state.latestRequest = null;
      });
  }
});

export const { clearAiInsights } = aiSlice.actions;
export default aiSlice.reducer;
