import React from 'react';
import './ComponentStyling/ProjectTag.css';

const ProjectTag = ({ projectName, clickHandler }) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Directly call the clickHandler with the project name
        clickHandler(projectName);
    };

    return (
        <div 
            className="project-tag" 
            onClick={handleClick}
            onMouseDown={(e) => {
                // Prevent blur and default behavior
                e.preventDefault();
                e.stopPropagation();
            }}
            title={`Click to select ${projectName}`}
        >
            <span className="project-tag-icon">#</span>
            <span className="project-tag-name">{projectName}</span>
        </div>
    );
};

export default ProjectTag;
