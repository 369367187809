import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSyncAlt, 
  faLightbulb, 
  faFire, 
  faUserGroup, 
  faTasks, 
  faExclamationTriangle,
  faCheckCircle,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';

const formatAiContent = (content) => {
  if (!content) return null;

  try {
    const expectedHeaders = [
      { header: "🔥 URGENT (Today's Priority):", name: 'URGENT', icon: faFire, className: 'urgent', previewCount: 3 },
      { header: "👥 FOLLOW-UPS NEEDED:", name: 'FOLLOW-UPS', icon: faUserGroup, className: 'followup', previewCount: 2 },
      { header: "📋 KEY TASKS:", name: 'KEY TASKS', icon: faTasks, className: 'tasks', previewCount: 2 },
      { header: "⚠️ WATCH LIST:", name: 'WATCH LIST', icon: faExclamationTriangle, className: 'watch', previewCount: 2 },
      { header: "💡 QUICK WINS:", name: 'QUICK WINS', icon: faCheckCircle, className: 'quick', previewCount: 2 }
    ];
    
    const sections = [];
    
    expectedHeaders.forEach((cat) => {
      const startIndex = content.indexOf(cat.header);
      if (startIndex !== -1) {
        let nextIndex = content.length;
        expectedHeaders.forEach(other => {
          if (other.header !== cat.header) {
            const idxOther = content.indexOf(other.header, startIndex + cat.header.length);
            if (idxOther !== -1 && idxOther < nextIndex) {
              nextIndex = idxOther;
            }
          }
        });
        
        const sectionText = content.substring(startIndex + cat.header.length, nextIndex).trim();
        // Clean up the lines by removing bullet points and extra whitespace
        const lines = sectionText
          .split(/\r?\n/)
          .map(line => line.trim())
          .map(line => line.replace(/^[•\-\*]\s*/, '')) // Remove bullet points
          .filter(Boolean);
        
        if (lines.length > 0) {
          sections.push({ 
            ...cat, 
            content: lines.slice(0, cat.previewCount), // Use category-specific preview count
            fullContent: lines,
            count: lines.length
          });
        }
      }
    });
    
    return sections.length > 0 ? sections : null;
  } catch (error) {
    console.error("Error formatting AI content:", error);
    return null;
  }
};

const AiInsightSection = ({ insights, isLoading, error, onRefresh, lastUpdated, className = '' }) => {
  const [formattedContent, setFormattedContent] = useState(null);
  const [expanded, setExpanded] = useState(true); // Initialize as true instead of false
  const [selectedInsight, setSelectedInsight] = useState(null);
  const compactViewRef = useRef(null);
  
  // Process content when insights change
  useEffect(() => {
    if (insights?.dailySummary) {
      const formatted = formatAiContent(insights.dailySummary);
      setFormattedContent(formatted);
    }
  }, [insights]);

  // Format the last updated time
  const getRefreshTime = () => {
    if (!lastUpdated) return null;
    
    const refreshTime = new Date(lastUpdated);
    const now = new Date();
    const diffMinutes = Math.floor((now - refreshTime) / (1000 * 60));
    
    if (diffMinutes < 1) return "just now";
    if (diffMinutes === 1) return "1 minute ago";
    if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
    
    const diffHours = Math.floor(diffMinutes / 60);
    if (diffHours === 1) return "1 hour ago";
    if (diffHours < 24) return `${diffHours} hours ago`;
    
    return refreshTime.toLocaleString();
  };

  const handleRefreshClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  };
  
  const handleToggleExpand = () => {
    setExpanded(!expanded);
    // Close selected insight view when expanding all
    if (!expanded) {
      setSelectedInsight(null);
    }
  };

  const handleChipClick = (e, insight) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Toggle selection if clicking the same chip again
    if (selectedInsight && selectedInsight.name === insight.name) {
      setSelectedInsight(null);
    } else {
      setSelectedInsight(insight);
      // Close expanded view if it's open
      if (expanded) {
        setExpanded(false);
      }
    }
  };

  // Parse error message for rate limits
  const getRateLimitMessage = (errorMessage) => {
    if (!errorMessage) return null;
    
    const rateLimitRegex = /Rate limit exceeded. Please try again in (\d+) seconds/;
    const match = errorMessage.match(rateLimitRegex);
    
    if (match && match[1]) {
      return {
        isRateLimit: true,
        seconds: parseInt(match[1]),
        message: `Rate limit reached. Try again in ${match[1]}s.`
      };
    }
    
    return {
      isRateLimit: false,
      message: errorMessage
    };
  };

  const rateLimitInfo = error ? getRateLimitMessage(error.message) : null;
  const errorMessage = error ? error.message : '';
  const needsRefresh = errorMessage?.includes('token') || 
                     errorMessage?.includes('authentication') || 
                     errorMessage?.includes('No authentication');

  // Header with title and buttons
  const renderHeader = () => (
    <div className="ai-header">
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faLightbulb} className="insight-highlight-icon" />
        <h4 className = "shiny-text">
          Daily Insights
          <small className="text-muted ms-2" style={{ fontSize: '0.7rem' }}>
            {lastUpdated && `· ${getRefreshTime()}`}
          </small>
        </h4>
      </div>
      <div className="d-flex align-items-center">
        <button 
          className="refresh-insights-btn me-1"
          onClick={handleRefreshClick}
          disabled={isLoading}
          title={isLoading ? "Generating..." : "Refresh insights"}
          style={{ width: 'auto', height: 'auto', padding: '3px' }}
        >
          <FontAwesomeIcon icon={faSyncAlt} spin={isLoading} size="xs" />
        </button>
        <button 
          className={`expand-toggle ${expanded ? 'expanded' : ''}`}
          onClick={handleToggleExpand}
        >
          {expanded ? 'Collapse' : 'Expand'}
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        </button>
      </div>
    </div>
  );

  // Compact view with scrollable chips - always visible
  const renderCompactView = () => {
    if (!formattedContent) {
      if (isLoading) {
        return (
          <div className="ai-loading">
            <div className="spinner-border spinner-border-sm text-primary me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <span style={{ fontSize: '0.75rem' }}>Generating summary...</span>
          </div>
        );
      } 
      
      if (error) {
        return (
          <div className="ai-placeholder">
            <span className="text-danger" style={{ fontSize: '0.75rem' }}>
              {rateLimitInfo?.message || 'Error generating insights'}
            </span>
            <button 
              onClick={handleRefreshClick} 
              className="btn btn-sm btn-outline-primary"
            >
              Retry
            </button>
          </div>
        );
      }
      
      return (
        <div className="ai-placeholder">
          <span style={{ fontSize: '0.75rem' }}>No insights available</span>
          <button 
            onClick={handleRefreshClick} 
            className="btn btn-sm btn-outline-primary"
          >
            Generate
          </button>
        </div>
      );
    }
    
    return (
      <div className="ai-insights-compact" ref={compactViewRef}>
        {formattedContent.map((insight, index) => (
          <div 
            key={index} 
            className={`ai-chip ${insight.className} ${selectedInsight && selectedInsight.name === insight.name ? 'active' : ''}`}
            onClick={(e) => handleChipClick(e, insight)}
          >
            <FontAwesomeIcon icon={insight.icon} className="ai-chip-icon" />
            <span>{insight.name}</span>
            <span className="ai-chip-count">{insight.count}</span>
          </div>
        ))}
      </div>
    );
  };

  // Render a single insight category in full view - showing ALL items
  const renderSingleInsightView = (insight) => {
    // Find the full data for this insight
    const fullInsight = formattedContent.find(item => item.name === insight.name);
    
    return (
      <div className="ai-category-detail" style={{
        height: 'auto', 
        overflow: 'visible',
        maxHeight: 'none'
      }}>
        <div className={`ai-category-content ${insight.className}`} style={{
          height: 'auto',
          overflow: 'visible'
        }}>
          <div className="ai-category-title">
            <FontAwesomeIcon icon={insight.icon} className="me-2" />
            {insight.name}
            <span className="count-badge">{fullInsight.fullContent.length} items</span>
          </div>
          <ul className="ai-bullet-list ai-bullet-list-large" style={{ 
            paddingLeft: '1.25rem',
            textAlign: 'left',
            height: 'auto',
            overflow: 'visible',
            marginBottom: '0'
          }}>
            {/* Use fullContent to show ALL items */}
            {fullInsight.fullContent.map((item, i) => (
              <li key={i} className="ai-bullet-item" style={{ 
                textAlign: 'left',
                display: 'block',
                wordBreak: 'break-word'
              }}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  // Detailed view that appears when expanded - with truncated content
  const renderDetailedView = () => {
    if (!formattedContent) return null;
    
    return (
      <div className={`ai-insights-detailed ${expanded ? 'expanded' : ''}`} style={{
        height: 'auto',
        overflow: 'visible',
        maxHeight: 'none'
      }}>
        <div className="ai-insights-grid" style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
          gap: '12px',
          height: 'auto',
          overflow: 'visible'
        }}>
          {formattedContent.map((section, index) => (
            <div key={index} className={`ai-category ${section.className}`} style={{
              padding: '8px 12px',
              height: 'auto',
              overflow: 'visible',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div className="ai-category-header" style={{marginBottom: '4px'}}>
                <span className="ai-category-icon">
                  <FontAwesomeIcon icon={section.icon} />
                </span>
                {section.name}
              </div>
              <ul className="ai-bullet-list" style={{ 
                paddingLeft: '1.25rem',
                textAlign: 'left',
                marginBottom: '0',
                height: 'auto',
                overflow: 'visible'
              }}>
                {/* Use the category-specific truncated content for the expanded view */}
                {section.content.map((item, i) => (
                  <li key={i} className="ai-bullet-item" style={{ 
                    textAlign: 'left',
                    display: 'block',
                    wordBreak: 'break-word'
                  }}>{item}</li>
                ))}
                {/* Show indicator if there are more items */}
                {section.count > section.content.length && (
                  <li className="ai-bullet-item more-items" style={{
                    display: 'block'
                  }}>
                    <span className="more-indicator" onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(false);
                      setSelectedInsight(section);
                    }}>
                      +{section.count - section.content.length} more...
                    </span>
                  </li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Main render for the component
  return (
    <div className={`ai-insight-section ${className}`} style={{ 
      height: 'auto', 
      overflow: 'visible',
      maxHeight: 'none',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {renderHeader()}
      {renderCompactView()}
      
      {selectedInsight ? (
        // Show selected insight content below the tabs
        renderSingleInsightView(selectedInsight)
      ) : (
        // Show expanded view if requested
        expanded && renderDetailedView()
      )}
    </div>
  );
};

export default AiInsightSection;

