import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import StakeholderMatrix from './StakeholderMatrix';
import './ComponentStyling/StakeholderRegister.css';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FaTrash } from "react-icons/fa"; // Import trash icon
import SlidingPane from "react-sliding-pane"; // Import SlidingPane
import "react-sliding-pane/dist/react-sliding-pane.css"; // Import SlidingPane CSS
import * as XLSX from 'xlsx-js-style';
import ReactGA from 'react-ga4';

const StakeholderRegister = () => {
    const { projectId } = useParams();
    const [stakeholders, setStakeholders] = useState([]);
    const [originalStakeholders, setOriginalStakeholders] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [stakeholderColumns, setStakeholderColumns] = useState([]);
    const [customStakeholderColumns, setCustomStakeholderColumns] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const [isPaneOpen, setIsPaneOpen] = useState(false); // State for sliding pane
    const [view, setView] = useState('register'); // State to toggle between register and matrix
    const [projectCode, setProjectCode] = useState("");
    const nextRowNumberRef = useRef(1);
    const [hasChanges, setHasChanges] = useState(false);
    const [pendingChanges, setPendingChanges] = useState({});

    const fetchStakeholders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
            const sortedData = response.data.sort((a, b) => a.id - b.id);
            setStakeholders(sortedData);
            setOriginalStakeholders(sortedData);
        } catch (e) {
            console.error('Error fetching stakeholders:', e);
        }
    };

    const fetchProjectSettings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
            if (response.status === 200) {
                setStakeholderColumns(response.data.stakeholderColumns || []); // Ensure stakeholderColumns is defined
                setCustomStakeholderColumns(response.data.customStakeholderColumns || []); // Ensure customStakeholderColumns is defined
            }
        } catch (error) {
            console.error('Error fetching project settings:', error);
        }
    };

    useEffect(() => {
        fetchStakeholders();
        fetchProjectSettings(); // Ensure project settings are fetched when the component mounts
    }, [projectId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProjectCode(response.data.project_code);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchProjectData();
    }, [projectId]);

    useEffect(() => {
        if (stakeholders.length > 0) {
            const maxId = Math.max(...stakeholders.map(item => {
                const parts = `${item.id}`.split('-');
                return Number(parts[1] || parts[0]);
            }));
            if (nextRowNumberRef.current <= maxId) {
                nextRowNumberRef.current = maxId + 1;
            }
        }
    }, [stakeholders]);

    useEffect(() => {
        const handleKeyDown = async (e) => {
            if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                e.preventDefault();
                await handleSaveChanges();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [stakeholders]);

    const handleEditChange = (index, e) => {
        const { name, value } = e.target;
        const updatedStakeholders = [...stakeholders];
        updatedStakeholders[index][name] = value;
        setStakeholders(updatedStakeholders);
        
        // Track that we have unsaved changes
        setHasChanges(true);
        setPendingChanges({
            ...pendingChanges,
            [index]: true
        });
    };

    const handleAddRow = () => {
        const newNumericId = nextRowNumberRef.current;
        nextRowNumberRef.current += 1;

        const newRow = [...stakeholderColumns, ...customStakeholderColumns].reduce((acc, column) => {
            acc[column.toLowerCase()] = '';
            return acc;
        }, { 
            id: `${projectCode}-${newNumericId}`,
            projectId: projectId.toString()
        });

        setStakeholders([...stakeholders, newRow]);
        setOriginalStakeholders([...stakeholders, newRow]);
    };

    const handleSaveChanges = async () => {
        if (!hasChanges) return;

        try {
            // Create a copy of stakeholders to work with
            const updatedStakeholders = [...stakeholders];

            // Save all stakeholders that have pending changes
            for (let index in pendingChanges) {
                const stakeholder = stakeholders[index];
                const originalStakeholder = originalStakeholders[index];
                const isNewItem = !stakeholder._id;

                const itemToSave = {
                    ...stakeholder,
                    projectId: projectId.toString()
                };

                if (isNewItem) {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/newItem`, itemToSave);
                    if (response.status === 201) {
                        // Update the stakeholder with the response data while preserving existing fields
                        updatedStakeholders[index] = {
                            ...stakeholder,
                            _id: response.data._id
                        };
                    }
                } else {
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/stakeholders/${stakeholder._id}`, itemToSave);
                    if (response.status === 200) {
                        // Merge the response data with existing stakeholder data
                        updatedStakeholders[index] = {
                            ...stakeholder,
                            ...response.data
                        };
                    }
                }
            }

            // Update state only once after all changes are processed
            setStakeholders(updatedStakeholders);
            setOriginalStakeholders(updatedStakeholders);
            setPendingChanges({});
            setHasChanges(false);

            // Show save indicator
            const indicator = document.createElement('div');
            indicator.className = 'save-indicator';
            indicator.textContent = 'Saved!';
            document.body.appendChild(indicator);
            
            setTimeout(() => {
                indicator.remove();
            }, 2000);

        } catch (error) {
            console.error('Error saving stakeholders:', error);
            // Show error indicator
            const indicator = document.createElement('div');
            indicator.className = 'save-indicator error';
            indicator.textContent = 'Error saving!';
            document.body.appendChild(indicator);
            
            setTimeout(() => {
                indicator.remove();
            }, 2000);
        }
    };

    const handleDelete = async (index) => {
        const stakeholderToDelete = stakeholders[index];

        if (!stakeholderToDelete._id) {
            const updatedStakeholders = stakeholders.filter((_, i) => i !== index);
            setStakeholders(updatedStakeholders);
            setOriginalStakeholders(updatedStakeholders);
            return;
        }

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/${stakeholderToDelete._id}`);
            if (response.status === 200) {
                const updatedStakeholders = stakeholders.filter((_, i) => i !== index);
                setStakeholders(updatedStakeholders);
                setOriginalStakeholders(updatedStakeholders);
                await fetchStakeholders();
            }
        } catch (error) {
            console.error('Error deleting stakeholder:', error);
        }
    };

    const saveProjectSettings = async (updatedColumns, updatedCustomColumns) => {
        const settings = {
            projectId: projectId.toString(),
            stakeholderColumns: updatedColumns,
            customStakeholderColumns: updatedCustomColumns
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, settings);
            console.log('Project settings saved');
        } catch (error) {
            console.error('Error saving project settings:', error);
        }
    };

    useEffect(() => {
        if (stakeholderColumns.length === 6) { // Check if all columns are selected
            saveProjectSettings(stakeholderColumns, customStakeholderColumns);
        }
    }, [stakeholderColumns, customStakeholderColumns]);

    const handleColumnChange = (column) => {
        setStakeholderColumns(prevColumns => {
            const updatedColumns = prevColumns.includes(column)
                ? prevColumns.filter(col => col !== column)
                : [...prevColumns, column];

            saveProjectSettings(updatedColumns, customStakeholderColumns);
            return updatedColumns;
        });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedColumns = Array.from(stakeholderColumns);
        const [removed] = reorderedColumns.splice(result.source.index, 1);
        reorderedColumns.splice(result.destination.index, 0, removed);

        setStakeholderColumns(reorderedColumns);
        saveProjectSettings(reorderedColumns, customStakeholderColumns); // Ensure the correct key is used here
    };

    const exportToExcel = () => {
        // Prepare the data with headers
        const headers = ['ID', ...stakeholderColumns];
        const dataToExport = stakeholders.map(stakeholder => {
            const row = { ID: stakeholder.id };
            stakeholderColumns.forEach(col => {
                row[col] = stakeholder[col.toLowerCase()] || '';
            });
            return row;
        });

        // Create worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport, {
            header: headers,
            origin: 'A2' // Start data from A2 to make room for headers
        });

        // Add headers manually for better formatting
        headers.forEach((header, idx) => {
            const cellRef = XLSX.utils.encode_cell({ r: 0, c: idx });
            if (!ws[cellRef]) ws[cellRef] = {};
            ws[cellRef].v = header;
            ws[cellRef].s = {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "0056B3" } },
                alignment: { horizontal: "center" }
            };
        });

        // Set column widths
        const colWidths = headers.map(header => ({ wch: Math.max(header.length * 1.5, 10) }));
        ws['!cols'] = colWidths;

        // Add table formatting
        const range = XLSX.utils.decode_range(ws['!ref']);
        const tableRange = {
            s: { r: 0, c: 0 },
            e: { r: range.e.r, c: range.e.c }
        };
        ws['!table'] = {
            ref: XLSX.utils.encode_range(tableRange),
            style: {
                theme: 'TableStyleMedium2',
                showFirstColumn: true,
                showLastColumn: false,
                showRowStripes: true,
                showColumnStripes: false
            }
        };

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Stakeholders");

        // Auto-size columns based on content
        const maxWidth = 50;
        const minWidth = 10;
        dataToExport.forEach(row => {
            headers.forEach((header, idx) => {
                const content = String(row[header] || '');
                colWidths[idx].wch = Math.min(
                    Math.max(
                        colWidths[idx].wch,
                        Math.min(content.length * 1.2, maxWidth),
                        minWidth
                    ),
                    maxWidth
                );
            });
        });

        // Save file
        XLSX.writeFile(wb, "stakeholder-register.xlsx", {
            bookType: 'xlsx',
            bookSST: false,
            type: 'binary',
            cellStyles: true
        });
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2 shiny-text">
                                {view === 'register' ? 'Stakeholder Register' : 'Stakeholder Matrix'}
                            </h1>
                            <div className="d-flex align-items-center">
                                {hasChanges && (
                                    <span className="save-prompt me-3">
                                        Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save
                                    </span>
                                )}
                                <button className="btn btn-primary stakeholder-btn-primary me-2" onClick={() => setView('register')}>Register</button>
                                <button className="btn btn-primary stakeholder-btn-primary" onClick={() => setView('matrix')}>Matrix</button>
                            </div>
                        </div>
                        {view === 'register' && (
                            <div className="d-flex justify-content-end mb-3">
                                <div className="dropdown me-2">
                                    <button 
                                        className="btn btn-primary dropdown-toggle" 
                                        onClick={() => setShowDropdown(!showDropdown)}
                                    >
                                        Export
                                    </button>
                                    {showDropdown && (
                                        <div className="dropdown-menu show">
                                            <button className="dropdown-item" onClick={exportToExcel}>
                                                Export as Excel
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <button 
                                    className="btn btn-primary stakeholder-btn-primary" 
                                    onClick={() => setIsPaneOpen(true)}
                                >
                                    Columns
                                </button>
                            </div>
                        )}
                        {view === 'register' ? (
                            stakeholders.length === 0 ? (
                                <p>No Stakeholders Found</p>
                            ) : (
                                <div className="stakeholder-register-table-responsive">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <table className="stakeholder-register-table">
                                            <thead>
                                                <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                                    {(provided) => (
                                                        <tr ref={provided.innerRef} {...provided.droppableProps}>
                                                            <th>ID</th>
                                                            {stakeholderColumns.map((col, index) => (
                                                                <Draggable key={col} draggableId={col} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <th
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={snapshot.isDragging ? 'dragging' : ''}
                                                                        >
                                                                            {col}
                                                                        </th>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                            <th>Delete</th> {/* Add header for the trash can icon */}
                                                        </tr>
                                                    )}
                                                </Droppable>
                                            </thead>
                                            <tbody>
                                                {stakeholders.map((stakeholder, index) => (
                                                    <tr key={index}>
                                                        <td>{stakeholder.id}</td>
                                                        {stakeholderColumns.map((col, colIndex) => (
                                                            <td key={colIndex}>
                                                                {col === 'Category' || col === 'Power' || col === 'Interest' ? (
                                                                    <select
                                                                        name={col.toLowerCase()}
                                                                        value={stakeholder[col.toLowerCase()] || ''}
                                                                        onChange={(e) => handleEditChange(index, e)}
                                                                    >
                                                                        <option value="">Select {col}</option>
                                                                        {col === 'Category' && (
                                                                            <>
                                                                                <option value="Internal">Internal</option>
                                                                                <option value="External">External</option>
                                                                            </>
                                                                        )}
                                                                        {col === 'Power' && (
                                                                            <>
                                                                                <option value="High">High</option>
                                                                                <option value="Low">Low</option>
                                                                            </>
                                                                        )}
                                                                        {col === 'Interest' && (
                                                                            <>
                                                                                <option value="High">High</option>
                                                                                <option value="Low">Low</option>
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                ) : (
                                                                    <input
                                                                        type="text"
                                                                        name={col.toLowerCase()}
                                                                        value={stakeholder[col.toLowerCase()] || ''}
                                                                        onChange={(e) => handleEditChange(index, e)}
                                                                    />
                                                                )}
                                                            </td>
                                                        ))}
                                                        <td>
                                                            <button className="btn btn-link text-danger" onClick={() => handleDelete(index)}>
                                                                <FaTrash />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </DragDropContext>
                                </div>
                            )
                        ) : (
                            <StakeholderMatrix />
                        )}
                        {view === 'register' && (
                            <button className="btn btn-success" type="button" onClick={handleAddRow}>Add Row</button>
                        )}
                    </main>
                </div>
            </div>
            <SlidingPane
                isOpen={isPaneOpen}
                title="Manage Columns"
                from="right"
                width="400px"
                overlayClassName="sliding-pane-overlay"
                className="sliding-pane"
                onRequestClose={() => setIsPaneOpen(false)}
            >
                <div className="stakeholder-register-pane-inner">
                    <div className="stakeholder-register-pane-content">
                        {['Name', 'Role', 'Email', 'Category', 'Power', 'Interest', ...customStakeholderColumns].map((col, index) => (
                            <div key={index} className="stakeholder-register-pane-item">
                                <input
                                    type="checkbox"
                                    id={col}
                                    checked={stakeholderColumns.includes(col)}
                                    onChange={() => handleColumnChange(col)}
                                />
                                <label htmlFor={col}>{col}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </SlidingPane>
        </div>
    );
};

export default StakeholderRegister;