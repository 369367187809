import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const DEPENDENCY_TYPES = {
    'blocks': 'Blocks/Blocked by',
    'start-to-start': 'Start to Start (SS)',
    'start-to-finish': 'Start to Finish (SF)',
    'finish-to-start': 'Finish to Start (FS)',
    'finish-to-finish': 'Finish to Finish (FF)'
};

const DependencyRow = ({ dependency, availableEpics, onUpdate, onRemove }) => (
    <div className="dependency-row mb-2 d-flex gap-2 align-items-start">
        <select
            className="form-select form-select-sm"
            value={dependency.epicId || ''}
            onChange={(e) => onUpdate({ ...dependency, epicId: e.target.value })}
            style={{ minWidth: '200px' }}
        >
            <option value="">Select Epic</option>
            {availableEpics.map(epic => (
                <option key={epic._id} value={epic._id}>
                    #{epic.epicNumber || epic._id?.substring(0, 6)} - {epic.title}
                </option>
            ))}
        </select>
        
        <select
            className="form-select form-select-sm"
            value={dependency.type || 'blocks'}
            onChange={(e) => onUpdate({ ...dependency, type: e.target.value })}
        >
            {Object.entries(DEPENDENCY_TYPES).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
            ))}
        </select>
        
        <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Description (optional)"
            value={dependency.description || ''}
            onChange={(e) => onUpdate({ ...dependency, description: e.target.value })}
        />
        
        <button
            type="button"
            className="btn btn-sm btn-outline-danger"
            onClick={onRemove}
        >
            <FontAwesomeIcon icon={faTrash} />
        </button>
    </div>
);

const CreateEpicSlider = ({ 
    showSlider, 
    sliderClosing, 
    closeSlider, 
    currentEpic,
    handleInputChange,
    handleSubmit,
    availableTeamMembers,
    availableEpics 
}) => {
    const handleBusinessValueChange = (value) => {
        // Ensure value is a number and within range
        const numValue = parseInt(value) || 0;
        const clampedValue = Math.max(0, Math.min(100, numValue));
        handleInputChange('businessValue', clampedValue);
    };

    const getBusinessValueLabel = (value) => {
        if (value >= 80) return 'Critical';
        if (value >= 60) return 'High';
        if (value >= 40) return 'Medium';
        if (value >= 20) return 'Low';
        return 'Minimal';
    };

    const getBusinessValueColor = (value) => {
        if (value >= 80) return '#dc3545';
        if (value >= 60) return '#fd7e14';
        if (value >= 40) return '#ffc107';
        if (value >= 20) return '#0dcaf0';
        return '#6c757d';
    };

    // Replace the business value range input with a dropdown
    const businessValueOptions = [
        { value: 'Critical', score: 100 },
        { value: 'High', score: 80 },
        { value: 'Medium', score: 60 },
        { value: 'Low', score: 40 },
        { value: 'Minimal', score: 20 }
    ];

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Create New Epic</h2>
                </div>
                
                <div className="sliding-panel-content">
                    <div className="epic-form">
                        {/* Basic Information Section */}
                        <div className="form-section">
                            <h3 className="section-title">Basic Information</h3>
                            <div className="mb-3">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={currentEpic.title || ''}
                                    onChange={(e) => handleInputChange('title', e.target.value)}
                                    required
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    value={currentEpic.description || ''}
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                    rows="3"
                                    required
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Priority</label>
                                    <select
                                        className="form-select"
                                        value={currentEpic.priority || 'Medium'}
                                        onChange={(e) => handleInputChange('priority', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Business Value</label>
                                    <select
                                        className="form-select"
                                        value={currentEpic.businessValue || 'Medium'}
                                        onChange={(e) => handleInputChange('businessValue', e.target.value)}
                                    >
                                        {businessValueOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Planning Section */}
                        <div className="form-section">
                            <h3 className="section-title">Planning</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={currentEpic.startDate || ''}
                                        onChange={(e) => handleInputChange('startDate', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Target Completion</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={currentEpic.targetDate || ''}
                                        onChange={(e) => handleInputChange('targetDate', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <label className="form-label">Story Points</label>
                                    <select
                                        className="form-select"
                                        value={currentEpic.storyPoints || ''}
                                        onChange={(e) => handleInputChange('storyPoints', e.target.value)}
                                    >
                                        <option value="">Select Points</option>
                                        {[1, 2, 3, 5, 8, 13, 21].map(points => (
                                            <option key={points} value={points}>{points}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        value={currentEpic.status || 'Not Started'}
                                        onChange={(e) => handleInputChange('status', e.target.value)}
                                    >
                                        <option value="Not Started">Not Started</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Complete">Complete</option>
                                        <option value="Backlogged">Backlogged</option>
                                        <option value="Blocked">Blocked</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Team and Tags Section */}
                        <div className="form-section">
                            <h3 className="section-title">Team & Categorization</h3>
                            <div className="mb-3">
                                <label className="form-label">Team Members</label>
                                <select
                                    className="form-select"
                                    multiple
                                    value={currentEpic.team || []}
                                    onChange={(e) => handleInputChange('team', 
                                        Array.from(e.target.selectedOptions, option => option.value)
                                    )}
                                >
                                    {availableTeamMembers.map(member => (
                                        <option key={member.id} value={member.id}>
                                            {member.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Tags</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={currentEpic.tags || ''}
                                    onChange={(e) => handleInputChange('tags', e.target.value)}
                                    placeholder="Comma-separated tags"
                                />
                                <small className="text-muted">Separate tags with commas</small>
                            </div>
                        </div>

                        {/* Dependencies and Technical Section */}
                        <div className="form-section">
                            <h3 className="section-title d-flex justify-content-between align-items-center">
                                Dependencies
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        const newDependencies = [
                                            ...(currentEpic.dependencies || []),
                                            { epicId: '', type: 'blocks', description: '' }
                                        ];
                                        handleInputChange('dependencies', newDependencies);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Dependency
                                </button>
                            </h3>
                            
                            <div className="dependencies-list">
                                {currentEpic.dependencies?.length > 0 ? (
                                    currentEpic.dependencies.map((dep, index) => (
                                        <DependencyRow
                                            key={index}
                                            dependency={dep}
                                            availableEpics={availableEpics}
                                            onUpdate={(updatedDep) => {
                                                const newDependencies = [...currentEpic.dependencies];
                                                newDependencies[index] = updatedDep;
                                                handleInputChange('dependencies', newDependencies);
                                            }}
                                            onRemove={() => {
                                                const newDependencies = currentEpic.dependencies.filter((_, i) => i !== index);
                                                handleInputChange('dependencies', newDependencies);
                                            }}
                                        />
                                    ))
                                ) : (
                                    <p className="text-muted text-center py-3">
                                        No dependencies defined. Add dependencies if this epic depends on others.
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="form-section">
                            <h3 className="section-title">Technical</h3>
                            <div className="mb-3">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="featureFlag"
                                        checked={currentEpic.requiresFeatureFlag || false}
                                        onChange={(e) => handleInputChange('requiresFeatureFlag', e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="featureFlag">
                                        Requires Feature Flag
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Success Criteria Section */}
                        <div className="form-section">
                            <h3 className="section-title d-flex justify-content-between align-items-center">
                                Success Criteria
                                <button 
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        const newCriteria = [
                                            ...(currentEpic.successCriteria || []), 
                                            { text: '', completed: false }
                                        ];
                                        handleInputChange('successCriteria', newCriteria);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Criterion
                                </button>
                            </h3>
                            <div className="success-criteria-list">
                                {currentEpic.successCriteria?.length > 0 ? (
                                    currentEpic.successCriteria.map((criterion, index) => (
                                        <div 
                                            key={index} 
                                            className={`success-criterion-item ${criterion.completed ? 'completed' : ''}`}
                                        >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={criterion.completed}
                                                onChange={(e) => {
                                                    const newCriteria = [...currentEpic.successCriteria];
                                                    newCriteria[index] = {
                                                        ...criterion,
                                                        completed: e.target.checked
                                                    };
                                                    handleInputChange('successCriteria', newCriteria);
                                                }}
                                                id={`new-criterion-${index}`}
                                            />
                                            
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={criterion.text}
                                                onChange={(e) => {
                                                    const newCriteria = [...currentEpic.successCriteria];
                                                    newCriteria[index] = {
                                                        ...criterion,
                                                        text: e.target.value
                                                    };
                                                    handleInputChange('successCriteria', newCriteria);
                                                }}
                                                placeholder="Enter success criterion..."
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger btn-remove"
                                                onClick={() => {
                                                    const newCriteria = currentEpic.successCriteria.filter((_, i) => i !== index);
                                                    handleInputChange('successCriteria', newCriteria);
                                                }}
                                                title="Remove criterion"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-muted text-center py-3">
                                        No success criteria defined. Add some criteria to track epic completion.
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="epic-actions mt-4">
                            <button 
                                className="btn btn-success"
                                onClick={handleSubmit}
                            >
                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                Create Epic
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateEpicSlider;
