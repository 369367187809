import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { getStatusIcon, getInitials, calculateCompletionPercentage } from '../utils/epicUtils';

const EpicCard = ({ epic, onClick, stories = [] }) => {
    // Calculate completion percentage using associated stories
    const completionPercentage = calculateCompletionPercentage(epic, stories);
    
    // Count stories associated with this epic
    const epicStoriesCount = stories.filter(story => story.epicId === epic._id).length;
    
    return (
        <div 
            className="epic-card-item"
            onClick={() => onClick(epic)}
        >
            <div className={`epic-priority-indicator priority-${epic.priority?.toLowerCase()}`}></div>
            <div className="epic-card-header">
                <span className="epic-card-number">#{epic.epicNumber || epic._id?.substring(0, 6)}</span>
                <span className={`badge bg-${epic.priority?.toLowerCase()}`}>
                    {epic.priority}
                </span>
            </div>
            <h4 className="epic-card-title">{epic.title}</h4>
            <div className="epic-card-footer">
                <div className="epic-meta">
                    <div className="epic-card-status">
                        <span className="epic-card-status-icon">
                            {getStatusIcon(epic.status)}
                        </span>
                        <span className="epic-card-status-text">{epic.status}</span>
                    </div>

                    <div className="epic-progress">
                        <div className="progress" style={{ width: '60px', height: '8px' }}>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ 
                                    width: `${completionPercentage}%`,
                                    backgroundColor: epic.status === 'Blocked' ? '#dc3545' : '#0d6efd'
                                }}
                                aria-valuenow={completionPercentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            />
                        </div>
                        <span className="progress-text ms-1">
                            {completionPercentage}%
                        </span>
                    </div>
                </div>
                
                <div className="epic-meta-info">
                    {epic.assignee ? (
                        <div className="epic-card-assignee">
                            <div className="epic-card-assignee-avatar">
                                {getInitials(epic.assignee)}
                            </div>
                            <span>{epic.assignee}</span>
                        </div>
                    ) : (
                        <div className="epic-card-assignee">
                            <FontAwesomeIcon icon={faUser} className="text-muted" />
                            <span className="text-muted">Unassigned</span>
                        </div>
                    )}
                    
                    {epicStoriesCount > 0 && (
                        <div className="epic-stories-count">
                            <span className="badge bg-info text-dark">{epicStoriesCount} stories</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EpicCard;
