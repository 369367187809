import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card, Nav, Badge, Form, Button, Pagination, Carousel } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; 
import Navbar from './Navbar';
import Footer from './Footer';
import './ComponentStyling/Wiki.css';
import { 
    FaBook, FaExclamationTriangle, FaUsers, FaColumns, FaCalendar, 
    FaComments, FaClock, FaSitemap, FaSearch, FaChevronRight, FaTools,
    FaClipboardList, FaFileAlt, FaChalkboardTeacher, FaChartBar, FaPlus,
    FaStar, FaEye, FaUser, FaTags, FaGlobe, FaSync, FaStream, FaPlayCircle, 
    FaChartLine, FaFlagCheckered, FaCrown, FaUserFriends, FaDollarSign, 
    FaCheck, FaShoppingCart, FaShieldAlt, FaBuilding, FaGraduationCap, 
    FaCertificate, FaBookOpen, FaEllipsisH 
} from 'react-icons/fa';
import wikiContent from '../WikiContent'; // Still importing for structure
import { useAuth } from './AuthContext';
import axiosInstance from '../axiosConfig';

const Wiki = () => {
    const { user } = useAuth();
    // Add articles state
    const [articles, setArticles] = useState([]);
    // State management
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    
    // Define categories
    const categories = [
        { id: 'all', name: 'All Topics', icon: <FaBook /> },
        { id: 'general', name: 'General Knowledge', icon: <FaGlobe /> },
        { id: 'basics', name: 'PM Basics', icon: <FaChalkboardTeacher /> },
        { id: 'methodologies', name: 'Methodologies', icon: <FaSitemap /> },
        { id: 'agile', name: 'Agile & Scrum', icon: <FaSync /> },
        { id: 'waterfall', name: 'Waterfall', icon: <FaStream /> },
        { id: 'tools', name: 'PM Tools', icon: <FaTools /> },
        { id: 'templates', name: 'Templates', icon: <FaFileAlt /> },
        { id: 'planning', name: 'Project Planning', icon: <FaClipboardList /> },
        { id: 'execution', name: 'Project Execution', icon: <FaPlayCircle /> },
        { id: 'monitoring', name: 'Monitoring & Control', icon: <FaChartLine /> },
        { id: 'closure', name: 'Project Closure', icon: <FaFlagCheckered /> },
        { id: 'risk', name: 'Risk Management', icon: <FaExclamationTriangle /> },
        { id: 'stakeholder', name: 'Stakeholder Management', icon: <FaUsers /> },
        { id: 'communication', name: 'Communication', icon: <FaComments /> },
        { id: 'leadership', name: 'Leadership', icon: <FaCrown /> },
        { id: 'team', name: 'Team Management', icon: <FaUserFriends /> },
        { id: 'metrics', name: 'Metrics & KPIs', icon: <FaChartBar /> },
        { id: 'budget', name: 'Budget & Cost', icon: <FaDollarSign /> },
        { id: 'quality', name: 'Quality Management', icon: <FaCheck /> },
        { id: 'procurement', name: 'Procurement', icon: <FaShoppingCart /> },
        { id: 'compliance', name: 'Compliance & Governance', icon: <FaShieldAlt /> },
        { id: 'pmo', name: 'PMO', icon: <FaBuilding /> },
        { id: 'career', name: 'Career Development', icon: <FaGraduationCap /> },
        { id: 'certifications', name: 'Certifications', icon: <FaCertificate /> },
        { id: 'best-practices', name: 'Best Practices', icon: <FaStar /> },
        { id: 'case-studies', name: 'Case Studies', icon: <FaBookOpen /> },
        { id: 'other', name: 'Other', icon: <FaEllipsisH /> }
    ];

    // Icons for pages
    const pageIcons = {
        'Welcome to PMHub Wiki': <FaBook />,
        'RAID Log Management': <FaExclamationTriangle />,
        'Stakeholder Management': <FaUsers />,
        'Kanban Board': <FaColumns />,
        'Daily Journal': <FaCalendar />,
        'Meeting Management': <FaComments />,
        'Project Scheduler': <FaClock />,
        'Work Breakdown Structure (WBS)': <FaSitemap />
    };

    useEffect(() => {
        document.title = 'PMHub - Wiki';
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/wiki/articles');
            setArticles(response.data);
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    // Add this helper function to search through arrays
    const searchInArray = (array, searchTerm) => {
        return array?.some(item => 
            item.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // Add this helper function to search through sections
    const searchInSections = (sections, searchTerm) => {
        return sections?.some(section => 
            section.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            section.content?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // Enhanced filter function
    const filteredArticles = articles.filter(article => {
        const searchTermLower = searchTerm.toLowerCase();
        const matchesCategory = selectedCategory === 'all' || article.category === selectedCategory;
        
        if (!matchesCategory) return false;
        
        if (searchTerm) {
            return (
                // Search in title
                article.title?.toLowerCase().includes(searchTermLower) ||
                // Search in content
                article.content?.toLowerCase().includes(searchTermLower) ||
                // Search in category
                article.category?.toLowerCase().includes(searchTermLower) ||
                // Search in tags
                searchInArray(article.tags, searchTermLower) ||
                // Search in sections
                searchInSections(article.sections, searchTermLower) ||
                // Search in author
                article.author?.toLowerCase().includes(searchTermLower)
            );
        }
        
        return true;
    });

    // Update search results message
    const getSearchResultsMessage = () => {
        if (!searchTerm) return '';
        const count = filteredArticles.length;
        return `Found ${count} ${count === 1 ? 'result' : 'results'} for "${searchTerm}"`;
    };

    // Real-time search as user types
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setIsSearching(value.length > 0);
    };

    // Clear search results
    const clearSearch = () => {
        setSearchTerm('');
        setIsSearching(false);
    };

    // Add this function to count articles in each category
    const getCategoryCount = (categoryId) => {
        if (categoryId === 'all') {
            return articles.length;
        }
        return articles.filter(article => article.category === categoryId).length;
    };

    const getArticleExcerpt = (content, maxLength = 150) => {
        const plainText = content.replace(/[#*`]/g, ''); // Remove markdown characters
        return plainText.length > maxLength 
            ? `${plainText.substring(0, maxLength)}...` 
            : plainText;
    };

    // Replace the empty display with articles list
    return (
        <>
            <Navbar />
            <div className="pmhub-wiki-container">
                <Container fluid className="py-4">
                    <div className="pmhub-wiki-welcome mb-4">
                        <h2>PMHub Knowledge Base</h2>
                        <p>Your comprehensive guide to project management excellence - explore hundreds of topics, templates, tools, and best practices.</p>
                        {user?.isAdmin && (
                            <Button 
                                variant="light" 
                                className="mt-3"
                                onClick={() => navigate('/wiki/new')}
                            >
                                <FaPlus className="me-2" /> Create New Article
                            </Button>
                        )}
                    </div>

                    <Row className="mb-4">
                        <Col>
                            <Form className="pmhub-wiki-search" onSubmit={(e) => e.preventDefault()}>
                                <div className="search-input-container">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search articles, content, tags, and more..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    {isSearching && (
                                        <Button 
                                            variant="outline-secondary" 
                                            onClick={clearSearch}
                                        >
                                            Clear
                                        </Button>
                                    )}
                                </div>
                                {isSearching && (
                                    <div className="search-result-stats mt-2">
                                        {getSearchResultsMessage()}
                                    </div>
                                )}
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={3}>
                            <div className="h-100">
                                <Card className="pmhub-wiki-sidebar mb-4">
                                    <Card.Header className="pmhub-wiki-header">
                                        Categories
                                    </Card.Header>
                                    <ListGroup variant="flush">
                                        {categories.map(category => (
                                            <ListGroup.Item 
                                                key={category.id}
                                                action 
                                                active={selectedCategory === category.id}
                                                onClick={() => {
                                                    setSelectedCategory(category.id);
                                                    setCurrentPage(1);
                                                }}
                                                className="pmhub-wiki-category-item"
                                            >
                                                <span className="pmhub-wiki-icon">{category.icon}</span>
                                                {category.name}
                                                <Badge bg="secondary" pill className="ms-2">
                                                    {getCategoryCount(category.id)}
                                                </Badge>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card>
                            </div>
                        </Col>
                        
                        <Col md={9}>
                            {isLoading ? (
                                <div className="text-center p-5">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p className="mt-3">Loading wiki content...</p>
                                </div>
                            ) : filteredArticles.length > 0 ? (
                                <div className="wiki-articles-grid">
                                    {filteredArticles.map(article => (
                                        <Card 
                                            key={article._id} 
                                            className="wiki-article-card h-100 shadow-sm hover-effect"
                                            onClick={() => navigate(`/wiki/${article.slug}`)}
                                        >
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-start mb-2">
                                                    <Badge 
                                                        bg="primary" 
                                                        className="category-badge"
                                                    >
                                                        {categories.find(c => c.id === article.category)?.icon} {article.category}
                                                    </Badge>
                                                    {article.popular && (
                                                        <Badge bg="warning" className="popular-badge">
                                                            <FaStar /> Popular
                                                        </Badge>
                                                    )}
                                                </div>

                                                <h3 className="article-title mb-3">{article.title}</h3>
                                                
                                                <p className="article-excerpt text-muted">
                                                    {getArticleExcerpt(article.content)}
                                                </p>

                                                <div className="article-metadata mt-3">
                                                    <div className="metadata-item">
                                                        <FaClock className="icon" />
                                                        <small>
                                                            {new Date(article.lastUpdated).toLocaleDateString()}
                                                        </small>
                                                    </div>
                                                    {article.author && (
                                                        <div className="metadata-item">
                                                            <FaUser className="icon" />
                                                            <small>{article.author}</small>
                                                        </div>
                                                    )}
                                                </div>

                                                {article.tags?.length > 0 && (
                                                    <div className="article-tags mt-3">
                                                        <FaTags className="tags-icon me-2" />
                                                        {article.tags.map(tag => (
                                                            <Badge 
                                                                key={tag} 
                                                                bg="secondary" 
                                                                className="me-1 tag-badge"
                                                            >
                                                                {tag}
                                                            </Badge>
                                                        ))}
                                                    </div>
                                                )}
                                            </Card.Body>
                                            <div className="card-hover-overlay">
                                                <FaEye /> Read More
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            ) : (
                                <Card className="p-5 text-center">
                                    <h3>No Articles Found</h3>
                                    <p className="mb-4">
                                        {searchTerm ? 'No articles match your search criteria.' : 'No articles available in this category yet.'}
                                    </p>
                                    {user?.isAdmin && (
                                        <div className="d-flex justify-content-center">
                                            <Button 
                                                variant="primary" 
                                                onClick={() => navigate('/wiki/new')}
                                            >
                                                <FaPlus className="me-2" /> Create First Article
                                            </Button>
                                        </div>
                                    )}
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Wiki;
