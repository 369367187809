import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavbarAuth from '../Components/Navbar_Authenticated';
import { useAuth } from '../Components/AuthContext.js';
import { Chart, ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import './PageCSS/Dashboard.css';
import Footer from "../Components/Footer";
import { fetchProjects, setActiveProject } from '../redux/slices/projectsSlice';
import { fetchRaidLogs, filterAndSortTasks } from '../redux/slices/tasksSlice';
import { fetchUserProfile } from '../redux/slices/profileSlice';
import { fetchDailyJournalTasks } from '../redux/slices/journalSlice';
import { fetchJournalAnalytics } from '../redux/slices/analyticsSlice';
import { validateUserId, sanitizeDataArray } from '../utils/securityUtils';

// Import Dashboard Components
import ProjectsCard from '../Components/Dashboard/ProjectsCard';
import RaidLogStatusCard from '../Components/Dashboard/RaidLogStatusCard';
import ProductivityCard from '../Components/Dashboard/ProductivityCard';
import OverdueTasksCard from '../Components/Dashboard/OverdueTasksCard';
import TodaysTasksCard from '../Components/Dashboard/TodaysTasksCard';
import DetailedOverdueTasksCard from '../Components/Dashboard/DetailedOverdueTasksCard';
import UpcomingTasksCard from '../Components/Dashboard/UpcomingTasksCard';
import DashboardHeader from '../Components/Dashboard/DashboardHeader';
import CalendarDisplayCard from '../Components/Dashboard/CalendarDisplayCard';
import DailySummaryCard from '../Components/Dashboard/DailySummaryCard';

// Register Chart components
Chart.register(
  ArcElement, 
  Tooltip, 
  Legend, 
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

function Dashboard() {
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useAuth();
    
    // Redux selectors
    const projects = useSelector(state => state.projects.data);
    const activeProject = useSelector(state => state.projects.activeProject);
    
    const raidLogs = useSelector(state => state.tasks.raidLogs);
    const raidLogStats = useSelector(state => state.tasks.raidLogStats);
    
    const userProfile = useSelector(state => state.profile.data);
    
    const dailyJournalTasks = useSelector(state => state.journal.taskCounts);
    
    const analyticsData = useSelector(state => state.analytics.data);
    
    // Add these to get all required data from Redux
    const journalTasks = useSelector(state => state.journal.tasks);
    const dailyJournalState = useSelector(state => state.dailyJournal);
    
    // Local state for filtered tasks
    const [upcomingTasks, setUpcomingTasks] = useState([]);
    const [overdueTasks, setOverdueTasks] = useState([]);
    const [securityErrors, setSecurityErrors] = useState([]);

    useEffect(() => {
        if (isAuthenticated && user && user.user_id) {
            // Validate user_id before using it
            if (validateUserId(user.user_id)) {
                console.log('Fetching data for user:', user.user_id);
                dispatch(fetchProjects(user.user_id));
                dispatch(fetchUserProfile(user.user_id));
                dispatch(fetchDailyJournalTasks(user.user_id));
                dispatch(fetchJournalAnalytics(user.user_id));
                setSecurityErrors([]);
            } else {
                setSecurityErrors(prevErrors => [
                    ...prevErrors, 
                    'Invalid user ID detected. Authentication may be compromised.'
                ]);
                console.error('Security warning: Invalid userId format detected');
            }
        }
    }, [isAuthenticated, user, dispatch]);

    // Fetch raid logs when projects are loaded
    useEffect(() => {
        if (projects.length > 0) {
            // Sanitize project IDs before using them
            const projectIds = projects
                .map(project => project._id)
                .filter(id => typeof id === 'string' && id.trim() !== '');
            
            if (projectIds.length > 0) {
                dispatch(fetchRaidLogs(projectIds));
            }
        }
    }, [projects, dispatch]);

    // Filter tasks when raidLogs or projects change
    useEffect(() => {
        if (raidLogs.length > 0 && projects.length > 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            // Sanitize data before using
            const sanitizedRaidLogs = sanitizeDataArray(raidLogs, ['title', 'description', 'status']);
            const sanitizedProjects = sanitizeDataArray(projects, ['name', 'description']);
            
            // Filter and sort tasks from Redux
            setUpcomingTasks(filterAndSortTasks(sanitizedRaidLogs, sanitizedProjects, today, 7));
            setOverdueTasks(filterAndSortTasks(sanitizedRaidLogs, sanitizedProjects, today, 0, true));
        }
    }, [raidLogs, projects]);

    const handleProjectClick = (projectId) => {
        // Validate projectId before dispatching
        if (typeof projectId === 'string' && projectId.trim() !== '') {
            dispatch(setActiveProject(projectId));
        } else {
            console.error('Invalid project ID provided');
        }
    };

    // Combine and preprocess the journal tasks for better data integrity
    const processedDailyJournalTasks = React.useMemo(() => {
        // Start with tasks from the redux store
        const tasks = [...(journalTasks || [])];
        
        // Add any tasks from the dailyJournal state if they don't already exist
        if (dailyJournalState?.tasks) {
            const existingIds = new Set(tasks.map(t => t._id));
            
            // Add todo tasks
            if (Array.isArray(dailyJournalState.tasks.todo)) {
                dailyJournalState.tasks.todo
                    .filter(t => t._id && !existingIds.has(t._id))
                    .forEach(t => {
                        tasks.push({...t, status: 'todo'});
                        existingIds.add(t._id);
                    });
            }
            
            // Add in-progress tasks
            if (Array.isArray(dailyJournalState.tasks.inProgress)) {
                dailyJournalState.tasks.inProgress
                    .filter(t => t._id && !existingIds.has(t._id))
                    .forEach(t => {
                        tasks.push({...t, status: 'inProgress'});
                        existingIds.add(t._id);
                    });
            }
            
            // Add completed tasks
            if (Array.isArray(dailyJournalState.tasks.complete)) {
                dailyJournalState.tasks.complete
                    .filter(t => t._id && !existingIds.has(t._id))
                    .forEach(t => {
                        tasks.push({...t, status: 'complete'});
                        existingIds.add(t._id);
                    });
            }
        }
        
        // Sanitize all tasks before returning
        const sanitizedTasks = sanitizeDataArray(tasks, ['title', 'description', 'content']);
        console.log(`Processed ${sanitizedTasks.length} daily journal tasks`);
        return sanitizedTasks;
    }, [journalTasks, dailyJournalState]);
    
    // Get validated userId safely
    const getValidatedUserId = () => {
        if (user?.user_id && validateUserId(user.user_id)) {
            return user.user_id;
        }
        return null;
    };

    return (
        <div className="App">
            <NavbarAuth />
            <div className="dashboard-container">
                {securityErrors.length > 0 && (
                    <div className="alert alert-danger security-alert" role="alert">
                        <strong>Security Warning:</strong>
                        <ul>
                            {securityErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {/* Dashboard Header */}
                <DashboardHeader 
                    userProfile={userProfile} 
                    userId={getValidatedUserId()} 
                />
                
                {/* Main Dashboard Grid */}
                <div className="dashboard-grid">
                    {/* Row 1 */}
                    <div className="projects-card">
                        <ProjectsCard 
                            projects={projects}
                            activeProject={activeProject}
                            handleProjectClick={handleProjectClick}
                            raidLogs={raidLogs}
                        />
                    </div>
                    
                    <div className="daily-summary-card">
                        <DailySummaryCard 
                            raidLogStats={raidLogStats}
                            analyticsData={analyticsData}
                            projects={projects}
                            overdueTasks={overdueTasks}
                            upcomingTasks={upcomingTasks}
                            raidLogs={raidLogs}
                            dailyJournalTasks={processedDailyJournalTasks}
                        />
                    </div>
                    
                    {/* Row 2 */}
                    <RaidLogStatusCard raidLogStats={raidLogStats} />
                    
                    <ProductivityCard analyticsData={analyticsData} />
                    
                    <TodaysTasksCard 
                        userId={getValidatedUserId()}
                    />
                    
                    {/* Row with side-by-side cards */}
                    <div className="cards-row">
                        <div>
                            <DetailedOverdueTasksCard overdueTasks={overdueTasks} />
                        </div>
                        <div>
                            <UpcomingTasksCard upcomingTasks={upcomingTasks} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Dashboard;