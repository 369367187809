import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Alert, Tab, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import wikiContent from '../WikiContent';
import Navbar from './Navbar';
import Footer from './Footer';
import axios from 'axios';
import axiosInstance from '../axiosConfig';
import ReactMarkdown from 'react-markdown';
import { 
    FaBold, FaItalic, FaListUl, FaListOl, FaLink, FaImage, 
    FaHeading, FaCode, FaQuoteRight, FaTable, FaSave,
    FaCheckSquare, FaExclamationTriangle, FaInfoCircle, FaTimes
} from 'react-icons/fa';
import ImageEditor from './ImageEditor';
import rehypeRaw from 'rehype-raw';

const WikiEditor = () => {
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [article, setArticle] = useState({
        id: '',
        title: '',
        slug: '',
        content: '',
        category: 'basics',
        tags: [],
        sections: [], // Make sure sections is initialized as an array
        lastUpdated: new Date().toISOString(),
        author: user?.email,
        published: false
    });
    const [previewMode, setPreviewMode] = useState(false);
    const [tagInput, setTagInput] = useState(article.tags ? article.tags.join(', ') : '');
    const [uploadingImage, setUploadingImage] = useState(false);
    const [autoSaveMessage, setAutoSaveMessage] = useState('');
    const [lastSaved, setLastSaved] = useState(null);
    const [imageInputKey, setImageInputKey] = useState(Date.now()); // Add this line
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const sectionTemplates = {
        overview: {
            title: "Overview",
            content: "## Overview\n\nBrief introduction to the topic.\n\n### Key Points\n- Point 1\n- Point 2\n- Point 3"
        },
        procedure: {
            title: "Procedure",
            content: "## Procedure\n\n1. First step\n2. Second step\n3. Third step\n\n### Notes\n> Important considerations..."
        },
        warning: {
            title: "Important Considerations",
            content: "⚠️ **Warning**\n\nCritical information and warnings about this process.\n\n- Risk 1\n- Risk 2"
        }
    };

    useEffect(() => {
        // More explicit admin check
        if (!user || user.isAdmin !== true) {
            console.log('Access denied - User:', user);
            setError('Only administrators can access the wiki editor');
            navigate('/wiki');
            return;
        }

        // If editing existing article, fetch it
        if (slug) {
            fetchArticle(slug);
        } else {
            setIsLoading(false);
        }
    }, [slug, user]);

    const fetchArticle = async (slug) => {
        try {
            const response = await axiosInstance.get(`/api/wiki/articles/${slug}`);
            setArticle({
                ...response.data,
                id: response.data._id // Make sure to set the id from _id
            });
        } catch (apiError) {
            console.error('API fetch failed:', apiError);
            setError('Article not found');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaveSuccess(false);
        setError(null);
        
        if (!user || user.isAdmin !== true) {
            setError('Only administrators can create or edit wiki articles');
            return;
        }

        try {
            const articleSlug = article.slug || generateSlug(article.title);
            
            // Create a clean article object without any DOM references
            const articleData = {
                id: article._id || article.id,
                title: article.title,
                slug: articleSlug,
                content: article.content,
                category: article.category,
                tags: article.tags || [],
                sections: article.sections.map(section => ({
                    title: section.title || '',
                    content: section.content || '',
                    order: section.order
                })),
                lastUpdated: new Date().toISOString(),
                author: user.email,
                published: article.published,
                popular: article.popular
            };

            setIsLoading(true);
            
            const response = await axiosInstance.post('/api/wiki/save', articleData, {
                withCredentials: true
            });
            
            setSaveSuccess(true);
            setIsLoading(false);
            
            setTimeout(() => navigate(`/wiki/${articleSlug}`), 2000);
        } catch (err) {
            console.error('Error saving article:', err);
            setError('Error saving article: ' + (err.response?.data?.message || err.message));
            setIsLoading(false);
        }
    };

    const generateSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/(^-|-$)+/g, '');
    };

    const handleAddSection = (template) => {
        const newSection = template || {
            title: '',
            content: '',
            order: article.sections.length // Add order for proper sequencing
        };
        setArticle(prev => ({
            ...prev,
            sections: [...(prev.sections || []), newSection]
        }));
    };

    // Auto-generate slug when title changes
    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setArticle({
            ...article,
            title: newTitle,
            slug: generateSlug(newTitle)
        });
    };

    const togglePreview = () => {
        setPreviewMode(!previewMode);
    };

    const handleImageClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (!file) return;

            const formData = new FormData();
            formData.append('image', file);

            setUploadingImage(true);
            try {
                const response = await axiosInstance.post('/api/wiki/upload-image', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setCurrentImageUrl(response.data.url);
                setShowImageEditor(true);
            } catch (error) {
                console.error('Error uploading image:', error);
                setError('Error uploading image: ' + (error.response?.data?.message || error.message));
            } finally {
                setUploadingImage(false);
            }
        };
        input.click();
    };

    const handleImageInsert = (imageMarkdown) => {
        const textarea = document.getElementById('content-textarea');
        if (textarea) {
            const cursorPos = textarea.selectionStart;
            const content = article.content;
            // Add newlines only if needed
            const before = cursorPos > 0 && content[cursorPos - 1] !== '\n' ? '\n' : '';
            const after = cursorPos < content.length && content[cursorPos] !== '\n' ? '\n' : '';
            
            setArticle({
                ...article,
                content: content.substring(0, cursorPos) + 
                        before + imageMarkdown + after + 
                        content.substring(cursorPos)
            });
        }
    };

    const formatText = (format) => {
        const textarea = document.getElementById('content-textarea');
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = article.content.substring(start, end);
        let formattedText = '';

        switch (format) {
            case 'bold':
                formattedText = `**${selectedText}**`;
                break;
            case 'italic':
                formattedText = `*${selectedText}*`;
                break;
            case 'heading':
                formattedText = `\n## ${selectedText}`;
                break;
            case 'list':
                formattedText = selectedText.split('\n').map(line => `- ${line}`).join('\n');
                break;
            case 'numbered':
                formattedText = selectedText.split('\n').map((line, i) => `${i + 1}. ${line}`).join('\n');
                break;
            case 'code':
                formattedText = `\`\`\`\n${selectedText}\n\`\`\``;
                break;
            case 'quote':
                formattedText = selectedText.split('\n').map(line => `> ${line}`).join('\n');
                break;
            case 'table':
                formattedText = '\n| Header 1 | Header 2 |\n|----------|----------|\n| Cell 1   | Cell 2   |';
                break;
        }

        const newContent = 
            article.content.substring(0, start) + 
            formattedText + 
            article.content.substring(end);

        setArticle({ ...article, content: newContent });
    };

    const autoSave = async () => {
        if (!article.title || !article.content) return;
        
        try {
            await axiosInstance.post('/api/wiki/save', {
                ...article,
                isDraft: true
            });
            setLastSaved(new Date());
            setAutoSaveMessage('Draft saved');
            setTimeout(() => setAutoSaveMessage(''), 2000);
        } catch (error) {
            console.error('Auto-save failed:', error);
            setAutoSaveMessage('Auto-save failed');
            setTimeout(() => setAutoSaveMessage(''), 2000);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (article.content) {
                autoSave();
            }
        }, 30000); // Auto-save every 30 seconds

        return () => clearTimeout(timer);
    }, [article.content]);

    if (isLoading) return (
        <>
            <Navbar />
            <Container className="py-5">
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-3">Loading editor...</p>
                </div>
            </Container>
            <Footer />
        </>
    );
    
    if (error) return (
        <>
            <Navbar />
            <Container className="py-4">
                <Alert variant="danger">{error}</Alert>
                <Button variant="primary" onClick={() => navigate('/wiki')}>
                    Return to Wiki
                </Button>
            </Container>
            <Footer />
        </>
    );

    return (
        <>
            <Navbar />
            <Container className="py-4">
                <h2>{slug ? 'Edit Article' : 'Create New Article'}</h2>
                
                {saveSuccess && (
                    <Alert variant="success" className="mb-4">
                        Article saved successfully! Redirecting...
                    </Alert>
                )}
                
                <Tab.Container id="edit-preview-tabs" defaultActiveKey="edit">
                    <Nav variant="tabs" className="mb-3">
                        <Nav.Item>
                            <Nav.Link eventKey="edit">Edit</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="preview">Preview</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="edit">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={article.title}
                                                onChange={handleTitleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Slug (URL-friendly identifier)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={article.slug}
                                                onChange={(e) => setArticle({
                                                    ...article,
                                                    slug: e.target.value
                                                })}
                                                required
                                            />
                                            <Form.Text className="text-muted">
                                                Auto-generated from title. Edit if needed.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Main Content (Supports Markdown)</Form.Label>
                                            <div className="editor-toolbar">
                                                <Button variant="light" onClick={() => formatText('heading')}><FaHeading /></Button>
                                                <Button variant="light" onClick={() => formatText('bold')}><FaBold /></Button>
                                                <Button variant="light" onClick={() => formatText('italic')}><FaItalic /></Button>
                                                <div className="separator" />
                                                <Button variant="light" onClick={() => formatText('list')}><FaListUl /></Button>
                                                <Button variant="light" onClick={() => formatText('numbered')}><FaListOl /></Button>
                                                <div className="separator" />
                                                <Button variant="light" onClick={() => formatText('code')}><FaCode /></Button>
                                                <Button variant="light" onClick={() => formatText('quote')}><FaQuoteRight /></Button>
                                                <Button variant="light" onClick={() => formatText('table')}><FaTable /></Button>
                                                <div className="separator" />
                                                <Button
                                                    variant="light"
                                                    onClick={handleImageClick}
                                                    disabled={uploadingImage}
                                                >
                                                    <FaImage /> {uploadingImage ? 'Uploading...' : 'Image'}
                                                </Button>
                                            </div>
                                            <Form.Control
                                                id="content-textarea"
                                                as="textarea"
                                                rows={12}
                                                value={article.content}
                                                onChange={(e) => setArticle({
                                                    ...article,
                                                    content: e.target.value
                                                })}
                                            />
                                        </Form.Group>

                                        <div className="section-templates mb-4">
                                            <h5>Section Templates</h5>
                                            <Row xs={1} md={3} className="g-3">
                                                {Object.entries(sectionTemplates).map(([key, template]) => (
                                                    <Col key={key}>
                                                        <div 
                                                            className="section-template"
                                                            onClick={() => handleAddSection(template)}
                                                        >
                                                            <h6>{template.title}</h6>
                                                            <small className="text-muted">Click to add this template</small>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>

                                        <div className="sections-container mb-3">
                                            <h4>Sections</h4>
                                            {article.sections.map((section, index) => (
                                                <div key={index} className="section-item mb-3 p-3 border rounded">
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Section Title</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={section.title}
                                                            onChange={(e) => {
                                                                const newSections = [...article.sections];
                                                                newSections[index].title = e.target.value;
                                                                setArticle({ ...article, sections: newSections });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Section Content (Supports Markdown)</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={section.content}
                                                            onChange={(e) => {
                                                                const newSections = [...article.sections];
                                                                newSections[index].content = e.target.value;
                                                                setArticle({ ...article, sections: newSections });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Button 
                                                        variant="outline-danger" 
                                                        size="sm"
                                                        onClick={() => {
                                                            const newSections = article.sections.filter((_, i) => i !== index);
                                                            setArticle({ ...article, sections: newSections });
                                                        }}
                                                    >
                                                        Remove Section
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button 
                                                variant="outline-primary" 
                                                onClick={handleAddSection}
                                            >
                                                Add Section
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                value={article.category}
                                                onChange={(e) => setArticle({
                                                    ...article,
                                                    category: e.target.value
                                                })}
                                            >
                                                <option value="general">General Knowledge</option>
                                                <option value="basics">PM Basics</option>
                                                <option value="methodologies">Methodologies</option>
                                                <option value="agile">Agile & Scrum</option>
                                                <option value="waterfall">Waterfall</option>
                                                <option value="tools">PM Tools</option>
                                                <option value="templates">Templates</option>
                                                <option value="planning">Project Planning</option>
                                                <option value="execution">Project Execution</option>
                                                <option value="monitoring">Monitoring & Control</option>
                                                <option value="closure">Project Closure</option>
                                                <option value="risk">Risk Management</option>
                                                <option value="stakeholder">Stakeholder Management</option>
                                                <option value="communication">Communication</option>
                                                <option value="leadership">Leadership</option>
                                                <option value="team">Team Management</option>
                                                <option value="metrics">Metrics & KPIs</option>
                                                <option value="budget">Budget & Cost</option>
                                                <option value="quality">Quality Management</option>
                                                <option value="procurement">Procurement</option>
                                                <option value="compliance">Compliance & Governance</option>
                                                <option value="pmo">PMO</option>
                                                <option value="career">Career Development</option>
                                                <option value="certifications">Certifications</option>
                                                <option value="best-practices">Best Practices</option>
                                                <option value="case-studies">Case Studies</option>
                                                <option value="other">Other</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Tags (comma-separated)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={tagInput}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    setTagInput(input); // Keep the raw input
                                                    // Update article tags only when we have valid input
                                                    if (input.trim()) {
                                                        const newTags = input
                                                            .split(',')
                                                            .map(tag => tag.trim())
                                                            .filter(tag => tag.length > 0);
                                                        setArticle(prev => ({
                                                            ...prev,
                                                            tags: newTags
                                                        }));
                                                    } else {
                                                        setArticle(prev => ({
                                                            ...prev,
                                                            tags: []
                                                        }));
                                                    }
                                                }}
                                                placeholder="Enter tags separated by commas (e.g., agile, scrum, planning)"
                                            />
                                            <Form.Text className="text-muted">
                                                Type your tags and separate them with commas. Press comma after each tag.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Check
                                            type="switch"
                                            id="popular-switch"
                                            label="Mark as Popular"
                                            checked={article.popular || false}
                                            onChange={(e) => setArticle({
                                                ...article,
                                                popular: e.target.checked
                                            })}
                                            className="mb-3"
                                        />

                                        <Form.Check
                                            type="switch"
                                            id="published-switch"
                                            label="Publish article"
                                            checked={article.published || false}
                                            onChange={(e) => setArticle({
                                                ...article,
                                                published: e.target.checked
                                            })}
                                            className="mb-3"
                                        />

                                        <Form.Text className="text-muted mb-3 d-block">
                                            Last updated: {new Date(article.lastUpdated).toLocaleString()}
                                        </Form.Text>

                                        <Button 
                                            variant="primary" 
                                            type="submit" 
                                            className="w-100 mb-2"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Saving...' : (slug ? 'Update Article' : 'Create Article')}
                                        </Button>
                                        
                                        <Button 
                                            variant="secondary" 
                                            className="w-100"
                                            onClick={() => navigate('/wiki')}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="preview">
                            <div className="wiki-preview p-4 border rounded">
                                <h1>{article.title || 'Untitled Article'}</h1>
                                <div className="metadata mb-4">
                                    <span className="badge bg-primary me-2">{article.category}</span>
                                    {article.tags && article.tags.map(tag => (
                                        <span key={tag} className="badge bg-secondary me-1">{tag}</span>
                                    ))}
                                    <div className="text-muted mt-2">
                                        Last updated: {new Date(article.lastUpdated).toLocaleString()}
                                    </div>
                                </div>
                                
                                <div className="content mb-4">
                                    <ReactMarkdown
                                        rehypePlugins={[rehypeRaw]}
                                        components={{
                                            img: ({node, ...props}) => (
                                                <div className="wiki-image-container">
                                                    <img
                                                        {...props}
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: 'auto',
                                                            display: 'block',
                                                            margin: '0 auto'
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }}
                                    >
                                        {article.content}
                                    </ReactMarkdown>
                                </div>
                                
                                {article.sections && article.sections.length > 0 && (
                                    <div className="sections">
                                        {article.sections.map((section, idx) => (
                                            <div key={idx} className="section mb-3">
                                                <h2>{section.title}</h2>
                                                <ReactMarkdown
                                                    rehypePlugins={[rehypeRaw]}
                                                    components={{
                                                        img: ({node, ...props}) => (
                                                            <div className="wiki-image-container">
                                                                <img
                                                                    {...props}
                                                                    style={{
                                                                        maxWidth: '100%',
                                                                        height: 'auto',
                                                                        display: 'block',
                                                                        margin: '0 auto'
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    {section.content}
                                                </ReactMarkdown>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                {autoSaveMessage && (
                    <div className="autosave-indicator">
                        {autoSaveMessage}
                    </div>
                )}
            </Container>
            <ImageEditor
                show={showImageEditor}
                onHide={() => setShowImageEditor(false)}
                imageUrl={currentImageUrl}
                onSave={handleImageInsert}
            />
            <Footer />
        </>
    );
};

export default WikiEditor;
