import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, useBeforeUnload } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import "./ComponentStyling/MeetingPage.css";
import ProjectNavbar from './ProjectNavbar';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchMeeting,
    updateMeetingMinutes,
    updateMeetingField,
    deleteMeeting,
    setHasUnsavedChanges
} from '../redux/slices/meetingsSlice';

console.log('Meeting page loaded');

const MeetingPage = () => {
    const { projectId, meetingId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentMeeting, loading, error, hasUnsavedChanges } = useSelector(state => state.meetings);
    const [meetingTitle, setMeetingTitle] = useState('Meeting 1');
    const [meetingMinutes, setMeetingMinutes] = useState('');
    const [meetingDate, setMeetingDate] = useState('');
    const [meetingTime, setMeetingTime] = useState('');
    const [quillMinutes, setQuillMinutes] = useState(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isEditingTime, setIsEditingTime] = useState(false);
    const [originalTitle, setOriginalTitle] = useState('');
    const [originalDate, setOriginalDate] = useState('');
    const [originalTime, setOriginalTime] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [lastSavedContent, setLastSavedContent] = useState('');
    const [initialContent, setInitialContent] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [currentContent, setCurrentContent] = useState('');
    const quillRef = useRef(null);
    const editorContainerRef = useRef(null);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    // Replace your two separate useEffects with this single effect for Quill
    useEffect(() => {
        // Only initialize once when the component mounts
        if (editorContainerRef.current && !quillRef.current) {
            console.log('Initializing Quill editor');
            const quillInstance = new Quill(editorContainerRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'font': [] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });

            quillInstance.on('text-change', () => {
                const newContent = quillInstance.root.innerHTML;
                setCurrentContent(newContent);
                setMeetingMinutes(newContent);
                setHasChanges(true);
            });

            quillRef.current = quillInstance;
            setQuillMinutes(quillInstance);
            
            // Fetch meeting data after Quill is initialized
            dispatch(fetchMeeting({ projectId, meetingId }));
        }
        
        // Cleanup function to prevent memory leaks
        return () => {
            if (quillRef.current) {
                // Any cleanup needed for Quill
                console.log('Cleaning up Quill editor');
            }
        };
    }, []); // Empty dependency array means this runs once on mount

    // Update Quill content when meeting data is loaded
    useEffect(() => {
        if (currentMeeting && quillRef.current) {
            // Prevent infinite loop by checking if content is different
            if (currentMeeting.meetingNotes !== currentContent) {
                quillRef.current.root.innerHTML = currentMeeting.meetingNotes || '';
                setInitialContent(currentMeeting.meetingNotes || '');
                setCurrentContent(currentMeeting.meetingNotes || '');
            }
            
            setMeetingTitle(currentMeeting.meetingTitle || 'Untitled Meeting');
            setMeetingDate(currentMeeting.date || new Date().toISOString().split('T')[0]);
            setMeetingTime(currentMeeting.time || new Date().toLocaleTimeString());
            
            setOriginalTitle(currentMeeting.meetingTitle || 'Untitled Meeting');
            setOriginalDate(currentMeeting.date || '');
            setOriginalTime(currentMeeting.time || '');
        }
    }, [currentMeeting]);

    useEffect(() => {
        const handleKeyDown = async (e) => {
            if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                e.preventDefault(); // Prevent browser's save dialog
                await handleSave();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [meetingMinutes]);

    useBeforeUnload(
        useCallback(
            (event) => {
                if (hasChanges) {
                    event.preventDefault();
                    return (event.returnValue = "You have unsaved changes. Are you sure you want to leave?");
                }
            },
            [hasChanges]
        )
    );

    useEffect(() => {
        const handleBeforeNavigate = (event) => {
            if (hasChanges && !window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
                event.preventDefault();
            }
        };

        window.addEventListener('popstate', handleBeforeNavigate);
        return () => window.removeEventListener('popstate', handleBeforeNavigate);
    }, [hasChanges]);

    useEffect(() => {
        const contentChanged = currentContent !== initialContent;
        const titleChanged = meetingTitle !== originalTitle && originalTitle !== '';
        const dateChanged = meetingDate !== originalDate && originalDate !== '';
        const timeChanged = meetingTime !== originalTime && originalTime !== '';
        setHasChanges(contentChanged || titleChanged || dateChanged || timeChanged);
    }, [currentContent, meetingTitle, meetingDate, meetingTime, initialContent, originalTitle, originalDate, originalTime]);

    // Add global link click handler for internal navigation
    useEffect(() => {
        const handleLinkClick = (e) => {
            const anchor = e.target.closest('a');
            if (anchor && anchor.href && anchor.getAttribute('target') !== '_blank') {
                const url = new URL(anchor.href);
                // Check if the link is internal and leads to a different pathname
                if (url.origin === window.location.origin && url.pathname !== location.pathname) {
                    if (hasChanges && !window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
                        e.preventDefault();
                    }
                }
            }
        };
        document.addEventListener('click', handleLinkClick);
        return () => document.removeEventListener('click', handleLinkClick);
    }, [hasChanges, location.pathname]);

    const handleSave = async () => {
        if (!hasChanges) return;
        
        try {
            setIsSaving(true);
            await dispatch(updateMeetingMinutes({
                projectId,
                meetingId,
                meetingMinutes: currentContent
            })).unwrap();
            
            setInitialContent(currentContent);
            dispatch(setHasUnsavedChanges(false));
            
            // Show save indicator
            const indicator = document.createElement('div');
            indicator.className = 'save-indicator';
            indicator.textContent = 'Saved!';
            document.body.appendChild(indicator);
            
            // Remove indicator after animation
            setTimeout(() => {
                indicator.remove();
            }, 2000);
        } catch (error) {
            console.error('Error saving meeting minutes:', error);
            // Show error indicator
            const indicator = document.createElement('div');
            indicator.className = 'save-indicator error';
            indicator.textContent = 'Error saving!';
            document.body.appendChild(indicator);
            
            setTimeout(() => {
                indicator.remove();
            }, 2000);
        } finally {
            setIsSaving(false);
        }
    };

    const handleBlur = async (field) => {
        try {
            if (field === 'title' && meetingTitle !== originalTitle) {
                await dispatch(updateMeetingField({
                    projectId,
                    meetingId,
                    field: 'title',
                    value: meetingTitle
                })).unwrap();
                setIsEditingTitle(false);
            } else if (field === 'date' && meetingDate !== originalDate) {
                await dispatch(updateMeetingField({
                    projectId,
                    meetingId,
                    field: 'date',
                    value: meetingDate
                })).unwrap();
                setIsEditingDate(false);
            } else if (field === 'time' && meetingTime !== originalTime) {
                await dispatch(updateMeetingField({
                    projectId,
                    meetingId,
                    field: 'time',
                    value: meetingTime
                })).unwrap();
                setIsEditingTime(false);
            }
        } catch (error) {
            console.error(`Error updating meeting ${field}:`, error);
        }
    };

    const handleDoubleClick = (field) => {
        if (field === 'title') {
            setOriginalTitle(meetingTitle);
            setIsEditingTitle(true);
        } else if (field === 'date') {
            setOriginalDate(meetingDate);
            setIsEditingDate(true);
        } else if (field === 'time') {
            setOriginalTime(meetingTime);
            setIsEditingTime(true);
        }
    };

    const handleDeleteMeeting = async () => {
        try {
            await dispatch(deleteMeeting({ projectId, meetingId })).unwrap();
            navigate(`/projects/${projectId}`);
        } catch (error) {
            console.error('Error deleting meeting:', error);
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() + 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const formatTime = (timeString) => {
        if (!timeString) return '';
        const [hour, minute] = timeString.split(':');
        const hourInt = parseInt(hour, 10);
        const ampm = hourInt >= 12 ? 'PM' : 'AM';
        const formattedHour = hourInt % 12 || 12;
        return `${formattedHour}:${minute} ${ampm}`;
    };

    const handleNavigation = (nextLocation) => {
        if (hasChanges) {
            return window.confirm(
                "You have unsaved changes. Are you sure you want to leave?"
            );
        }
        return true;
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <main className="main-content">
                <div className="meeting-header">
                    <div className="meeting-header-top">
                        <div className="d-flex align-items-center gap-3">
                            {isEditingTitle ? (
                                <input
                                    type="text"
                                    className="form-control meeting-title-input"
                                    value={meetingTitle}
                                    onChange={(e) => setMeetingTitle(e.target.value)}
                                    onBlur={() => handleBlur('title')}
                                    autoFocus
                                />
                            ) : (
                                <div className="meeting-title" onDoubleClick={() => handleDoubleClick('title')}>
                                    {meetingTitle}
                                </div>
                            )}
                            {hasChanges && (
                                <span className="save-prompt">
                                    Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save
                                </span>
                            )}
                        </div>
                        <button className="btn btn-danger delete-meeting" onClick={handleDeleteMeeting}>
                            Delete Meeting
                        </button>
                    </div>
                    <div className="meeting-metadata">
                        <div className="metadata-item">
                            <i className="far fa-calendar"></i>
                            {isEditingDate ? (
                                <input
                                    type="date"
                                    className="form-control meeting-date"
                                    value={meetingDate}
                                    onChange={(e) => setMeetingDate(e.target.value)}
                                    onBlur={() => handleBlur('date')}
                                    autoFocus
                                />
                            ) : (
                                <span onDoubleClick={() => handleDoubleClick('date')}>
                                    {formatDate(meetingDate)}
                                </span>
                            )}
                        </div>
                        <div className="metadata-divider"></div>
                        <div className="metadata-item">
                            <i className="far fa-clock"></i>
                            {isEditingTime ? (
                                <input
                                    type="time"
                                    className="form-control meeting-time"
                                    value={meetingTime}
                                    onChange={(e) => setMeetingTime(e.target.value)}
                                    onBlur={() => handleBlur('time')}
                                    autoFocus
                                />
                            ) : (
                                <span onDoubleClick={() => handleDoubleClick('time')}>
                                    {formatTime(meetingTime)}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row min-vh-100">
                    <div className="col-md-12">
                        <div ref={editorContainerRef} />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MeetingPage;