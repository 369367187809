import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndent, faOutdent, faSave, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const WBSToolbar = ({ onIndent, onOutdent, selectedRow, onDelete, onInsert, onSave, saveStatus }) => {
    return (
        <div className="wbs-toolbar">
            <div className="toolbar-group">
                <button 
                    className="toolbar-button" 
                    onClick={() => onOutdent(selectedRow)}
                    disabled={selectedRow === null}
                    title="Outdent (Shift + Tab)"
                >
                    <FontAwesomeIcon icon={faOutdent} /> Outdent
                </button>
                <button 
                    className="toolbar-button" 
                    onClick={() => onIndent(selectedRow)}
                    disabled={selectedRow === null}
                    title="Indent (Tab)"
                >
                    <FontAwesomeIcon icon={faIndent} /> Indent
                </button>
            </div>
            <div className="toolbar-group">
                <button 
                    className="toolbar-button success"
                    onClick={() => onInsert(selectedRow)}
                    disabled={selectedRow === null}
                    title="Insert Row (⌘/Ctrl + I)"
                >
                    <FontAwesomeIcon icon={faPlus} /> Insert
                </button>
                <button 
                    className="toolbar-button danger"
                    onClick={() => onDelete(selectedRow)}
                    disabled={selectedRow === null}
                    title="Delete Row (Shift + Backspace)"
                >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                </button>
                <button 
                    className={`toolbar-button ${saveStatus === 'Saved!' ? 'success' : ''}`}
                    onClick={onSave}
                    title="Save (⌘/Ctrl + S)"
                >
                    <FontAwesomeIcon icon={faSave} /> 
                    {saveStatus || 'Save'}
                </button>
            </div>
        </div>
    );
};

export default WBSToolbar;
