import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/projects/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching projects');
    }
  }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    data: [],
    activeProject: null,
    loading: false,
    error: null,
  },
  reducers: {
    setActiveProject: (state, action) => {
      state.activeProject = state.activeProject === action.payload ? null : action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching projects';
      });
  },
});

export const { setActiveProject } = projectsSlice.actions;
export default projectsSlice.reducer;
