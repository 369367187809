import { isLimitReached, getLimitValue } from './featureUtils';
import store from '../redux/store';  // Changed this line: import default export correctly

/**
 * Check if the user has reached their project limit
 * 
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {Object} - Result object { limitReached, currentCount, maxAllowed }
 */
export const checkProjectLimit = (accountType, isAdmin = false) => {
  // Get current projects from Redux store
  const state = store.getState();
  const projects = state.projects.data || [];
  const currentCount = projects.length;
  
  // Get max allowed projects
  const maxAllowed = getLimitValue('maxProjects', accountType, isAdmin);
  
  // Check if limit is reached
  const limitReached = isLimitReached('maxProjects', accountType, currentCount, isAdmin);
  
  return {
    limitReached,
    currentCount,
    maxAllowed
  };
};

/**
 * Format a message about project limits
 * 
 * @param {string} accountType - The user's account type
 * @param {boolean} isAdmin - Whether the user is an admin
 * @returns {string} - Formatted message
 */
export const getProjectLimitMessage = (accountType, isAdmin = false) => {
  const { limitReached, currentCount, maxAllowed } = checkProjectLimit(accountType, isAdmin);
  
  if (isAdmin || maxAllowed === Infinity) {
    return `Projects: ${currentCount} (unlimited)`;
  }
  
  if (limitReached) {
    return `Projects: ${currentCount}/${maxAllowed} (limit reached)`;
  }
  
  return `Projects: ${currentCount}/${maxAllowed}`;
};
