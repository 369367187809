import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const DropdownPortal = ({ isOpen, triggerRef, children, onClose }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  
  const handleClickOutside = (e) => {
    if (triggerRef.current && !triggerRef.current.contains(e.target)) {
      onClose();
    }
  };
  
  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + 5, // Added 5px padding
        left: rect.left + window.scrollX
      });
      
      document.addEventListener('click', handleClickOutside); // Changed from 'mousedown' to 'click'
    }
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, triggerRef, onClose]);
  
  if (!isOpen) return null;
  
  // Remove the onClick handler from the parent div to allow button clicks to work
  return ReactDOM.createPortal(
    <div 
      className="pmh-filter-dropdown" 
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 99999
      }}
      onClick={(e) => e.stopPropagation()} // Ensure child clicks fire without closing
    >
      {children}
    </div>,
    document.body
  );
};

export default DropdownPortal;
