import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const fetchJournalAnalytics = createAsyncThunk(
  'analytics/fetchJournalAnalytics',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/journalAnalytics`, {
        params: { user_id: userId }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching journal analytics');
    }
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJournalAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJournalAnalytics.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchJournalAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching journal analytics';
      });
  },
});

export default analyticsSlice.reducer;
