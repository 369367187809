import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge, Button, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './ComponentStyling/Wiki.css';
import { FaArrowLeft, FaPencilAlt, FaExclamationTriangle } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import axiosInstance from '../axiosConfig';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const WikiPage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [article, setArticle] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchArticle();
    }, [slug]);

    const fetchArticle = async () => {
        try {
            const response = await axiosInstance.get(`/api/wiki/articles/${slug}`);
            setArticle(response.data);
            document.title = `PMHub Wiki - ${response.data.title}`;
        } catch (error) {
            console.error('Error fetching article:', error);
            setError('Article not found');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <>
                <Navbar />
                <Container className="py-5">
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Loading article...</p>
                    </div>
                </Container>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="pmhub-wiki-container">
                <Container fluid className="py-4">
                    <div className="article-header-nav mb-4">
                        <Row className="align-items-center">
                            <Col>
                                <Button 
                                    variant="outline-primary" 
                                    className="back-button"
                                    onClick={() => navigate('/wiki')}
                                >
                                    <FaArrowLeft className="me-2" /> Back to Wiki
                                </Button>
                            </Col>
                            <Col className="text-end">
                                {user?.isAdmin && (
                                    <Button 
                                        variant="warning" 
                                        className="me-2"
                                        onClick={() => navigate(`/wiki/edit/${slug}`)}
                                    >
                                        <FaPencilAlt className="me-2" /> Edit Article
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                    
                    <Row>
                        <Col md={12}>
                            {article ? (
                                <Card className="pmhub-wiki-content-card">
                                    <Card.Header className="pmhub-wiki-header">
                                        <h2>{article.title}</h2>
                                        <div className="mt-2">
                                            <Badge bg="primary" className="me-2">{article.category}</Badge>
                                            {article.tags?.map(tag => (
                                                <Badge key={tag} bg="secondary" className="me-1">{tag}</Badge>
                                            ))}
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ReactMarkdown 
                                            rehypePlugins={[rehypeRaw]} 
                                            components={{
                                                img: ({node, ...props}) => (
                                                    <img 
                                                        {...props}
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: 'auto',
                                                            margin: '0 auto',
                                                            display: 'block'
                                                        }}
                                                        loading="lazy"
                                                    />
                                                )
                                            }}
                                        >
                                            {article.content}
                                        </ReactMarkdown>
                                        
                                        {Array.isArray(article.sections) && article.sections.length > 0 && (
                                            <div className="wiki-sections mt-4">
                                                {article.sections
                                                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                                                    .map((section, index) => (
                                                        <div key={index} className="wiki-section mt-4">
                                                            <h3 className="section-title">{section.title}</h3>
                                                            <div className="section-content">
                                                                <ReactMarkdown 
                                                                    rehypePlugins={[rehypeRaw]}
                                                                    components={{
                                                                        img: ({node, ...props}) => (
                                                                            <img 
                                                                                {...props}
                                                                                style={{
                                                                                    maxWidth: '100%',
                                                                                    height: 'auto',
                                                                                    display: 'block',
                                                                                    margin: '1rem auto'
                                                                                }}
                                                                                loading="lazy"
                                                                            />
                                                                        )
                                                                    }}
                                                                >
                                                                    {section.content}
                                                                </ReactMarkdown>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">
                                            Last updated: {new Date(article.lastUpdated).toLocaleString()}
                                            {article.author && ` by ${article.author}`}
                                        </small>
                                    </Card.Footer>
                                </Card>
                            ) : (
                                <Card className="text-center p-5">
                                    <FaExclamationTriangle size={48} className="text-warning mb-3" />
                                    <h4>Article Not Found</h4>
                                    <p>The requested article does not exist or may have been removed.</p>
                                    {user?.isAdmin && (
                                        <div className="mt-4">
                                            <Button 
                                                variant="primary"
                                                onClick={() => navigate('/wiki/new')}
                                            >
                                                Create New Article
                                            </Button>
                                        </div>
                                    )}
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default WikiPage;
