import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

const UserStorySlider = ({ 
    showSlider,
    sliderClosing,
    closeSlider,
    currentStory,
    handleInputChange,
    handleSubmit,
    availableEpics = [],
    availableStories = [],
    availableSprints = []
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);
    const [localStory, setLocalStory] = useState({...currentStory});

    useEffect(() => {
        setLocalStory({...currentStory});
    }, [currentStory]);

    const handleFieldChange = (field, value) => {
        const updatedStory = { ...localStory, [field]: value };
        setLocalStory(updatedStory);
        setIsSaving(true);

        // Clear any existing timeout
        if (saveTimeout) {
            clearTimeout(saveTimeout);
        }

        // Set new timeout for auto-save
        const timeoutId = setTimeout(async () => {
            try {
                // Pass the _id if it exists (needed for determining if it's a new story)
                const saveData = { ...updatedStory };
                // Remove fields that shouldn't be part of the payload
                if (saveData._isExplicitSave) delete saveData._isExplicitSave;
                
                await handleSubmit(saveData);
            } catch (error) {
                console.error('Auto-save failed:', error);
            } finally {
                setIsSaving(false);
            }
        }, 1000);

        setSaveTimeout(timeoutId);
    };

    return (
        <div className="sliding-panel-wrapper">
            <div 
                className={`panel-overlay ${showSlider ? 'visible' : ''} ${sliderClosing ? 'hiding' : ''}`} 
                onClick={closeSlider}
            ></div>
            <div className={`sliding-panel ${showSlider ? 'open' : ''} ${sliderClosing ? 'closing' : ''}`}>
                <div className="sliding-panel-header">
                    <button className="sliding-panel-close" onClick={closeSlider}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="sliding-panel-title">Create User Story</h2>
                    {isSaving && (
                        <div className="saving-indicator">
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <span className="ms-2">Saving...</span>
                        </div>
                    )}
                </div>
                
                <div className="sliding-panel-content">
                    <div className="form-section">
                        <h3 className="section-title">Basic Information</h3>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.title || ''}
                                onChange={(e) => handleFieldChange('title', e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={localStory.description || ''}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">User Story Format</h3>
                        <div className="mb-3">
                            <label className="form-label">As a</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.asA || ''}
                                onChange={(e) => handleFieldChange('asA', e.target.value)}
                                placeholder="Type of user (e.g., Admin, Customer, Manager)"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">I want to</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.iWantTo || ''}
                                onChange={(e) => handleFieldChange('iWantTo', e.target.value)}
                                placeholder="Action or feature desired"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">So that</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.soThat || ''}
                                onChange={(e) => handleFieldChange('soThat', e.target.value)}
                                placeholder="Benefit or value achieved"
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Planning</h3>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Epic</label>
                                <select
                                    className="form-select"
                                    value={localStory.epicId || ''}
                                    onChange={(e) => handleFieldChange('epicId', e.target.value)}
                                >
                                    <option value="">Select Epic</option>
                                    {availableEpics.map(epic => (
                                        <option key={epic._id} value={epic._id}>
                                            {epic.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Priority</label>
                                <select
                                    className="form-select"
                                    value={localStory.priority || 'Medium'}
                                    onChange={(e) => handleFieldChange('priority', e.target.value)}
                                >
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Story Points</label>
                            <select
                                className="form-select"
                                value={localStory.storyPoints || ''}
                                onChange={(e) => handleFieldChange('storyPoints', e.target.value)}
                            >
                                <option value="">Select Points</option>
                                {[1, 2, 3, 5, 8, 13, 21].map(points => (
                                    <option key={points} value={points}>{points}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title d-flex justify-content-between align-items-center">
                            Acceptance Criteria
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    const newCriteria = [
                                        ...(localStory.acceptanceCriteria || []),
                                        { text: '', completed: false }
                                    ];
                                    handleFieldChange('acceptanceCriteria', newCriteria);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="me-1" />
                                Add Criterion
                            </button>
                        </h3>
                        
                        {localStory.acceptanceCriteria?.map((criterion, index) => (
                            <div key={index} className="input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={criterion.text}
                                    onChange={(e) => {
                                        const newCriteria = [...localStory.acceptanceCriteria];
                                        newCriteria[index].text = e.target.value;
                                        handleFieldChange('acceptanceCriteria', newCriteria);
                                    }}
                                    placeholder="Enter acceptance criterion"
                                />
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => {
                                        const newCriteria = localStory.acceptanceCriteria.filter((_, i) => i !== index);
                                        handleFieldChange('acceptanceCriteria', newCriteria);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Technical Details</h3>
                        <div className="mb-3">
                            <label className="form-label">Dependencies</label>
                            <select
                                className="form-select"
                                multiple
                                value={localStory.dependencies || []}
                                onChange={(e) => handleFieldChange('dependencies', 
                                    Array.from(e.target.selectedOptions, option => option.value))}
                            >
                                {availableStories.map(story => (
                                    <option key={story._id} value={story._id}>
                                        {story.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Technical Notes</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={localStory.technicalNotes || ''}
                                onChange={(e) => handleFieldChange('technicalNotes', e.target.value)}
                                placeholder="Implementation details, architectural considerations, etc."
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Testing & Quality</h3>
                        <div className="mb-3">
                            <label className="form-label">Test Scenarios</label>
                            <div className="test-scenarios">
                                {(localStory.testScenarios || []).map((scenario, index) => (
                                    <div key={index} className="input-group mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={scenario}
                                            onChange={(e) => {
                                                const newScenarios = [...localStory.testScenarios];
                                                newScenarios[index] = e.target.value;
                                                handleFieldChange('testScenarios', newScenarios);
                                            }}
                                            placeholder="Enter test scenario"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => {
                                                const newScenarios = localStory.testScenarios.filter((_, i) => i !== index);
                                                handleFieldChange('testScenarios', newScenarios);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        const newScenarios = [...(localStory.testScenarios || []), ''];
                                        handleFieldChange('testScenarios', newScenarios);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-1" />
                                    Add Test Scenario
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3 className="section-title">Planning & Tracking</h3>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Sprint</label>
                                <select
                                    className="form-select"
                                    value={localStory.sprintId || ''}
                                    onChange={(e) => handleFieldChange('sprintId', e.target.value)}
                                >
                                    <option value="">Backlog</option>
                                    {availableSprints.map(sprint => (
                                        <option key={sprint._id} value={sprint._id}>
                                            {sprint.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Estimated Time (hours)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={localStory.estimatedHours || ''}
                                    onChange={(e) => handleFieldChange('estimatedHours', e.target.value)}
                                    min="0"
                                    step="0.5"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tags</label>
                            <input
                                type="text"
                                className="form-control"
                                value={localStory.tags || ''}
                                onChange={(e) => handleFieldChange('tags', e.target.value)}
                                placeholder="Enter tags separated by commas"
                            />
                        </div>
                    </div>

                    <div className="epic-actions mt-4">
                        <button 
                            className="btn btn-success"
                            onClick={() => handleSubmit({...localStory, _isExplicitSave: true})}
                            disabled={isSaving}
                        >
                            <FontAwesomeIcon icon={faSave} className="me-2" />
                            {isSaving ? 'Saving...' : 'Create Story'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStorySlider;
