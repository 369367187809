import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTachometerAlt, faUsers, faClipboardList, faColumns, 
  faCalendarAlt, faComments, faCog, faListOl, 
  faProjectDiagram, faStickyNote, faBolt, faFilter,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Components/AuthContext';
import { checkProjectLimit } from '../../utils/projectLimitCheck';
import { calculateProjectAttentionScore } from '../../utils/dashboardUtils';
import { ACCOUNT_TYPES } from '../../config/featureAccess';
import '../ComponentStyling/ProjectPage.css';
import DropdownPortal from '../UI/DropdownPortal';

const ProjectsCard = ({ projects, activeProject, handleProjectClick, raidLogs = [] }) => {
  const { accountType, user } = useAuth();
  const isFreeUser = accountType === ACCOUNT_TYPES.FREE;
  
  // Filter state
  const [filterMode, setFilterMode] = useState('active');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const filterBtnRef = useRef(null);
  
  // Only calculate limit info for free users
  const limitInfo = isFreeUser ? checkProjectLimit(accountType, user?.isAdmin) : null;
  
  // Determine the progress percentage
  const progressPercentage = limitInfo ? 
    Math.min((limitInfo.currentCount / limitInfo.maxAllowed) * 100, 100) : 0;
  
  // Determine color based on usage
  const getProgressColor = () => {
    if (progressPercentage < 60) return '#28a745'; // Green
    if (progressPercentage < 85) return '#ffc107'; // Yellow/Warning
    return '#dc3545'; // Red/Danger
  };
  
  // Filter projects based on selected filter mode
  useEffect(() => {
    if (!projects || projects.length === 0) {
      setFilteredProjects([]);
      return;
    }
    
    switch (filterMode) {
      case 'active':
        setFilteredProjects(
          projects.filter(project => 
            project.projectState !== 'Completed' && 
            project.projectState !== 'Archived' && 
            project.projectState !== 'Cancelled'
          )
        );
        break;
      case 'completed':
        setFilteredProjects(
          projects.filter(project => 
            project.projectState === 'Completed' || 
            project.projectState === 'Archived' || 
            project.projectState === 'Cancelled'
          )
        );
        break;
      case 'all':
        setFilteredProjects([...projects]);
        break;
      default:
        setFilteredProjects([...projects]);
    }
  }, [filterMode, projects]);
  
  const handleFilterChange = (mode) => {
    setFilterMode(mode);
    setDropdownOpen(false);
    // Remember user preference in localStorage
    localStorage.setItem('projectFilterMode', mode);
  };
  
  // Toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };
  
  // Load user's preferred filter on component mount
  useEffect(() => {
    const savedFilter = localStorage.getItem('projectFilterMode');
    if (savedFilter) {
      setFilterMode(savedFilter);
    }
  }, []);
  
  return (
    <div className="dashboard-card">
      <div className="dashboard-card-header">
        <div className="d-flex align-items-center">
          <h2 className="shiny-text">Projects</h2>
          
          {/* Show usage indicator only for free users - positioned right next to Projects */}
          {isFreeUser && limitInfo && (
            <div className="usage-indicator ms-2">
              <div className="d-flex align-items-center">
                <small className="text-muted mx-2">
                  {limitInfo.currentCount}/{limitInfo.maxAllowed}
                </small>
                <div className="progress" style={{ height: '8px', width: '50px' }}>
                  <div 
                    className="progress-bar" 
                    role="progressbar" 
                    style={{ 
                      width: `${progressPercentage}%`,
                      backgroundColor: getProgressColor()
                    }} 
                    aria-valuenow={progressPercentage} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              {limitInfo.limitReached && (
                <div className="limit-badge text-danger">
                  LIMIT REACHED
                </div>
              )}
            </div>
          )}
        </div>
        
        <div className="d-flex align-items-center">
          <div className="pmh-filter-container">
            <button 
              ref={filterBtnRef}
              className="pmh-filter-button"
              onClick={toggleDropdown}
            >
              <FontAwesomeIcon icon={faFilter} className="me-1" />
              <span className="d-none d-sm-inline">
                {filterMode === 'active' ? 'Active' : 
                 filterMode === 'completed' ? 'Completed' : 'All'}
              </span>
              <FontAwesomeIcon icon={faChevronDown} className={`ms-1 ${dropdownOpen ? 'pmh-rotate' : ''}`} />
            </button>
            
            <DropdownPortal 
              isOpen={dropdownOpen}
              triggerRef={filterBtnRef}
              onClose={() => setDropdownOpen(false)}
            >
              <button 
                className={`pmh-filter-item ${filterMode === 'active' ? 'pmh-filter-active' : ''}`}
                onClick={() => {
                  handleFilterChange('active');
                }}
              >
                Active Projects
              </button>
              <button 
                className={`pmh-filter-item ${filterMode === 'completed' ? 'pmh-filter-active' : ''}`}
                onClick={() => {
                  handleFilterChange('completed');
                }}
              >
                Completed/Archived
              </button>
              <button 
                className={`pmh-filter-item ${filterMode === 'all' ? 'pmh-filter-active' : ''}`}
                onClick={() => {
                  handleFilterChange('all');
                }}
              >
                All Projects
              </button>
            </DropdownPortal>
          </div>
          <FontAwesomeIcon icon={faProjectDiagram} />
        </div>
      </div>
      <div className="dashboard-card-body">
        {filteredProjects.length === 0 ? (
          <div className="text-center py-3">
            <p>{filterMode === 'active' ? 'No active projects found' : 
                filterMode === 'completed' ? 'No completed projects found' : 
                'No projects found'}</p>
          </div>
        ) : (
          <ul className="project-list">
            {filteredProjects.map((project) => {
              
              return (
                <li key={project._id}>
                  <div className="project-item" onClick={() => handleProjectClick(project._id)}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <span className="project-name">{project.project_name}</span>
                      <div className="d-flex align-items-center">
                        <span className={`project-status-badge ${project.status ? project.status.toLowerCase() : ''}`}>
                          {project.status === 'Green' ? 'On Track' :
                           project.status === 'Yellow' ? 'At Risk' :
                           project.status === 'Red' ? 'Off Track' :
                           project.status || 'No Status'}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  {activeProject === project._id && (
                    <div className="project-menu">
                      <Link to={`/projects/${project._id}`} title="Project Dashboard">
                        <FontAwesomeIcon icon={faTachometerAlt} />
                      </Link>
                      <Link to={`/projects/${project._id}/stakeholders`} title="Stakeholders">
                        <FontAwesomeIcon icon={faUsers} />
                      </Link>
                      <Link to={`/projects/${project._id}/raidlog`} title="RAID Log">
                        <FontAwesomeIcon icon={faClipboardList} />
                      </Link>
                      <Link to={`/projects/${project._id}/kanban`} title="Kanban Board">
                        <FontAwesomeIcon icon={faColumns} />
                      </Link>
                      <Link to={`/projects/${project._id}/scheduler`} title="Project Scheduler">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </Link>
                      <Link to={`/projects/${project._id}/meetings`} title="Meetings">
                        <FontAwesomeIcon icon={faComments} />
                      </Link>
                      <Link to={`/projects/${project._id}/wbs`} title="Work Breakdown Structure">
                        <FontAwesomeIcon icon={faListOl} />
                      </Link>
                      <Link to={`/projects/${project._id}/notes`} title="Project Notes">
                        <FontAwesomeIcon icon={faStickyNote} />
                      </Link>
                      <Link to={`/projects/${project._id}/agilehub`} title="AgileHub">
                        <FontAwesomeIcon icon={faBolt} />
                      </Link>
                      <Link to={`/projects/${project._id}/settings`} title="Project Settings">
                        <FontAwesomeIcon icon={faCog} />
                      </Link>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        
        {/* Show upgrade message if close to limit */}
        {isFreeUser && limitInfo && progressPercentage >= 85 && !limitInfo.limitReached && (
          <div className="mt-3 text-center">
            <small className="text-muted">
              You're approaching your project limit. 
              <Link to="/account" className="ms-1">Upgrade to Professional</Link>
            </small>
          </div>
        )}
        
        {/* Show upgrade message if limit reached */}
        {isFreeUser && limitInfo && limitInfo.limitReached && (
          <div className="mt-3 text-center">
            <small className="text-danger">
              You've reached your project limit.
              <Link to="/account" className="ms-1">Upgrade to Professional</Link>
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsCard;
