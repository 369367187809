import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { adjustDateForDisplay } from '../../utils/dashboardUtils';
import { sanitizeText } from '../../utils/securityUtils';

const UpcomingTasksCard = ({ upcomingTasks }) => {
  // Ensure we have a valid array to prevent mapping errors
  const tasks = Array.isArray(upcomingTasks) ? upcomingTasks : [];
  
  // Create a secure link URL
  const getSecureProjectLink = (projectId) => {
    if (typeof projectId === 'string' && projectId.trim() !== '') {
      return `/projects/${encodeURIComponent(projectId)}/raidlog`;
    }
    return '/projects';
  };
  
  return (
    <div className="dashboard-card half-width">
      <div className="dashboard-card-header">
        <h2 className = "shiny-text">Upcoming Actions</h2>
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
      <div className="dashboard-card-body">
        {tasks.length === 0 ? (
          <p>No upcoming tasks</p>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <table className="dashboard-table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Due Date</th>
                  <th>Owner</th>
                  <th>Status</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={getSecureProjectLink(task.projectId)}>
                        {sanitizeText(task.action)}
                      </Link>
                    </td>
                    <td>{task.due ? adjustDateForDisplay(task.due) : 'No date'}</td>
                    <td>{sanitizeText(task.owner)}</td>
                    <td>{sanitizeText(task.status)}</td>
                    <td>{sanitizeText(task.projectName)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingTasksCard;
