export const DEPENDENCY_TYPES = {
    'blocks': 'Blocks/Blocked by',
    'start-to-start': 'Start to Start (SS)',
    'start-to-finish': 'Start to Finish (SF)',
    'finish-to-start': 'Finish to Start (FS)',
    'finish-to-finish': 'Finish to Finish (FF)'
};

export const FEATURE_TYPES = {
    'functionality': 'Functionality',
    'ui': 'UI/UX',
    'enhancement': 'Enhancement',
    'optimization': 'Optimization',
    'integration': 'Integration',
    'security': 'Security'
};

export const FEATURE_STATUSES = [
    'Planning',
    'In Progress',
    'Testing',
    'Complete',
    'On Hold'
];
