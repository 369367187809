import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import "./ComponentStyling/RAIDLog.css";
import ReactGA from 'react-ga4';
import { FaTrash } from "react-icons/fa"; // Import trash icon
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const RAIDLog = () => {
    const { projectId } = useParams();
    const [raidlog, setRAID] = useState([]);
    const [originalRaidLog, setOriginalRAID] = useState([]);
    const [, setIsEditing] = useState(null);
    const [columns, setColumns] = useState({
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments', 'Created Date', 'Last Edited'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due', 'Created Date', 'Last Edited'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments', 'Created Date', 'Last Edited'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status', 'Created Date', 'Last Edited']
    });
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('Actions'); // Changed from 'Risks' to 'Actions'
    const [editableCell, setEditableCell] = useState({ rowId: null, column: null });
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, raidId: null });
    const [activeFilterDropdown, setActiveFilterDropdown] = useState(null);
    const [, setVisibleColumns] = useState({
        Risks: [],
        Actions: [],
        Issues: [],
        Decisions: []
    });
    const [projectCode, setProjectCode] = useState(""); // new state for project code
    // New ref for tracking next numeric id
    const nextRowNumberRef = useRef(1);

    const predefinedColumns = {
        Risks: ['Risk', 'Identified Date', 'Risk Impact', 'Risk Probability', 'Risk Score', 'Mitigation Plan', 'Comments', 'Created Date', 'Last Edited'],
        Actions: ['Action', 'Owner', 'Status', 'Priority', 'Due', 'Created Date', 'Last Edited'],
        Issues: ['Issue', 'Severity', 'Status', 'Identified Date', 'Action Plan', 'Comments', 'Created Date', 'Last Edited'],
        Decisions: ['Decision', 'Made By', 'Decision Date', 'Status', 'Created Date', 'Last Edited']
    };

    // 1) Update columnFilters state to store arrays of selected values:
    const [columnFilters, setColumnFilters] = useState({}); // e.g. { 'Status': ['Open'], 'Priority': [] }

    useEffect(() => {
        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                setRAID(response.data);
                setOriginalRAID(response.data);
            } catch (error) {
                console.error('Error fetching RAID log:', error);
            }
        };

        const fetchProjectSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projectSettings/${projectId}`);
                const fetchedColumns = response.data.raidLogColumns || predefinedColumns;
                setColumns(fetchedColumns);
                // Remove or comment out code that resets visibleColumns here
                // (e.g., don't automatically set all columns as visible on load)
            } catch (error) {
                console.error('Error fetching project settings:', error);
            }
        };

        fetchRAID();
        fetchProjectSettings();
    }, [projectId]);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProjectCode(response.data.project_code);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchProjectData();
        // existing fetchRAID() and fetchProjectSettings() calls...
    }, [projectId]);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    useEffect(() => {
        const handleClickOutside = () => {
            if (contextMenu.visible) {
                setContextMenu({ visible: false, x: 0, y: 0, raidId: null });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenu.visible]);

    useEffect(() => {
        function handleOutsideFilterClick(event) {
            if (activeFilterDropdown && !event.target.closest('.raidlog-context-menu')) {
                setActiveFilterDropdown(null);
            }
        }
        document.addEventListener('mousedown', handleOutsideFilterClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideFilterClick);
        };
    }, [activeFilterDropdown]);

    const fetchRAID = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
            const sortedData = response.data.sort((a, b) => a.raidId - b.raidId);
            setRAID(sortedData);
            setOriginalRAID(sortedData);
        } catch (e) {
            console.error('Error fetching RAID Log:', e);
        }
    };


    const saveProjectSettings = async (updatedColumns) => {
        const settings = {
            projectId: projectId.toString(),
            raidLogColumns: updatedColumns
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/projectSettings`, settings);
            console.log('Project settings saved');
        } catch (error) {
            console.error('Error saving project settings:', error);
        }
    };

    const handleEditChange = (raidId, e) => {
        const { name, value } = e.target;
        const updatedRAIDLog = raidlog.map(item => 
            item.raidId === raidId ? { 
                ...item, 
                [name]: name === 'percentComplete' ? value : value 
            } : item
        );
        setRAID(updatedRAIDLog);
    };

    // After fetching RAID log, set nextRowNumberRef if rows exist
    useEffect(() => {
        if (raidlog.length > 0) {
            const maxId = Math.max(...raidlog.map(item => {
                const parts = `${item.raidId}`.split('-');
                return Number(parts[1] || parts[0]);
            }));
            // Only update if our current ref is lower than maxId + 1
            if (nextRowNumberRef.current <= maxId) {
                nextRowNumberRef.current = maxId + 1;
            }
        }
    }, [raidlog]);

    // Modify handleAddRow to use nextRowNumberRef for permanent id generation
    const handleAddRow = () => {
        const newNumericId = nextRowNumberRef.current;
        nextRowNumberRef.current += 1; // increment for next new row

        const newRow = columns[activeTab].reduce((acc, column) => {
            acc[column.toLowerCase().replace(' ', '')] = '';
            return acc;
        }, { 
            raidId: `${projectCode}-${newNumericId}`,
            raidType: activeTab.slice(0, -1),
            identifiedDate: '', // Leave identifiedDate blank on creation
            createdDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0], // Set to one day before today's date
            lastEdited: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0], // Initialize Last Edited to one day before today's date
            ...(activeTab === 'Actions' && { 
                percentComplete: '', // Initialize Percent Complete as empty string only for Actions
                criticality: '!' // Initialize Criticality with default value '!'
            }) 
        });

        setRAID([...raidlog, newRow]);
        setOriginalRAID([...raidlog, newRow]); // Ensure original RAID log is updated
        setIsEditing(newNumericId); // Set the new row as the one being edited
    };

    const handleSave = async (raidId) => {
        const currentItem = raidlog.find(item => item.raidId === raidId);
        const originalItem = originalRaidLog.find(item => item.raidId === raidId);
        const isNewItem = !currentItem._id;
        
        const hasChanges = Object.keys(currentItem).some(key => 
            key !== 'lastEdited' && key !== '_id' && currentItem[key] !== originalItem?.[key]
        );
        const percentComplete = currentItem.percentComplete === '' ? 0 : Number(currentItem.percentComplete);
        
        const itemToSave = {
            ...currentItem,
            projectId: projectId.toString(),
            due: currentItem.due ? new Date(currentItem.due) : '',
            raidType: currentItem.raidType || activeTab.slice(0, -1),
            status: currentItem.status || (activeTab === 'Actions' ? 'Not Started' : (activeTab === 'Issues' || activeTab === 'Decisions') ? 'Open' : ''),
            priority: currentItem.priority || (activeTab === 'Actions' ? 'Low' : ''),
            severity: currentItem.severity || (activeTab === 'Issues' ? 'Low' : ''),
            createdDate: currentItem.createdDate || new Date().toISOString().split('T')[0],
            percentComplete: percentComplete,
            ...(hasChanges && { lastEdited: new Date().toISOString().split('T')[0] })
        };

        try {
            if (isNewItem) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/raidlog/newItem`, itemToSave);
                if (response.status === 201) {
                    // Update the state with the new _id from MongoDB
                    const updatedItem = { ...itemToSave, _id: response.data._id };
                    setRAID(prev => prev.map(item => 
                        item.raidId === raidId ? updatedItem : item
                    ));
                    setOriginalRAID(prev => prev.map(item => 
                        item.raidId === raidId ? updatedItem : item
                    ));
                }
            } else {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/raidlog/${currentItem._id}`, itemToSave);
                if (response.status === 200) {
                    setRAID(prev => prev.map(item => 
                        item.raidId === raidId ? itemToSave : item
                    ));
                    setOriginalRAID(prev => prev.map(item => 
                        item.raidId === raidId ? itemToSave : item
                    ));
                }
            }
        } catch (error) {
            console.error('Error saving RAID log item:', error);
        }
    };

    const handleDelete = async (raidId) => {
        const raidlogitemToDelete = raidlog.find(item => item.raidId === raidId);
    
        if (!raidlogitemToDelete._id) {
            // Remove item without reassigning IDs
            const updatedRAIDLog = raidlog.filter(item => item.raidId !== raidId);
            setRAID(updatedRAIDLog);
            setOriginalRAID(updatedRAIDLog);
            return;
        }
    
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/raidlog/${raidlogitemToDelete._id}`);
            if (response.status === 200) {
                // Only filter out the deleted row
                const updatedRAIDLog = raidlog.filter(item => item.raidId !== raidId);
                setRAID(updatedRAIDLog);
                setOriginalRAID(updatedRAIDLog);
                await fetchRAID();
            }
        } catch (error) {
            console.error('Error deleting RAID Log item:', error);
        }
    };

    const handleColumnChange = useCallback((column) => {
        setColumns(prevColumns => {
            const currentColumns = prevColumns[activeTab];
            const updatedColumns = currentColumns.includes(column)
                ? currentColumns.filter(col => col !== column)
                : [...currentColumns, column];
            const newColumns = { ...prevColumns, [activeTab]: updatedColumns };
            saveProjectSettings(newColumns); // Save updated columns
            return newColumns;
        });
    }, [activeTab, saveProjectSettings]);

    const handleDoubleClick = (rowId, column) => {
        if (column === 'lastEdited') return; // Prevent editing Last Edited
        setEditableCell({ rowId, column });
    };

    const handleBlur = () => {
        setEditableCell({ rowId: null, column: null });
    };

    const handleContextMenu = (event, raidId) => {
        event.preventDefault();
        setContextMenu({ visible: true, x: event.clientX, y: event.clientY, raidId });
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() + 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    function getCriticalityStyle(value) {
        switch (value) {
            case '!':
                return { color: 'green' };
            case '!!':
                return { color: 'goldenrod' };
            case '!!!':
                return { color: 'red' };
            default:
                return {};
        }
    }

    // 1) Define allColumns before uniqueColumnValues:
    const allColumns = useMemo(() => {
        return [
            ...new Set([
                ...predefinedColumns[activeTab],
                ...columns[activeTab],
                ...(activeTab === 'Actions' ? ['Percent Complete', 'Criticality'] : [])
            ])
        ];
    }, [predefinedColumns, columns, activeTab]);

    // 2) Now define uniqueColumnValues, referencing allColumns safely:
    const uniqueColumnValues = useMemo(() => {
        const values = {};
        raidlog.forEach(item => {
            const colName = item.raidType === activeTab.slice(0, -1) ? item : null;
            if (!colName) return;
            allColumns.forEach(col => {
                const fieldKey =
                    col === 'Created Date' ? 'createdDate' :
                    col === 'Last Edited' ? 'lastEdited' :
                    col === 'Percent Complete' ? 'percentComplete' :
                    col.toLowerCase().replace(' ', '');
                const val = item[fieldKey] ?? '';
                if (!values[col]) values[col] = new Set();
                values[col].add(val.toString());
            });
        });
        Object.keys(values).forEach(col => {
            values[col] = Array.from(values[col]);
        });
        return values;
    }, [raidlog, activeTab, allColumns]);

    // 2) Filter RAID log items using the updated columnFilters:
    const filteredRAIDLog = useMemo(() => {
        const baseFiltered = raidlog.filter(item => item.raidType === activeTab.slice(0, -1));
        return baseFiltered.filter(item => {
            return Object.entries(columnFilters).every(([col, filterVals]) => {
                if (!filterVals.length || !columns[activeTab].includes(col)) return true; // No filter or column is not visible
                const fieldKey =
                    col === 'Created Date' ? 'createdDate' :
                    col === 'Last Edited' ? 'lastEdited' :
                    col === 'Percent Complete' ? 'percentComplete' :
                    col.toLowerCase().replace(' ', '');
                return filterVals.includes((item[fieldKey] ?? '').toString());
            });
        });
    }, [raidlog, activeTab, columnFilters, columns]);

    // 4) In the table header, remove the old filter UI and add an icon that toggles a dropdown:
    //    For simplicity, let's use a simple state to show/hide the dropdown for each column.

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid">
                <div className="row">
                    <main className="col-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="shiny-text h2">RAID Log</h1>
                            <div className="dropdown">
                                <button className="btn btn-primary raidlog-btn-primary" onClick={() => setIsPaneOpen(true)}>
                                    Columns
                                </button>
                            </div>
                        </div>
                        <div className="raidlog-tabs">
                            {['Risks', 'Actions', 'Issues', 'Decisions'].map(tab => (
                                <button
                                    key={tab}
                                    className={`raidlog-tab ${activeTab === tab ? 'active' : ''}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                        {filteredRAIDLog.length === 0 ? (
                            <p>No {activeTab} Found</p>
                        ) : (
                            <div className="raidlog-table-responsive">
                                <table className="raidlog-table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            {allColumns.map((col, index) => columns[activeTab].includes(col) && (
                                                <th key={index}>
                                                    {col}
                                                    <span
                                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                        onClick={() => setActiveFilterDropdown(activeFilterDropdown === col ? null : col)}
                                                    >
                                                        ▼
                                                    </span>
                                                    {activeFilterDropdown === col && (
                                                        <div className="raidlog-context-menu" style={{ position: 'absolute' }}>
                                                            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                                                Filter by {col}
                                                            </div>
                                                            <button onClick={() => {
                                                                setColumnFilters(prev => ({ ...prev, [col]: [] }));
                                                                setActiveFilterDropdown(null);
                                                            }}>Show All</button>
                                                            {uniqueColumnValues[col]?.map(val => (
                                                                <div key={val}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`filter-${col}-${val}`}
                                                                        className = "filter-checkbox"
                                                                        checked={columnFilters[col]?.includes(val) || false}
                                                                        onChange={() => {
                                                                            setColumnFilters(prev => {
                                                                                const newFilters = prev[col] || [];
                                                                                if (newFilters.includes(val)) {
                                                                                    return { ...prev, [col]: newFilters.filter(v => v !== val) };
                                                                                } else {
                                                                                    return { ...prev, [col]: [...newFilters, val] };
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`filter-${col}-${val}`}>{val || '(empty)'}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </th>
                                            ))}
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRAIDLog.map((raidlogitem) => (
                                            <tr key={raidlogitem.raidId} onContextMenu={(e) => handleContextMenu(e, raidlogitem.raidId)}>
                                                <td>{raidlogitem.raidId}</td> {/* Updated ID column */}
                                                {allColumns.map((col, colIndex) => {
                                                    const fieldKey = col === 'Created Date' ? 'createdDate' 
                                                                    : col === 'Last Edited' ? 'lastEdited'
                                                                    : col === 'Percent Complete' ? 'percentComplete'  // Add this mapping
                                                                    : col.toLowerCase().replace(' ', '');
                                                    return (
                                                        columns[activeTab].includes(col) && (
                                                            <td
                                                                key={colIndex}
                                                                style={fieldKey === 'criticality' ? getCriticalityStyle(raidlogitem[fieldKey]) : {}}
                                                                onDoubleClick={() => handleDoubleClick(raidlogitem.raidId, fieldKey)}
                                                            >
                                                                {editableCell.rowId === raidlogitem.raidId && editableCell.column === fieldKey ? (
                                                                    fieldKey === 'action' ? (
                                                                        <textarea
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            rows="3"
                                                                            style={{ width: '100%' }}
                                                                            autoFocus
                                                                        />
                                                                    ) : fieldKey === 'status' ? (
                                                                        <select
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        >
                                                                            {activeTab === 'Actions' ? (
                                                                                <>
                                                                                    <option value="Not Started">Not Started</option>
                                                                                    <option value="Blocked">Blocked</option>
                                                                                    <option value="On Hold">On Hold</option>
                                                                                    <option value="In Progress">In Progress</option>
                                                                                    <option value="Complete">Complete</option>
                                                                                </>
                                                                            ) : activeTab === 'Decisions' ? (
                                                                                <>
                                                                                    <option value="Open">Open</option>
                                                                                    <option value="Closed">Closed</option>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <option value="Open">Open</option>
                                                                                    <option value="Closed">Closed</option>
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                    ) : fieldKey === 'priority' ? (
                                                                        <select
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        >
                                                                            <option value="High">High</option>
                                                                            <option value="Medium">Medium</option>
                                                                            <option value="Low">Low</option>
                                                                        </select>
                                                                    ) : fieldKey === 'due' || fieldKey === 'createdDate' ? (
                                                                        <input
                                                                            type="date"
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] ? new Date(raidlogitem[fieldKey]).toISOString().split('T')[0] : ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            className="raidlog-input"
                                                                            autoFocus
                                                                        />
                                                                    ) : fieldKey === 'lastEdited' ? (
                                                                        <input
                                                                            type="date"
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] ? new Date(new Date(raidlogitem[fieldKey]).setDate(new Date(raidlogitem[fieldKey]).getDate() - 1)).toISOString().split('T')[0] : ''}
                                                                            className="raidlog-input"
                                                                            readOnly // Make Last Edited read-only
                                                                        />
                                                                    ) : fieldKey === 'percentComplete' ? (
                                                                        <input
                                                                            type="number"
                                                                            name="percentComplete"
                                                                            value={raidlogitem.percentComplete ?? ''}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
                                                                                    handleEditChange(raidlogitem.raidId, { 
                                                                                        target: { 
                                                                                            name: 'percentComplete', 
                                                                                            value: value 
                                                                                        } 
                                                                                    });
                                                                                }
                                                                            }}
                                                                            onBlur={() => {
                                                                                // Only save if there's a value or if the field is empty
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            min="0"
                                                                            max="100"
                                                                            className="raidlog-input"
                                                                            autoFocus
                                                                        />
                                                                    ) : fieldKey === 'criticality' ? (
                                                                        <select
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        >
                                                                            <option value="!">!</option>
                                                                            <option value="!!">!!</option>
                                                                            <option value="!!!">!!!</option>
                                                                        </select>
                                                                    ) : (
                                                                        <input
                                                                            type="text"
                                                                            name={fieldKey}
                                                                            value={raidlogitem[fieldKey] || ''}
                                                                            onChange={(e) => handleEditChange(raidlogitem.raidId, e)}
                                                                            onBlur={() => {
                                                                                handleSave(raidlogitem.raidId);
                                                                                handleBlur();
                                                                            }}
                                                                            autoFocus
                                                                        />
                                                                    )
                                                                ) : (
                                                                    fieldKey === 'due' || fieldKey === 'createdDate' || fieldKey === 'lastEdited'
                                                                        ? formatDate(raidlogitem[fieldKey])
                                                                        : fieldKey === 'percentComplete'
                                                                            ? (raidlogitem[fieldKey] !== '' && raidlogitem[fieldKey] !== undefined
                                                                                ? raidlogitem[fieldKey] + '%'
                                                                                : '')
                                                                            : (raidlogitem[fieldKey] || '')
                                                                )}
                                                            </td>
                                                        )
                                                    );
                                                })}
                                                <td>
                                                    <button className="btn btn-danger" onClick={() => handleDelete(raidlogitem.raidId)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <button className="btn btn-success" type="button" onClick={handleAddRow}>Add Row</button>
                    </main>
                </div>
            </div>
            {contextMenu.visible && (
                <div
                    className="context-menu"
                    style={{ top: contextMenu.y, left: contextMenu.x }}
                >
                    <button onClick={() => handleDelete(contextMenu.raidId)}>Delete</button>
                </div>
            )}
            <SlidingPane
                isOpen={isPaneOpen}
                from="right"
                width="400px"
                title="Manage Columns"
                onRequestClose={() => setIsPaneOpen(false)}
            >
                <div className="stakeholder-register-pane-inner">
                    {allColumns.map((col, index) => (
                        <div key={index} className="stakeholder-register-pane-item">
                            <input
                                type="checkbox"
                                checked={columns[activeTab].includes(col)}
                                onChange={() => handleColumnChange(col)}
                            />
                            <label>{col}</label>
                        </div>
                    ))}
                </div>
            </SlidingPane>
        </div>
    );
};

export default RAIDLog;