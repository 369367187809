import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchMeeting = createAsyncThunk(
    'meetings/fetchMeeting',
    async ({ projectId, meetingId }) => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/meetings/${meetingId}`
        );
        return response.data;
    }
);

export const updateMeetingMinutes = createAsyncThunk(
    'meetings/updateMinutes',
    async ({ projectId, meetingId, meetingMinutes }) => {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/meetings/${meetingId}/minutes`,
            { meetingMinutes }
        );
        return { meetingMinutes, response: response.data };
    }
);

export const updateMeetingField = createAsyncThunk(
    'meetings/updateField',
    async ({ projectId, meetingId, field, value }) => {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/meetings/${meetingId}/${field}`,
            { [field]: value }
        );
        return { field, value, response: response.data };
    }
);

export const deleteMeeting = createAsyncThunk(
    'meetings/deleteMeeting',
    async ({ projectId, meetingId }) => {
        await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/meetings/${meetingId}`
        );
        return { projectId, meetingId };
    }
);

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: {
        currentMeeting: null,
        loading: false,
        error: null,
        hasUnsavedChanges: false
    },
    reducers: {
        setHasUnsavedChanges: (state, action) => {
            state.hasUnsavedChanges = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMeeting.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMeeting.fulfilled, (state, action) => {
                state.currentMeeting = action.payload;
                state.loading = false;
                state.hasUnsavedChanges = false;
            })
            .addCase(fetchMeeting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateMeetingMinutes.fulfilled, (state, action) => {
                if (state.currentMeeting) {
                    state.currentMeeting.meetingNotes = action.payload.meetingMinutes;
                    state.hasUnsavedChanges = false;
                }
            })
            .addCase(updateMeetingField.fulfilled, (state, action) => {
                if (state.currentMeeting) {
                    state.currentMeeting[action.payload.field] = action.payload.value;
                }
            });
    }
});

export const { setHasUnsavedChanges } = meetingsSlice.actions;
export default meetingsSlice.reducer;
