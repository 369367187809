/**
 * Security utility functions for input validation and sanitization
 */

/**
 * Validates if a userId is in proper format
 * @param {string} userId - The user ID to validate
 * @returns {boolean} - Whether the ID is valid
 */
export const validateUserId = (userId) => {
  if (!userId) return false;
  
  // Check if userId is a string and has proper format (alphanumeric with possible hyphens)
  // Adjust this regex based on your actual userId format
  return typeof userId === 'string' && /^[a-zA-Z0-9-_]+$/.test(userId);
};

/**
 * Sanitizes text input to prevent XSS attacks
 * @param {string} input - The text to sanitize
 * @returns {string} - Sanitized text
 */
export const sanitizeText = (input) => {
  if (!input) return '';
  if (typeof input !== 'string') return String(input);
  
  // Basic HTML character encoding
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

/**
 * Sanitizes an array of objects by sanitizing specific text fields
 * @param {Array} items - Array of objects to sanitize
 * @param {Array} textFields - Fields to sanitize in each object
 * @returns {Array} - Sanitized array
 */
export const sanitizeDataArray = (items, textFields = ['name', 'title', 'description']) => {
  if (!Array.isArray(items)) return [];
  
  return items.map(item => {
    if (!item) return item;
    
    const sanitizedItem = { ...item };
    textFields.forEach(field => {
      if (sanitizedItem[field]) {
        sanitizedItem[field] = sanitizeText(sanitizedItem[field]);
      }
    });
    
    return sanitizedItem;
  });
};

/**
 * Validates a project ID
 * @param {string} projectId - The project ID to validate
 * @returns {boolean} - Whether the ID is valid
 */
export const validateProjectId = (projectId) => {
  if (!projectId) return false;
  return typeof projectId === 'string' && /^[a-zA-Z0-9-_]+$/.test(projectId);
};

/**
 * Creates a safe URL by encoding parameters and validating IDs
 * @param {string} baseUrl - The base URL path
 * @param {string} id - The ID to append to URL
 * @returns {string} - A safe URL
 */
export const createSafeUrl = (baseUrl, id) => {
  if (!baseUrl || typeof baseUrl !== 'string') return '/';
  if (!id || typeof id !== 'string') return baseUrl;
  
  try {
    return `${baseUrl}/${encodeURIComponent(id)}`;
  } catch (e) {
    console.error('Error creating safe URL:', e);
    return baseUrl;
  }
};

/**
 * Safely parses a date string or returns a fallback
 * @param {string|Date} dateInput - Date to parse
 * @param {Date} fallback - Fallback date if parsing fails
 * @returns {Date} - Parsed date or fallback
 */
export const safeParseDate = (dateInput, fallback = new Date()) => {
  try {
    if (!dateInput) return fallback;
    if (dateInput instanceof Date) return dateInput;
    const parsed = new Date(dateInput);
    return isNaN(parsed.getTime()) ? fallback : parsed;
  } catch (e) {
    console.error('Error parsing date:', e);
    return fallback;
  }
};

/**
 * Safely parses a number or returns a fallback
 * @param {any} value - Value to parse as number
 * @param {number} fallback - Fallback value
 * @returns {number} - Parsed number or fallback
 */
export const safeParseNumber = (value, fallback = 0) => {
  if (value === null || value === undefined) return fallback;
  if (typeof value === 'number' && !isNaN(value)) return value;
  
  try {
    const parsed = Number(value);
    return isNaN(parsed) ? fallback : parsed;
  } catch (e) {
    return fallback;
  }
};

/**
 * Validates if an email is in proper format
 * @param {string} email - The email to validate
 * @returns {boolean} - Whether the email is valid
 */
export const validateEmail = (email) => {
  if (!email || typeof email !== 'string') return false;
  // Basic email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * Validates if a password meets minimum security requirements
 * @param {string} password - The password to validate
 * @param {number} minLength - Minimum length requirement
 * @returns {object} - Validation result and message
 */
export const validatePassword = (password, minLength = 8) => {
  if (!password || typeof password !== 'string') {
    return { valid: false, message: 'Password must be a string' };
  }
  
  if (password.length < minLength) {
    return { valid: false, message: `Password must be at least ${minLength} characters` };
  }
  
  // Check for at least one number
  if (!/\d/.test(password)) {
    return { valid: false, message: 'Password must contain at least one number' };
  }
  
  // Check for at least one special character
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return { valid: false, message: 'Password must contain at least one special character' };
  }
  
  return { valid: true, message: 'Password is valid' };
};

/**
 * Safely checks property existence in an object
 * @param {object} obj - Object to check
 * @param {string} propertyPath - Period-separated property path
 * @param {any} defaultValue - Default value if property doesn't exist
 * @returns {any} - Property value or default
 */
export const safeGet = (obj, propertyPath, defaultValue = null) => {
  if (!obj || typeof obj !== 'object') return defaultValue;
  
  try {
    const properties = propertyPath.split('.');
    let value = obj;
    
    for (const prop of properties) {
      if (value === null || value === undefined || typeof value !== 'object') {
        return defaultValue;
      }
      value = value[prop];
    }
    
    return value === undefined ? defaultValue : value;
  } catch (e) {
    console.error('Error accessing object property:', e);
    return defaultValue;
  }
};

/**
 * Creates a Content Security Policy nonce for inline scripts
 * @returns {string} - Random CSP nonce
 */
export const generateCSPNonce = () => {
  const randomBytes = new Uint8Array(16);
  window.crypto.getRandomValues(randomBytes);
  return Array.from(randomBytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
};

/**
 * Sanitizes content while preserving emojis and formatting
 * Used for AI-generated content that may contain emojis
 * @param {string} input - The text to sanitize
 * @returns {string} - Sanitized text with emojis preserved
 */
export const sanitizeAiContent = (input) => {
  if (!input) return '';
  if (typeof input !== 'string') return String(input);
  
  // Only encode HTML-dangerous characters while preserving emojis and formatting
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

/**
 * Creates safe HTML from AI-generated markdown-like content
 * @param {string} input - Input text with markdown-like formatting
 * @returns {string} - Safely formatted HTML
 */
export const formatAiMarkdown = (input) => {
  if (!input) return '';
  
  // First sanitize the input
  const sanitized = sanitizeAiContent(input);
  
  // Handle basic markdown-like formatting
  return sanitized
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
    .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
    .replace(/\n/g, '<br>'); // Line breaks
};
