import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { isFeatureAvailable } from '../../utils/featureUtils';
import { ACCOUNT_TYPES } from '../../config/featureAccess';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faArrowRight, faChartBar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/dashboardUtils';
import { Link } from 'react-router-dom';

const ProductivityCard = ({ analyticsData }) => {
    const userProfile = useSelector(state => state.profile.data);
    const accountType = userProfile?.accountType;
    const isAdmin = userProfile?.isAdmin || false;

    const canAccessAnalytics = isFeatureAvailable(
        'ADVANCED_ANALYTICS',
        accountType === 'Professional' ? ACCOUNT_TYPES.PROFESSIONAL : accountType,
        isAdmin
    );

    if (!canAccessAnalytics) {
        return (
            <div className="dashboard-card productivity-card">
                <div className="dashboard-card-header">
                    <h2>Daily Productivity</h2>
                    <FontAwesomeIcon icon={faChartLine} />
                </div>
                <div className="dashboard-card-body p-0">
                    <div className="upgrade-container">
                        <div className="upgrade-content">
                            <FontAwesomeIcon 
                                icon={faChartLine} 
                                style={{ fontSize: '2.5rem', color: '#0056b3' }} 
                                className="upgrade-icon mb-3"
                            />
                            <h5 className="fw-bold mb-3">Track Your Progress</h5>
                            <p className="text-muted mb-4">
                                Visualize your daily productivity, track trends, and identify peak performance patterns with our 
                                Professional plan.
                            </p>
                            
                            <Link to="/account" className="btn btn-outline-primary upgrade-btn fw-bold">
                                Upgrade to Professional <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard-card productivity-card">
            <div className="dashboard-card-header">
                <h2 className="shiny-text">Daily Productivity</h2>
                <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="dashboard-card-body">
                {analyticsData && analyticsData.length > 0 ? (
                    <div className="productivity-chart-container">
                        <Line
                            data={{
                                labels: analyticsData.map(entry => formatDate(entry.date)),
                                datasets: [
                                    {
                                        label: 'Total',
                                        data: analyticsData.map(entry => entry.totalPoints),
                                        fill: false,
                                        backgroundColor: 'rgb(0, 122, 255)',
                                        borderColor: 'rgba(0, 122, 255, 0.8)',
                                        borderWidth: 2,
                                    },
                                    {
                                        label: 'Completed',
                                        data: analyticsData.map(entry => entry.completedPoints),
                                        fill: false,
                                        backgroundColor: 'rgb(40, 167, 69)',
                                        borderColor: 'rgba(40, 167, 69, 0.8)',
                                        borderWidth: 2,
                                    },
                                ],
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: 'bottom',
                                        labels: {
                                            boxWidth: 12,
                                            padding: 8,
                                            font: { size: 9 }
                                        }
                                    },
                                    title: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    x: {
                                        display: false,
                                    },
                                    y: {
                                        beginAtZero: true,
                                        display: true,
                                        ticks: {
                                            font: {
                                                size: 9,
                                            }
                                        }
                                    },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <div className="text-center">
                        <p>No productivity data available</p>
                        <p className="text-muted small">Complete tasks in Daily Journal</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductivityCard;
