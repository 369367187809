import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const fetchRaidLogs = createAsyncThunk(
  'tasks/fetchRaidLogs',
  async (projectIds, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/raidlog/fetchRaidLogs`, { projectIds });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching raid logs');
    }
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    raidLogs: [],
    upcomingTasks: [],
    overdueTasks: [],
    noDueDateTasks: [],
    raidLogStats: {
      complete: 0,
      inProgress: 0,
      notStarted: 0,
      blocked: 0
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRaidLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRaidLogs.fulfilled, (state, action) => {
        state.raidLogs = action.payload;
        state.loading = false;
        
        // Calculate RAID log statistics
        const stats = {
          complete: 0,
          inProgress: 0,
          notStarted: 0,
          blocked: 0
        };

        action.payload.forEach(item => {
          if (item.status) {
            const status = item.status.toLowerCase();
            if (status === 'complete') stats.complete++;
            else if (status === 'in progress') stats.inProgress++;
            else if (status === 'not started') stats.notStarted++;
            else if (status === 'blocked') stats.blocked++;
          }
        });

        state.raidLogStats = stats;
      })
      .addCase(fetchRaidLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching raid logs';
      });
  },
});

export default tasksSlice.reducer;

// Selector to filter tasks based on dates
export const filterAndSortTasks = (tasks, projects, today, days, isOverdue = false) => {
  return tasks
    .filter(task => {
      if (!task.due || task.raidType !== 'Action') return false;
      const dueDate = new Date(task.due);
      dueDate.setHours(0, 0, 0, 0);
      dueDate.setDate(dueDate.getDate() + 1);

      if (isOverdue) {
        return dueDate < today && task.status !== 'Complete';
      } else {
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + days);
        return dueDate >= today && dueDate <= futureDate && task.status !== 'Complete';
      }
    })
    .map(task => ({
      ...task,
      projectName: projects.find(project => project._id === task.projectId)?.project_name || 'Unknown Project'
    }))
    .sort((a, b) => new Date(a.due) - new Date(b.due));
};
