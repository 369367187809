import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  checkAuthStatus,
  loginUser,
  logoutUser,
  updateUserProfile as updateProfile,
  updateAccountType
} from '../redux/slices/authSlice';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const [csrfToken, setCsrfToken] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);

    // Select auth state from Redux store
    const { 
        user, 
        isAuthenticated, 
        loading, 
        error, 
        accountType 
    } = useSelector(state => state.auth);

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                await dispatch(checkAuthStatus()).unwrap();
            } catch (error) {
                console.error('Auth initialization error:', error);
            } finally {
                setIsInitialized(true);
            }
        };

        initializeAuth();

        // Fetch CSRF token
        fetch('/api/auth/csrf-token')
            .then(response => response.json())
            .then(data => {
                setCsrfToken(data.state);
                localStorage.setItem('csrfToken', data.state);
            })
            .catch(console.error);
    }, [dispatch]);

    // Don't render children until auth is initialized
    if (!isInitialized) {
        return null; // or a loading spinner
    }

    const login = async (userData) => {
        if (userData.email && userData.password) {
            await dispatch(loginUser({ 
                email: userData.email, 
                password: userData.password, 
                csrfToken: csrfToken || localStorage.getItem('csrfToken')
            }));
        }
    };

    const logout = async () => {
        try {
            await dispatch(logoutUser());
            // Remove stored CSRF token to prevent session leakage
            localStorage.removeItem('csrfToken');
            
            // Clear any other localStorage items that might contain user data
            localStorage.removeItem('lastProject');
            localStorage.removeItem('dashboardPreferences');
            sessionStorage.clear();
        } catch (error) {
            console.error('Logout error:', error);
            // Force state reset even if API call failed
        }
    };

    // Wrapper for the Redux update profile action
    const updateUserProfile = (profileData) => {
        dispatch(updateProfile(profileData));
    };

    // Add method to update account type
    const setAccountType = (newAccountType) => {
        dispatch(updateAccountType(newAccountType));
    };

    // Helper for checking if user has premium features
    const hasPremiumAccess = () => {
        return accountType === 'Professional' || user?.isAdmin === true;
    };

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            user, 
            login, 
            logout, 
            loading,
            error,
            updateUserProfile,
            accountType,
            setAccountType,
            hasPremiumAccess,
            csrfToken // Expose CSRF token for use in other components
        }}>
            {!isInitialized ? null : children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}