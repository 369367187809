import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useBeforeUnload } from 'react-router-dom';
import axios from 'axios';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import './ComponentStyling/ProjectNotes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faProjectDiagram, faBrain } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import NoteNetwork from './NoteNetwork';
import { useDispatch, useSelector } from 'react-redux';
import { isFeatureAvailable } from '../utils/featureUtils';
import { FEATURES } from '../config/featureAccess';
import { 
    fetchNotes, 
    createNote, 
    updateNote, 
    deleteNote, 
    setSelectedNote,
    clearSelectedNote  // Add this import
} from '../redux/slices/notesSlice';
import { fetchUserProfile } from '../redux/slices/profileSlice';

const ProjectNotes = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { notes, selectedNote, loading, error } = useSelector(state => state.notes);
    const profile = useSelector(state => state.profile);
    const auth = useSelector(state => state.auth);  // Move auth selector up
    
    // Now we can use auth safely
    const userId = auth?.user?.id || auth?.data?.id || auth?.userId || localStorage.getItem('userId');
    const accountType = profile?.data?.accountType || auth?.user?.accountType;
    const isAdmin = profile?.data?.isAdmin || auth?.user?.isAdmin || false;

    const [editMode, setEditMode] = useState(false);
    const [noteTitle, setNoteTitle] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [originalTitle, setOriginalTitle] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [quillEditor, setQuillEditor] = useState(null);
    const editorRef = useRef(null);
    const [currentContent, setCurrentContent] = useState('');
    const [initialContent, setInitialContent] = useState('');
    const [showNetwork, setShowNetwork] = useState(false);

    // Keep track of Quill instances to prevent duplicates
    const quillInstanceRef = useRef(null);

    useEffect(() => {
        // Try to fetch profile if we have any form of user ID
        if ((!profile || !profile.data) && userId) {
            dispatch(fetchUserProfile(userId));
        }
        
        // Fallback: if we don't have account type info, check local storage
        if (!accountType) {
            const storedAccountType = localStorage.getItem('accountType');
            if (storedAccountType) {
                // We'd need to dispatch an action to update the store with this account type
            }
        }
    }, [profile, dispatch, userId, accountType]);

    // Update the feature check to include auth.accountType
    const canUseNoteNetwork = 
        (profile?.data?.accountType && isFeatureAvailable('NOTE_NETWORK', profile.data.accountType, isAdmin)) ||
        (auth?.user?.accountType && isFeatureAvailable('NOTE_NETWORK', auth.user.accountType, isAdmin)) ||
        isAdmin ||
        accountType === 'Professional' || 
        accountType === 'Enterprise';

    useEffect(() => {
        // When component mounts or project changes, fetch notes and clear selection
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        
        // Clear selected note first
        dispatch(clearSelectedNote());
        
        // Then fetch new project's notes
        dispatch(fetchNotes(projectId));
        
        // Reset local state
        setNoteTitle('');
        setNoteContent('');
        setCurrentContent('');
        setInitialContent('');
        setOriginalTitle('');
        setHasChanges(false);
        setEditMode(false);
        
        // Add cleanup function to clear selected note when unmounting
        return () => {
            dispatch(clearSelectedNote());
        };
    }, [projectId, dispatch]);

    // Initialize Quill editor when the component mounts or when returning from network view
    useEffect(() => {
        // Clean up function to properly remove Quill instance
        const cleanupQuill = () => {
            // If we have a reference to a Quill instance, remove it properly
            if (quillInstanceRef.current) {
                try {
                    // Remove event listeners and DOM elements added by Quill
                    const container = editorRef.current?.closest('.quill-container');
                    if (container) {
                        // Remove the toolbar to prevent duplication
                        const toolbar = container.querySelector('.ql-toolbar');
                        if (toolbar) {
                            toolbar.remove();
                        }
                    }
                } catch (error) {
                    console.error('Error cleaning up Quill:', error);
                }
                
                // Clear the reference
                quillInstanceRef.current = null;
            }
            
            // Reset the state
            setQuillEditor(null);
        };
        
        const initializeQuill = () => {
            // Clean up any existing instance first
            cleanupQuill();
            
            if (editorRef.current) {
                // Ensure the editor container is empty before initializing
                const toolbar = editorRef.current.parentNode.querySelector('.ql-toolbar');
                if (toolbar) {
                    toolbar.remove();
                }
                
                // Use consistent toolbar configuration with MeetingPage
                const editor = new Quill(editorRef.current, {
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            [{ 'font': [] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'script': 'sub' }, { 'script': 'super' }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'align': ['', 'center', 'right', 'justify'] }],
                            ['clean']
                        ]
                    }
                });
                
                editor.on('text-change', () => {
                    const newContent = editor.root.innerHTML;
                    setCurrentContent(newContent);
                });
                
                editor.root.style.fontSize = '16px'; // Set default font size
                setQuillEditor(editor);
                quillInstanceRef.current = editor;
                
                // Initially hide the Quill container properly
                const quillContainer = document.querySelector('.quill-container');
                if (quillContainer) {
                    quillContainer.style.display = 'none';
                    quillContainer.style.visibility = 'hidden';
                }
                
                return editor;
            }
            return null;
        };

        // Only initialize if we need to
        if (!quillEditor && !showNetwork) {
            const editor = initializeQuill();
            
            // If we have a selected note, update the editor content
            if (editor && selectedNote && selectedNote.content) {
                editor.root.innerHTML = selectedNote.content;
            }
        }
        
        // Clean up when toggling to network view or unmounting
        return () => {
            if (showNetwork) {
                cleanupQuill();
            }
        };
    }, [showNetwork]);

    // Toggle edit mode with improved debugging
    useEffect(() => {
        if (!selectedNote) return;
        
        const quillContainer = document.querySelector('.quill-container');
        const viewContainer = document.querySelector('.note-content-view');
        
        if (editMode) {
            if (quillEditor) {
                quillEditor.root.innerHTML = noteContent || '<p><br></p>';
            }
            
            if (quillContainer) {
                quillContainer.style.display = 'flex';
                quillContainer.style.visibility = 'visible';
                quillContainer.classList.add('editing');
            }
            
            if (viewContainer) {
                viewContainer.style.display = 'none';
                viewContainer.style.visibility = 'hidden';
            }
            
            setTimeout(() => {
                if (quillEditor) quillEditor.focus();
            }, 100);
        } else {
            if (quillContainer) {
                quillContainer.style.display = 'none';
                quillContainer.style.visibility = 'hidden';
                quillContainer.classList.remove('editing');
            }
            
            if (viewContainer) {
                viewContainer.style.display = 'block';
                viewContainer.style.visibility = 'visible';
                viewContainer.innerHTML = noteContent || '<p><br></p>';
            }
        }
    }, [editMode, selectedNote, quillEditor, noteContent]);

    // Track content changes
    useEffect(() => {
        if (selectedNote) {
            const titleChanged = noteTitle !== originalTitle && originalTitle !== '';
            const contentChanged = currentContent !== initialContent && initialContent !== '';
            setHasChanges(titleChanged || contentChanged);
        }
    }, [noteTitle, currentContent, originalTitle, initialContent, selectedNote]);

    // Add keyboard shortcut for saving (Ctrl+S or Cmd+S)
    useEffect(() => {
        const handleKeyDown = async (e) => {
            if ((e.metaKey || e.ctrlKey) && e.key === 's') {
                e.preventDefault(); // Prevent browser's save dialog
                if (editMode && hasChanges) {
                    await saveNote();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [editMode, hasChanges, currentContent, noteTitle]);

    // Warn before closing or navigating away with unsaved changes
    useBeforeUnload(
        useCallback(
            (event) => {
                if (hasChanges) {
                    event.preventDefault();
                    return (event.returnValue = "You have unsaved changes. Are you sure you want to leave?");
                }
            },
            [hasChanges]
        )
    );

    // Update the effect for handling selectedNote changes to ensure content appears in view mode
    useEffect(() => {
        if (selectedNote) {
            setNoteTitle(selectedNote.title);
            setNoteContent(selectedNote.content);
            setCurrentContent(selectedNote.content);
            setInitialContent(selectedNote.content);
            setOriginalTitle(selectedNote.title);
            
            // Make sure the view container is visible when a note is selected
            const viewContainer = document.querySelector('.note-content-view');
            if (viewContainer && !editMode) {
                viewContainer.style.display = 'block';
                viewContainer.style.visibility = 'visible';
            }
            
            // If the quill editor exists, update its content as well
            if (quillEditor) {
                quillEditor.root.innerHTML = selectedNote.content || '<p><br></p>';
            }
        }
    }, [selectedNote, quillEditor]);

    // Let's add a debug effect to help understand what's happening with the content display
    useEffect(() => {
        // Ensure content is always visible when a note is selected
        if (selectedNote && !editMode) {
            // Delayed execution to ensure DOM is updated
            setTimeout(() => {
                const viewContainer = document.querySelector('.note-content-view');
                if (viewContainer) {
                    viewContainer.style.display = 'block';
                    viewContainer.style.visibility = 'visible';
                    
                    // Force content update - this is key to fixing the issue
                    if (!viewContainer.innerHTML || viewContainer.innerHTML === '<p><br></p>') {
                        viewContainer.innerHTML = noteContent || '<p>No content</p>';
                    }
                }
            }, 50);
        }
    }, [selectedNote, noteContent, editMode]);

    // Update the effect for setting content in view mode
    useEffect(() => {
        if (selectedNote && !editMode) {
            // Delayed execution to ensure DOM is updated
            setTimeout(() => {
                const viewContainer = document.querySelector('.note-content-view');
                if (viewContainer) {
                    // Make sure container is visible
                    viewContainer.style.display = 'block';
                    viewContainer.style.visibility = 'visible';
                    
                    // Set content explicitly, preserving all HTML attributes and classes
                    viewContainer.innerHTML = noteContent || '<p>No content</p>';
                    
                    // Add debug logs to check for alignment classes
                    const alignCenterElements = viewContainer.querySelectorAll('.ql-align-center');
                    const alignRightElements = viewContainer.querySelectorAll('.ql-align-right');
                }
            }, 50);
        }
    }, [selectedNote, noteContent, editMode]);

    const createNewNote = async () => {
        try {
            // Create the note and get the response
            const newNote = await dispatch(createNote(projectId)).unwrap();
            
            // Explicitly set the new note as selected
            dispatch(setSelectedNote(newNote));
            
            // Initialize the editor state with empty content
            setNoteTitle(newNote.title || 'Untitled Note');
            setNoteContent(newNote.content || '<p><br></p>');
            setCurrentContent(newNote.content || '<p><br></p>');
            setInitialContent(newNote.content || '<p><br></p>');
            setOriginalTitle(newNote.title || 'Untitled Note');
            setHasChanges(false);
            
            // CRITICAL: Set edit mode AFTER the note is created and selected
            setEditMode(true);
            
            // Show and properly position the Quill container immediately
            setTimeout(() => {
                const quillContainer = document.querySelector('.note-editor .quill-container');
                const viewContainer = document.querySelector('.note-editor .note-content-view');
                
                // Hide the view container
                if (viewContainer) {
                    viewContainer.style.display = 'none';
                    viewContainer.style.visibility = 'hidden';
                }
                
                // Show the Quill container
                if (quillContainer) {
                    quillContainer.style.display = 'flex';
                    quillContainer.style.visibility = 'visible';
                    quillContainer.style.alignItems = 'flex-start';
                    quillContainer.classList.add('editing');
                }
                
                // Initialize Quill if needed or update its content
                if (!quillEditor) {
                    const editor = new Quill(editorRef.current, {
                        theme: 'snow',
                        modules: {
                            toolbar: [
                                [{ 'font': [] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'align': ['', 'center', 'right', 'justify'] }],
                                ['clean']
                            ]
                        }
                    });
                    
                    editor.on('text-change', () => {
                        setCurrentContent(editor.root.innerHTML);
                    });
                    
                    editor.root.style.fontSize = '16px';
                    setQuillEditor(editor);
                    quillInstanceRef.current = editor;
                } else {
                    quillEditor.root.innerHTML = '<p><br></p>';
                }
                
                // Focus on the editor to allow immediate typing
                setTimeout(() => {
                    if (quillEditor) {
                        quillEditor.focus();
                    }
                }, 10);
            }, 100);
            
        } catch (error) {
            console.error('Error creating note:', error);
        }
    };

    const saveNote = async () => {
        if (!selectedNote) return;

        try {
            setIsSaving(true);
            const contentToSave = editMode && quillEditor ? quillEditor.root.innerHTML : currentContent;

            await dispatch(updateNote({
                projectId,
                noteId: selectedNote._id,
                title: noteTitle,
                content: contentToSave
            })).unwrap();

            // Refresh the notes so the sidebar displays the updated title and content
            await dispatch(fetchNotes(projectId));

            setNoteContent(contentToSave);
            setCurrentContent(contentToSave);
            setInitialContent(contentToSave);
            setOriginalTitle(noteTitle);
            setHasChanges(false);
            setEditMode(false);

            // Show the view container with updated content
            const viewContainer = document.querySelector('.note-content-view');
            if (viewContainer) {
                viewContainer.innerHTML = contentToSave;
                viewContainer.style.display = 'block';
                viewContainer.style.visibility = 'visible';
            }

        } catch (error) {
            console.error('Error saving note:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteNote = async (noteId) => {
        if (!window.confirm('Are you sure you want to delete this note?')) return;

        try {
            await dispatch(deleteNote({ projectId, noteId })).unwrap();
        } catch (error) {
            console.error('Error deleting note:', error);
        }
    };

    // Update the selectNote function to better preserve formatting
    const selectNote = (note) => {
        dispatch(setSelectedNote(note));
        setNoteTitle(note.title);
        setNoteContent(note.content);
        setCurrentContent(note.content);
        setInitialContent(note.content);
        setOriginalTitle(note.title);
        setHasChanges(false);
        setEditMode(false);
        
        // Explicitly ensure the view is displayed with content, preserving formatting
        const viewContainer = document.querySelector('.note-content-view');
        if (viewContainer) {
            viewContainer.style.display = 'block';
            viewContainer.style.visibility = 'visible';
            
            // Set the innerHTML directly to preserve all classes and formatting
            viewContainer.innerHTML = note.content || '<p><br></p>';
        }
        
        if (quillEditor) {
            quillEditor.root.innerHTML = note.content || '<p><br></p>';
        }
    };

    // Update the handleEditClick function to ensure content is properly loaded
    const handleEditClick = () => {
        if (selectedNote) {
            const quillContainer = document.querySelector('.note-editor .quill-container');
            const viewContainer = document.querySelector('.note-editor .note-content-view');

            // Hide the view container
            if (viewContainer) {
                viewContainer.style.display = 'none';
                viewContainer.style.visibility = 'hidden';
            }

            // Ensure the Quill editor is initialized
            if (!quillEditor) {
                const editor = new Quill(editorRef.current, {
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            [{ 'font': [] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'script': 'sub' }, { 'script': 'super' }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'align': ['', 'center', 'right', 'justify'] }],
                            ['clean']
                        ]
                    }
                });

                editor.on('text-change', () => {
                    setCurrentContent(editor.root.innerHTML);
                });

                editor.root.style.fontSize = '16px';
                setQuillEditor(editor);
                quillInstanceRef.current = editor;
                
                // CRITICAL FIX: Ensure content is loaded using selectedNote directly
                const contentToLoad = selectedNote.content || '<p><br></p>';
                editor.root.innerHTML = contentToLoad;
            } else {
                // CRITICAL FIX: Use selectedNote directly to ensure the most current content
                const contentToLoad = selectedNote.content || '<p><br></p>';
                quillEditor.root.innerHTML = contentToLoad;
            }

            // Show and properly position the Quill container
            if (quillContainer) {
                quillContainer.style.display = 'flex';
                quillContainer.style.visibility = 'visible';
                quillContainer.style.alignItems = 'flex-start';
                quillContainer.classList.add('editing');
            }

            // ADDED: Log for debugging
            console.log('Edit mode activated, content loaded:', selectedNote.content ? 'yes' : 'no');

            setEditMode(true);
            
            // ADDED: Give focus to the editor after a small delay to ensure it's ready
            setTimeout(() => {
                if (quillEditor) {
                    quillEditor.focus();
                }
            }, 10);
        } else {
            console.error('Cannot enter edit mode: No note selected');
        }
    };

    // Add this new handler for title changes
    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setNoteTitle(newTitle);
        
        // Don't trigger immediate save, just track the change
        // The title will be saved when the user clicks Save or presses Ctrl+S
    };

    const handleNodeClick = (nodeId) => {
        const note = notes.find(n => n._id === nodeId);
        if (note) {
            selectNote(note);
            setShowNetwork(false);
        }
    };

    const toggleNetworkView = () => {
        if (!canUseNoteNetwork) {
            alert('Neural Network visualization is only available for Pro and Enterprise plans. Please upgrade to access this feature.');
            return;
        }
        
        // If we're showing the network view, we need to clean up the editor
        if (!showNetwork) {
            // Remove any existing editor before switching to network view
            const container = editorRef.current?.closest('.quill-container');
            if (container) {
                const toolbar = container.querySelector('.ql-toolbar');
                if (toolbar) {
                    toolbar.remove();
                }
            }
            setQuillEditor(null);
            quillInstanceRef.current = null;
        }
        setShowNetwork(!showNetwork);
    };

    const handleReturnFromNetwork = (nodeId) => {
        setShowNetwork(false);
        
        // We'll initialize the editor in the effect when showNetwork changes
        // Let this complete first
        setTimeout(() => {
            const note = notes.find(n => n._id === nodeId);
            if (note) {
                selectNote(note);
            }
        }, 50);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="notes-container">
                <div className="notes-sidebar">
                    <div className="notes-sidebar-header">
                        <h2>Notes</h2>
                        <div className="notes-sidebar-actions">
                            <button className="sidebar-button new-note-btn" onClick={createNewNote}>
                                <FontAwesomeIcon icon={faPlus} className="fa-icon" /> New Note
                            </button>
                            {canUseNoteNetwork && (
                                <button 
                                    className="sidebar-button network-view-btn" 
                                    onClick={toggleNetworkView}
                                >
                                    <FontAwesomeIcon icon={faProjectDiagram} className="fa-icon" /> Network
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="notes-list">
                        {notes.map(note => (
                            <div
                                key={note._id}
                                className={`note-item ${selectedNote?._id === note._id ? 'selected' : ''}`}
                                onClick={() => selectNote(note)}
                            >
                                <div className="note-item-title">{note.title}</div>
                                <div className="note-item-date">{formatDate(note.created_date)}</div>
                                <button
                                    className="btn btn-danger btn-sm delete-note"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteNote(note._id);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="note-editor">
                    {selectedNote ? (
                        <>
                            <div className="note-editor-header">
                                {editMode ? (
                                    <input
                                        type="text"
                                        className="note-title-input"
                                        value={noteTitle}
                                        onChange={handleTitleChange}
                                    />
                                ) : (
                                    <h2>{noteTitle}</h2>
                                )}
                                <div className="note-actions">
                                    {editMode ? (
                                        hasChanges && (
                                            <span className="save-prompt">
                                                Press {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + S to save
                                            </span>
                                        )
                                    ) : (
                                        <button className="btn btn-primary" onClick={handleEditClick}>
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="note-editor-content">
                                <div className="quill-container">
                                    <div ref={editorRef} className="quill-editor"></div>
                                </div>
                                
                                {/* Don't use dangerouslySetInnerHTML, we'll manage content directly */}
                                <div className="note-content-view"></div>
                            </div>
                        </>
                    ) : (
                        <div className="no-note-selected">
                            <p>Select a note or create a new one</p>
                        </div>
                    )}
                </div>
                {showNetwork && (
                    <div className="network-overlay">
                        <NoteNetwork 
                            notes={notes} 
                            onNodeClick={handleReturnFromNetwork}
                            projectId={projectId}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectNotes;
