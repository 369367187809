import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from "../Components/AuthContext.js"; 
import NavbarAuth from '../Components/Navbar_Authenticated';
import axiosInstance from '../axiosConfig'; // Ensure axiosInstance is used
import { loadStripe } from '@stripe/stripe-js';
import "./PageCSS/Account.css";
import outlookIcon from "../Components/Images/microsoft-outlook-icon.png";
import jiraIcon from "../Components/Images/jira-icon.jpeg";
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { FiUser, FiMail, FiClock, FiShield, FiActivity, FiSettings, FiGrid, FiCreditCard, FiCheck } from 'react-icons/fi';
import Footer from '../Components/Footer';
import PaymentForm from '../Components/PaymentForm';
import ConfirmationModal from '../Components/ConfirmationModal';

// Initialize Stripe
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AccountPage = () => {
    const { user, isAuthenticated, updateUserProfile } = useAuth();
    const [userProfile, setUserProfile] = useState(null);
    // Added state for tab navigation
    const [activeTab, setActiveTab] = useState("account");
    const [outlookConnected, setOutlookConnected] = useState(false);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imageRef = useRef(null);
    const [newEmail, setNewEmail] = useState('');
    const [verifyEmail, setVerifyEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailChangeError, setEmailChangeError] = useState('');
    const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
    const [billingHistory, setBillingHistory] = useState(null);
    const [isLoadingBilling, setIsLoadingBilling] = useState(false);
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [showPayment, setShowPayment] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);
    const [modalConfig, setModalConfig] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
        type: 'info',
        confirmText: 'Confirm'
    });

    useEffect(() => {
        let isMounted = true;

        const fetchUser = async (userId) => {
            try {
                console.log('Fetching user information for user ID:', userId);
                const response = await axiosInstance.get(`/api/user/${userId}`, { withCredentials: true });
                console.log('User information fetched:', response.data);
                if (isMounted) {
                    setUserProfile(response.data);
                }
            } catch (e) {
                console.error('Error fetching user data:', e);
                if (e.response) {
                    console.error('Response status:', e.response.status);
                    console.error('Response data:', e.response.data);
                }
            }
        };

        if (isAuthenticated && user) {
            console.log('User from context:', user);
            const userId = user.user_id;

            fetchUser(userId);
        } else {
            console.log('User is not authenticated or not available');
        }

        return () => {
            console.log('AccountPage component unmounted');
            isMounted = false;
        };

    }, [isAuthenticated, user]);

    useEffect(() => {
        if (!isAuthenticated) {
            console.error('User is not authenticated');
        }
        if (!user) {
            console.error('User data is not available in context');
        }
        if (userProfile) {
            console.log('User profile data:', userProfile);
        }
    }, [isAuthenticated, user, userProfile]);

    // Updated handler for Outlook OAuth to fetch CSRF state from correct endpoint
    const handleOutlookOAuth = async () => {
        const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID;
        const redirectUri = encodeURIComponent(process.env.REACT_APP_OUTLOOK_REDIRECT_URI);
        const responseType = 'code';
        const scope = encodeURIComponent('openid profile email offline_access https://outlook.office.com/calendars.read');
        
        // Use the correct endpoint path
        const response = await axiosInstance.get('/auth/csrf-token', { withCredentials: true });
        const state = response.data.state;
        
        const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=${state}`;
        window.location.href = authUrl;
    };

    // New effect to check for OAuth code in URL - now including state in the POST request
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state'); // Get state from URL
        if (code) {
            axiosInstance.post(`/auth/outlook/callback`, { code, state }, { withCredentials: true })
                .then(response => {
                    const { accessToken } = response.data;
                    if (accessToken) {
                        localStorage.setItem('outlookAccessToken', accessToken);
                        // Optionally update user context or state here
                    }
                    // Remove code and state from URL
                    params.delete('code');
                    params.delete('state');
                    const newUrl = window.location.pathname + (params.toString() ? '?' + params.toString() : '');
                    window.history.replaceState({}, document.title, newUrl);
                })
                .catch(error => {
                    console.error('Error exchanging code for token:', error);
                });
        }
    }, []);

    useEffect(() => {
        // Check if Outlook is connected by looking for the token
        const outlookToken = localStorage.getItem('outlookAccessToken');
        setOutlookConnected(!!outlookToken);
    }, []);

    const handleDisconnectOutlook = () => {
        localStorage.removeItem('outlookAccessToken');
        setOutlookConnected(false);
        // Add any additional cleanup or API calls needed
    };

    const handleProfilePictureSelect = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            setTempImage(reader.result);
            setCropModalOpen(true);
        };
        reader.readAsDataURL(file);
    };

    const handleCropComplete = async () => {
        if (!completedCrop || !imageRef.current) return;

        const canvas = document.createElement('canvas');
        const image = imageRef.current;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
        );

        // Convert canvas to blob
        canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append('image', blob, 'cropped-profile.png');

            setUploading(true);
            try {
                const response = await axiosInstance.post('/api/account/upload-profile-picture', formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Update both local state and auth context
                setUserProfile(prev => ({
                    ...prev,
                    profilePicture: response.data.url
                }));
                updateUserProfile({ profilePicture: response.data.url });
                
                setCropModalOpen(false);
                setTempImage(null);
            } catch (error) {
                console.error('Error uploading profile picture:', error);
                alert('Failed to upload profile picture');
            } finally {
                setUploading(false);
            }
        }, 'image/png');
    };

    const handleEmailChange = async (e) => {
        e.preventDefault();
        setEmailChangeError('');
        setEmailChangeSuccess(false);

        if (newEmail !== verifyEmail) {
            setEmailChangeError('Email addresses do not match');
            return;
        }

        try {
            const response = await axiosInstance.post('/api/account/change-email', {
                newEmail,
                password
            }, { withCredentials: true });

            setUserProfile(prev => ({
                ...prev,
                email: response.data.email
            }));
            updateUserProfile({ email: response.data.email });
            setEmailChangeSuccess(true);
            
            // Reset form
            setNewEmail('');
            setVerifyEmail('');
            setPassword('');
            setIsEditingEmail(false);
            
            // Clear success message after delay
            setTimeout(() => {
                setEmailChangeSuccess(false);
            }, 3000);
        } catch (error) {
            setEmailChangeError(error.response?.data?.message || 'Failed to change email');
        }
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setPasswordChangeError('');
        setPasswordChangeSuccess(false);

        if (newPassword !== verifyPassword) {
            setPasswordChangeError('New passwords do not match');
            return;
        }

        try {
            await axiosInstance.post('/api/account/change-password', {
                currentPassword,
                newPassword,
                verifyPassword
            }, { withCredentials: true });

            setPasswordChangeSuccess(true);
            
            // Reset form
            setCurrentPassword('');
            setNewPassword('');
            setVerifyPassword('');
            setIsEditingPassword(false);
            
            // Clear success message after delay
            setTimeout(() => {
                setPasswordChangeSuccess(false);
            }, 3000);
        } catch (error) {
            setPasswordChangeError(error.response?.data?.message || 'Failed to change password');
        }
    };

    const renderProfilePictureSection = () => (
        <div className="profile-picture-section">
            <div className="profile-picture-container">
                {userProfile?.profilePicture ? (
                    <img 
                        src={userProfile.profilePicture} 
                        alt="Profile" 
                        className="profile-picture"
                    />
                ) : (
                    <div className="profile-picture-placeholder">
                        <FiUser size={60} />
                    </div>
                )}
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleProfilePictureSelect}
                    accept="image/*"
                    style={{ display: 'none' }}
                />
                <button 
                    onClick={() => fileInputRef.current.click()}
                    disabled={uploading}
                    className="upload-picture-button"
                >
                    {uploading ? 'Uploading...' : 'Change Picture'}
                </button>
            </div>

            {cropModalOpen && (
                <div className="crop-modal">
                    <div className="crop-container">
                        <ReactCrop
                            crop={crop}
                            onChange={c => setCrop(c)}
                            onComplete={c => setCompletedCrop(c)}
                            aspect={1}
                        >
                            <img
                                ref={imageRef}
                                src={tempImage}
                                alt="Crop preview"
                            />
                        </ReactCrop>
                        <div className="crop-actions">
                            <button 
                                onClick={handleCropComplete}
                                disabled={uploading}
                                className="save-crop-button"
                            >
                                {uploading ? 'Saving...' : 'Save'}
                            </button>
                            <button 
                                onClick={() => {
                                    setCropModalOpen(false);
                                    setTempImage(null);
                                }}
                                className="cancel-crop-button"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const renderAccountInformation = () => (
        <div className="account-container">
            <div className="account-header">
                {renderProfilePictureSection()}
                <div className="account-info">
                    <h1 className="account-name">
                        {userProfile?.firstname} {userProfile?.lastname}
                    </h1>
                    <p className="account-email">{userProfile?.email}</p>
                    <div className="time-zone-info">
                        <FiClock />
                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </div>
                </div>
            </div>

            <div className="account-details">
                <div className="detail-card">
                    <h3><FiUser /> Personal Information</h3>
                    <div className="detail-field">
                        <span className="detail-label">First Name</span>
                        <div className="detail-value">{userProfile?.firstname}</div>
                    </div>
                    <div className="detail-field">
                        <span className="detail-label">Last Name</span>
                        <div className="detail-value">{userProfile?.lastname}</div>
                    </div>
                    <div className="detail-field">
                        <span className="detail-label">Email</span>
                        {isEditingEmail ? (
                            <form onSubmit={handleEmailChange} className="email-change-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="New Email Address"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Verify New Email Address"
                                        value={verifyEmail}
                                        onChange={(e) => setVerifyEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="Current Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                {emailChangeError && (
                                    <div className="error-message">{emailChangeError}</div>
                                )}
                                {emailChangeSuccess && (
                                    <div className="success-message">Email updated successfully!</div>
                                )}
                                <div className="form-actions">
                                    <button type="submit" className="save-button">
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => {
                                            setIsEditingEmail(false);
                                            setEmailChangeError('');
                                            setNewEmail('');
                                            setVerifyEmail('');
                                            setPassword('');
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="detail-value">{userProfile?.email}</div>
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditingEmail(true)}
                                >
                                    Change Email
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className="detail-card">
                    <h3><FiShield /> Account Security</h3>
                    <div className="detail-field">
                        <span className="detail-label">Password</span>
                        {isEditingPassword ? (
                            <form onSubmit={handlePasswordChange} className="password-change-form">
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="Current Password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="Verify New Password"
                                        value={verifyPassword}
                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                {passwordChangeError && (
                                    <div className="error-message">{passwordChangeError}</div>
                                )}
                                {passwordChangeSuccess && (
                                    <div className="success-message">Password updated successfully!</div>
                                )}
                                <div className="form-actions">
                                    <button type="submit" className="save-button">
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => {
                                            setIsEditingPassword(false);
                                            setPasswordChangeError('');
                                            setCurrentPassword('');
                                            setNewPassword('');
                                            setVerifyPassword('');
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="detail-value">••••••••</div>
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditingPassword(true)}
                                >
                                    Change Password
                                </button>
                            </>
                        )}
                    </div>
                    <div className="detail-field">
                        <span className="detail-label">Last Password Change</span>
                        <div className="detail-value">
                            {userProfile?.lastPasswordChange 
                                ? new Date(userProfile.lastPasswordChange).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })
                                : 'Never'}
                        </div>
                    </div>
                </div>

                <div className="detail-card">
                    <h3><FiActivity /> Account Activity</h3>
                    <div className="detail-field">
                        <span className="detail-label">Member Since</span>
                        <div className="detail-value">
                            {userProfile?.memberSince 
                                ? new Date(userProfile.memberSince).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })
                                : 'Not available'}
                        </div>
                    </div>
                    <div className="detail-field">
                        <span className="detail-label">Last Login</span>
                        <div className="detail-value">
                            {userProfile?.lastLogin 
                                ? new Date(userProfile.lastLogin).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })
                                : 'Not available'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Add this helper function
    const capitalizeWords = (str) => {
        return str.split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const fetchBillingHistory = async () => {
        if (user?.user_id) {
            setIsLoadingBilling(true);
            try {
                const response = await axiosInstance.get(`/api/account/billing-history/${user.user_id}`);
                setBillingHistory(response.data);
            } catch (error) {
                console.error('Error fetching billing history:', error);
            } finally {
                setIsLoadingBilling(false);
            }
        }
    };

    useEffect(() => {
        if (activeTab === 'billing') {
            fetchBillingHistory();
        }
    }, [user, activeTab]);

    const handleSubscriptionChange = async (newPlan, newBillingCycle) => {
        if (newPlan === 'professional') {
            setRegistrationData({
                billingCycle: newBillingCycle,
                subscriptionTier: newPlan,
                userData: {
                    name: `${userProfile.firstname} ${userProfile.lastname}`,
                    email: userProfile.email
                }
            });
            setShowPayment(true);
            return;
        }

        // Show confirm modal for downgrade
        setModalConfig({
            isOpen: true,
            title: 'Confirm Downgrade',
            message: 'Are you sure you want to downgrade to the free plan? You will maintain access to professional features until the end of your current billing period.',
            type: 'warning',
            confirmText: 'Downgrade',
            onConfirm: async () => {
                try {
                    await axiosInstance.post('/api/account/change-subscription', {
                        newPlan,
                        billingCycle: 'none'
                    }, { withCredentials: true });
                    
                    // Refresh billing history
                    fetchBillingHistory();
                } catch (error) {
                    console.error('Error changing subscription:', error);
                    setModalConfig({
                        isOpen: true,
                        title: 'Error',
                        message: 'Failed to change subscription plan. Please try again.',
                        type: 'danger',
                        confirmText: 'OK',
                        onConfirm: () => {}
                    });
                }
            }
        });
    };

    const handlePaymentSubmit = async (paymentDetails) => {
        try {
            const response = await axiosInstance.post('/api/account/change-subscription', {
                newPlan: 'professional',
                billingCycle: registrationData.billingCycle,
                paymentDetails
            }, { withCredentials: true });

            if (response.data.requiresAction && response.data.clientSecret) {
                const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                const { error } = await stripeInstance.confirmCardPayment(response.data.clientSecret);
                
                if (error) {
                    throw new Error(error.message);
                }

                // After successful payment confirmation
                await axiosInstance.post('/api/account/confirm-payment', {
                    subscriptionId: response.data.subscriptionId
                }, { withCredentials: true });
            }

            setShowPayment(false);
            fetchBillingHistory();
        } catch (error) {
            console.error('Payment error:', error);
            alert('Payment failed: ' + (error.message || 'An error occurred'));
        }
    };

    const handleResumeSubscription = async () => {
        // Show confirm modal for resuming subscription
        setModalConfig({
            isOpen: true,
            title: 'Resume Subscription',
            message: 'Would you like to resume your subscription? Your billing cycle will continue as before and you\'ll retain access to all professional features.',
            type: 'success',
            confirmText: 'Resume Subscription',
            onConfirm: async () => {
                try {
                    const response = await axiosInstance.post('/api/account/resume-subscription', {}, 
                        { withCredentials: true }
                    );
                    
                    if (response.data.success) {
                        fetchBillingHistory();
                    }
                } catch (error) {
                    console.error('Error resuming subscription:', error);
                    setModalConfig({
                        isOpen: true,
                        title: 'Error',
                        message: 'Failed to resume subscription. Please try again.',
                        type: 'danger',
                        confirmText: 'OK',
                        onConfirm: () => {}
                    });
                }
            }
        });
    };

    return (
        <div>
            <NavbarAuth />
            <div className="page-header">
                Account Settings
            </div>
            <div className="tab-navigation">
                <button 
                    className={`tab-button ${activeTab === "account" ? "active" : ""}`} 
                    onClick={() => setActiveTab("account")}
                >
                    <FiSettings />
                    Account
                </button>
                <button 
                    className={`tab-button ${activeTab === "integrations" ? "active" : ""}`} 
                    onClick={() => setActiveTab("integrations")}
                >
                    <FiGrid />
                    Integrations
                </button>
                <button 
                    className={`tab-button ${activeTab === "billing" ? "active" : ""}`} 
                    onClick={() => setActiveTab("billing")}
                >
                    <FiCreditCard />
                    Billing
                </button>
            </div>
            {activeTab === "account" && renderAccountInformation()}
            {activeTab === "integrations" && (
                <div className="integrations-container">
                    <div className="integration-card">
                        <div className="integration-header">
                            <img 
                                src={outlookIcon} 
                                alt="Outlook" 
                                className="integration-icon"
                            />
                            <h3 className="integration-title">Microsoft Outlook Calendar</h3>
                        </div>
                        <div className="integration-status">
                            <span className={`status-indicator ${outlookConnected ? 'status-connected' : 'status-disconnected'}`}></span>
                            {outlookConnected ? 'Connected' : 'Not connected'}
                        </div>
                        <p className="integration-description">
                            Sync your Outlook calendar with PMHub to manage all your events and meetings in one place. 
                            Stay organized and never miss important project deadlines or meetings.
                        </p>
                        {outlookConnected ? (
                            <button 
                                className="integration-button connected"
                                onClick={handleDisconnectOutlook}
                            >
                                Disconnect Outlook Calendar
                            </button>
                        ) : (
                            <button 
                                className="integration-button"
                                onClick={handleOutlookOAuth}
                            >
                                Connect Outlook Calendar
                            </button>
                        )}
                    </div>
                    
                    {/* Template for future integrations */}
                    <div className="integration-card">
                        <div className="integration-header">
                            <img 
                                src={jiraIcon} 
                                alt="Jira" 
                                className="integration-icon"
                            />
                            <h3 className="integration-title">Jira Integration</h3>
                        </div>
                        <div className="integration-status">
                            <span className="status-indicator status-disconnected"></span>
                            Coming Soon
                        </div>
                        <p className="integration-description">
                            Seamlessly integrate your Jira projects with PMHub. 
                            Track issues, manage sprints, and keep your project data in sync.
                        </p>
                        <button className="integration-button" disabled>
                            Coming Soon
                        </button>
                    </div>
                </div>
            )}
            {activeTab === "billing" && (
                <div className="billing-container">
                    <div className="billing-header">
                        <h2><FiCreditCard /> Subscription & Billing</h2>
                        <p>Manage your subscription and billing details</p>
                    </div>
                    {isLoadingBilling ? (
                        <div className="loading-spinner">Loading billing information...</div>
                    ) : billingHistory ? (
                        <>
                            {showPayment ? (
                                <div className="payment-form-container">
                                    <PaymentForm
                                        onSubmit={handlePaymentSubmit}
                                        billingCycle={registrationData.billingCycle}
                                        onBack={() => setShowPayment(false)}
                                        userData={registrationData.userData}
                                    />
                                </div>
                            ) : (
                                <div className="billing-details">
                                    {billingHistory.accountType === 'freeForever' ? (
                                        <div className="billing-details">
                                            <div className="detail-card current-plan-card">
                                                <h3>Current Plan</h3>
                                                <div className="plan-badge freeforever">Free Forever</div>
                                                <p className="plan-description">
                                                    You're currently on our Free plan with:
                                                </p>
                                                <ul className="current-features-list">
                                                    <li>Up to 3 projects</li>
                                                    <li>Basic analytics</li>
                                                </ul>
                                            </div>
                                            
                                            <div className="detail-card upgrade-card">
                                                <h3>Upgrade to Professional</h3>
                                                <div className="pro-features-list">
                                                    <div className="feature-item">
                                                        <FiCheck className="feature-check" />
                                                        <div>
                                                            <strong>Unlimited Projects</strong>
                                                            <p>Create and manage as many projects as you need</p>
                                                        </div>
                                                    </div>
                                                    <div className="feature-item">
                                                        <FiCheck className="feature-check" />
                                                        <div>
                                                            <strong>Unlimited Team Members</strong>
                                                            <p>Collaborate with your entire team</p>
                                                        </div>
                                                    </div>
                                                    <div className="feature-item">
                                                        <FiCheck className="feature-check" />
                                                        <div>
                                                            <strong>Advanced Analytics</strong>
                                                            <p>Get detailed insights and project metrics</p>
                                                        </div>
                                                    </div>
                                                    <div className="feature-item">
                                                        <FiCheck className="feature-check" />
                                                        <div>
                                                            <strong>Priority Support</strong>
                                                            <p>Get help when you need it most</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="subscription-pricing">
                                                    <div className="price-option-container">
                                                        <div 
                                                            className={`price-option ${billingCycle === 'monthly' ? 'selected' : ''}`}
                                                            onClick={() => setBillingCycle('monthly')}
                                                        >
                                                            <div className="price-amount">$2.99</div>
                                                            <div className="price-period">per month</div>
                                                        </div>
                                                        
                                                        <div className="price-separator">or</div>
                                                        
                                                        <div 
                                                            className={`price-option ${billingCycle === 'yearly' ? 'selected' : ''}`}
                                                            onClick={() => setBillingCycle('yearly')}
                                                        >
                                                            <div className="price-amount">$29.99</div>
                                                            <div className="price-period">per year</div>
                                                            <div className="price-save">Save 16%</div>
                                                        </div>
                                                    </div>
                                                    
                                                    <button 
                                                        className="upgrade-button"
                                                        onClick={() => handleSubscriptionChange('professional', billingCycle)}
                                                    >
                                                        Upgrade Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="detail-card current-plan-card">
                                                <h3>Current Plan</h3>
                                                <div className="plan-badge professional">Professional</div>
                                                
                                                {billingHistory.subscriptionStatus === 'canceling' && billingHistory.nextPlan === 'free' && billingHistory.subscriptionEndDate && (
                                                    <div className="subscription-cancel-notice">
                                                        <div className="notice-badge">Subscription Canceled</div>
                                                        <p>
                                                            Your subscription has been canceled. You will have access to Professional 
                                                            features until <strong>{new Date(billingHistory.subscriptionEndDate).toLocaleDateString()}</strong>, 
                                                            after which your account will be converted to a Free plan.
                                                        </p>
                                                        <button 
                                                            className="resume-subscription-button"
                                                            onClick={handleResumeSubscription}
                                                        >
                                                            Resume Subscription
                                                        </button>
                                                    </div>
                                                )}
                                                
                                                {billingHistory.currentPeriod && (
                                                    <p className="current-period">
                                                        Current period: {new Date(billingHistory.currentPeriod.start).toLocaleDateString()} 
                                                        - {new Date(billingHistory.currentPeriod.end).toLocaleDateString()}
                                                    </p>
                                                )}
                                                
                                                {billingHistory.upcomingInvoice && (
                                                    <div className="upcoming-payment-section">
                                                        <h4>Upcoming Payment</h4>
                                                        <p className="upcoming-payment">
                                                            Amount due: ${(billingHistory.upcomingInvoice.amount / 100).toFixed(2)}<br />
                                                            Due date: {new Date(billingHistory.upcomingInvoice.dueDate).toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                )}
                                                <div className="manage-plan-section">
                                                    {billingHistory.subscriptionStatus !== 'canceling' && (
                                                        <button 
                                                            className="downgrade-button"
                                                            onClick={() => handleSubscriptionChange('free')}
                                                        >
                                                            Downgrade to Free
                                                        </button>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="detail-card billing-history-card">
                                                <h3>Billing History</h3>
                                                {billingHistory.invoices.length > 0 ? (
                                                    <div className="billing-history-table-container">
                                                        <table className="billing-history-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                    <th>Period</th>
                                                                    <th>Invoice</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {billingHistory.invoices.map(invoice => (
                                                                    <tr key={invoice.id}>
                                                                        <td>{new Date(invoice.date).toLocaleDateString()}</td>
                                                                        <td>${(invoice.amount / 100).toFixed(2)}</td>
                                                                        <td>
                                                                            <span className={`status-badge ${invoice.status}`}>
                                                                                {invoice.status}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {new Date(invoice.periodStart).toLocaleDateString()} - 
                                                                            {new Date(invoice.periodEnd).toLocaleDateString()}
                                                                        </td>
                                                                        <td>
                                                                            {invoice.pdfUrl && (
                                                                                <a href={invoice.pdfUrl} target="_blank" rel="noopener noreferrer" 
                                                                                   className="invoice-download-link">
                                                                                    Download
                                                                                </a>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <p className="no-history">No billing history available</p>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="error-message">Unable to load billing information</p>
                    )}
                </div>
            )}
            <ConfirmationModal
                isOpen={modalConfig.isOpen}
                onClose={() => setModalConfig({...modalConfig, isOpen: false})}
                onConfirm={modalConfig.onConfirm}
                title={modalConfig.title}
                message={modalConfig.message}
                type={modalConfig.type}
                confirmText={modalConfig.confirmText}
            />
            <Footer />
        </div>
    );
};

export default AccountPage;