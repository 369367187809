import React from 'react';
import './ComponentStyling/TimelineContainer.css';

const TimelineContainer = ({ 
    timelineRef, 
    currentDayOffset, 
    yearsRange, 
    events,
    handleMouseDownDrag,
    handleEventDoubleClick,
    handleEventContextMenu,
    handleMouseDownResize,
    editingEventId,
    editingTitle,
    setEditingTitle,
    finishEditingEventTitle 
}) => {
    const mapping = { 0: "S", 1: "M", 2: "T", 3: "W", 4: "TR", 5: "F", 6: "S" };

    // Calculate the maximum bottom position of all events
    const maxBottom = Math.max(...events.map(event => event.top + event.height), 1000);
    const minContentHeight = maxBottom + 200; // Add padding

    // Add this style object for dynamic day content height
    const dayContentStyle = {
        height: `${minContentHeight}px`,
        borderLeft: '1px solid #eee'
    };

    return (
        <div className="timeline-container">
            <div 
                className="timeline-visual" 
                ref={timelineRef}
            >
                <div 
                    className="current-day-marker" 
                    style={{ 
                        left: `${currentDayOffset}px`,
                        height: `${minContentHeight}px`
                    }} 
                />
                <div 
                    className="timeline-content"
                    style={{ minHeight: `${minContentHeight}px` }}
                >
                    <div className="timeline-years">
                        {Array.from(
                            { length: yearsRange.end - yearsRange.start + 1 }, 
                            (_, i) => yearsRange.start + i
                        ).map(year => (
                            ["January","February","March","April","May","June",
                             "July","August","September","October","November","December"
                            ].map((monthName, monthIndex) => {
                                const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
                                const dayNumbers = Array.from({ length: daysInMonth }, (_, i) => i + 1);
                                const dayAbbrs = dayNumbers.map(day => {
                                    const dayOfWeek = new Date(year, monthIndex, day).getDay();
                                    return mapping[dayOfWeek];
                                });

                                return (
                                    <div key={`${year}-${monthIndex}`} className="month-column">
                                        <div className="month-header">
                                            {monthName} '{year.toString().slice(-2)}
                                        </div>
                                        <div className="days-grid">
                                            {dayNumbers.map((d, idx) => (
                                                <div key={d} className="day-column">
                                                    <div className="day-number">{d}</div>
                                                    <div className="day-abbr">{dayAbbrs[idx]}</div>
                                                    <div className="day-content" style={dayContentStyle} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })
                        ))}
                    </div>

                    {/* Events Layer */}
                    {events.map(event => (
                        <div 
                            key={event.id}
                            className="timeline-event"
                            style={{
                                top: event.top,
                                left: `${event.left}px`,
                                width: `${event.width}px`,
                                height: `${event.height}px`,
                                backgroundColor: event.color,
                                display: 'flex',
                                alignItems: 'center',      // Center vertically
                                justifyContent: 'center',  // Center horizontally
                                textAlign: event.textAlign || 'center',
                                padding: event.width < 80 ? '4px 2px' : '4px 8px', // Reduce padding for small events
                                overflow: 'visible', // Always keep overflow visible
                                color: event.textColor || '#ffffff',  // Add text color support
                            }}
                            onMouseDown={(e) => handleMouseDownDrag(e, event)}
                            onDoubleClick={(e) => handleEventDoubleClick(e, event)}
                            onContextMenu={(e) => handleEventContextMenu(e, event)}
                        >
                            <div
                                className="event-resizer left"
                                data-resizer
                                onMouseDown={(e) => handleMouseDownResize(e, event, 'left')}
                            />
                            <div
                                className="event-resizer right"
                                data-resizer
                                onMouseDown={(e) => handleMouseDownResize(e, event, 'right')}
                            />
                            <div style={{ 
                                position: 'absolute',  // Position absolutely to center the text
                                width: '100%',        // Take full width
                                height: '100%',       // Take full height
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: event.textAlign === 'left' ? 'flex-start' : 
                                              event.textAlign === 'right' ? 'flex-end' : 'center',
                                padding: event.width < 80 ? '0 2px' : '0 8px', // Reduce padding for small events
                                whiteSpace: event.textWrap ? 'normal' : 'nowrap',
                                overflow: 'visible', // Always keep overflow visible
                                wordBreak: event.textWrap ? 'break-word' : 'normal',
                                color: event.textColor || '#ffffff',  // Add text color support
                                zIndex: 1, // Ensure text stays above other elements
                            }}>
                                {editingEventId === event.id ? (
                                    <input
                                        type="text"
                                        value={editingTitle}
                                        autoFocus
                                        onChange={(e) => setEditingTitle(e.target.value)}
                                        onBlur={finishEditingEventTitle}
                                        onKeyDown={(e) => {
                                            if(e.key === 'Enter') finishEditingEventTitle();
                                        }}
                                        className="event-title-input"
                                        style={{ 
                                            textAlign: event.textAlign || 'center',
                                            width: '100%',
                                            minWidth: 0,
                                            color: event.textColor || '#ffffff',  // Add text color support
                                        }}
                                    />
                                ) : (
                                    <span 
                                        className="event-title"
                                        style={{
                                            maxHeight: event.textWrap ? '100%' : 'none',
                                            display: '-webkit-box',
                                            WebkitLineClamp: event.textWrap ? '3' : '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'visible',
                                            wordBreak: event.textWrap ? 'break-word' : 'normal',
                                            textOverflow: 'clip'
                                        }}
                                    >
                                        {event.title}
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimelineContainer;
