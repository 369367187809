import axios from 'axios';
import { getAuthToken } from './authUtils';

/**
 * Fetch AI-generated insights from the backend which communicates with OpenAI
 * 
 * @param {Object} userData - User's projects, tasks, and other relevant data
 * @returns {Promise<Object>} - Promise resolving to AI insights
 */
export const fetchAiInsights = async (userData) => {
  try {
    // Add more detailed logging to debug issues
    console.log('AI Insights request details:');
    console.log('- Daily journal tasks count:', userData.dailyJournal?.tasks?.length || 0);
    console.log('- Overdue tasks count:', userData.overdueTasks?.length || 0);
    console.log('- Upcoming tasks count:', userData.upcomingTasks?.length || 0);
    console.log('- RAID high priority items:', userData.raidDetails?.highPriorityItems?.length || 0);
    
    // Make a clean copy of the data to avoid reference issues
    const cleanData = {
      userId: userData.userId,
      projects: userData.projects || [],
      raidDetails: userData.raidDetails || {
        activeIssues: [],
        highPriorityItems: [],
        upcomingDecisions: []
      },
      dailyJournal: {
        tasks: userData.dailyJournal?.tasks || [],
        stats: userData.dailyJournal?.stats || {
          totalTasks: 0,
          completedTasks: 0,
          todoTasks: 0,
          inProgressTasks: 0
        }
      },
      stats: {
        ...(userData.stats || {}),
        // Ensure we have the correct counts from the passed arrays
        overdueTasks: userData.overdueTasks?.length || userData.stats?.overdueTasks || 0,
        upcomingTasks: userData.upcomingTasks?.length || userData.stats?.upcomingTasks || 0
      },
      // Include these full arrays to give more context
      overdueTasks: userData.overdueTasks || [],
      upcomingTasks: userData.upcomingTasks || [],
      forceRefresh: Boolean(userData.forceRefresh)
    };

    // Get token explicitly from cookie
    const authToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('token='))
      ?.split('=')[1];
      
    if (!authToken) {
      console.error('No auth token found in cookies');
    } else {
      console.log('Auth token found in cookies (length):', authToken.length);
    }

    // Build API URL
    const baseUrl = process.env.REACT_APP_API_URL || '';
    // Use relative URL which is better for cookies
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/ai/insights`;
    
    console.log('Making request to:', apiUrl);

    // Special handling for development vs production
    const axiosConfig = {
      url: apiUrl,
      method: 'POST',
      data: cleanData,
      withCredentials: true, // Important for sending cookies
      headers: {
        'Content-Type': 'application/json',
      }
    };

    // Only include Authorization header if we have a token
    // Some servers reject requests with empty Authorization headers
    if (authToken) {
      axiosConfig.headers['Authorization'] = `Bearer ${authToken}`;
    }

    console.log('Using withCredentials:', axiosConfig.withCredentials);
    
    // Make the request with axios
    const response = await axios(axiosConfig);
    
    console.log('AI response received successfully');
    
    // Check if response has the expected format
    if (!response.data || typeof response.data !== 'object') {
      throw new Error('Invalid response from AI service');
    }

    return {
      ...response.data,
      refreshedAt: new Date().toISOString()
    };
  } catch (error) {
    console.error('Error fetching AI insights:', error);
    
    // Check if the error is due to missing token
    if (error.response?.status === 401 || 
        error.response?.data?.message?.includes('token') ||
        error.message?.includes('No authentication token')) {
          
      // Try to refresh the token
      try {
        console.log('Attempting to refresh authentication status');
        await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/status`, { withCredentials: true });
        // If successful, retry the original request
        return fetchAiInsights(userData);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        throw new Error('Authentication token expired. Please refresh the page and try again.');
      }
    }
    
    // Detailed error logging
    if (error.response) {
      console.error('Error details:', {
        status: error.response.status,
        data: error.response.data,
        headers: Object.fromEntries(
          Object.entries(error.response.headers || {})
            .filter(([key]) => !key.toLowerCase().includes('auth'))
        )
      });
    }
    
    // Handle rate limiting errors specifically
    if (error.response && error.response.status === 429) {
      const retryAfter = error.response.data?.error?.retryAfter || 60;
      throw new Error(`Rate limit exceeded. Please try again in ${retryAfter} seconds.`);
    }
    
    // Generic error with best available message
    throw new Error(error.response?.data?.error?.message || error.message || 'Failed to fetch AI summary');
  }
};

/**
 * Generate prompt for OpenAI based on user's data
 * 
 * @param {Object} userData - User data including projects, tasks, etc.
 * @returns {String} - Formatted prompt for OpenAI
 */
export const generateAiPrompt = (userData) => {
  const { projects, overdueTasks, upcomingTasks, raidLogStats } = userData;
  
  const projectNames = projects.map(p => p.project_name).join(', ');
  const overdueCount = overdueTasks?.length || 0;
  const upcomingCount = upcomingTasks?.length || 0;
  const completedTasks = raidLogStats?.complete || 0;
  const inProgressTasks = raidLogStats?.inProgress || 0;
  
  return `
    As an AI project management assistant, provide a comprehensive, personalized daily summary for the user. 
    
    Here's the current state of their projects:
    - Projects: ${projectNames || 'No projects found'}
    - ${overdueCount} overdue tasks
    - ${upcomingCount} upcoming tasks in the next week
    - ${completedTasks} completed tasks
    - ${inProgressTasks} tasks in progress

    Based on this information:
    1. Provide a concise, encouraging greeting (1 sentence)
    2. Highlight the most pressing priorities (1-2 sentences)
    3. Give 1 actionable tip to improve productivity
    
    FORMAT YOUR RESPONSE EXACTLY LIKE THIS WITH THESE EXACT SECTION HEADERS:

    🔥 URGENT
    • [First urgent task/priority with details]
    • [Second urgent task/priority]
    • [And so on - include 5-8 items total with details]

    👥 FOLLOW-UPS
    • [Person to follow up with, including reason]
    • [Another person to follow up with]
    • [And so on - include 5-8 follow-ups total]

    📋 KEY TASKS
    • [Important task with project name]
    • [Another important task]
    • [And so on - include 5-8 tasks total]

    ⚠️ WATCH LIST
    • [Potential issue or deadline with timing]
    • [Another potential issue]
    • [And so on - include 5-8 watch items total]

    💡 QUICK WINS
    • [Easy task with time estimate]
    • [Another quick task]
    • [And so on - include 5-8 quick wins total]

    IMPORTANT: For each category, aim to provide 5-8 detailed items.
  `;
};
