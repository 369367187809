/**
 * Feature Access Configuration
 * 
 * This file defines which features are available to different account types.
 * You can easily modify this file to change feature access without touching application code.
 */

// Account Types
export const ACCOUNT_TYPES = {
  FREE: 'freeForever',
  PROFESSIONAL: 'Professional',
  ENTERPRISE: 'Enterprise'
};

// Resource Limits
export const LIMITS = {
  [ACCOUNT_TYPES.FREE]: {
    maxProjects: 3,
    maxUsersPerProject: 5,
    maxTasksPerProject: 100,
    maxMeetings: 10,
    maxStorage: 100, // MB
  },
  [ACCOUNT_TYPES.PROFESSIONAL]: {
    maxProjects: 1000,
    maxUsersPerProject: 100,
    maxTasksPerProject: 10000,
    maxMeetings: 1000,
    maxStorage: 10000, // MB
  },
  [ACCOUNT_TYPES.ADMIN]: {
    maxProjects: Infinity,
    maxUsersPerProject: Infinity,
    maxTasksPerProject: Infinity,
    maxMeetings: Infinity,
    maxStorage: Infinity,
  }
};

// Feature Flags
export const FEATURES = {
  // Dashboard Features
  ADVANCED_ANALYTICS: {
    name: 'Advanced Analytics',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ENTERPRISE],
    description: 'Access detailed analytics and project insights'
  },
  CALENDAR_INTEGRATION: {
    name: 'Calendar Integration',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Sync with external calendars and schedule events'
  },
  
  // Project Features
  GANTT_CHART: {
    name: 'Gantt Charts',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Create and manage detailed Gantt charts'
  },
  PROJECT_TEMPLATES: {
    name: 'Project Templates',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Create and use project templates'
  },
  
  // Collaboration Features
  FILE_SHARING: {
    name: 'File Sharing',
    availableTo: [ACCOUNT_TYPES.FREE, ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Share files with team members',
    limits: {
      [ACCOUNT_TYPES.FREE]: { maxSize: 10 }, // MB per file
      [ACCOUNT_TYPES.PROFESSIONAL]: { maxSize: 100 } // MB per file
    }
  },
  MEETING_MANAGEMENT: {
    name: 'Meeting Management',
    availableTo: [ACCOUNT_TYPES.FREE, ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Manage meetings and meeting notes',
    limits: {
      [ACCOUNT_TYPES.FREE]: { maxDuration: 60 }, // minutes
      [ACCOUNT_TYPES.PROFESSIONAL]: { maxDuration: 240 } // minutes
    }
  },
  
  // Export Features
  EXPORT_PDF: {
    name: 'Export to PDF',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Export projects and reports to PDF'
  },
  EXPORT_EXCEL: {
    name: 'Export to Excel',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Export data to Excel'
  },
  
  // Integration Features
  API_ACCESS: {
    name: 'API Access',
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.ADMIN],
    description: 'Access to PMHub API for integrations'
  },
  NOTE_NETWORK: {
    key: 'NOTE_NETWORK',
    name: 'Neural Network Visualization',
    description: 'Visualize connections between your notes using AI',
    availableTo: [
      ACCOUNT_TYPES.PROFESSIONAL, 
      ACCOUNT_TYPES.ENTERPRISE, 
      'Professional', 
      'Enterprise', 
      'PRO'
    ]  // Include all possible values
  },
  
  // AI features
  AI_INSIGHTS: {
    name: "AI-Powered Insights",
    description: "Personalized AI insights for task prioritization and project management",
    availableTo: [ACCOUNT_TYPES.PROFESSIONAL, ACCOUNT_TYPES.TEAM, ACCOUNT_TYPES.ENTERPRISE],
    limits: {
      [ACCOUNT_TYPES.PROFESSIONAL]: {
        refreshesPerDay: 10
      },
      [ACCOUNT_TYPES.TEAM]: {
        refreshesPerDay: 20
      },
      [ACCOUNT_TYPES.ENTERPRISE]: {
        refreshesPerDay: 50
      }
    }
  }
};
