import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Initialize with empty arrays to prevent undefined errors
const initialState = {
  tasks: [],
  tasksByProject: {},
  taskCounts: {
    total: 0,
    todo: 0,
    inProgress: 0,
    complete: 0
  },
  loading: false,
  error: null
};

export const fetchDailyJournalTasks = createAsyncThunk(
  'journal/fetchTasks',
  async (userId, { rejectWithValue }) => {
    try {
      if (!userId) {
        return rejectWithValue('User ID is required');
      }
      
      // Get today's date in YYYY-MM-DD format without timezone issues
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const todayStr = `${year}-${month}-${day}`;
      
      console.log(`Fetching journal tasks for user ${userId} and date ${todayStr}`);
      
      try {
        // Make the API request with the formatted date
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/dailyJournalTasks`,
          { params: { userId, date: todayStr } }
        );
        
        console.log(`Fetched ${response.data?.length || 0} daily journal tasks for today (${todayStr})`);
        
        // Log the actual date info for the first task to help debug
        if (response.data && response.data.length > 0) {
          const firstTask = response.data[0];
          console.log('First task date info:', {
            rawDate: firstTask.date,
            dateType: typeof firstTask.date,
            dateStr: new Date(firstTask.date).toISOString().split('T')[0],
            todayStr
          });
        } else {
          console.log('No tasks found for today');
        }
        
        return response.data || [];
      } catch (error) {
        console.error('Error in API call:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error fetching daily journal tasks:', error);
      return rejectWithValue(error.message);
    }
  }
);

const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyJournalTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDailyJournalTasks.fulfilled, (state, action) => {
        state.loading = false;
        
        // Ensure we always have an array and process date strings properly
        let tasks = Array.isArray(action.payload) ? action.payload : [];
        
        // Normalize data to ensure consistency
        tasks = tasks.map(task => ({
          ...task,
          // Ensure status is a string and normalize to lowercase
          status: (task.status || 'todo').toLowerCase(),
          // Ensure storyPoints is a number
          storyPoints: parseInt(task.storyPoints || 0),
          // Ensure task has a projectId property
          projectId: task.projectId || '',
          // Ensure task has projectName
          projectName: task.projectName || ''
        }));
        
        state.tasks = tasks;
        
        // Group tasks by status and count them
        const counts = {
          total: tasks.length,
          todo: tasks.filter(task => task.status === 'todo').length,
          inProgress: tasks.filter(task => task.status === 'inprogress' || task.status === 'inProgress').length,
          complete: tasks.filter(task => task.status === 'complete').length
        };
        
        console.log('Task counts in redux store:', counts);
        state.taskCounts = counts;
        
        // Group tasks by project
        state.tasksByProject = tasks.reduce((acc, task) => {
          const projectId = task.projectId || 'unassigned';
          if (!acc[projectId]) acc[projectId] = [];
          acc[projectId].push(task);
          return acc;
        }, {});
      })
      .addCase(fetchDailyJournalTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // Keep existing tasks instead of resetting to prevent UI flickering
      });
  }
});

export default journalSlice.reducer;
