import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlus, faEllipsisH, faPlay, faStop, 
    faClock, faCheckCircle, faBan 
} from '@fortawesome/free-solid-svg-icons';
import { getStatusIcon } from '../utils/epicUtils';
import { createPortal } from 'react-dom';
import NewSprintSlider from '../components/NewSprintSlider';
import { 
    fetchCurrentSprint, 
    createSprint, 
    endSprint,
    updateUserStory
} from '../../../redux/slices/agileHubSlice';
import './CurrentSprint.css';  // Add this import

// StrictMode workaround for react-beautiful-dnd
// https://github.com/atlassian/react-beautiful-dnd/issues/2396
const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false);
    
    useEffect(() => {
        // Enable after a timeout to avoid the React 18 strict mode issue
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    
    if (!enabled) {
        return null;
    }
    
    return <Droppable {...props}>{children}</Droppable>;
};

export const CurrentSprint = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { 
        currentSprint, 
        sprintStories,
        userStories, // Add this
        sprintLoading,
        sprintError 
    } = useSelector(state => state.agileHub);

    // Load current sprint on component mount
    useEffect(() => {
        dispatch(fetchCurrentSprint(projectId));
    }, [dispatch, projectId]);

    const [columns, setColumns] = useState({
        'todo': {
            name: 'To Do',
            items: []
        },
        'inProgress': {
            name: 'In Progress',
            items: []
        },
        'review': {
            name: 'Review',
            items: []
        },
        'done': {
            name: 'Done',
            items: []
        }
    });

    // Sprint state
    const [isSprintActive, setIsSprintActive] = useState(false);
    const [sprintDetails, setSprintDetails] = useState({
        name: '',
        startDate: null,
        endDate: null,
        goal: '',
        totalPoints: 0,
        completedPoints: 0
    });

    // Replace modal state with slider states
    const [showSprintSlider, setShowSprintSlider] = useState(false);
    const [sprintSliderClosing, setSprintSliderClosing] = useState(false);
    const [isStartingSprint, setIsStartingSprint] = useState(false);

    // Update sprint states when currentSprint changes
    useEffect(() => {
        if (currentSprint) {
            setIsSprintActive(true);
            setSprintDetails({
                name: currentSprint.name,
                startDate: currentSprint.startDate,
                endDate: currentSprint.endDate,
                goal: currentSprint.goal,
                totalPoints: currentSprint.totalPoints,
                completedPoints: sprintStories?.filter(story => story.status === 'Done')
                    .reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0) || 0
            });
        } else {
            setIsSprintActive(false);
            setSprintDetails({
                name: '',
                startDate: null,
                endDate: null,
                goal: '',
                totalPoints: 0,
                completedPoints: 0
            });
        }
    }, [currentSprint, sprintStories]);

    // Update columns when sprint stories change
    useEffect(() => {
        if (sprintStories && sprintStories.length > 0) {
            const newColumns = {
                'todo': { name: 'To Do', items: [] },
                'inProgress': { name: 'In Progress', items: [] },
                'review': { name: 'Review', items: [] },
                'done': { name: 'Done', items: [] }
            };

            sprintStories.forEach(story => {
                // Skip stories with undefined _id
                if (!story || !story._id) {
                    console.warn('Found a story without an _id', story);
                    return;
                }
                
                // Handle lowercase status values from database
                switch (story.status?.toLowerCase()) {
                    case 'in progress':
                        newColumns['inProgress'].items.push(story);
                        break;
                    case 'review':
                        newColumns['review'].items.push(story);
                        break;
                    case 'done':
                        newColumns['done'].items.push(story);
                        break;
                    case 'to do':
                        newColumns['todo'].items.push(story);
                        break;
                    default:
                        newColumns['todo'].items.push(story);
                }
            });

            setColumns(newColumns);
        } else {
            // Reset columns when no stories
            setColumns({
                'todo': { name: 'To Do', items: [] },
                'inProgress': { name: 'In Progress', items: [] },
                'review': { name: 'Review', items: [] },
                'done': { name: 'Done', items: [] }
            });
        }
    }, [sprintStories]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        
        const { source, destination } = result;
        
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            
            // Update local state immediately for smooth UI experience
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });

            // Get the new status based on destination column
            const newStatus = getStatusFromColumnId(destination.droppableId);
            const storyId = result.draggableId;
            
            // Find the story to update
            const story = sourceItems.find(item => item._id === storyId) || 
                          destItems.find(item => item._id === storyId);
            
            if (story) {
                // Update story status in backend
                dispatch(updateUserStory({ 
                    projectId, 
                    storyId, 
                    userStoryData: { 
                        ...story,
                        status: newStatus 
                    }
                })).catch(error => {
                    console.error('Error updating story status:', error);
                    // If there's an error, revert the UI change
                    setColumns({
                        ...columns,
                        [source.droppableId]: {
                            ...sourceColumn,
                            items: [...sourceColumn.items]
                        },
                        [destination.droppableId]: {
                            ...destColumn,
                            items: [...destColumn.items]
                        }
                    });
                });
            }
        } else {
            // Same column reordering - no status change needed
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    // Convert column ID to database status value (lowercase)
    const getStatusFromColumnId = (columnId) => {
        switch (columnId) {
            case 'inProgress': return 'in progress';
            case 'review': return 'review';
            case 'done': return 'done';
            default: return 'to do';
        }
    };

    // Modified handleStartSprint to show slider instead of modal
    const handleStartSprint = () => {
        setShowSprintSlider(true);
        setSprintSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeSprintSlider = () => {
        setSprintSliderClosing(true);
        setTimeout(() => {
            setShowSprintSlider(false);
            setSprintSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    // Update handleEndSprint to refresh data after ending sprint
    const handleEndSprint = async () => {
        if (!currentSprint?._id) {
            console.error('No active sprint to end');
            return;
        }

        if (window.confirm('Are you sure you want to end the current sprint?')) {
            try {
                await dispatch(endSprint({ 
                    projectId, 
                    sprintId: currentSprint._id 
                })).unwrap();
                
                // Reset states immediately
                setIsSprintActive(false);
                setSprintDetails({
                    name: '',
                    startDate: null,
                    endDate: null,
                    goal: '',
                    totalPoints: 0,
                    completedPoints: 0
                });
                setSprintAnalytics({
                    completionRate: 0,
                    pointsPerDay: 0,
                    estimatedCompletion: null,
                    riskLevel: 'low'
                });
                setColumns({
                    'todo': { name: 'To Do', items: [] },
                    'inProgress': { name: 'In Progress', items: [] },
                    'review': { name: 'Review', items: [] },
                    'done': { name: 'Done', items: [] }
                });
                
                // Refresh the current sprint data
                dispatch(fetchCurrentSprint(projectId));
            } catch (error) {
                console.error('Error ending sprint:', error);
            }
        }
    };

    // New function to actually start the sprint after slider is submitted
    const startSprint = async (sprintData) => {
        setIsStartingSprint(true);
        try {
            console.log('Starting sprint with data:', sprintData);
            
            const result = await dispatch(createSprint({ 
                projectId, 
                sprintData: {
                    ...sprintData,
                    isActive: true
                }
            })).unwrap();
            
            console.log('Sprint created successfully:', result);
            
            await dispatch(fetchCurrentSprint(projectId));
            closeSprintSlider();
        } catch (error) {
            console.error('Error starting sprint:', error);
        } finally {
            setIsStartingSprint(false);
        }
    };

    // Get eligible stories for sprint (those not in a sprint)
    const getEligibleStories = () => {
        // Filter out stories that have a defined sprintId property
        const eligibleStories = userStories.filter(story => {
            // Debug each story to check why it might not be eligible
            if (story.sprintId) {
                console.log(`Story ${story._id} has sprintId: ${story.sprintId}`);
                return false;
            }
            return true;
        });
        
        console.log('Eligible stories for sprint:', eligibleStories);
        return eligibleStories;
    };

    // Add debugging for sprint and stories
    useEffect(() => {
        console.log('Current Sprint Data:', currentSprint);
        console.log('Sprint Stories:', sprintStories);
    }, [currentSprint, sprintStories]);

    useEffect(() => {
        let portalRoot = document.getElementById('sliding-panel-root');
        if (!portalRoot) {
            portalRoot = document.createElement('div');
            portalRoot.id = 'sliding-panel-root';
            document.body.appendChild(portalRoot);
        }
        return () => {
            if (portalRoot && portalRoot.parentNode) {
                portalRoot.parentNode.removeChild(portalRoot);
            }
        };
    }, []);

    // Add new analytics state
    const [sprintAnalytics, setSprintAnalytics] = useState({
        completionRate: 0,
        pointsPerDay: 0,
        estimatedCompletion: null,
        riskLevel: 'low'
    });

    // Calculate sprint analytics
    useEffect(() => {
        if (!currentSprint || !sprintStories?.length) {
            setSprintAnalytics({
                completionRate: 0,
                pointsPerDay: 0,
                estimatedCompletion: null,
                riskLevel: 'low'
            });
            return;
        }

        try {
            const totalPoints = sprintStories.reduce((sum, story) => 
                sum + (parseInt(story.storyPoints) || 0), 0);
            
            const completedPoints = sprintStories
                .filter(story => story.status?.toLowerCase() === 'done')
                .reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0);
            
            const startDate = new Date(currentSprint.startDate);
            const endDate = new Date(currentSprint.endDate);
            const today = new Date();
            
            // Ensure valid dates
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                console.error('Invalid sprint dates:', { startDate, endDate });
                return;
            }

            const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
            const daysElapsed = Math.max(0, Math.ceil((today - startDate) / (1000 * 60 * 60 * 24)));
            
            // Calculate completion rate
            const completionRate = totalPoints > 0 ? (completedPoints / totalPoints) * 100 : 0;
            const expectedCompletion = totalDays > 0 ? (daysElapsed / totalDays) * 100 : 0;
            
            // Calculate velocity and estimated completion
            const velocity = daysElapsed > 0 ? completedPoints / daysElapsed : 0;
            const remainingPoints = totalPoints - completedPoints;
            const daysRequired = velocity > 0 ? remainingPoints / velocity : 0;
            
            let estimatedCompletion = null;
            if (velocity > 0) {
                estimatedCompletion = new Date(today);
                estimatedCompletion.setDate(today.getDate() + Math.ceil(daysRequired));
            }
            
            // Calculate risk level
            let riskLevel = 'low';
            if (completionRate < expectedCompletion - 20) {
                riskLevel = 'high';
            } else if (completionRate < expectedCompletion - 10) {
                riskLevel = 'medium';
            }

            setSprintAnalytics({
                completionRate: Math.round(completionRate),
                pointsPerDay: Math.round(velocity * 10) / 10,
                estimatedCompletion,
                riskLevel
            });
        } catch (error) {
            console.error('Error calculating sprint analytics:', error);
            setSprintAnalytics({
                completionRate: 0,
                pointsPerDay: 0,
                estimatedCompletion: null,
                riskLevel: 'low'
            });
        }
    }, [currentSprint, sprintStories]);

    return (
        <div className="current-sprint-container">
            <div className="sprint-header">
                <div className="sprint-info">
                    <h3>{sprintDetails.name || 'Current Sprint'}</h3>
                    {isSprintActive ? (
                        <div className="sprint-status active">
                            <FontAwesomeIcon icon={faClock} className="me-2" />
                            Sprint in Progress
                            {sprintDetails.startDate && sprintDetails.endDate && (
                                <span className="ms-2">
                                    ({new Date(sprintDetails.startDate).toLocaleDateString()} - {new Date(sprintDetails.endDate).toLocaleDateString()})
                                </span>
                            )}
                        </div>
                    ) : (
                        <div className="sprint-status inactive">
                            <FontAwesomeIcon icon={faBan} className="me-2" />
                            No Active Sprint
                        </div>
                    )}
                </div>
                <button 
                    className={`btn ${isSprintActive ? 'btn-danger' : 'btn-primary'}`}
                    onClick={isSprintActive ? handleEndSprint : handleStartSprint}
                >
                    <FontAwesomeIcon icon={isSprintActive ? faStop : faPlay} className="me-2" />
                    {isSprintActive ? 'End Sprint' : 'Start New Sprint'}
                </button>
            </div>

            {currentSprint && sprintDetails.goal && (
                <div className="sprint-goal-banner mb-4">
                    <strong>Sprint Goal:</strong> {sprintDetails.goal}
                </div>
            )}

            {/* Add Sprint Progress Summary */}
            {isSprintActive && currentSprint && (
                <div className="sprint-progress-summary">
                    <div className="progress-header">
                        <h4>Sprint Progress</h4>
                        <div className={`risk-indicator ${sprintAnalytics.riskLevel}`}>
                            {sprintAnalytics.riskLevel.toUpperCase()} RISK
                        </div>
                    </div>
                    
                    <div className="progress-metrics">
                        <div className="metric">
                            <div className="metric-title">Completion Rate</div>
                            <div className="progress">
                                <div 
                                    className="progress-bar" 
                                    style={{ width: `${sprintAnalytics.completionRate}%` }}
                                >
                                    {sprintAnalytics.completionRate}%
                                </div>
                            </div>
                        </div>
                        
                        <div className="metric">
                            <div className="metric-title">Velocity</div>
                            <div className="metric-value">{sprintAnalytics.pointsPerDay} points/day</div>
                        </div>
                        
                        <div className="metric">
                            <div className="metric-title">Estimated Completion</div>
                            <div className="metric-value">
                                {sprintAnalytics.estimatedCompletion ? (
                                    <span className={
                                        sprintAnalytics.estimatedCompletion > new Date(currentSprint.endDate) 
                                        ? 'text-danger' 
                                        : 'text-success'
                                    }>
                                        {sprintAnalytics.estimatedCompletion.toLocaleDateString()}
                                        <small className="ms-2">
                                            ({sprintAnalytics.estimatedCompletion > new Date(currentSprint.endDate) 
                                                ? 'Delayed' 
                                                : 'On Track'})
                                        </small>
                                    </span>
                                ) : (
                                    <span className="text-muted">Not enough data</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="sprint-metrics">
                <div className="metric-card">
                    <div className="metric-title">Total Points</div>
                    <div className="metric-value">
                        {sprintDetails.totalPoints || Object.values(columns).flatMap(column => column.items).reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0)}
                    </div>
                </div>
                <div className="metric-card">
                    <div className="metric-title">Completed</div>
                    <div className="metric-value">
                        {sprintDetails.completedPoints || columns.done.items.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0)}
                    </div>
                </div>
                <div className="metric-card">
                    <div className="metric-title">Remaining</div>
                    <div className="metric-value">
                        {(sprintDetails.totalPoints || Object.values(columns).flatMap(column => column.items).reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0)) - 
                        (sprintDetails.completedPoints || columns.done.items.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0))}
                    </div>
                </div>
                <div className="metric-card">
                    <div className="metric-title">Progress</div>
                    <div className="metric-value">
                        {Math.round(((sprintDetails.completedPoints || columns.done.items.reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0)) / 
                        (sprintDetails.totalPoints || Object.values(columns).flatMap(column => column.items).reduce((sum, story) => sum + (parseInt(story.storyPoints) || 0), 0) || 1)) * 100 || 0)}%
                    </div>
                </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="sprint-board">
                    {Object.entries(columns).map(([columnId, column]) => (
                        <div className="sprint-column" key={columnId}>
                            <div className="column-header">
                                <h4>{column.name}</h4>
                                <div className="column-metrics">
                                    <span className="story-count">{column.items.length}</span>
                                    <span className="points-count">
                                        {column.items.reduce((sum, story) => 
                                            sum + (parseInt(story.storyPoints) || 0), 0)} pts
                                    </span>
                                </div>
                            </div>
                            
                            <StrictModeDroppable droppableId={columnId}>
                                {(provided, snapshot) => (
                                    <div
                                        className={`column-content ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {column.items.filter(story => story && story._id).map((story, index) => (
                                            <Draggable 
                                                key={story._id.toString()} 
                                                draggableId={story._id.toString()}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className={`story-card ${snapshot.isDragging ? 'dragging' : ''}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="story-header">
                                                            <span className="story-id">US-{story.storyNumber}</span>
                                                            <div className="story-badges">
                                                                <span className={`badge bg-${story.priority?.toLowerCase()}`}>
                                                                    {story.priority}
                                                                </span>
                                                                {story.blockers?.length > 0 && (
                                                                    <span className="badge bg-danger ms-1">
                                                                        <i className="fas fa-exclamation-triangle"></i>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="story-title">{story.title}</div>
                                                        
                                                        <div className="story-footer">
                                                            <div className="story-metrics">
                                                                <span className="story-points">
                                                                    {story.storyPoints} pts
                                                                </span>
                                                                {story.acceptanceCriteria?.length > 0 && (
                                                                    <div className="acceptance-progress">
                                                                        <div className="progress">
                                                                            <div 
                                                                                className="progress-bar"
                                                                                style={{
                                                                                    width: `${(story.acceptanceCriteria.filter(c => c.completed).length / 
                                                                                        story.acceptanceCriteria.length) * 100}%`
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                        <span className="criteria-count">
                                                                            {story.acceptanceCriteria.filter(c => c.completed).length}/
                                                                            {story.acceptanceCriteria.length}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {story.assignee && (
                                                                <div className="story-assignee">
                                                                    <div className="assignee-avatar">
                                                                        {story.assignee.charAt(0).toUpperCase()}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        {column.items.length === 0 && (
                                            <div className="empty-column">
                                                <i className="fas fa-inbox"></i>
                                                <p>Drop stories here</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </StrictModeDroppable>
                        </div>
                    ))}
                </div>
            </DragDropContext>

            {/* Replace the incorrect portal code with this: */}
            {showSprintSlider && (
                createPortal(
                    <NewSprintSlider
                        showSlider={showSprintSlider}
                        sliderClosing={sprintSliderClosing}
                        closeSlider={closeSprintSlider}
                        onStartSprint={startSprint}
                        availableStories={getEligibleStories()}
                        isLoading={isStartingSprint}
                    />,
                    document.getElementById('sliding-panel-root') || document.body
                )
            )}
        </div>
    );
};

export default CurrentSprint;
