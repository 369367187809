import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglass, faPauseCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

// Status icon helper
export const getStatusIcon = (status) => {
    switch(status?.toLowerCase()) {
        case 'complete':
            return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
        case 'in progress':
            return <FontAwesomeIcon icon={faHourglass} className="text-primary" />;
        case 'not started':
            return <FontAwesomeIcon icon={faPauseCircle} className="text-secondary" />;
        case 'blocked':
            return <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />;
        default:
            return <FontAwesomeIcon icon={faPauseCircle} className="text-secondary" />;
    }
};

// Function to get initials from assignee name
export const getInitials = (name) => {
    if (!name) return '?';
    return name
        .split(' ')
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .substring(0, 2);
};

// Calculate completion percentage based on status and associated stories
export const calculateCompletionPercentage = (epic, stories = []) => {
    // If epic has a fixed status, return predefined percentages
    if (epic.status === 'Complete') return 100;
    if (epic.status === 'Not Started') return 0;
    if (epic.status === 'Blocked') return epic.completionPercentage || 25;
    
    // Get stories associated with this epic
    const epicStories = stories.filter(story => story.epicId === epic._id);
    
    // If no stories are associated, use status-based calculation
    if (epicStories.length === 0) {
        if (epic.status === 'In Progress') return epic.completionPercentage || 50;
        return epic.completionPercentage || 0;
    }
    
    // Calculate based on story completion
    const totalStories = epicStories.length;
    
    // Count completed stories (those with status 'Done')
    const completedStories = epicStories.filter(story => 
        story.status?.toLowerCase() === 'done'
    ).length;
    
    // Count in-progress stories
    const inProgressStories = epicStories.filter(story => 
        story.status?.toLowerCase() === 'in progress' || 
        story.status?.toLowerCase() === 'review'
    ).length;
    
    // Weight completed stories as 100% and in-progress as 50%
    const completionPercentage = Math.round(
        ((completedStories * 1.0) + (inProgressStories * 0.5)) / totalStories * 100
    );
    
    return completionPercentage;
};

// Calculate feature completion percentage based on related epics
export const calculateFeatureCompletion = (feature, epics, userStories) => {
    if (!feature || !epics || !userStories) return 0;
    
    const featureEpics = epics.filter(epic => epic.featureId === feature._id);
    
    if (featureEpics.length === 0) return feature.status === 'Complete' ? 100 : 0;
    
    const epicIds = featureEpics.map(epic => epic._id);
    const storiesForFeature = userStories.filter(story => epicIds.includes(story.epicId));
    
    const totalStories = storiesForFeature.length;
    if (totalStories === 0) {
        // Calculate based on epic status if no stories
        const completedEpics = featureEpics.filter(epic => epic.status === 'Complete').length;
        const inProgressEpics = featureEpics.filter(epic => epic.status === 'In Progress').length;
        
        return featureEpics.length > 0 
            ? Math.round(((completedEpics * 1.0) + (inProgressEpics * 0.5)) / featureEpics.length * 100)
            : 0;
    }
    
    const completedStories = storiesForFeature.filter(story => story.status === 'Done').length;
    const inProgressStories = storiesForFeature.filter(
        story => story.status === 'In Progress' || story.status === 'Review'
    ).length;
    
    return Math.round(((completedStories * 1.0) + (inProgressStories * 0.5)) / totalStories * 100);
};

// Format date for display
export const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

// Format comment date with relative time
export const formatCommentDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.round(diffMs / 1000);
    const diffMins = Math.round(diffSecs / 60);
    const diffHours = Math.round(diffMins / 60);
    const diffDays = Math.round(diffHours / 24);
    
    if (diffSecs < 60) {
        return 'just now';
    } else if (diffMins < 60) {
        return `${diffMins} minute${diffMins !== 1 ? 's' : ''} ago`;
    } else if (diffHours < 24) {
        return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    } else if (diffDays < 7) {
        return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } else {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
};

// Get user display name consistently
export const getUserDisplayName = (user) => {
    if (!user) return 'Anonymous User';
    
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
    } else if (user.name) {
        return user.name;
    } else if (user.display_name) {
        return user.display_name;
    } else if (user.email) {
        const emailName = user.email.split('@')[0].replace(/[._-]/g, ' ');
        return emailName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
    return 'Anonymous User';
};

// Format feature type with icon
export const getFeatureTypeInfo = (type) => {
    const types = {
        'functionality': { label: 'Functionality', className: 'text-primary' },
        'ui': { label: 'UI/UX', className: 'text-success' },
        'enhancement': { label: 'Enhancement', className: 'text-info' },
        'optimization': { label: 'Optimization', className: 'text-warning' },
        'integration': { label: 'Integration', className: 'text-secondary' },
        'security': { label: 'Security', className: 'text-danger' }
    };
    
    return types[type?.toLowerCase()] || { label: type || 'Other', className: 'text-muted' };
};
