import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import NavbarAuth from './Navbar_Authenticated';
import ProjectNavbar from './ProjectNavbar';
import Footer from "./Footer";
import ReactGA from 'react-ga4';
import { FaPlus, FaClock, FaCalendarAlt, FaFileAlt } from 'react-icons/fa';
import './ComponentStyling/MeetingsListPage.css';

const MeetingsListPage = () => {
    const { projectId } = useParams();
    const [meetings, setMeetings] = useState([]);
    const [showAllMeetings, setShowAllMeetings] = useState(false);
    const twoWeeksAgo = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);

    useEffect(() => {
        ReactGA.initialize('G-SC3PE98CER');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        fetchMeetings();
    }, [projectId]);

    const fetchMeetings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/meetings/${projectId}`);
            const formattedMeetings = response.data.map(meeting => ({
                id: meeting.meetingId,
                title: meeting.meetingTitle,
                date: meeting.date,
                time: meeting.time
            }));
            setMeetings(formattedMeetings);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const createNewMeeting = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/meetings`, { projectId });
            const newMeeting = response.data;
            setMeetings([...meetings, {
                id: newMeeting.meetingId,
                title: newMeeting.meetingTitle,
                date: newMeeting.date,
                time: newMeeting.time
            }]);
        } catch (error) {
            console.error('Error creating meeting:', error);
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return d.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatTime = (time) => {
        if (!time) return '';
        return new Date(`2000/01/01 ${time}`).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const getFilteredMeetings = () => {
        if (showAllMeetings) return meetings;
        return meetings.filter(meeting => {
            const meetingDate = new Date(meeting.date);
            return meetingDate >= twoWeeksAgo;
        });
    };

    const displayedMeetings = getFilteredMeetings();
    const hiddenMeetingsCount = meetings.length - displayedMeetings.length;

    return (
        <div className="App">
            <NavbarAuth />
            <ProjectNavbar />
            <div className="container-fluid meetings-container">
                <div className="row">
                    <main className="main-content col-12 px-md-4">
                        <div className="meetings-header">
                            <h1 className = "shiny-text">Meeting Notes</h1>
                            <div className="meetings-actions">
                                <button 
                                    className="btn btn-outline-primary show-all-btn"
                                    onClick={() => setShowAllMeetings(!showAllMeetings)}
                                >
                                    {showAllMeetings ? 'Show Recent' : `Show All (${hiddenMeetingsCount} more)`}
                                </button>
                                <button className="btn btn-primary create-meeting-btn" onClick={createNewMeeting}>
                                    <FaPlus /> New Meeting
                                </button>
                            </div>
                        </div>
                        {!showAllMeetings && displayedMeetings.length < meetings.length && (
                            <div className="filter-notice">
                                Showing meetings from the last 2 weeks
                            </div>
                        )}
                        <div className="meetings-grid">
                            {displayedMeetings.map(meeting => (
                                <Link 
                                    key={meeting.id}
                                    to={`/projects/${projectId}/${meeting.id}`}
                                    className="meeting-card"
                                >
                                    <div className="meeting-card-content">
                                        <div className="meeting-icon">
                                            <FaFileAlt />
                                        </div>
                                        <h3 className="meeting-title">{meeting.title}</h3>
                                        <div className="meeting-details">
                                            <div className="meeting-date">
                                                <FaCalendarAlt /> {formatDate(meeting.date)}
                                            </div>
                                            <div className="meeting-time">
                                                <FaClock /> {formatTime(meeting.time)}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </main>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MeetingsListPage;
