import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faTh, faList } from '@fortawesome/free-solid-svg-icons';
import { 
    fetchEpics, 
    createEpic, 
    updateEpic, 
    deleteEpic,
    fetchComments,
    addComment,
    clearComments,
    updateComment,
    fetchTeamMembers,
    fetchAvailableEpics,
    fetchUserStories
} from '../../../redux/slices/agileHubSlice';

// Import utility functions
import { getUserDisplayName } from '../utils/epicUtils';

// Import components
import EpicCard from '../components/EpicCard';
import EpicListView from '../components/EpicListView';
import EpicFilters from '../components/EpicFilters';
import EpicDetailsPanel from '../components/EpicDetailsPanel';
import CreateEpicSlider from '../components/CreateEpicSlider';

import './Epics.css';  // Add this import

export const Epics = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { epics, userStories, loading, error, comments, commentsLoading, availableTeamMembers, availableEpics } = useSelector(state => state.agileHub);
    const { user } = useSelector(state => state.auth);
    
    // State for UI controls
    const [viewMode, setViewMode] = useState('grid');
    const [showPanel, setShowPanel] = useState(false);
    const [panelClosing, setPanelClosing] = useState(false);
    const [showCreateSlider, setShowCreateSlider] = useState(false);
    const [createSliderClosing, setCreateSliderClosing] = useState(false);
    
    // State for filtering and sorting
    const [searchTerm, setSearchTerm] = useState('');
    const [filterPriority, setFilterPriority] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [sortField, setSortField] = useState('createdAt');
    const [sortDirection, setSortDirection] = useState('desc');
    
    // State for the current epic being viewed/edited
    const [currentEpic, setCurrentEpic] = useState({
        title: '',
        description: '',
        priority: 'Medium',
        status: 'Not Started',
        assignee: '',
        successCriteria: [] // Initialize as empty array
    });
    
    // State for comments
    const [newComment, setNewComment] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedCommentContent, setEditedCommentContent] = useState('');
    const editCommentRef = useRef(null);
    const activeEpicComments = currentEpic._id ? comments[currentEpic._id] || [] : [];

    // Add new analytics state
    const [epicAnalytics, setEpicAnalytics] = useState({
        totalStoryPoints: 0,
        completionRate: 0,
        averageCycleTime: 0,
        priorityDistribution: { high: 0, medium: 0, low: 0 }
    });

    // Fetch epics and user stories on component mount
    useEffect(() => {
        dispatch(fetchEpics(projectId));
        dispatch(fetchUserStories(projectId));
        dispatch(fetchTeamMembers(projectId));
        dispatch(fetchAvailableEpics(projectId));
        
        // Clean up comments when unmounting
        return () => {
            dispatch(clearComments());
        };
    }, [dispatch, projectId]);

    // Set up sliding panel portal
    useEffect(() => {
        let panelRoot = document.getElementById('sliding-panel-root');
        if (!panelRoot) {
            panelRoot = document.createElement('div');
            panelRoot.id = 'sliding-panel-root';
            document.body.appendChild(panelRoot);
        }
        
        return () => {
            if (panelRoot && panelRoot.parentNode) {
                document.body.removeChild(panelRoot);
            }
        };
    }, []);

    // Calculate epic analytics
    useEffect(() => {
        if (epics && userStories) {
            const storiesByEpic = userStories.reduce((acc, story) => {
                if (story.epicId) {
                    if (!acc[story.epicId]) acc[story.epicId] = [];
                    acc[story.epicId].push(story);
                }
                return acc;
            }, {});

            const totalPoints = userStories.reduce((sum, story) => 
                sum + (parseInt(story.storyPoints) || 0), 0);

            const completedStories = userStories.filter(s => s.status?.toLowerCase() === 'done');
            const completionRate = (completedStories.length / userStories.length) * 100;

            const priorityCount = epics.reduce((acc, epic) => {
                const priority = epic.priority?.toLowerCase() || 'medium';
                acc[priority] = (acc[priority] || 0) + 1;
                return acc;
            }, { high: 0, medium: 0, low: 0 });

            setEpicAnalytics({
                totalStoryPoints: totalPoints,
                completionRate: Math.round(completionRate),
                priorityDistribution: priorityCount
            });
        }
    }, [epics, userStories]);

    // Filter and sort epics
    const filteredEpics = epics
        .filter(epic => 
            epic.title?.toLowerCase().includes(searchTerm.toLowerCase()) || 
            epic.description?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter(epic => filterPriority === 'All' || epic.priority === filterPriority)
        .filter(epic => filterStatus === 'All' || epic.status === filterStatus)
        .sort((a, b) => {
            let comparison = 0;
            if (sortField === 'title') {
                comparison = a.title?.localeCompare(b.title || '');
            } else if (sortField === 'priority') {
                const priorityOrder = { 'High': 1, 'Medium': 2, 'Low': 3 };
                comparison = (priorityOrder[a.priority] || 0) - (priorityOrder[b.priority] || 0);
            } else if (sortField === 'status') {
                const statusOrder = { 'Not Started': 1, 'In Progress': 2, 'Complete': 3, 'Blocked': 4 };
                comparison = (statusOrder[a.status] || 0) - (statusOrder[b.status] || 0);
            } else if (sortField === 'createdAt') {
                comparison = new Date(a.createdAt || 0) - new Date(b.createdAt || 0);
            }
            return sortDirection === 'asc' ? comparison : -comparison;
        });

    // Slider handlers
    const openCreateSlider = () => {
        setCurrentEpic({
            title: '',
            description: '',
            priority: 'Medium',
            status: 'Not Started',
            assignee: '',
            successCriteria: [] // Initialize as empty array
        });
        setShowCreateSlider(true);
        setCreateSliderClosing(false);
        document.body.style.overflow = 'hidden';
    };

    const closeCreateSlider = () => {
        setCreateSliderClosing(true);
        setTimeout(() => {
            setShowCreateSlider(false);
            setCreateSliderClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    const handleInputChange = (field, value) => {
        setCurrentEpic({...currentEpic, [field]: value});
    };

    const handleSubmit = async () => {
        try {
            await dispatch(createEpic({ projectId, epicData: currentEpic }));
            closeCreateSlider();
        } catch (error) {
            console.error('Error creating epic:', error);
        }
    };

    // Sliding panel handlers
    const openPanel = (epic) => {
        setCurrentEpic({...epic});
        setShowPanel(true);
        setPanelClosing(false);
        document.body.style.overflow = 'hidden';
        dispatch(fetchComments({ projectId, epicId: epic._id }));
    };

    const closePanel = () => {
        setPanelClosing(true);
        setTimeout(() => {
            setShowPanel(false);
            setPanelClosing(false);
            document.body.style.overflow = '';
        }, 400);
    };

    // Comment handlers
    const submitComment = async () => {
        if (!newComment.trim() || !currentEpic._id) return;
        
        await dispatch(addComment({
            projectId,
            epicId: currentEpic._id,
            content: newComment,
            author: getUserDisplayName(user)
        }));
        
        setNewComment('');
    };

    const startEditingComment = (comment) => {
        setEditingCommentId(comment._id);
        setEditedCommentContent(comment.content);
        
        setTimeout(() => {
            if (editCommentRef.current) {
                editCommentRef.current.focus();
                const textarea = editCommentRef.current;
                const length = textarea.value.length;
                textarea.setSelectionRange(length, length);
            }
        }, 50);
    };

    const cancelEditingComment = () => {
        setEditingCommentId(null);
        setEditedCommentContent('');
    };

    const saveEditedComment = async (commentId) => {
        if (!editedCommentContent.trim()) return;
        
        await dispatch(updateComment({
            projectId,
            epicId: currentEpic._id,
            commentId,
            content: editedCommentContent
        }));
        
        setEditingCommentId(null);
        setEditedCommentContent('');
    };

    const canEditComment = (commentAuthor) => {
        if (!user) return false;
        const currentUserName = getUserDisplayName(user).toLowerCase();
        const commentUserName = commentAuthor.toLowerCase();
        return currentUserName === commentUserName;
    };

    const handleDeleteEpic = async (epicId) => {
        if (window.confirm('Are you sure you want to delete this epic?')) {
            dispatch(deleteEpic({ projectId, epicId }));
        }
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const handleEpicUpdate = async (epicData) => {
        try {
            await dispatch(updateEpic({
                projectId,
                epicId: epicData.epicId,
                epicData: epicData.epicData
            }));
        } catch (error) {
            console.error('Error updating epic:', error);
        }
    };

    // Render loading and error states
    if (loading && epics.length === 0) {
        return (
            <div className="epics-container">
                <div className="d-flex justify-content-center p-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="epics-container">
                <div className="alert alert-danger">
                    Error loading epics: {error}
                </div>
            </div>
        );
    }

    return (
        <div className="epics-container">
            <div className="epics-header">
                <div className="header-content">
                    <h3>Epics</h3>
                    <div className="epic-metrics">
                        <div className="metric-card">
                            <div className="metric-title">Total Epics</div>
                            <div className="metric-value">{epics?.length || 0}</div>
                        </div>
                        <div className="metric-card">
                            <div className="metric-title">Story Points</div>
                            <div className="metric-value">{epicAnalytics.totalStoryPoints}</div>
                        </div>
                        <div className="metric-card">
                            <div className="metric-title">Completion Rate</div>
                            <div className="metric-value">{epicAnalytics.completionRate}%</div>
                        </div>
                    </div>
                </div>
                <button 
                    className="btn btn-primary"
                    onClick={openCreateSlider}
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    New Epic
                </button>
            </div>

            <div className="epics-toolbar">
                <div className="search-filters">
                    <div className="input-group">
                        <span className="input-group-text">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search epics..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <select 
                        className="form-select"
                        value={filterPriority}
                        onChange={(e) => setFilterPriority(e.target.value)}
                    >
                        <option value="All">All Priorities</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                    <select 
                        className="form-select"
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                    >
                        <option value="All">All Statuses</option>
                        <option value="Not Started">Not Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Complete">Complete</option>
                        <option value="Blocked">Blocked</option>
                    </select>
                </div>
                <div className="view-controls">
                    <button 
                        className={`btn btn-outline-secondary ${viewMode === 'grid' ? 'active' : ''}`}
                        onClick={() => setViewMode('grid')}
                    >
                        <FontAwesomeIcon icon={faTh} />
                    </button>
                    <button 
                        className={`btn btn-outline-secondary ${viewMode === 'list' ? 'active' : ''}`}
                        onClick={() => setViewMode('list')}
                    >
                        <FontAwesomeIcon icon={faList} />
                    </button>
                </div>
            </div>

            {viewMode === 'grid' ? (
                <div className="epics-grid">
                    {filteredEpics.length > 0 ? (
                        filteredEpics.map(epic => (
                            <EpicCard 
                                key={epic._id}
                                epic={epic}
                                onClick={openPanel}
                                stories={userStories}
                            />
                        ))
                    ) : (
                        <div className="text-center w-100 py-5">
                            <p className="text-muted">
                                {epics.length === 0 
                                    ? "No epics found. Create your first epic to get started!"
                                    : "No epics match your search criteria."
                                }
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <EpicListView 
                    epics={filteredEpics}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    handleSort={handleSort}
                    onEpicClick={openPanel}
                    stories={userStories}
                />
            )}
            
            {showCreateSlider && createPortal(
                <CreateEpicSlider 
                    showSlider={showCreateSlider}
                    sliderClosing={createSliderClosing}
                    closeSlider={closeCreateSlider}
                    currentEpic={currentEpic}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    availableTeamMembers={availableTeamMembers}
                    availableEpics={epics.filter(e => e._id !== currentEpic._id)}
                />,
                document.getElementById('sliding-panel-root')
            )}

            {showPanel && createPortal(
                <EpicDetailsPanel 
                    showPanel={showPanel}
                    panelClosing={panelClosing}
                    currentEpic={currentEpic}
                    closePanel={closePanel}
                    openEditModal={openCreateSlider}
                    handleDeleteEpic={handleDeleteEpic}
                    activeEpicComments={activeEpicComments}
                    commentsLoading={commentsLoading}
                    canEditComment={canEditComment}
                    startEditingComment={startEditingComment}
                    editingCommentId={editingCommentId}
                    editedCommentContent={editedCommentContent}
                    setEditedCommentContent={setEditedCommentContent}
                    cancelEditingComment={cancelEditingComment}
                    saveEditedComment={saveEditedComment}
                    editCommentRef={editCommentRef}
                    newComment={newComment}
                    setNewComment={setNewComment}
                    submitComment={submitComment}
                    updateEpic={handleEpicUpdate} // Add this line
                    availableEpics={epics.filter(e => e._id !== currentEpic._id)}
                />, 
                document.getElementById('sliding-panel-root')
            )}
        </div>
    );
};

export default Epics;