import React from "react";
import { Link } from "react-router-dom";
import "./ComponentStyling/Footer.css";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="footer-main" style={{ position: 'relative', zIndex: 1 }}>
            <div className="footer-content">
                <div className="footer-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms">Terms of Use</Link>
                    <Link to="/release-notes">Release Notes</Link>
                    <Link to="/wiki">Wiki</Link>
                </div>
                <p className="footer-copyright">
                    &copy; {year} PMHub.io | All Rights Reserved
                </p>
            </div>
        </footer>
    );
};

export default Footer;