import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './ComponentStyling/ReleaseNotes.css';
import { motion } from 'framer-motion';

const releases = [
    {
        version: "2.0.0",
        date: "March 2024",
        type: "Minor Release",
        changes: [
            "AI-powered summary dashboard",
            "Various bug fixes and performance improvements",
            "Security hardening",
            "Project state filtering in dashboard"
        ]
    },
    {
        version: "1.3.0",
        date: "March 2024",
        type: "Minor Release",
        changes: [
            "Added project notes",
            "Added AI-powered nueral network visualization for project notes",
            "Added payment form and plans",
            "Added billing section functionality"
        ]
    },
    {
        version: "1.2.0",
        date: "March 2024",
        type: "Minor Release",
        changes: [
            "Added comprehensive wiki for project managers",
            "Fixed bug related to meeting minutes",
            "Added user profile images",
            "Redesigned the account settings page",
            "Added last login timestamp tracking to user profile",
            "Added member-since date to user profile",
            "Added ability to change user email",
            "Added ability to change user password from account settings",
            "Added functionality to billing section of account settings",
        ]
    },
    {
        version: "1.1.0",
        date: "March 2024",
        type: "Minor Release",
        changes: [
            "Release notes page for versioning",
            "Added a suite of individualized project dashboard cards",
            "Added project linking to daily journal",
            "Fixed daily journal task modal sizing"
        ]
    },
    {
        version: "1.0.0",
        date: "March 2024",
        type: "Major Release",
        changes: [
            "Initial release of PMHub.io",
            "Project dashboard with overview and analytics",
            "RAID log functionality for risk and issue tracking",
            "Stakeholder register with contact management",
            "Daily journal for personal task management",
            "Tracker for meeting notes",
            "Kanban board for visual task management",
            "Project scheduler with timeline view",
            "WBS (Work Breakdown Structure) feature",
            "User authentication and account management"
        ]
    }
];

const ReleaseNotes = () => {
    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: "easeOut" }
        }
    };

    return (
        <div className="release-notes-container">
            <Navbar />
            <div className="release-notes-content">
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    className="release-notes-header"
                >
                    <h1>Release Notes</h1>
                    <p>Track our latest updates and improvements</p>
                </motion.div>

                <div className="releases-timeline">
                    {releases.map((release, index) => (
                        <motion.div
                            key={release.version}
                            className="release-item"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={fadeInUp}
                            transition={{ delay: index * 0.2 }}
                        >
                            <div className="release-header">
                                <div>
                                    <h2>Version {release.version}</h2>
                                    <span className="release-type">{release.type}</span>
                                </div>
                                <span className="release-date">{release.date}</span>
                            </div>
                            <ul className="changes-list">
                                {release.changes.map((change, i) => (
                                    <li key={i}>{change}</li>
                                ))}
                            </ul>
                        </motion.div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReleaseNotes;
