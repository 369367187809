import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArrowRight, faTasksAlt, faHistory, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { adjustDateForDisplay } from '../../utils/dashboardUtils';
import { Card } from 'react-bootstrap';
import { isFeatureAvailable } from '../../utils/featureUtils';
import { ACCOUNT_TYPES } from '../../config/featureAccess';
import { sanitizeText } from '../../utils/securityUtils';

const DetailedOverdueTasksCard = ({ overdueTasks }) => {
  const userProfile = useSelector(state => state.profile.data);
  const accountType = userProfile?.accountType;
  const isAdmin = userProfile?.isAdmin || false;

  // Ensure overdueTasks is an array to prevent mapping errors
  const tasks = Array.isArray(overdueTasks) ? overdueTasks : [];

  const canAccessFeature = isFeatureAvailable(
    'ADVANCED_ANALYTICS',
    accountType === 'Professional' ? ACCOUNT_TYPES.PROFESSIONAL : accountType,
    isAdmin
  );

  // Create a secure link URL
  const getSecureProjectLink = (projectId) => {
    if (typeof projectId === 'string' && projectId.trim() !== '') {
      return `/projects/${encodeURIComponent(projectId)}/raidlog`;
    }
    return '/projects';
  };

  if (!canAccessFeature) {
    return (
      <div className="dashboard-card half-width overdue-card">
        <div className="dashboard-card-header">
          <h2>Detailed Overdue Tasks</h2>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#dc3545' }} />
        </div>
        <div className="dashboard-card-body p-0">
          <div className="upgrade-container">
            <div className="upgrade-content">
              <FontAwesomeIcon 
                icon={faExclamationTriangle} 
                style={{ fontSize: '2.5rem', color: '#dc3545' }} 
                className="upgrade-icon mb-3"
              />
              <h5 className="fw-bold mb-3">Never Miss a Deadline</h5>
              <p className="text-muted mb-4">
                Get detailed tracking of overdue tasks, prioritized reminders, and automatic follow-up prompts with our
                Professional plan.
              </p>
              
              <Link to="/account" className="btn btn-outline-danger upgrade-btn fw-bold">
                Upgrade to Professional <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (tasks.length === 0) return null;
  
  return (
    <div className="dashboard-card half-width overdue-card">
      <div className="dashboard-card-header">
        <h2 className = "shiny-text">Overdue Tasks</h2>
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#dc3545' }} />
      </div>
      <div className="dashboard-card-body">
        <div style={{ overflowX: 'auto' }}>
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Action</th>
                <th>Due Date</th>
                <th>Owner</th>
                <th>Days Overdue</th>
                <th>Project</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, index) => {
                let daysOverdue = 0;
                try {
                  const dueDate = new Date(task.due);
                  const today = new Date();
                  daysOverdue = Math.floor((today - dueDate) / (1000 * 60 * 60 * 24));
                  if (isNaN(daysOverdue)) daysOverdue = 0;
                } catch (e) {
                  console.error("Error calculating days overdue:", e);
                  daysOverdue = 0;
                }
                
                return (
                  <tr key={index} className="overdue-task-row">
                    <td>
                      <Link to={getSecureProjectLink(task.projectId)} className="overdue-task-link">
                        {sanitizeText(task.action)}
                      </Link>
                    </td>
                    <td>{task.due ? adjustDateForDisplay(task.due) : 'No date'}</td>
                    <td>{sanitizeText(task.owner)}</td>
                    <td className="days-overdue">{daysOverdue} days</td>
                    <td>{sanitizeText(task.projectName)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DetailedOverdueTasksCard;
