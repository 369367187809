import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button, Form } from 'react-bootstrap';

const ImageEditor = ({ show, onHide, imageUrl, onSave }) => {
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        aspect: undefined
    });
    const [imageSize, setImageSize] = useState({ width: '100%', height: 'auto' });
    const imageRef = useRef(null);

    const getCroppedImageStyle = () => {
        if (!crop.width || !crop.height) return {};
        
        return {
            clipPath: `inset(${crop.y}% ${100 - (crop.x + crop.width)}% ${100 - (crop.y + crop.height)}% ${crop.x}%)`
        };
    };

    const handleSave = () => {
        const style = getCroppedImageStyle();
        const cropStyle = Object.keys(style).length 
            ? ` clip-path: ${style.clipPath};` 
            : '';

        const imgHtml = `<div style="text-align: center; margin: 1rem 0;"><img src="${imageUrl}" alt="Wiki image" style="max-width: ${imageSize.width}; height: ${imageSize.height};${cropStyle} display: block; margin: 0 auto;" /></div>`;
        onSave(imgHtml);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="image-editor-container">
                    <ReactCrop
                        crop={crop}
                        onChange={setCrop}
                        style={{ maxWidth: '100%' }}
                    >
                        <img
                            ref={imageRef}
                            src={imageUrl}
                            style={{ maxWidth: '100%', height: 'auto' }}
                            alt="Edit"
                        />
                    </ReactCrop>

                    <Form className="mt-3">
                        <Form.Group>
                            <Form.Label>Image Size</Form.Label>
                            <div className="d-flex gap-2">
                                <Form.Control
                                    type="text"
                                    value={imageSize.width}
                                    onChange={(e) => setImageSize(prev => ({ ...prev, width: e.target.value }))}
                                    placeholder="Width (e.g., 100%, 500px)"
                                />
                                <Form.Control
                                    type="text"
                                    value={imageSize.height}
                                    onChange={(e) => setImageSize(prev => ({ ...prev, height: e.target.value }))}
                                    placeholder="Height (e.g., auto, 300px)"
                                />
                            </div>
                            <Form.Text className="text-muted">
                                Use percentages (%) or pixels (px). Leave height as "auto" to maintain aspect ratio.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Insert Image</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageEditor;
