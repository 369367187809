import "./App.css";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Components/AuthContext";
import ProtectedRoute from "./Components/ProtectedRoute";
import Dashboard from "./Pages/Dashboard";
import Account from "./Pages/Account";
import Register from "./Components/Register";
import NewProject from "./Pages/NewProject";
import ProjectPage from "./Components/ProjectPage";
import NewStakeholderRegister from "./Components/NewStakeholderRegister";
import StakeholderRegister from "./Components/StakeholderRegister";
import TriColumnToDo from "./Components/TriColumnToDo";
import NewRAIDLog from "./Components/NewRAIDLog";
import RAIDLog from "./Components/RAIDLog";
import PasswordResetRequest from "./Pages/RequestPasswordReset";
import ResetPassword from "./Pages/ResetPassword";
import Kanban from "./Components/Kanban";
import DailyJournal from "./Components/DailyJournal";
import MeetingPage from "./Components/MeetingPage";
import ProjectSettings from "./Components/ProjectSettings";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";
import ProjectScheduler from "./Components/ProjectScheduler";
import MeetingsListPage from "./Components/MeetingsListPage";
import WBS from "./Components/WBS";
import ReleaseNotes from "./Components/ReleaseNotes";
import Wiki from "./Components/Wiki";
import WikiPage from "./Components/WikiPage"; // Import the new WikiPage component
import WikiEditor from './Components/WikiEditor';
import ProjectNotes from "./Components/ProjectNotes";
import AgileHub from "./Components/AgileHub/AgileHub";

function App() {
  return (
    <AuthProvider>
      <div className="container-fluid min-vh-100 d-flex flex-column">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path ="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path ="/terms" element={<TermsOfUse />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/account" element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          } />
          <Route path="/newProject" element={
            <ProtectedRoute>
              <NewProject />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId" element={
            <ProtectedRoute>
              <ProjectPage />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/newStakeholderRegister" element={
            <ProtectedRoute>
              <NewStakeholderRegister />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/stakeholders" element={
            <ProtectedRoute>
              <StakeholderRegister />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/newRAIDLog" element={
            <ProtectedRoute>
              <NewRAIDLog />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/raidlog" element={
            <ProtectedRoute>
              <RAIDLog />
            </ProtectedRoute>
          } />
          <Route path="/todo" element={
            <ProtectedRoute>
              <TriColumnToDo />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/kanban" element={
            <ProtectedRoute>
              <Kanban />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/settings" element={
            <ProtectedRoute>
              <ProjectSettings />
            </ProtectedRoute>
          } />
          <Route path="/:userId/dailyJournal" element={
            <ProtectedRoute>
              <DailyJournal />
            </ProtectedRoute>
          } />
          <Route path="/:userId/dailyJournal/:date" element={
            <ProtectedRoute>
              <DailyJournal />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/:meetingId" element={
            <ProtectedRoute>
              <MeetingPage />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/scheduler" element={
            <ProtectedRoute>
              <ProjectScheduler />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/meetings" element={
            <ProtectedRoute>
              <MeetingsListPage />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/wbs" element={
            <ProtectedRoute>
              <WBS />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/notes" element={
            <ProtectedRoute>
              <ProjectNotes />
            </ProtectedRoute>
          } />
          <Route path="/register" element={<Register />} />
          <Route path = "/requestPasswordReset" element = {<PasswordResetRequest /> } />
          <Route path = "/reset-password" element = {<ResetPassword /> } />
          <Route path = "/release-notes" element = {<ReleaseNotes/>} />
          <Route path="/wiki" element={<Wiki />} />
          <Route path="/wiki/:slug" element={<WikiPage />} /> {/* Add route for individual wiki pages */}
          <Route path="/wiki/new" element={
            <ProtectedRoute>
              <WikiEditor />
            </ProtectedRoute>
          } />
          <Route path="/wiki/edit/:slug" element={
            <ProtectedRoute>
              <WikiEditor />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/agilehub" element={
            <ProtectedRoute>
              <AgileHub />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;